import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import AddressBlock from '../components/AddressBlock';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import TopMenu from './Menus/TopMenu';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces

// CSS
import '../styles/InformeDetallado/InformeDetallado.css';

// Hooks
import {
  useViewport,
  useSelectedPatient,
  useSelectedQuote,
  useGetQuoteProducts,
  useUrlToReturnValue,
  useShowQuoteProductsError,
  useShowErrorPopup,
  useShowEditTreatmentsPopup,
  useUserAddress,
} from '../hooks';

// Images
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GreenCheck from '../img/greenCheck.png';
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import { useGetPatientDeals } from '../hooks/patients/use-get-patient-deals';
import { Button } from '../components';

interface IReportArticle {
  description?: string;
  id?: string;
  isSelected?: boolean;
  isShowingDescription: boolean;
  name?: string;
  price?: number;
}

const InformeDetallado = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, reportId } = useParams();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { selectedQuote, setSelectedQuote } = useSelectedQuote();
  const { getQuoteProducts } = useGetQuoteProducts();
  const { urlToReturn } = useUrlToReturnValue();
  const { getPatientDeals } = useGetPatientDeals();
  const { showQuoteProductsError } = useShowQuoteProductsError();
  const { showErrorPopup } = useShowErrorPopup();
  const { showEditTreatmentPopup } = useShowEditTreatmentsPopup();
  const { userAddress } = useUserAddress();

  /***********
   *  States *
   ***********/
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [quoteProducts, setQuoteProducts] = useState<IReportArticle[]>([]);
  const [total, setTotal] = useState<number>(0);
  const { viewportHeight, viewportWidth } = useViewport();

  /**********
   *  Hooks *
   **********/
  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patientId]);

  useEffect(() => {
    handleGetQuoteProducts();
  }, [selectedPatient]);

  useEffect(() => {
    setSelectedQuote(reportId as string);
  }, [reportId]);

  /*************
   *  Handlers *
   *************/
  const handleCheckToggle = (article: IReportArticle) => {
    if (isDisabledIfPayed()) return;
    article.isSelected = !article.isSelected;
    const resultArticles: IReportArticle[] = [];
    quoteProducts.forEach(item => {
      resultArticles.push(item.id === article.id ? article : item);
    });
    setQuoteProducts(resultArticles);
    calcAndSetTotal();
  };

  const handleCheckTreatments = () => {
    const control = quoteProducts.filter((item: IReportArticle) => !item.isSelected);
    if (control.length === quoteProducts.length) {
      showErrorPopup('errors.treatmentNotSelected');
      return;
    }
    if (control.length) {
      showEditTreatmentPopup();
      return;
    }
    if (
      userAddress.Ciudad === '' ||
      userAddress.Codigo_postal === '' ||
      userAddress.Direcci_n === '' ||
      userAddress.Pa_s === ''
    ) {
      showErrorPopup('errors.addressesNotFound');
      return;
    }
    handleModalContinueButton();
  };

  const handleModalContinueButton = () => {
    // If deal has Consentimientos, don't go to sign
    if (selectedPatient.dealDetails.Archivo_Consentimientos?.length) {
      navigate(`/informeDetallado/${patientId}/${reportId}/paymentmethod`);
    } else {
      navigate(`/informeDetallado/${patientId}/${reportId}/pago`);
    }
  };

  const handleShowDetails = (article: IReportArticle) => {
    article.isShowingDescription = !article.isShowingDescription;
    const resultArticles: IReportArticle[] = [];

    quoteProducts.forEach(item => {
      if (item.id === article.id) {
        resultArticles.push(article);
      } else {
        resultArticles.push(item);
      }
    });
    setQuoteProducts(resultArticles);
  };

  /***********
   * Helpers *
   ***********/
  const calcAndSetTotal = (newArticles?: IReportArticle[]) => {
    const articles: IReportArticle[] | undefined = quoteProducts.length
      ? quoteProducts
      : newArticles;
    let total = 0;
    if (articles?.length) {
      articles.map((article: IReportArticle) => {
        if (article.isSelected && article.price) total += article.price;
      });
    }
    setTotal(total);
    window.localStorage.setItem(
      'tot',
      total.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }),
    );
  };

  const handleGetQuoteProducts = () => {
    if (selectedQuote == null) return;

    getQuoteProducts(
      { quoteId: selectedQuote.id },
      {
        onSuccess: quotes => {
          const hideDescriptionQuotes = quotes.map(quote => ({
            ...quote,
            isShowingDescription: false,
          }));
          setQuoteProducts(hideDescriptionQuotes);
          calcAndSetTotal(hideDescriptionQuotes);
          setIsLoading(false);
        },
        onError: error => {
          showQuoteProductsError(error.message);
        },
      },
    );
  };

  const handleClickContinue = () => {
    getPatientDeals(
      {
        patientId: selectedPatient.id,
      },
      {
        onSuccess: data => {
          const deal = data[selectedPatient.id][0];
          if (deal.Payment_In_Process || deal.Pago_Inicial) return;

          handleCheckTreatments();
        },
      },
    );
  };

  const isDisabledIfPayed = () =>
    selectedPatient.dealDetails.Payment_In_Process || selectedPatient.dealDetails.Pago_Inicial;

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const isScreenTiny = viewportWidth < 321;

  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.mainTitle'))}:`;

  const addresBlock = (
    <AddressBlock
      isScreenBig={isScreenBig}
      title={GlobalService.uppercaseFirstLetter(t('pages.tienda.billingAddress'))}
    />
  );

  const formattedDetails = quoteProducts.map((article: IReportArticle, index: number) => (
    <div key={index} className={`p-2 ${index !== 0 ? 'border-t-2' : ''}`}>
      <div className='flex justify-between'>
        <div className='flex gap-2'>
          <div
            className='relative w-5 h-5 border-2 rounded border-black cursor-pointer'
            onClick={() => {
              handleCheckToggle(article);
            }}
          >
            {article.isSelected ? (
              <>
                <div className='absolute w-1.5 h-1.5 bg-white checkbox-white-properties'></div>
                <img src={GreenCheck} alt='Check' className='absolute checkbox-check-properties' />
              </>
            ) : null}
          </div>

          <p>
            <strong>{article.name}</strong>
          </p>
        </div>

        <div className='flex flex-row items-center gap-4'>
          <p>{article.price}€</p>
          {article.isShowingDescription && article.description?.length ? (
            <FontAwesomeIcon
              className='cursor-pointer'
              icon={faAngleDown}
              onClick={() => handleShowDetails(article)}
            />
          ) : !article.isShowingDescription && article.description?.length ? (
            <FontAwesomeIcon
              className='cursor-pointer'
              icon={faAngleUp}
              onClick={() => handleShowDetails(article)}
            />
          ) : null}
        </div>
      </div>
      {article.isShowingDescription ? (
        <div className='py-3'>
          <p className='text-xs text-slate-400'>{article.description}</p>
        </div>
      ) : null}
    </div>
  ));

  const buttonLine = (
    <div
      className={`flex justify-center 3xs:mt-10 3xs:mb-8 3xs:ml-4 3xs:mr-4 md:mt-4 md:mb-0 ${
        isScreenTiny ? 'ml-2 mr-2' : ''
      }`}
    >
      <div
        className={`flex ${
          isScreenTiny ? 'flex-col' : 'justify-between gap-2'
        } pago-resumen-buttons-container`}
      >
        <Button type='error' onClick={() => navigate(urlToReturn)} fullWidth={true}>
          {GlobalService.uppercaseFirstLetter(t('cancel'))}
        </Button>

        <Button
          type='primary'
          onClick={handleClickContinue}
          disabled={isDisabledIfPayed()}
          fullWidth={true}
        >
          {isDisabledIfPayed()
            ? GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.presupuestoAceptado'))
            : GlobalService.uppercaseFirstLetter(t('continue'))}
        </Button>
      </div>
    </div>
  );

  const text1 = (
    <p
      className={`${isScreenBig ? 'my-auto border-none' : 'my-auto border-none text-sm'} ${
        !isScreenTiny ? 'text-center' : ''
      }`}
    >
      <strong>{GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.text1'))}</strong>
    </p>
  );

  const totalLine = (
    <div className='flex justify-between mt-4'>
      <p>
        <strong>{t('total').toUpperCase()}</strong>
      </p>
      <p>
        <strong>{total}€</strong>
      </p>
    </div>
  );

  // Desktop values
  const desktopGoBackArrow = (
    <div className='my-auto'>
      <img
        onClick={() => {
          navigate(urlToReturn);
        }}
        src={LeftArrow}
        alt='Back'
        className='Notificaciones-Arrow cursor-pointer my-auto'
      />
    </div>
  );

  // Responsive values
  const responsivePageTitle = GlobalService.uppercaseFirstLetter(
    t('pages.reports.responsiveMainTitle'),
  );

  return (
    <div
      className={`w-full ${isScreenBig ? '' : 'bg-white h-full'}`}
      style={{ minHeight: viewportHeight }}
    >
      {isScreenBig ? (
        <>
          <TopMenu title={pageTitle} user={selectedPatient.title} />
          <div className='w-full p-8'>
            <div className='flex text-left gap-x-4 pb-6 relative'>{desktopGoBackArrow}</div>
            <div className='informeDetallado-height grid grid-cols-1 gap-y-3 w-full'>
              {isLoading ? (
                <RegularSpinner />
              ) : (
                <>
                  <div className='w-full bg-white lg:w-[596px] mx-auto pago-resumen-container p-4'>
                    <div>{formattedDetails}</div>

                    <div className='border-t-2 mt-40'>
                      {totalLine}
                      {/* {dueLine} */}
                    </div>
                  </div>
                  <div className='w-full lg:w-[596px] mx-auto'>{addresBlock}</div>
                  <div className='w-full lg:w-[596px] mx-auto'>{text1}</div>
                  {buttonLine}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title={responsivePageTitle}
            leftIcon={LeftArrowSmall}
            leftIconLink={`/informes/${patientId}`}
          ></TopMenu>
          <div
            className='mt-[80px] md:mt-0 3xs:mx-2 md:ml-10 md:mr-2 md:w-full'
            style={{ height: viewportHeight - 325 + 'px', overflow: 'scroll' }}
          >
            <div className='flex flex-col gap-y-4 w-full px-2.5 h-full bg-white mt-4'>
              {isLoading ? (
                <RegularSpinner />
              ) : (
                <>
                  <div className='pago-resumen-container-responsive pago-resumen-responsive p-4'>
                    <div>{formattedDetails}</div>
                    <div className='border-t-2 mt-4'>{totalLine}</div>
                  </div>
                  <div className='w-full'>{addresBlock}</div>
                </>
              )}
            </div>
          </div>
          <div className={!isScreenBig ? 'button-container-tiny-screen' : ''}>
            <div className='m-4'>{text1}</div>
            {buttonLine}
          </div>
        </>
      )}
    </div>
  );
};

export default InformeDetallado;
