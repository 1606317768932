import { useAtomValue } from 'jotai';
import { downloadDealFileAtom } from '../../../store/global/atoms';

export const useDownloadDealFile = () => {
  const getDeal = useAtomValue(downloadDealFileAtom);

  return {
    downloadDealFile: getDeal.mutate,
    isLoading: getDeal.isPending,
    error: getDeal.error,
  };
};
