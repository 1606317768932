import { personasRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';
import { personasServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';
import { MarkServiceAsPaidParams } from '../../../ts';

export const markServiceAsPaidService = async ({
  serviceId,
  subject,
  paidAmount,
}: MarkServiceAsPaidParams) => {
  const response = await personasServices(personasRepositoryInstance).updateServiceAfterPayment(
    serviceId,
    subject,
    paidAmount,
  );

  if (typeof response === 'string') throw new Error(response);

  return response;
};
