import { PatientInfo } from '../../ts';

export const defaultSelectedPatient: PatientInfo = {
  dealDetails: {
    Archivo_Consentimientos: [],
    Autorizaciones_Firmadas: false,
    Deal_Name: '',
    id: '',
    Patomedi: false,
    Signed_SEPA: false,
    Created_Time: 0,
    Modified_Time: 0,
  },
  id: '0',
  image: '',
  title: '',
  residence: '',
  email: '',
  eternalReviewAuth: false,
  birthDate: null,
};
