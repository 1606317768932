import React from 'react';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import Card from '../../components/Cards/Card';

const elem = `interface Props {
    id: string;
    backgroundColor: string;
    borderRadius: string;
    borderShadow?: string;
    border?: string;
    padding: string;
    topImage?: string;
    width: number;
    action: () => void;
    children: ReactNode;
}
const Card: React.FC<Props> = ({ id, borderRadius, topImage, width, action, children, backgroundColor, padding, borderShadow, border }) => {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [viewportWidth]);

    const parentStyle: CSSProperties = {
        width: viewportWidth <= 1024 ? '100%' : width,
        height: 'auto',
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        padding: topImage ? '0' : padding,
        boxShadow: borderShadow ? borderShadow : 'none',
        border: border ? border : 'none'
    };

    return (
        <div style={parentStyle} key={id} className='flex flex-col' onClick={() => action}>
            <div>
                {topImage ? (
                    <div className='card-image'>
                        <img src={topImage} width='100%' alt='card image' />
                    </div>
                ) : null}
            </div>
            <div className='card-content' style={{ padding: topImage ? padding : '0' }}>
                {children}
            </div>
        </div>
    );
};
`;
const elem2 = `
    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [viewportWidth]);

`;

const elem3 = `
<div style={parentStyle} key={id} className='flex flex-col' onClick={() => action}>
    <div>
        {topImage ? (
            <div className='card-image'>
                <img src={topImage} width='100%' alt='card image' />
            </div>
        ) : null}
    </div>
    <div className='card-content' style={{ padding: topImage ? padding : '0' }}>
        {children}
    </div>
</div>`;

const imageExample = `
    <Card
        id='1'
        topImage='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZpPDnjQBynSxEHYXo-orWYtvNybtifvhMUdsVjEhuOFpkDYPshISj_TpITVzJullxW4&usqp=CAU'
        width={300}
        padding='3rem'
        borderRadius='0px'
        backgroundColor='white'
        borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
        action={() => console.log('Card clicked!')}
    >
        <p className='text-lg mt-4'>Nombre y Apellidos</p>
        <p className='text-lg mt-4'>Posición en la empresa</p>
    </Card>`;
const textExample = `
    <Card
        id='4'
        width={250}
        padding='2rem'
        borderRadius='10px'
        backgroundColor='lightgrey'
        borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
        border='1px solid grey'
        action={() => console.log('Card clicked!')}
    >
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores magni delectus nam minima. Repellat autem ipsum
            doloremque aut, expedita alias rerum id quis ea saepe illum quas, dolorem fuga. Optio!
        </p>
    </Card>`;

const Cards = () => {
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Card</div>
            <div className='text-lg my-10'>
                A card component is a user interface element typically used to group related content in a visually appealing way. It can
                contain various types of content such as text, images, links, or buttons and is often used to display content in a grid or
                list layout.
            </div>

            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='my-10'>
                This card component is designed to be responsive, using the viewport width to determine whether to display the card at full
                width or the specified width. You can test it here.
            </div>
            <CopyBlock language={'jsx'} text={elem2} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='my-10'>
                There is conditional rendering based on whether the topImage prop is passed. If the topImage prop is present, the card will
                render an image at the top of the component with no padding. If the topImage prop is not present, the padding prop will be
                used to add padding to the content inside the card. This is related to the structure of the design we may use.
            </div>
            <CopyBlock language={'jsx'} text={elem3} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />

            <h2 className='my-10 text-xl'>Some examples of usage</h2>
            <div className='flex flex-wrap lg:flex-row md:flex-col sm:flex-col gap-6'>
                <Card
                    id='1'
                    topImage='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZpPDnjQBynSxEHYXo-orWYtvNybtifvhMUdsVjEhuOFpkDYPshISj_TpITVzJullxW4&usqp=CAU'
                    width={300}
                    padding='3rem'
                    borderRadius='0px'
                    backgroundColor='white'
                    borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                    action={() => console.log('Card clicked!')}
                >
                    <p className='text-lg mt-4'>Nombre y Apellidos</p>
                    <p className='text-lg mt-4'>Posición en la empresa</p>
                </Card>
                <Card
                    id='2'
                    topImage='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZpPDnjQBynSxEHYXo-orWYtvNybtifvhMUdsVjEhuOFpkDYPshISj_TpITVzJullxW4&usqp=CAU'
                    width={230}
                    padding='2rem'
                    borderRadius='0px'
                    backgroundColor='lightgrey'
                    action={() => console.log('Card clicked!')}
                >
                    <p className='text-lg mt-4'>Nombre y Apellidos</p>
                    <p className='text-lg mt-4'>Posición en la empresa</p>
                </Card>

                <Card
                    id='3'
                    topImage='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZpPDnjQBynSxEHYXo-orWYtvNybtifvhMUdsVjEhuOFpkDYPshISj_TpITVzJullxW4&usqp=CAU'
                    width={250}
                    padding='3rem'
                    borderRadius='20px'
                    backgroundColor='whitesmoke'
                    action={() => console.log('Card clicked!')}
                >
                    <p className='text-lg mt-4'>Nombre y Apellidos</p>
                    <p className='text-lg mt-4'>Posición en la empresa</p>
                </Card>

                <Card
                    id='4'
                    width={250}
                    padding='2rem'
                    borderRadius='10px'
                    backgroundColor='lightgrey'
                    borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                    border='1px solid grey'
                    action={() => console.log('Card clicked!')}
                >
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores magni delectus nam minima. Repellat autem ipsum
                        doloremque aut, expedita alias rerum id quis ea saepe illum quas, dolorem fuga. Optio!
                    </p>
                </Card>
                <Card
                    id='5'
                    width={300}
                    padding='2rem'
                    borderRadius='10px'
                    backgroundColor='beige'
                    action={() => console.log('Card clicked!')}
                >
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores magni delectus nam minima. Repellat autem ipsum
                        doloremque aut, expedita alias rerum id quis ea saepe illum quas, dolorem fuga. Optio!Lorem ipsum dolor sit amet
                        consectetur adipisicing elit. Maiores magni delectus nam minima. Repellat autem ipsum doloremque aut, expedita alias
                        rerum id quis ea saepe illum quas, dolorem fuga. Optio!
                    </p>
                </Card>
            </div>
            <h2 className='my-10 text-xl'>Example of image card</h2>
            <CopyBlock language={'jsx'} text={imageExample} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />

            <h2 className='my-10 text-xl'>Example of text card</h2>
            <CopyBlock language={'jsx'} text={textExample} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
        </div>
    );
};

export default Cards;
