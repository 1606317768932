import { useState } from 'react';
import { Attachment, DocsObject } from '../../ts';
import {
  useDeletePatomedi,
  useDownloadDealFile,
  useDownloadPatientAttachedFile,
  useSelectedPatientValue,
} from '../patients';
import { PATIENTS_ATTACHED_FILES } from '../../consts';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { useUploadPatomedi } from './use-upload-patomedi';
import {
  useShowDocumentDownloadOkPopup,
  useShowEternalReviewAuthPopup,
  useShowFileDownloadError,
  useShowUploadPatomediPopups,
} from '../popup';

export const useDocumentCardsObject = (isLoading: boolean) => {
  const { t } = useTranslation();
  const { selectedPatient } = useSelectedPatientValue();
  const { downloadPatientAttachedFile } = useDownloadPatientAttachedFile();
  const { downloadDealFile, isLoading: isDownloadDealFileLoading } = useDownloadDealFile();
  const { isLoading: isUploadingPatomedi } = useUploadPatomedi();
  const { isLoading: isDeletingPatomedi } = useDeletePatomedi();
  const { showUploadPatomediPopups, showReplacePatomediPopups } = useShowUploadPatomediPopups();
  const { showEternalReviewAuthPopup } = useShowEternalReviewAuthPopup();
  const { showDocumentDownloadOkPopup } = useShowDocumentDownloadOkPopup();
  const { showFileDownloadError } = useShowFileDownloadError();

  const [isDownloadingEternalReviewAuthDoc, setIsDownloadingEternalReviewAuthDoc] = useState(false);
  const [isDownloadingPatomedi, setIsDownloadingPatomedi] = useState(false);
  const [isDownloadingSepaFile, setIsDownloadingSepaFile] = useState(false);
  const [isDownloadingConsent, setIsDownloadingConsent] = useState(false);

  const downloadFile = (field: string, setIsDownloadingState?: (val: boolean) => void) => {
    if (isDownloadDealFileLoading) return;

    setIsDownloadingState && setIsDownloadingState(true);

    // Get the data
    const file =
      selectedPatient.dealDetails[
        field as 'SEPA_File' | 'Archivo_Consentimientos' | 'Archivo_Patomedi'
      ];
    let fileId = '';
    let fileName = '';
    if (file?.length) {
      fileId = file[0].id;
      fileName = file[0].File_Name__s;
    }
    if (!fileId || !fileName) return;

    downloadDealFile(
      { moduleEntryId: selectedPatient.dealDetails.id, fileId, fileName },
      {
        onSuccess: () => {
          showDocumentDownloadOkPopup();
        },
        onSettled: () => setIsDownloadingState && setIsDownloadingState(false),
        onError: () => showFileDownloadError(),
      },
    );
  };

  const downloadEternalReviewAuthDoc = (eternalReviewAuthDoc: Attachment | undefined) => {
    if (eternalReviewAuthDoc == null) return;
    setIsDownloadingEternalReviewAuthDoc(true);
    downloadPatientAttachedFile(
      {
        moduleEntryId: selectedPatient.id,
        fileId: eternalReviewAuthDoc.id,
        fileName: eternalReviewAuthDoc.fileName,
      },
      {
        onSuccess: () => showDocumentDownloadOkPopup(),
        onSettled: () => setIsDownloadingEternalReviewAuthDoc(false),
        onError: () => showFileDownloadError(),
      },
    );
  };

  const eternalReviewAuthDoc = selectedPatient.attachments?.find(
    doc => doc.fileName === PATIENTS_ATTACHED_FILES.ETERNAL_REVIEW_AUTH,
  );

  const docsObj: DocsObject = {
    eternalReviewAuth: {
      documentCard: {
        title: 'pages.documentos.freeAuthorization',
        icons: [
          {
            image: selectedPatient.eternalReviewAuth ? 'download' : 'add',
            title: GlobalService.uppercaseFirstLetter(t('pages.documentos.addDocumentLabel')),
            onClick: selectedPatient.eternalReviewAuth
              ? () => downloadEternalReviewAuthDoc(eternalReviewAuthDoc)
              : showEternalReviewAuthPopup,
          },
        ],
        notification: !selectedPatient.eternalReviewAuth,
        isLoading: isLoading || isDownloadingEternalReviewAuthDoc,
      },
    },
    patomedi: {
      documentCard: {
        title: 'pages.documentos.medPathologyReport',
        icons: selectedPatient.dealDetails.Patomedi
          ? [
              {
                image: 'download',
                title: GlobalService.uppercaseFirstLetter(t('pages.documentos.downloadDocLabel')),
                onClick: () => downloadFile('Archivo_Patomedi', setIsDownloadingPatomedi),
              },
              {
                image: 'add',
                title:
                  GlobalService.uppercaseFirstLetter(t('pages.documentos.replaceDocLabel')) + 1,
                onClick: showReplacePatomediPopups,
              },
            ]
          : [
              {
                image: 'add',
                title:
                  GlobalService.uppercaseFirstLetter(t('pages.documentos.replaceDocLabel')) + 1,
                onClick: showUploadPatomediPopups,
              },
            ],

        notification: !selectedPatient.dealDetails.Patomedi,
        isLoading: isLoading || isDownloadingPatomedi || isUploadingPatomedi || isDeletingPatomedi,
      },
    },
    sepaFile: {
      documentCard: {
        title: 'pages.documentos.sepa',
        icons: [
          {
            image: 'download',
            title: GlobalService.uppercaseFirstLetter(t('pages.documentos.downloadDocLabel')),
            onClick: () => downloadFile('SEPA_File', setIsDownloadingSepaFile),
          },
        ],
        isLoading: isLoading || isDownloadingSepaFile,
        notification: false,
      },
      hidden: !selectedPatient.dealDetails.SEPA_File,
    },
    consentsFile: {
      documentCard: {
        title: 'pages.documentos.consentForTreatment',
        icons: [
          {
            image: 'download',
            title: GlobalService.uppercaseFirstLetter(t('pages.documentos.downloadDocLabel')),
            onClick: () => downloadFile('Archivo_Consentimientos', setIsDownloadingConsent),
          },
        ],
        notification: !selectedPatient.dealDetails.Archivo_Consentimientos,
        isLoading: isLoading || isDownloadingConsent,
      },
      hidden: !selectedPatient.dealDetails.Archivo_Consentimientos,
    },
  };

  return { documentos: docsObj };
};
