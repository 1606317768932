import { TutorInfo, UserInfo } from '../../ts';

export const convertUserToTutor = (user: UserInfo): TutorInfo => {
  const value: TutorInfo = {
    id: user.id,
    dni: user.dni,
    email: user.email,
    name: user.name,
    phone: user.phone,
  };
  return value;
};
