import { QUERY_KEYS } from '../../../../../consts';
import { markServiceAsPaidService } from '../../../../../services';
import { getAtomWithMutation } from '../../../../../utils';
import { getAllPatientsAtom } from '../getters';

export const markServiceAsPaidAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.PATIENTS.MARK_SERVICE_AS_PAID,
  mutationFn: () => markServiceAsPaidService,
  onSuccess: get => () => {
    const getAllPatients = get(getAllPatientsAtom);
    getAllPatients.mutate();
  },
});
