import { IdToPatientExtraInfo, PatientInfo, PatientVisit } from '../../ts';

export const assignVisitsToPatients = (
  patientsList: PatientInfo[],
  visits: IdToPatientExtraInfo<PatientVisit>
) => {
  const newPatientsList: PatientInfo[] = [];
  for (const patient of patientsList) {
    const { id } = patient;
    const patientVisits = visits[id] || [];

    if (patientVisits.length === 0) {
      const newPatient: PatientInfo = { ...patient, pendingVisits: false };
      newPatientsList.push(newPatient);
      continue;
    }

    const parsedPatientVisits = patientVisits
      .sort((a, b) => {
        return new Date(a.Fecha_de_la_visita) > new Date(b.Fecha_de_la_visita) ? 1 : -1;
      })
      .map(visit => {
        const visitDate = new Date(visit.Fecha_de_la_visita);
        const day = visitDate.getDate();
        const month = (visitDate.getMonth() + 1).toString().padStart(2, '0');
        const year = visitDate.getFullYear();
        const date = `${day}/${month}/${year}`;
        const treatment = Array.isArray(visit.Tratamiento) ? visit.Tratamiento.join(', ') : '';
        const visitState = Array.isArray(visit.Estado_de_la_visita)
          ? visit.Estado_de_la_visita.join(', ')
          : '';

        return {
          ...visit,
          Fecha_de_la_visita: date,
          Tratamiento: treatment,
          Estado_de_la_visita: visitState
        };
      });

    const pendingVisits = parsedPatientVisits.some(
      visit =>
        visit.Estado_de_la_visita.toLocaleLowerCase().includes('visita pendiente') &&
        new Date(visit.Fecha_de_la_visita_raw) <= new Date()
    );

    const newPatient: PatientInfo = {
      ...patient,
      visitsDetails: [...parsedPatientVisits],
      pendingVisits
    };

    newPatientsList.push(newPatient);
  }

  return newPatientsList;
};
