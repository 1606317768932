import { settingsRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/settingsRepository';
import { settingsServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/settings.services';
import { Setting } from '../../ts';

export const getSettingsService = () => async () => {
  const response: Setting[] | string = await settingsServices(
    settingsRepositoryInstance
  ).getLinks();
  if (typeof response === 'string') throw new Error('settingNotFound');
  return response;
};
