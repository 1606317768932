import React, { CSSProperties } from 'react';
import PulsatingLoader from '../Loaders/PulsatingLoader';
import { Row } from 'src/hexagonal-architecture-frontend-base/src/domain/models/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

interface Props {
    columns: Row[] | number;
    borderRow?: string;
    padding?: string;
    textAlignTable?: CSSProperties['textAlign'] | undefined;
    backgroundRow?: string;
    textPrimary?: string;
    handleSort: any;
    sortOrder?: number;
    paramSort?: string;
}

const TableHeader: React.FC<Props> = ({
    columns,
    borderRow,
    padding,
    textAlignTable,
    backgroundRow,
    textPrimary,
    handleSort,
    sortOrder,
    paramSort
}) => {
    const headerStyle: CSSProperties = {
        borderBottom: borderRow ? borderRow : 'transparent',
        padding: padding ? padding : 'Opx',
        textAlign: textAlignTable ? textAlignTable : 'unset',
        backgroundColor: backgroundRow ? backgroundRow : 'transparent',
        color: textPrimary ? textPrimary : 'black',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    };
    return (
        <thead>
            <tr>
                {typeof columns !== 'number'
                    ? columns.map((column, index) => (
                          <th
                              style={headerStyle}
                              key={index}
                              className=''
                              onClick={sortOrder ? () => handleSort(column, sortOrder) : undefined}
                          >
                              <div className='flex'>
                                  {column.title}
                                  {sortOrder && column.sortable ? (
                                      paramSort === column.name ? (
                                          sortOrder === 2 ? (
                                              <FontAwesomeIcon
                                                  icon={faAngleUp}
                                                  className='opacity-50 pl-3 cursor-auto hover:cursor-pointer'
                                              />
                                          ) : (
                                              <FontAwesomeIcon
                                                  icon={faAngleDown}
                                                  className='opacity-50 pl-3 cursor-auto hover:cursor-pointer mt-2'
                                              />
                                          )
                                      ) : (
                                          <div className='flex flex-col  cursor-auto hover:cursor-pointer'>
                                              <FontAwesomeIcon icon={faAngleUp} className='opacity-50 pl-3 fa-sm ' />
                                              <FontAwesomeIcon icon={faAngleDown} className='opacity-50 pl-3 fa-sm' />
                                          </div>
                                      )
                                  ) : null}
                              </div>
                          </th>
                      ))
                    : Array.from({ length: columns }, (_, i) => (
                          <th style={headerStyle} key={i}>
                              {i === 0 ? (
                                  <PulsatingLoader minWidth={20} maxWidth={30} background='#C6CBD2' />
                              ) : (
                                  <PulsatingLoader maxWidth={150} background='#C6CBD2' />
                              )}
                          </th>
                      ))}
            </tr>
        </thead>
    );
};

export default TableHeader;
