import React from 'react';
import PrivateRoutes from './routes/PrivateRoutes';
import PublicRoutes from './routes/PublicRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import AllPatients from './views/AllPatients';
import Avatars from './views/examples/Avatars';
import Blog from './views/Blog';
import Buttons from './views/examples/Buttons';
import Cards from './views/examples/Cards';
import Carrito from './views/Carrito';
import ChangePassword from './views/ChangePassword';
import Checkboxes from './views/examples/Checkboxes';
import ClientEditForm from './views/examples/ClientEditForm';
import ClientForm from './views/examples/ClientForm';
import Dates from './views/examples/Dates';
import DatosPersonaDocumentos from './views/DatosPersonaDocumentos';
import DatosPersonaYServicio from './views/DatosPersonaYServicio';
import Doc from './views/examples/Doc';
import Documentos from './views/Documentos';
import Facturas from './views/Facturas';
import FallBacks from './views/examples/FallBacks';
import ForgotPassword from './views/ForgotPassword';
import Forms from './views/examples/Forms';
import Help from './views/Help';
import Images from './views/examples/Images';
import InformeDetallado from './views/InformeDetallado';
import Informes from './views/informes/Informes';
import Inputs from './views/examples/Inputs';
import Loaders from './views/examples/Loader';
import Login from './views/login/Login';
import Modals from './views/examples/Modals';
import Navigation from './views/examples/Navigation';
import Notificaciones from './views/Notificaciones';
import PaginationTest from './views/examples/Pagination';
import Pagos from './views/Pagos';
import Pedidos from './views/Pedidos';
// import Personas from './views/Personas';
import Producto from './views/Producto';
import Radios from './views/examples/Radios';
import ResetPassword from './views/ResetPassword';
import Servicios from './views/Servicios';
import Signup from './views/signup/Signup';
import SolicitudVisitaServicio from './views/SolicitudVisitaServicio';
import StarRatings from './views/examples/StarRatings';
import Subscripciones from './views/Subscripciones';
import Tables from './views/examples/Tables';
import TabsContent from './views/examples/Tabs';
import Tags from './views/examples/Tags';
import Tienda from './views/Tienda';
import Times from './views/examples/Times';
import TitleTest from './views/examples/Title';
import Toggles from './views/examples/Toggles';
import VerifyEmail from './views/VerifyEmail';
import Visitas from './views/Visitas';
import Pago from './views/Pago';
import Signature from './views/Signature';
import PaymentMethod from './views/PaymentMethod';
import { Profile } from './views/profile/profile';
import { useSession } from './hooks';
import { MainLayout } from './components/layouts';

function App() {
  const { isUserLogged } = useSession();

  return (
    <MainLayout>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route
            path='/'
            element={isUserLogged ? <Navigate to='/user/blog' replace /> : <Blog />}
          />
          <Route path='/avatars' element={<Avatars />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/buttons' element={<Buttons />} />
          <Route path='/cards' element={<Cards />} />
          <Route path='/changePassword' element={<ChangePassword />} />
          <Route path='/checkboxes' element={<Checkboxes />} />
          <Route path='/dates' element={<Dates />} />
          <Route path='/doc' element={<Doc />} />
          <Route path='/fallBacks' element={<FallBacks />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path='/forms' element={<Forms />} />
          <Route path='/formsClientEdit' element={<ClientEditForm />} />
          <Route path='/formsClientRegister' element={<ClientForm />} />
          <Route path='/help' element={<Help />} />
          <Route path='/images' element={<Images />} />
          <Route path='/inputs' element={<Inputs />} />
          <Route path='/loaders' element={<Loaders />} />
          <Route path='/login' element={<Login />} />
          <Route path='/modals' element={<Modals />} />
          <Route path='/navbar' element={<Navigation />} />
          <Route path='/pagination' element={<PaginationTest />} />
          <Route path='/radios' element={<Radios />} />
          <Route path='/resetPassword/:token' element={<ResetPassword />} />
          <Route path='/starRatings' element={<StarRatings />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/tables' element={<Tables />} />
          <Route path='/tabs' element={<TabsContent />} />
          <Route path='/tags' element={<Tags />} />
          <Route path='/times' element={<Times />} />
          <Route path='/title' element={<TitleTest />} />
          <Route path='/toggles' element={<Toggles />} />
          <Route path='/users/:userId/:verify' element={<VerifyEmail />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path='/'
            element={isUserLogged ? <Navigate to='/user/blog' replace /> : <Blog />}
          />
          {/* <Route path='/blog' element={<Blog />} /> */}
          <Route path='/allPatients' element={<AllPatients />} />
          <Route path='/carrito' element={<Carrito />} />
          <Route path='/datosDocumentos/:patientId/:docId' element={<DatosPersonaDocumentos />} />
          <Route path='/datosPersona/:patientId' element={<DatosPersonaYServicio />} />
          <Route path='/documentos/:patientId' element={<Documentos />} />
          <Route path='/facturas/:patientId' element={<Facturas />} />
          <Route path='/informeDetallado/:patientId/:reportId' element={<InformeDetallado />} />
          <Route path='/informeDetallado/:patientId/:reportId/pago' element={<Pago />} />
          <Route
            path='/informeDetallado/:patientId/:reportId/paymentmethod'
            element={<PaymentMethod />}
          />
          <Route path='/informeDetallado/:patientId/:reportId/signature' element={<Signature />} />
          <Route path='/informes/:patientId' element={<Informes />} />
          <Route path='/notificaciones' element={<Notificaciones />} />
          <Route path='/pagos' element={<Pagos />} />
          <Route path='/pedidos' element={<Pedidos />} />
          {/* <Route path='/personas' element={<Personas />} /> */}
          <Route path='/personas' element={<AllPatients />} />
          <Route path='/producto/:itemId' element={<Producto />} />
          <Route path='/servicios' element={<Servicios />} />
          <Route path='/solicitudVisitaServicio' element={<SolicitudVisitaServicio />} />
          <Route path='/subscripciones' element={<Subscripciones />} />
          <Route path='/tienda' element={<Tienda />} />
          <Route path='/user/blog' element={<Blog />} />
          <Route path='/user/help' element={<Help />} />
          <Route path='/visitas/:patientId' element={<Visitas />} />
          <Route path='/profile' element={<Profile />} />
        </Route>
      </Routes>
    </MainLayout>
  );
}

export default App;
