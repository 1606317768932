import { FC } from 'react';

// Components
import { ButtonBase } from '../Buttons/button-base';
import Modal from './Modal';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IModalInfoProps } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IModals';

// CSS
import './Modal.css';

// Icons
import shortLeftArrow from '../../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport, useLogOut } from '../../hooks';

const ModalInfo: FC<IModalInfoProps> = ({
  bodyText,
  buttonText,
  link = '',
  linkText = '',
  onClickOk,
  onClose,
  showBodyAsLink = false,
  showTitleIcon = false,
  showXCloseButton = true,
  titleIcon,
  titleText,
  zIndex = 55,
}) => {
  const { t } = useTranslation();
  const { logOut } = useLogOut();
  buttonText = buttonText || 'pages.personas.ok';

  /**********
   * States *
   **********/
  const { viewportWidth } = useViewport();

  /**************
   * Functions *
   **************/
  const handleOnCloseInfoModal = () => {
    if (bodyText === 'errors.userDataInvalid') {
      logOut(true);
    }

    onClickOk();
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 1200;
  titleIcon = titleIcon || isScreenBig ? '' : shortLeftArrow;

  const modalOptions: any = {
    addTitle: true,
    alignItemsParent: 'flex-end',
    backgroundColor: 'white',
    backgroundColorParent: 'rgba(0, 0, 0, 0.5)',
    borderColor: 'none',
    borderRadius: '25px',
    justifyContent: 'start',
    justifyContentParent: 'flex-end',
    marginChildren: 'auto',
    onClose: onClose || handleOnCloseInfoModal,
    overflow: 'hidden',
    padding: isScreenBig ? '2rem' : '1rem',
    showButtons: false,
    showCloseButton: showXCloseButton,
    showTitleIcon,
    titleIcon,
    titleMargin: 'auto',
    titleText: titleText ? GlobalService.uppercaseFirstLetter(t(titleText)) : '',
    width: '440px',
    zIndex: zIndex,
  };

  const modalButton = (
    <ButtonBase
      text={GlobalService.uppercaseFirstLetter(t(buttonText))}
      background='linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'
      color={'white'}
      paddingX={5}
      paddingY={6}
      buttonWidth={isScreenBig ? '100%' : '60%'}
      borderRadius='50px'
      onClick={handleOnCloseInfoModal}
    />
  );

  /*******
   * JSX *
   *******/
  return (
    <Modal {...modalOptions}>
      <div className='flex flex-col justify-center items-center'>
        {showBodyAsLink ? (
          <p
            className='text-center mt-4'
            dangerouslySetInnerHTML={{
              __html: GlobalService.uppercaseAndAddLink(t(bodyText), t(linkText), link),
            }}
          ></p>
        ) : (
          <p className='text-center mt-4'>
            {GlobalService.uppercaseAfterPointFirstsLetters(t(bodyText))}
          </p>
        )}

        <div className='flex justify-center w-full mt-6'>{modalButton}</div>
      </div>
    </Modal>
  );
};

export default ModalInfo;
