import axios from 'axios';
import { LoginResponse, UserLogin } from '../../ts';

// TODO find out why it doesn't allow me to fetch the /auth/login endpoint
export const loginService = async ({ username, password }: UserLogin) => {
  username = username.toLowerCase();

  let response;
  try {
    response = await axios.post<LoginResponse>('/auth/login', { username, password });
  } catch (error: any) {
    let errorMessage = 'errors.loginInvalid';
    if (error?.response?.status === 403) errorMessage = 'errors.updateMail';
    throw new Error(errorMessage);
  }

  return response.data;
};
