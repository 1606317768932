import { useAtomValue } from 'jotai';
import { getSinglePatientAttachedFilesAtom } from '../../../../store/global/atoms';
import type { Attachment, PatientAttachedFileIdentifier } from '../../../../ts';
import { MutateOptions } from '@tanstack/query-core';

export const useGetSinglePatientAttachedFiles = () => {
  const getSignlePatientAttachedFilesBase = useAtomValue(getSinglePatientAttachedFilesAtom);

  const getSinglePatientAttachedFiles = (
    { patientId }: PatientAttachedFileIdentifier,
    options?:
      | MutateOptions<Attachment[], Error, PatientAttachedFileIdentifier, unknown>
      | undefined,
  ) => {
    if (Number.parseInt(patientId) <= 0) return;
    getSignlePatientAttachedFilesBase.mutate({ patientId }, options);
  };

  const getSinglePatientAttachedFilesAsync = async (
    { patientId }: PatientAttachedFileIdentifier,
    options?:
      | MutateOptions<Attachment[], Error, PatientAttachedFileIdentifier, unknown>
      | undefined,
  ) => {
    if (Number.parseInt(patientId) <= 0) return;
    return await getSignlePatientAttachedFilesBase.mutateAsync({ patientId }, options);
  };

  return {
    getSinglePatientAttachedFiles,
    getSinglePatientAttachedFilesAsync,
    isLoading: getSignlePatientAttachedFilesBase.isPending,
    error: getSignlePatientAttachedFilesBase.error,
  };
};
