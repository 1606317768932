import { useAtomValue } from 'jotai';
import { getPatientDealsAtom } from '../../store/global/atoms';

export const useGetPatientDeals = () => {
  const getPatientDealsBase = useAtomValue(getPatientDealsAtom);

  return {
    patientDeals: getPatientDealsBase.data,
    getPatientDeals: getPatientDealsBase.mutate,
    getPatientDealsAsync: getPatientDealsBase.mutateAsync,
    isLoading: getPatientDealsBase.isPending,
    isError: getPatientDealsBase.isError,
  };
};
