import React from 'react';
import { ButtonBase } from '../../components/Buttons/button-base';
import { AppStore } from '../../components/Buttons/AppStore';
import { PlayStore } from '../../components/Buttons/PlayStore';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faX } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../hooks/Loader';

const elem = `interface CommonProps {
    // the text has to be translated and formatted
    // in case always capitalized add capitilized to div className
    text?: string;
    color?: string;
    background: string;
    weight?: string;
    border?: string;
    borderRadius?: string;
    iconLeft?: ReactNode | string;
    iconRight?: ReactNode | string;
    onclick?: () => void;
    onClickNavigationRoute?: string;
    loader?: string;
    loaderColor?: string;
    paddingX?: number;
    paddingY?: number;
    disabled?: boolean;
}

type ConditionalProps =
    | {
          loading?: never;
          onclick?: never;
      }
    | {
          loading?: boolean;
          onclick?: () => Promise<void>;
      }
    | {
          loading?: never;
          onclick?: () => void;
      };
type Props = CommonProps & ConditionalProps;

export const Button: React.FC<Props> = ({
    text,
    color,
    background,
    weight,
    border,
    borderRadius = '0px',
    iconLeft,
    iconRight,
    loader = 'regular',
    loading = false,
    onclick,
    onClickNavigationRoute,
    loaderColor,
    paddingX,
    paddingY,
    disabled = false
}) => {
    const navigate = useNavigate();

    const clickNavigation = (route: string) => {
        navigate(route);
    };

    const uuid = generateUid();
    const [length, setLength] = useState(0);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        const el = document.getElementById(uuid);
        setLength(el?.clientWidth ? el?.clientWidth : 0);
        setHeight(el?.offsetHeight ? el?.offsetHeight : 0);
    }, []);
    const padX = paddingX || paddingX === 0 ? paddingX : 16;
    const padY = paddingY || paddingY === 0 ? paddingY : 6;
    return (
        <div
        className=\`rounded-md text-center h-fit w-fit flex items-center  \${disabled ? 'cursor-normal' : 'cursor-pointer opacity-100 hover:opacity-75'}\`
        id={uuid}
        style={{
            background: background,
            border: border,
            borderRadius: borderRadius,
            color: color ? color : 'black',
            minWidth: length !== 0 ? \`\${length}px\` : 'auto',
            minHeight: height !== 0 ? \`\${height}px\` : 'auto',
            padding: \`\${padY}px \${padX}px \${padY}px \${padX}px\`
        }}
        onClick={() => {
            if (onclick) {
                !disabled && !loading ? onclick() : null;
            }
            if (onClickNavigationRoute) clickNavigation(onClickNavigationRoute);
        }}
    >
            <div className='flex items-center w-full justify-center'>
                {loading ? (
                    <Loader
                        type={loader}
                        size='12px'
                        border='8px'
                        color='transparent'
                        spinnerColor={loaderColor ? loaderColor : color ? color : 'black'}
                    />
                ) : null}
                {iconLeft && !loading ? (
                    <div className={\`\${text ? 'pr-3' : ''}\`} style={{ color: color ? color : 'black', fontWeight: weight }}>
                        {' '}
                        {iconLeft}
                    </div>
                ) : null}
                {text && !loading ? (
                    <p
                        style={{
                            color: color ? color : 'black',
                            whiteSpace: 'nowrap',
                            fontWeight: weight,
                            padding: !iconLeft && !iconRight ? \`$\{padY - 2}px $\{padX}px $\{padY - 2}px $\{padX}px\` : 'none'
                        }}
                    >
                        {text}
                    </p>
                ) : null}
                {iconRight && !loading ? (
                    <div className={\`\${text ? 'pl-3' : ''}\`} style={{ color: color ? color : 'black', fontWeight: weight }}>
                        {' '}
                        {iconRight}
                    </div>
                ) : null}
            </div>
        </div>
    );
};`;
const appStore = `import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
interface Props {
    onclick?: () => void;
}

export const AppStore: React.FC<Props> = ({ onclick }) => {
    const { t } = useTranslation();
    return (
        <div
            className='px-3 py-4 rounded-xl border-2 border-solid text-center text-white gap-3 flex items-center cursor-pointer opacity-100 bg-black hover:opacity-75'
            style={{ height: 53.56, width: 165.09, borderColor: '#A6A6A6' }}
            onClick={() => {
                if (onclick) onclick();
            }}
        >
            <div className='w-1/4 text-3xl items-center'>
                <FontAwesomeIcon icon={faApple} />
            </div>
            <div className='w-3/4 flex flex-col items-start '>
                <p className='uppercase mb-0' style={{ fontSize: '0.7rem', lineHeight: 1 }}>
                    {t('appStoreAvailable')}
                </p>
                <p className='mt-0' style={{ fontSize: '1rem', lineHeight: 1 }}>
                    App Store
                </p>
            </div>
        </div>
    );
};`;
const playStore = `import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';
interface Props {
    onclick?: () => void;
}

export const PlayStore: React.FC<Props> = ({ onclick }) => {
    const { t } = useTranslation();
    return (
        <div
            className='px-3 py-4 rounded-xl border-2 border-solid text-center text-white gap-3 flex items-center cursor-pointer opacity-100 bg-black hover:opacity-75'
            style={{ height: 53.56, width: 165.09, borderColor: '#A6A6A6' }}
            onClick={() => {
                if (onclick) onclick();
            }}
        >
            <div className='w-1/4 text-2xl items-center'>
                <FontAwesomeIcon icon={faGooglePlay} />
            </div>
            <div className='w-3/4 flex flex-col items-start '>
                <p className='uppercase mb-0' style={{ fontSize: '0.7rem', lineHeight: 1 }}>
                    {t('playStoreAvailable')}
                </p>
                <p className='mt-0' style={{ fontSize: '1rem', lineHeight: 1 }}>
                    Play Store
                </p>
            </div>
        </div>
    );
};
`;
const house = <FontAwesomeIcon className='p-0' icon={faHouse} />;
const cross = <FontAwesomeIcon className='p-0' icon={faX} />;

const Buttons = () => {
  const l1 = Loader();
  const onclick = () => {
    const promise: Promise<void> = new Promise(resolve => {
      l1.setLoader(true);
      resolve();
    });
    promise.then(() => {
      setTimeout(() => {
        onClick();
        l1.setLoader(false);
      }, 2000);
    });
    promise.catch(() => {
      l1.setLoader(false);
    });
    return promise;
  };
  const onClick = () => {
    alert('you cicked me!');
  };
  return (
    <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
      <div className='text-2xl'>Buttons</div>
      <div className='text-lg mt-4'>A button is a clickable element that triggers an action</div>
      <div className='text-lg'>The text must be passed translated and formatted.</div>
      <CopyBlock
        language={'jsx'}
        text={elem}
        showLineNumbers={true}
        theme={atomOneDark}
        wrapLines={true}
        codeBlock
      />
      <div className='text-lg mt-4'>Next some implementations of the element</div>
      <div className='text-lg mt-2'>Disabled prop</div>
      <CopyBlock
        language={'jsx'}
        text={`<Button text={\`you can't click me!\`} background='#E3E3E3' color='#2F3447' disabled={true}/>`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase
          text={`you can't click me!`}
          background='#E3E3E3'
          color='#2F3447'
          disabled={true}
        />{' '}
      </div>
      <div className='text-lg mt-2'>Adding an action without API call</div>
      <div className='text-base mt-2'>Function type: </div>
      <CopyBlock
        language={'jsx'}
        text={`() => void`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <CopyBlock
        language={'jsx'}
        text={`const onClick = () => {
    alert('you cicked me!');
};
<Button text='click me!' background='#E3E3E3' color='#2F3447' onclick={onClick}/>`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' onClick={onClick} />
      </div>
      <div className='text-lg mt-2'>Adding an action with API call</div>
      <div className='text-base mt-2'>
        You&aposll need to send loading type Loader using the hook prepared to do so and a function
        type:{' '}
      </div>
      <CopyBlock
        language={'jsx'}
        text={`() => Promise<void>`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <CopyBlock
        language={'jsx'}
        text={`const l1 = Loader();
const onclick = () => {
    const promise: Promise<void> = new Promise(resolve => {
        l1.setLoader(true);
        resolve();
    });
    promise.then(() => {
        setTimeout(() => {
            onClick();
            l1.setLoader(false);
        }, 2000);
    });
    promise.catch(() => {
        l1.setLoader(false);
    });
    return promise;
};
const onClick = () => {
    alert('you cicked me!');
};`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <CopyBlock
        language={'jsx'}
        text={`<Button
        text='click me!'
        background='#E3E3E3'
        color='#2F3447'
        onclick={onclick}
        loading={l1.loader}
        loader='gradient'
/>`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase
          text='click me!'
          background='#E3E3E3'
          color='#2F3447'
          onClick={onclick}
          loading={l1.loader}
          loader='gradient'
          loaderColor='indianred'
        />
      </div>

      <div className='text-lg mt-2'>Trigger change of the navigation</div>
      <div className='text-base mt-2'>
        You only need to pass the optional prop <b>onClickNavigationRoute</b> and its value should
        contain the desired path
      </div>
      <ButtonBase
        text='Go to doc route!'
        background='#E3E3E3'
        color='#2F3447'
        onClickNavigationRoute='/doc'
      />
      <CopyBlock
        language={'jsx'}
        text={`<Button text='Go to doc route!' background='#E3E3E3' color='#2F3447' onClickNavigationRoute='/doc' />`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <CopyBlock
        language={'jsx'}
        text={`onClick={() => {
                    if (onclick) {
                        !disabled && !loading ? onclick() : null;
                    }
                    if (onClickNavigationRoute) clickNavigation(onClickNavigationRoute);
                }}`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />

      <CopyBlock
        language={'jsx'}
        text={`const navigate = useNavigate();

                const clickNavigation = (route: string) => {
                    navigate(route);
                };`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='text-lg mt-2'>
        Changing the loader color with <b>loaderColor</b> prop:
      </div>
      <div className='text-base mt-2'>
        If not entered the <b>color</b> prop is considered the loader color and if there is none the
        default is <b>black</b>{' '}
      </div>
      <CopyBlock
        language={'jsx'}
        text={`<Button
        text='click me!'
        background='#E3E3E3'
        color='#2F3447'
        onclick={onclick}
        loading={l1.loader}
        loader='gradient'
        loaderColor='indianred'
/>`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <CopyBlock
        language={'jsx'}
        text={`const l1 = Loader();
const onclick = () => {
    const promise: Promise<void> = new Promise(resolve => {
        l1.setLoader(true);
        resolve();
    });
    promise.then(() => {
        setTimeout(() => {
            onClick();
            l1.setLoader(false);
        }, 2000);
    });
    promise.catch(() => {
        l1.setLoader(false);
    });
    return promise;
};
const onClick = () => {
    alert('you cicked me!');
};`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase
          text='click me!'
          background='#E3E3E3'
          color='#2F3447'
          onClick={onclick}
          loading={l1.loader}
          loader='gradient'
          loaderColor='indianred'
        />
      </div>
      <div className='text-base mt-2'>Text, icon right & icon left are optional</div>
      <div className='text-base mt-2'></div>
      <CopyBlock
        language={'jsx'}
        text={`const house = <FontAwesomeIcon icon={faHouse} />
const cross = <FontAwesomeIcon icon={faX} />
<Button text='click me!' background='#E3E3E3' color='#2F3447' iconLeft={icon} iconRight={cross}/>`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase text='icon left' background='#E3E3E3' color='#2F3447' iconLeft={house} />
        <ButtonBase text='icon right' background='#E3E3E3' color='#2F3447' iconRight={cross} />
        <ButtonBase
          text='two icons'
          background='#E3E3E3'
          color='#2F3447'
          iconLeft={house}
          iconRight={cross}
        />
        <ButtonBase background='#E3E3E3' color='#2F3447' iconLeft={house} />
        <ButtonBase background='#E3E3E3' color='#2F3447' borderRadius='20px' iconLeft={house} />
        <ButtonBase
          text='two icons rounded'
          background='#E3E3E3'
          color='#2F3447'
          borderRadius='17px'
          iconLeft={house}
          iconRight={cross}
        />
      </div>
      <div className='text-base mt-2'>Different background and text colors</div>
      <CopyBlock
        language={'jsx'}
        text={`<Button text='pink' background='pink' color='indianred' />`}
        showLineNumbers={false}
        theme={atomOneDark}
        wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase text='click me!' background='pink' color='indianred' />
        <ButtonBase text='click me!' background='none' color='#2F3447' />
        <ButtonBase text='click me!' background='orange' color='#2F3447' />
        <ButtonBase text='click me!' background='blue' color='#2F3447' />
        <ButtonBase text='click me!' background='green' color='#2F3447' />
        <ButtonBase text='click me!' background='#2F3447' color='white' />
      </div>
      <div className='text-base mt-2'>Different padding props X and Y axis (number of pixels)</div>
      <div className='text-base mt-2'>
        <b>
          *Careful with Fontawesome paddings, if you want NONE set the Fontawesome padding to zero
          as well
        </b>
      </div>
      <CopyBlock
        language={'jsx'}
        text={`<Button text='pink' background='pink' color='indianred' paddingX={0} paddingY={0}  />`}
        showLineNumbers={false}
        theme={atomOneDark}
        wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase
          text='click me!'
          background='pink'
          color='indianred'
          paddingX={0}
          paddingY={0}
        />
        <ButtonBase
          text='click me!'
          background='none'
          color='indianred'
          paddingX={0}
          paddingY={0}
        />
        <ButtonBase
          iconLeft={house}
          background='orange'
          color='#2F3447'
          paddingX={0}
          paddingY={0}
        />
        <ButtonBase
          iconLeft={house}
          iconRight={cross}
          text='click me!'
          background='blue'
          color='white'
          paddingX={0}
          paddingY={0}
        />
        <ButtonBase
          text='click me!'
          iconLeft={house}
          background='green'
          color='white'
          paddingX={0}
          paddingY={0}
        />
        <ButtonBase
          text='click me!'
          iconRight={cross}
          background='#2F3447'
          color='white'
          paddingX={0}
          paddingY={0}
        />
        <ButtonBase text='click me!' background='olive' color='white' paddingX={50} paddingY={10} />
      </div>
      <div className='text-base mt-2'>Different border colors</div>
      <CopyBlock
        language={'jsx'}
        text={`<Button text='border' background='none' color='#2F3447' border='2px solid #2F3447' />`}
        showLineNumbers={false}
        theme={atomOneDark}
        wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase text='click me!' background='none' color='#2F3447' border='2px solid #2F3447' />
        <ButtonBase text='click me!' background='none' color='#2F3447' border='2px solid pink' />
        <ButtonBase text='click me!' background='none' color='#2F3447' border='2px solid orange' />
        <ButtonBase text='click me!' background='none' color='#2F3447' border='2px solid blue' />
        <ButtonBase text='click me!' background='none' color='#2F3447' border='2px solid green' />
      </div>
      <div className='text-base mt-2'>Different font weight</div>
      <CopyBlock
        language={'jsx'}
        text={`<Button text='thinfont' background='#E3E3E3' color='#2F3447' weight='200' />`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' weight='200' />
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' weight='400' />
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' weight='600' />
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' weight='800' />
      </div>
      <div className='text-base mt-2'>Different border radius</div>
      <CopyBlock
        language={'jsx'}
        text={`<Button text='thinfont' background='#E3E3E3' color='#2F3447' weight='200' borderRadius='12px'/>`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' borderRadius='5px' />
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' borderRadius='12px' />
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' borderRadius='17px' />
        <ButtonBase text='click me!' background='#E3E3E3' color='#2F3447' borderRadius='25px' />
      </div>
      <div className='text-2xl mt-16'>PlayStore & Appstore Buttons</div>
      <CopyBlock
        language={'jsx'}
        text={appStore}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <CopyBlock
        language={'jsx'}
        text={playStore}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />
      <div className='text-lg'>
        Text is translated automatically if the language is added & it accepts onclick function:
      </div>
      <CopyBlock
        language={'jsx'}
        text={`const onClick = () => {
        alert('you cicked me!');
};
<AppStore onclick={onClick}/>
<PlayStore onclick={onClick}/>`}
        showLineNumbers={false}
        theme={atomOneDark}
        flex-wrapLines={true}
        codeBlock
      />

      <div className='flex gap-4 my-2 flex-wrap w-auto'>
        <AppStore onclick={onClick} />
        <PlayStore onclick={onClick} />
      </div>
    </div>
  );
};

export default Buttons;
