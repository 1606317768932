import { PersonasRepository } from '../../domain/repositories/personas.repository';

export const personasServices = (repository: PersonasRepository): PersonasRepository => ({
  deleteConsent: (data: { Deal_Name: string; fileId: string; recordId: string }) => {
    return repository.deleteConsent(data);
  },
  deleteContactConsent: (data: { fileId: string; patientId: string }) => {
    return repository.deleteContactConsent(data);
  },
  deletePatoMedi: async (dealId: string, contactId: string, fileId: { deal: string }) => {
    return repository.deletePatoMedi(dealId, contactId, fileId);
  },
  getActivePatient: () => {
    return repository.getActivePatient();
  },
  getAllPatientDeals: (names: string[], page: number) => {
    return repository.getAllPatientDeals(names, page);
  },
  getAllPatientReports: (names: string[], page: number) => {
    return repository.getAllPatientReports(names, page);
  },
  getAllPatientVisits: (names: string[], page: number) => {
    return repository.getAllPatientVisits(names, page);
  },
  getSinglePatientAttachedFiles: (patientId: string) => {
    return repository.getSinglePatientAttachedFiles(patientId);
  },
  getFile: async (module: string, dealId: string, fileId, fileName: string) => {
    return repository.getFile(module, dealId, fileId, fileName);
  },
  getPatients: async (userId: number) => {
    return repository.getPatients(userId);
  },
  getPatientDealsById: async (id: string, page: number, per_page: number) => {
    return repository.getPatientDealsById(id, page, per_page);
  },
  getPatientReportsById: async (id: string, page: number, per_page: number) => {
    return repository.getPatientReportsById(id, page, per_page);
  },
  getPatientVisitsById: async (id: string, page: number, per_page: number) => {
    return repository.getPatientVisitsById(id, page, per_page);
  },
  getProduct: async (articleId: string) => {
    return repository.getProduct(articleId);
  },
  getReportArticles: async (reportId: string) => {
    return repository.getReportArticles(reportId);
  },
  getTotalPatients: async (id: number) => {
    return repository.getTotalPatients(id);
  },
  sendSolicitud: async (data: any) => {
    return repository.sendSolicitud(data);
  },
  updateContactConsent: async (data: any) => {
    return repository.updateContactConsent(data);
  },
  updateConsent: async (data: any) => {
    return repository.updateConsent(data);
  },
  updateDeal: async (Deal_Name: string, DealId: string, data: { [key: string]: any }) => {
    return repository.updateDeal(Deal_Name, DealId, data);
  },

  uploadPatoMedi: async (file: FormData, contactId: string, dealName: string) => {
    return repository.uploadPatoMedi(file, contactId, dealName);
  },
  updateServiceAfterPayment: async (serviceId: string, subject: string, paidAmount: number) => {
    return repository.updateServiceAfterPayment(serviceId, subject, paidAmount);
  }
});
