import React, { ReactNode } from 'react';

interface Props {
    status?: boolean;
    onToggle?: () => void;
    bgTrue: string;
    left?: ReactNode | string;
    right?: ReactNode | string;
}
export const Checkbox: React.FC<Props> = ({ status, onToggle, bgTrue, left, right }) => {
    return (
        <div className='px-3 py-2 text-center w-fit flex items-center '>
            {left ? <div className='pr-3'> {left}</div> : null}
            <div
                className={`w-6 h-6 top-0 left-0 cursor-pointer relative border-2 border-solid rounded${!left && !right ? 'mx-4' : ''}`}
                style={{ borderColor: bgTrue }}
                onClick={() => {
                    if (onToggle) onToggle();
                }}
            >
                <div
                    className={`w-4 h-4 absolute rounded`}
                    style={{ top: '0.125rem', left: '0.125rem', background: status ? bgTrue : 'transparent' }}
                ></div>
            </div>
            {right ? <div className='ml-3'> {right}</div> : null}
        </div>
    );
};
