import { atomWithQuery } from 'jotai-tanstack-query';
import { QUERY_KEYS } from '../../../../../consts';
import { getUserAddressService } from '../../../../../services';
import { AddressData } from '../../../../../ts';

const DEFAULT_ADDRESS: AddressData = {
  Pa_s: '',
  Codigo_postal: '',
  Direcci_n: '',
  id: '',
  Ciudad: '',
  Name: 'Principal',
};

export const getUserAddressAtom = atomWithQuery(() => ({
  queryKey: [QUERY_KEYS.PAYMENT.USER.ADDRESS],
  queryFn: getUserAddressService,
  staleTime: Infinity,
  refetchInterval: false,
  initialData: DEFAULT_ADDRESS,
}));
