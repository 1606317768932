import { PopupOptionsSetter } from 'src/ts';
import { usePopup } from '../use-popup';
import { useTranslation } from 'react-i18next';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

export const useShowDocumentDownloadOkPopup = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = usePopup();

  const downloadOkOptions: PopupOptionsSetter = {
    title: GlobalService.uppercaseFirstLetter(t('information')),
    children: <p>{GlobalService.uppercaseFirstLetter(t('pages.documentos.documentDownloadOk'))}</p>,
    buttons: [
      {
        type: 'primary',
        children: GlobalService.uppercaseFirstLetter(t('pages.personas.ok')),
        onClick: () => closePopup(),
      },
    ],
  };

  const showDocumentDownloadOkPopup = () => openPopup(downloadOkOptions);

  return { showDocumentDownloadOkPopup };
};
