import { useTranslation } from 'react-i18next';
import { PopupOptionsSetter } from '../../../ts';
import { useSetPopup } from '../use-popup';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { UploadPatomediPopup } from '../../../components';

export const useShowUploadPatomediPopups = () => {
  const { t } = useTranslation();
  const { openPopup, openNextPopup, closePopup } = useSetPopup();

  const uploadDocumentPopupOptions = (isReplace = false): PopupOptionsSetter => ({
    title: GlobalService.uppercaseFirstLetter(
      t(isReplace ? 'pages.documentos.replaceDocLabel' : 'pages.documentos.selectFile'),
    ),
    children: <UploadPatomediPopup newFileWillReplaceOldOneReminder={isReplace} />,
  });

  const patomediSuccessfulyUploadedPopupOptions: PopupOptionsSetter = {
    title: GlobalService.uppercaseFirstLetter(t('pages.documentos.right')),
    children: <p>{GlobalService.uppercaseFirstLetter(t('pages.documentos.documentUploadOk'))}</p>,
    buttons: [
      {
        children: GlobalService.uppercaseFirstLetter(t('pages.personas.ok')),
        onClick: () => closePopup(),
        type: 'primary',
      },
    ],
  };

  const uploadPatomediPopup1: PopupOptionsSetter = {
    ...uploadDocumentPopupOptions(),
    buffer: [patomediSuccessfulyUploadedPopupOptions],
  };

  const replacePatomediPopup1: PopupOptionsSetter = {
    title: GlobalService.uppercaseFirstLetter(t('pages.documentos.replaceDocLabel')),
    children: (
      <>
        <p>{GlobalService.uppercaseFirstLetter(t('pages.documentos.deletePatoMediExplanation'))}</p>
        <p>{GlobalService.uppercaseFirstLetter(t('pages.documentos.deletePatoMediQuestion'))}?</p>
      </>
    ),
    buttons: [
      {
        children: GlobalService.uppercaseFirstLetter(t('continue')),
        onClick: () => openNextPopup(),
        type: 'secondary',
      },
      {
        children: GlobalService.uppercaseFirstLetter(t('cancel')),
        onClick: () => closePopup(),
        type: 'primary',
      },
    ],
    buffer: [uploadDocumentPopupOptions(true), patomediSuccessfulyUploadedPopupOptions],
  };

  const showReplacePatomediPopups = () => {
    openPopup(replacePatomediPopup1);
  };

  const showUploadPatomediPopups = () => {
    openPopup(uploadPatomediPopup1);
  };

  return { showUploadPatomediPopups, showReplacePatomediPopups };
};
