import axios from 'axios';
import { AddressUpsert, AddressUpsertResponse } from '../../../ts';

export const updateUserAddressService = async (newAddress: AddressUpsert) => {
  try {
    const response = await axios.post<AddressUpsertResponse>('/addresses', newAddress);
    return response.data;
  } catch (e) {
    throw new Error('errors.addressesNotFound');
  }
};
