import React, { FC, useEffect, useState } from 'react';

// Components
import { ButtonBase } from '../Buttons/button-base';
import Modal from './Modal';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IModalTransferenciaProps } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IModals';

// CSS
import './Modal.css';

// Icons
import shortLeftArrow from '../../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../../hooks/use-viewport';

const ModalTransferencia: FC<IModalTransferenciaProps> = ({
  account,
  amount,
  mail,
  patientName,
  onClickNo,
  onClickYes,
}) => {
  const { t } = useTranslation();

  /**********
   * States *
   **********/
  const [showMailSended, setShowMailSended] = useState<boolean>(false);
  const { viewportHeight, viewportWidth } = useViewport();

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const titleIcon = isScreenBig ? '' : shortLeftArrow;

  const responsiveStyle = {
    alignItems: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: viewportHeight - 10,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 25,
  };

  const modalOptions: any = {
    addTitle: true,
    alignItemsParent: 'flex-end',
    backgroundColor: 'white',
    backgroundColorParent: 'rgba(0, 0, 0, 0.5)',
    borderColor: 'none',
    borderRadius: '25px',
    justifyContent: 'start',
    justifyContentParent: 'flex-end',
    marginChildren: 'auto',
    onClose: onClickNo,
    overflow: 'hidden',
    padding: isScreenBig ? '2rem' : '1rem',
    showButtons: false,
    showCloseButton: true,
    showTitleIcon: false,
    titleIcon,
    titleMargin: 'auto',
    titleText: '',
    width: '440px',
  };

  if (!isScreenBig) {
    modalOptions['forcedChildWidth'] = '90%';
    modalOptions['parentStyle'] = responsiveStyle;
  }

  const modalNoButton = (
    <ButtonBase
      text={GlobalService.uppercaseFirstLetter(t('goBack'))}
      background='#FFF'
      color='#28337d'
      border='1px solid #28337d'
      paddingX={5}
      paddingY={6}
      buttonWidth={isScreenBig ? '100%' : '60%'}
      borderRadius='50px'
      onClick={onClickNo}
    />
  );

  const modalYesButton = (
    <ButtonBase
      text='Email'
      color='white'
      background='linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'
      border='1px solid #28337d'
      borderRadius='50px'
      paddingY={6}
      buttonWidth={isScreenBig ? '100%' : '60%'}
      onClick={() => {
        setShowMailSended(true);
        onClickYes();
      }}
    />
  );

  /*******
   * JSX *
   *******/
  return (
    <Modal {...modalOptions}>
      <div className='flex flex-col'>
        <p className='font-bold'>
          <strong>{GlobalService.uppercaseFirstLetter(t('modal.transferencia.accountNum'))}</strong>
        </p>
        <p>{account}</p>
        <p className='font-bold'>
          <strong>{GlobalService.uppercaseFirstLetter(t('modal.transferencia.amount'))}</strong>
        </p>
        <p>{amount}</p>
        <p className='font-bold'>
          <strong>{GlobalService.uppercaseFirstLetter(t('modal.transferencia.concept'))}</strong>
        </p>
        <p>{patientName}</p>
        <p className='mt-14'>
          {GlobalService.uppercaseFirstLetter(t('modal.transferencia.bodyText'))}{' '}
          <strong>{mail}</strong>
        </p>
        <p className='mt-3.5'>
          {GlobalService.uppercaseFirstLetter(t('modal.transferencia.thanks'))}
        </p>
        <div className='flex justify-between gap-2 w-full mt-6'>
          {modalNoButton} {modalYesButton}
        </div>
        {showMailSended ? (
          <div className='mt-8' style={{ color: 'green' }}>
            {GlobalService.uppercaseJustFirstLetter(t('modal.transferencia.mailsended'))}
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default ModalTransferencia;
