import { useAtomValue } from 'jotai';
import { getTemplateInfoAtom } from '../../../store/global/atoms';

export const useGetTemplateInfo = () => {
  const getTemplateInfo = useAtomValue(getTemplateInfoAtom);

  return {
    templateInfo: getTemplateInfo.data,
    getTemplateInfo: getTemplateInfo.mutate,
    getTemplateInfoAsync: getTemplateInfo.mutateAsync,
    error: getTemplateInfo.error,
    isLoading: getTemplateInfo.isPending
  };
};
