export const QUERY_KEYS = {
  USER_SESSION: {
    LOGIN: 'userLoginQuery',
  },
  USER: {
    USER_INFO: 'userInfoQuery',
  },
  LOGIN_PAGE: {
    LOGIN: 'loginQuery',
  },
  PATIENTS: {
    PATIENTS_LIST: 'patientsListQuery',
    ALL_PATIENTS_DEALS: 'allPatientsDealsQuery',
    ALL_PATIENTS_QUOTES: 'allPatientsReportsQuery',
    ALL_PATIENTS_VISITS: 'allPatientsVisitsQuery',
    ALL_PATIENTS_ATTACHED_FILES: 'allPatientsAttachedFilesQuery',
    PATIENT_DEALS: 'patientDealsQuery',
    DELETE_PATOMEDI: 'deletePatomediQuery',
    DOWNLOAD_DEAL: 'downloadDealQuery',
    DOWNLOAD_ATTACHED_FILE: 'downloadAttachedFileQuery',
    MARK_SERVICE_AS_PAID: 'markServiceAsPaidQuery',
    UPLOAD_SIGNED_FILE: 'uploadSignedFileQuery',
    INVOICES: 'patientInvoicesQuery',
  },
  SETTINGS: 'settingsQuery',
  DOCUMENTS: {
    TEMPLATE_INFO: 'templateInfoQuery',
    DOCUMENT_INFO: 'documentInfoQuery',
    SIGN_URL: 'signUrlQuery',
    UPLOAD_PATOMEDI: 'uploadPatomediQuery',
  },
  QUOTES: {
    QUOTE_PRODUCTS: 'quoteProductsQuery',
    DOWNLOAD_PDF: 'downloadQuotePdfQuery',
  },
  DEALS: {
    UPDATE_AFTER_PAYMENT: 'updateDealAfterPaymentQuery',
  },
  PAYMENT: {
    QUOTE_PAYMENT_CHECKOUT: 'quotePaymentCheckoutQuery',
    USER: {
      ADDRESS: 'paymentUserAddressQuery',
      UPDATE_ADDRESS: 'paymentUpdateUserAddressQuery',
    },
  },
} as const;
