import { useAtomValue } from 'jotai';
import { uploadPatomediAtom } from '../../../store/global/atoms';

export const useUploadPatomedi = () => {
  const uploadPatomedi = useAtomValue(uploadPatomediAtom);

  return {
    data: uploadPatomedi.data,
    uploadPatomedi: uploadPatomedi.mutate,
    uploadPatomediAsync: uploadPatomedi.mutateAsync,
    isLoading: uploadPatomedi.isPending,
    error: uploadPatomedi.error,
  };
};
