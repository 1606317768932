import { AvailableLanguage } from 'src/ts/types';
import { AVAILABLE_LANGUAGES, LOCAL_STORAGE_KEYS } from '../../consts';
import { i18n } from '../../i18n';
import { useLocalStorage } from '../use-local-storage';
import { useEffect } from 'react';

const initialLanguage = (i18n.resolvedLanguage ?? AVAILABLE_LANGUAGES.ES) as AvailableLanguage;

export const useLanguage = () => {
  const [language, setLanguage] = useLocalStorage<AvailableLanguage>(
    LOCAL_STORAGE_KEYS.LANGUAGE,
    initialLanguage
  );

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return { language, setLanguage };
};
