import { atomWithStorage } from 'jotai/vanilla/utils';
import { LOCAL_STORAGE_KEYS } from '../../../consts';
import { Auth, UserInfo } from '../../../ts';
import { QUERY_KEYS } from '../../../consts';
import { getUserInfoService } from '../../../services';
import { getAtomWithMutation } from '../../../utils';

export const getUserInfoAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.USER.USER_INFO,
  mutationFn: () => getUserInfoService,
});

export const userInfoAtom = atomWithStorage<UserInfo | null>(
  LOCAL_STORAGE_KEYS.USER,
  null,
  undefined,
  {
    getOnInit: true,
  },
);

export const authAtom = atomWithStorage<Auth | null>(LOCAL_STORAGE_KEYS.AUTH, null, undefined, {
  getOnInit: true,
});
