import React, { useEffect, useState } from 'react';
import {
  FormConfig,
  FormDataResposeItem,
} from '../../hexagonal-architecture-frontend-base/src/domain/models/Form';
import { ButtonBase } from '../Buttons/button-base';
import { FormItem } from './FormItem';
import { optionItem } from '../../hexagonal-architecture-frontend-base/src/domain/models/Input';
import { formServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/form.services';
import { useNavigate } from 'react-router-dom';

interface Props {
  config: FormConfig[];
  flex: 'row' | 'column';
  id?: string;
  justifyForm?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-between';
  alignItem?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  justifyButtonSubmit?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  onFormSubmit: (data: any) => void;
  dataService?: any;
  cancel?: boolean;
}

export const Form: React.FC<Props> = ({
  config,
  flex,
  onFormSubmit,
  justifyForm,
  alignItem,
  justifyButtonSubmit,
  cancel = false,
  dataService,
  id,
}) => {
  const [formData, setFormData] = useState<FormDataResposeItem[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    const basicFormData: FormDataResposeItem[] = dataService
      ? id
        ? formServices().formData(config, dataService, id)
        : formServices().formData(config, dataService)
      : formServices().formData(config);
    setFormData(basicFormData);
  }, [config]);

  const changeFormData = (
    formInput: FormConfig,
    value: string | object | Array<optionItem> | undefined,
  ) => {
    const auxFormData = formServices().setFormData(formData, formInput, value);
    setFormData(auxFormData);
  };

  const handleSubmit = () => {
    const [canSubmit, missingValues] = formServices().ableToSubmit(formData);

    if (canSubmit) {
      onFormSubmit(formData);
    } else {
      const missingLabels = missingValues
        .map(item => config.find(input => input.key === item.key)?.label)
        .filter(Boolean)
        .join(', ');

      setErrorMessage(`Please complete the following fields: ${missingLabels}`);
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };
  return (
    <div>
      {errorMessage ? (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          <p>{errorMessage}</p>
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: flex,
          gap: '20px',
          margin: '20px 0',
          justifyContent: justifyForm ? justifyForm : 'center',
          alignItems: alignItem ? alignItem : 'center',
        }}
      >
        {config.length && formData.length
          ? config.map((input: FormConfig, index: number) => {
              return (
                <FormItem
                  index={index}
                  key={index}
                  label={input.label}
                  type={input.type}
                  width={input.width}
                  required={input.required}
                  disabled={Boolean(input.disabled)}
                  form={input}
                  formResponse={formData}
                  minDate={input.minDate}
                  maxDate={input.maxDate}
                  startingDate={input.startingDate}
                  countryPreFix={input.countryPreFix}
                  isPrefixDisabled={input.isPrefixDisabled}
                  details={input.details}
                  text={input.text}
                  singleChoice={Boolean(input.singleChoice)}
                  isMulti={Boolean(input.isMulti)}
                  options={input.options ? input.options : []}
                  selectedOptionBG={input.selectedOptionBG ? input.selectedOptionBG : ''}
                  changeFormData={changeFormData}
                />
              );
            })
          : null}
      </div>
      <div
        className='flex'
        style={{ justifyContent: justifyButtonSubmit ? justifyButtonSubmit : 'center' }}
      >
        {cancel ? (
          <ButtonBase
            onClick={() => {
              navigate(-1);
            }}
            text='Cancelar'
            background='transparent'
            color='black'
            borderRadius='40px'
          />
        ) : null}

        <ButtonBase
          onClick={handleSubmit}
          text='Submit'
          background='#D74121'
          color='white'
          borderRadius='40px'
          //disabled={!formServices().ableToSubmit(formData)}
        />
      </div>
    </div>
  );
};
