import React, { CSSProperties, lazy, Suspense } from 'react';
import { Row } from 'src/hexagonal-architecture-frontend-base/src/domain/models/Row';
import PulsatingLoader from '../Loaders/PulsatingLoader';

interface SpecialColumn {
    column: number;
    element: string;
    module: string;
}

interface CommonProps {
    setRowValue?: (value: string) => void;
    setModal?: (value: boolean) => void;
    modalState?: boolean;
    borderRow?: string;
    padding?: string;
    textAlignTable?: CSSProperties['textAlign'] | undefined;
    backgroundRow?: string;
    textSecondary?: string;
    width?: string;
    specialColumns?: SpecialColumn[];
}
type ConditionalProps =
    | {
          row: Row[];
          columns: Row[];
      }
    | {
          row: number;
          columns: number;
      };

type Props = CommonProps & ConditionalProps;

const TableRows: React.FC<Props> = ({
    setRowValue,
    setModal,
    modalState,
    row,
    columns,
    borderRow,
    padding,
    textAlignTable,
    backgroundRow,
    textSecondary,
    specialColumns
}) => {
    const rowStyle: CSSProperties = {
        borderBottom: borderRow ? borderRow : 'unset',
        padding: padding ? padding : 'Opx',
        textAlign: textAlignTable ? textAlignTable : 'unset',
        backgroundColor: backgroundRow ? backgroundRow : 'transparent'
    };

    const cellRowStyle: CSSProperties = {
        padding: padding ? padding : 'Opx',
        textAlign: textAlignTable ? textAlignTable : 'unset',
        color: textSecondary ? textSecondary : 'black',
        width: 'auto',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    };
    return (
        <tr style={rowStyle}>
            {typeof columns !== 'number' && typeof row !== 'number'
                ? columns.map((column, index) => {
                      const cell = row.find((cell: Row) => cell.title === column.title);
                      const value = cell?.value || '';

                      switch (cell?.type) {
                          case 'custom':
                              if (specialColumns?.length) {
                                  for (let i = 0; i < specialColumns.length; i++) {
                                      if (specialColumns[i].column === index) {
                                          const Component = lazy(() =>
                                              import('../' + specialColumns[i].element).then(module => ({
                                                  default: module[specialColumns[i].module]
                                              }))
                                          );
                                          const cellProps = { ...cell.props };
                                          if (specialColumns[i].module === 'Button') {
                                              if (cellProps.onclick === 'custom' && setRowValue && setModal) {
                                                  cellProps.onclick = () => {
                                                      setModal ? setModal(!modalState) : null;
                                                      setRowValue
                                                          ? setRowValue(cell.value ? String(cell.value) : String(cell.value))
                                                          : null;
                                                  };
                                              }
                                          }

                                          return (
                                              <td style={cellRowStyle} key={column.title.toLowerCase()}>
                                                  <Suspense fallback={<div></div>}>
                                                      <Component {...cellProps} />
                                                  </Suspense>
                                              </td>
                                          );
                                      }
                                  }
                              }
                              break;
                          case 'text':
                              return (
                                  <td style={cellRowStyle} key={column.title.toLowerCase()}>
                                      <span>{value}</span>
                                  </td>
                              );
                      }
                  })
                : typeof columns === 'number' && typeof row === 'number'
                ? Array.from({ length: columns }, (_, i) => (
                      <td style={cellRowStyle} key={i}>
                          {i === 0 ? <PulsatingLoader minWidth={20} maxWidth={30} /> : <PulsatingLoader maxWidth={150} />}
                      </td>
                  ))
                : null}
        </tr>
    );
};

export default TableRows;
