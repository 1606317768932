import React, { useRef, CSSProperties } from 'react';

interface Props {
    placeholder: string;
    areaHeight?: string;
    borderRadius?: string;
    maxCharacters?: number;
    showRemainingCharacters?: boolean;
    onChange: (e: string) => void;
    isDisabled?: boolean;
    width?: string;
}

const InputTextArea: React.FC<Props> = ({
    placeholder,
    areaHeight = '120px',
    borderRadius,
    maxCharacters = 999999,
    showRemainingCharacters,
    onChange,
    isDisabled,
    width
}) => {
    const remainingCharacters = useRef<number>(maxCharacters);

    const parentStyle: CSSProperties = {
        height: areaHeight,
        paddingBottom: '12px',
        borderRadius: borderRadius,
        width: width ? width : '100%'
    };

    const textAreaStyle: CSSProperties = {
        backgroundColor: isDisabled ? 'rgb(226 232 240)' : 'white',
        width: '100%',
        height: '100%',
        resize: 'none',
        borderRadius: borderRadius
    };

    const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (maxCharacters > 0) {
            const actualCharacters = event.target.value.length;
            remainingCharacters.current = maxCharacters - actualCharacters;
        }
        onChange(event.target.value);
    };

    return (
        <div style={parentStyle} className='relative'>
            <textarea
                placeholder={placeholder}
                onChange={onChangeHandler}
                style={textAreaStyle}
                rows={1}
                maxLength={maxCharacters}
                className='p-3 outline-0 border'
                disabled={isDisabled}
            />
            {showRemainingCharacters ? (
                <div className='-mt-6 flex items-center'>
                    <p className='m-auto text-xs text-slate-400'>
                        {remainingCharacters.current}/{maxCharacters}
                    </p>
                </div>
            ) : null}
        </div>
    );
};

export default InputTextArea;
