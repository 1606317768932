import React from 'react';
import { Link } from 'react-router-dom';
import { StarRating } from '../../components/Ratings/StarRating';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import useRating from '../../hooks/useRating';
import Loader from '../../hooks/Loader';

const elem = `import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader';
import '../styles/StarRating.css';
import { starRatingService } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/starRating.services';

interface CommonProps {
    stars: 5 | 10;
    color?: string;
    loader?: string;
}
type ConditionalProps =
    | {
          disabled?: boolean;
          loading?: never;
          saveRating?: never;
          rating?: number;
      }
    | {
          disabled?: never;
          loading?: boolean;
          saveRating?: (num: number) => Promise<number>;
          rating?: number;
      }
    | {
          disabled?: never;
          loading?: never;
          saveRating?: (num: number) => Promise<number>;
          rating?: number;
      };
type Props = CommonProps & ConditionalProps;
export const StarRating: React.FC<Props> = ({
    rating = 0,
    saveRating,
    disabled = false,
    color = 'black',
    loader = 'regular',
    loading = false,
    stars
}) => {
    const uuid = generateUid();
    const [hover, setHover] = useState(0);
    const [value, setValue] = useState(0);
    const [inside, setInside] = useState(false);
    const five = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
    const ten = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10];
    const setRating = () => {
        const num = starRatingService().round(hover * (stars / 100));
        saveRating
            ? saveRating(num).then((res: number) => {
                  setValue(res);
              })
            : null;
    };
    useEffect(() => {
        stars === 5 ? (five.includes(rating) ? setValue(rating) : null) : ten.includes(rating) ? setValue(rating) : null;
        const example: any = document.getElementById(\`\${uuid}\`);
        example.onmousemove = (e: any) => {
            const calc = starRatingService().onMouseMove(e.pageX, e.currentTarget.offsetLeft, example.offsetWidth);
            // const x = e.pageX - e.currentTarget.offsetLeft;
            // const calc = 100 - (example.offsetWidth - x) * (100 / example.offsetWidth);

            setHover(calc);
            setInside(true);
        };
        example.onmouseleave = () => {
            setInside(false);
        };
    }, []);
    return (
        <div className='px-3 py-2 text-center w-fit flex items-center '>
            <div
                className={\`stars-outer-five opacity-100 \${disabled ? 'cursor-normal' : 'cursor-pointer hover:opacity-60'}\`}
                onClick={() => {
                    !disabled && !loading ? setRating() : null;
                }}
                id={uuid}
                style={{ color: color }}
            >
                {inside && !disabled && !loading ? (
                    <div className='stars-inner-five' style={{ width: \`\${hover}%\`, color: color }}></div>
                ) : (
                    <div className={\`stars-inner-five\`} style={{ width: \`\${value * (100 / stars)}%\`, color: color }}></div>
                )}
            </div>
            {loading ? (
                <div className='ml-4'>
                    <Loader type={loader} size='12px' border='8px' color='transparent' spinnerColor={color} />
                </div>
            ) : (
                <div className='ml-4' style={{ color: color }}>
                    {value}
                </div>
            )}
        </div>
    );
};
`;

const prom2 = `const r = useRating();
const [loading, setLoading] = useState(false);
const saveRating = (value: number) => {
    const promise: Promise<number> = new Promise((resolve, reject) => {
        setLoading(true);
        // yourAPIcall(value)
    });
    promise.then(() => {
        setLoading(false);
        r.setRating(value);
 
    });
    promise.catch(() => {
        setLoading(false);
    });
    return promise;
};`;
const prom3 = `const r = useRating();
const saveRating = (value: number) => {
const promise: Promise<number> = new Promise((resolve, reject) => {
        // response(value)
        });
    promise.then(() => {
        r.setRating(value);
 
    });
    promise.catch(() => {
    });
    return promise;
};`;
const hook = `import { useState } from 'react';

const useRating = (initialState = 0) => {
    const [rating, setRating] = useState(initialState);

    return { rating, setRating };
};

export default useRating;
`;

const StarRatings = () => {
    const r1 = useRating();
    const l1 = Loader();
    const r2 = useRating();
    const r3 = useRating();
    const saveRating1 = (value: number) => {
        const promise: Promise<number> = new Promise(resolve => {
            l1.setLoader(true);
            resolve(value);
        });
        promise.then(() => {
            setTimeout(() => {
                l1.setLoader(false);
                r1.setRating(value);
            }, 2000);
        });
        promise.catch(() => {
            l1.setLoader(false);
        });
        return promise;
    };
    const saveRating2 = (value: number) => {
        const promise: Promise<number> = new Promise(resolve => {
            resolve(value);
        });
        promise.then(() => {
            setTimeout(() => {
                r2.setRating(value);
            }, 3000);
        });
        promise.catch();
        return promise;
    };
    const saveRating3 = (value: number) => {
        const promise: Promise<number> = new Promise(resolve => {
            resolve(value);
        });
        promise.then(() => {
            setTimeout(() => {
                r3.setRating(value);
            }, 3000);
        });
        promise.catch();
        return promise;
    };

    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Star Ratings</div>
            <div className='text-lg mt-4'>Star ratings allow the user to rate something.</div>
            <div className='text-lg'>There are two components: 5 or 10 star ratings.</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-8'>
                Always mandatory props: <b>stars</b> ( 5 | 10 )
            </div>
            <div className='text-lg mt-8'>
                Always optional props: <b>loader</b>{' '}
                <Link to='/loaders' className='underline'>
                    see all options
                </Link>{' '}
                (default: regular) & <b>color</b> (default: black)
            </div>
            <div className='text-lg mt-8'>There are three options when it comes to this component:</div>
            <div className='text-lg mt-4'>
                1. Using the component to display data, only passing the <b>disabled</b> prop as <b>true</b>
            </div>
            <div className='text-lg mt-4'>
                2. Using the component to set a local variable, only passing the <b>saveRating</b> prop
            </div>
            <div className='text-lg mt-4'>
                3. Using the component to make an api call, only passing the <b>loading</b> & <b>saveRating</b> props
            </div>
            <div className='text-xl mt-8'>Case 1: Using the component to display data</div>
            <div className='text-lg mt-8'>
                Mandatory props: <b>disabled</b> & <b>rating</b>
            </div>
            <CopyBlock
                language={'jsx'}
                text={`<StarRating stars={5} color='olive' disabled={true} rating={3.5} />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <StarRating stars={5} color='gold' disabled={true} rating={3.5} />
            <div className='text-xl mt-8'>Case 2: Using the component to set a local variable</div>
            <div className='text-lg mt-8'>
                Mandatory props: <b>saveRating</b>
            </div>
            <div className='text-lg mt-8'>
                Optional props: <b>rating</b> (to set an initial value)
            </div>
            <CopyBlock language={'jsx'} text={hook} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <CopyBlock language={'jsx'} text={prom3} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <CopyBlock
                language={'jsx'}
                text={`<StarRating stars={5} color='olive' rating={r.rating} saveRating={saveRating} />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <StarRating stars={5} color='olive' rating={r2.rating} saveRating={saveRating2} />
            <div className='text-xl mt-8'>Case 3: Using the component to make an api call</div>
            <div className='text-lg mt-8'>
                Mandatory props: <b>saveRating</b> & <b>loading</b>
            </div>
            <div className='text-lg mt-8'>
                Optional props: <b>rating</b> (to set an initial value)
            </div>
            <CopyBlock language={'jsx'} text={hook} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <CopyBlock language={'jsx'} text={prom2} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <CopyBlock
                language={'jsx'}
                text={`<StarRating stars={5} color='olive' loader='gradient' rating={r.rating} saveRating={saveRating} loading={loading} />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <StarRating stars={5} color='olive' loader='gradient' rating={r1.rating} saveRating={saveRating1} loading={l1.loader} />
            <div className='text-xl mt-8'>Rating prop</div>
            <div className='text-base'>The components accept inicial numbers & check that they are one of the following:</div>
            <div className='text-base'>five stars: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]</div>
            <div className='text-base'>ten stars: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10]</div>
            <div className='text-base'>
                <b>Attention! If not, the rating is set to 0.</b>
            </div>
            <CopyBlock
                language={'jsx'}
                text={`<StarRating stars={5} color='indianred' rating={3.4} disabled={true}/>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <StarRating stars={5} color='indianred' rating={3.4} disabled={true} />`<div className='text-xl mt-8 mb-4'>Color prop</div>
            <CopyBlock
                language={'jsx'}
                text={`<StarRating stars={5} color='darkcyan' rating={3.5} disabled={true}/>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <StarRating stars={5} color='darkcyan' rating={3.5} disabled={true} />
            <div className='text-xl mt-8 mb-4'>Size</div>
            <div className='text-base'>The size of the component is picked from the font size of the context</div>
            <CopyBlock
                language={'jsx'}
                text={`<div className='text-xl'>
        <StarRating stars={5} color='gold' disabled={true} rating={4} />
</div>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='text-xl flex items-center'>
                <StarRating stars={5} color='darkcyan' disabled={true} rating={4} /> <div className='ml-4 opacity-20'>xl</div>
            </div>
            <div className='text-3xl flex items-center'>
                <StarRating stars={5} color='indianred' disabled={true} rating={2.5} /> <div className='ml-4 opacity-20'>3xl</div>
            </div>
            <div className='text-6xl flex items-center'>
                <StarRating stars={5} color='olive' rating={r3.rating} saveRating={saveRating3} />{' '}
                <div className='ml-4 opacity-20'>6xl</div>
            </div>
        </div>
    );
};

export default StarRatings;
