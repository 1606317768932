import { atomEffect } from 'jotai-effect';
import {
  getAllPatientsAtom,
  getPatientsDealsAtom,
  getPatientsQuotesAtom,
  getPatientsVisitsAtom,
} from '../..';
import { isGettingAllPatientsAtom } from '../get-patients-extra-info-atom-effect';
import { accumulatedPatientsAtom } from '../../patients-atoms';
import { assignVisitsToPatients } from '../../../../../../utils';

export const assignVisitsToPatientsAtomEffect = atomEffect((get, set) => {
  const getAllPatients = get.peek(getAllPatientsAtom);
  const getPatientsVisits = get(getPatientsVisitsAtom);

  set(isGettingAllPatientsAtom, true);

  const setIsNotLoading = () => {
    const getAllPatients = get.peek(getAllPatientsAtom);
    const isReportsLoading = get.peek(getPatientsQuotesAtom).isPending;
    const isDealsLoading = get.peek(getPatientsDealsAtom).isPending;
    if (!getAllPatients.isPending && !isReportsLoading && !isDealsLoading) {
      set(isGettingAllPatientsAtom, false);
    }
  };

  if (getAllPatients.isPending || getPatientsVisits.isPending) return;

  if (getAllPatients.isError || getAllPatients.data == null || getPatientsVisits.isError) {
    setIsNotLoading();
    return;
  }

  const visits = getPatientsVisits.data ?? {};
  const accumulatedPatients = get.peek(accumulatedPatientsAtom);
  const listToUse = accumulatedPatients.length === 0 ? getAllPatients.data : accumulatedPatients;
  const listWithVisits = assignVisitsToPatients(listToUse, visits);
  set(accumulatedPatientsAtom, listWithVisits);

  setIsNotLoading();
});
