import { useSessionValue } from './use-session-value';
import { useSetSession } from './use-set-session';

export const useSession = () => {
  const { auth, userInfo, isUserLogged } = useSessionValue();
  const { setAuth, setSession, setUserInfo, removeSession, refreshUser } = useSetSession();

  return {
    auth,
    userInfo,
    isUserLogged,
    setAuth,
    setSession,
    setUserInfo,
    removeSession,
    refreshUser
  };
};
