import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { ButtonBase } from '../components/Buttons/button-base';
import ModalInfo from '../components/Modals/ModalInfo';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Slider from 'react-slick';
import Title from '../components/Title';
import TopMenu from './Menus/TopMenu';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { tiendaRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { ICart } from 'src/hexagonal-architecture-frontend-base/src/domain/models/ICart';
import { ICartItem } from 'src/hexagonal-architecture-frontend-base/src/domain/models/ICartItem';
import { IShopItem } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IShopItem';

// CSS
import '../styles/Tienda/Tienda.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Images
import ArrowBlue from '../img/Product/arrow-blue.png';
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';

const Producto = () => {
  const navigate = useNavigate();
  const { itemId } = useParams();
  const { t } = useTranslation();

  /**********
   * States *
   **********/
  const [numberItems] = useState<number>(5);
  const [currentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingRelated, setLoadingRelated] = useState<boolean>(true);
  const [producto, setProducto] = useState<IShopItem>({
    id: 0,
    image: [],
    marca: 'string',
    name: 'string',
    price: 0,
    formattedPrice: 'string',
    categoryId: 0,
  });
  const [imageSelected, setImageSelected] = useState<string>('');
  const [productos, setProductos] = useState<IShopItem[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const { viewportWidth } = useViewport();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [totalCartItems, setTotalCartItems] = useState<number>(0);

  /*********
   * Hooks *
   *********/

  const isScreenBig = viewportWidth > 768;

  // Get data
  useEffect(() => {
    tiendaServices(tiendaRepositoryInstance)
      .getProducto(parseInt(itemId as string))
      .then((response: IShopItem | string) => {
        if (typeof response !== 'string') {
          const editedItem: IShopItem = {
            ...response,
            formattedPrice: tiendaServices(tiendaRepositoryInstance).formatPrice(response.price),
          };
          setProducto(editedItem);
          setLoading(false);

          setImageSelected(response?.image && response.image.length > 0 ? response?.image[0] : '');
          if (isScreenBig) {
            handleBuyMoreProducts(response);
          }
        } else {
          setModalMessage(response);
          setShowModal(true);
          setModalTitle('error');
        }
      });

    calculateTotalCartItems();
  }, []);

  useEffect(() => {
    //console.log('handleSearch text', searchText);
  }, [searchText]);

  /************
   * Handlers *
   ************/
  const handleBuyMoreProducts = (prod: IShopItem) => {
    setLoadingRelated(true);
    tiendaServices(tiendaRepositoryInstance)
      .getProductos(prod?.categoryId ? prod?.categoryId : undefined, '', numberItems, currentPage)
      .then((response: IShopItem[] | string) => {
        if (typeof response === 'string') {
          setModalMessage(response);
          setShowModal(true);
          setModalTitle('error');
        } else {
          const filteredProducts: IShopItem[] = [];
          response.forEach((item: IShopItem) => {
            if (prod && item.id !== prod.id) {
              filteredProducts.push(item);
            }
          });

          if (filteredProducts.length > 4) filteredProducts.pop();
          const editedFilteredProducts = filteredProducts.map((item: IShopItem) => {
            item.formattedPrice = tiendaServices(tiendaRepositoryInstance).formatPrice(item.price);
            return item;
          });
          setProductos(editedFilteredProducts);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingRelated(false);
        }, 500);
      });
  };

  const handleClickModal = () => {
    setShowModal(false);
    setModalMessage('');
    setModalTitle('');
  };

  const handleNavigate = (id: string) => {
    if (parseInt(id) !== producto?.id) {
      const newProduct = productos.find((prod: IShopItem) => prod.id === Number(id));
      if (newProduct) {
        navigate(`/producto/${id}`);
        setProducto(newProduct);
        handleBuyMoreProducts(newProduct);
      } else {
        setModalMessage('errors.genericError');
        setShowModal(true);
        setModalTitle('error');
      }
    }
  };

  /***********
   * Helpers *
   ***********/
  const addItemToBasket = async (id?: number) => {
    let quantity = 1;

    await tiendaServices(tiendaRepositoryInstance)
      .getBasketItems()
      .then(async (res: ICart | string) => {
        if (typeof res !== 'string') {
          res.items.forEach((item: ICartItem) => {
            if (item.productId === id) {
              quantity += item.quantity;
            }
          });
          const message = await tiendaServices(tiendaRepositoryInstance).addItemToBasket(
            id,
            quantity,
          );

          setModalMessage(message);
          setShowModal(true);
          setModalTitle('confirmed');
        } else {
          setModalMessage(res);
          setShowModal(true);
          setModalTitle('error');
        }
        setLoading(false);
      });
    calculateTotalCartItems();
  };

  const calculateTotalCartItems = async () => {
    await tiendaServices(tiendaRepositoryInstance)
      .getBasketItems(true)
      .then((res: ICart | string) => {
        if (typeof res !== 'string') {
          setTotalCartItems(res.details.totalItems);
        } else {
          setModalMessage(res);
          setShowModal(true);
          setModalTitle('error');
        }
        setLoading(false);
      });
  };

  /**************
   * JSX Values *
   **************/
  const noData = <p>{GlobalService.uppercaseAllFirstsLetters(t('nodata'))}</p>;

  // Slider Previous Arrow
  const CustomPrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className='w-full'>
        <button className='w-full mb-3' onClick={onClick}>
          <img className='mx-auto' src={ArrowBlue} alt='' />
        </button>
      </div>
    );
  };

  // Slider Next Arrow
  const CustomNextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className='w-full'>
        <button className='w-full mt-3' onClick={onClick}>
          <img className='mx-auto rotate-180' src={ArrowBlue} alt='' />
        </button>
      </div>
    );
  };

  const sliderDesktopSettings = {
    arrows: true,
    centerMode: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    swipeToSlide: true,
    vertical: true,
    verticalSwiping: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const sliderResponsiveSettings = {
    arrows: false,
    dots: true,
    dotsClass: 'dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Desktop JSX
  const addToBasketButton = (
    <ButtonBase
      text={GlobalService.uppercaseAllFirstsLetters(t('pages.tienda.addToBasket'))}
      onClick={() => addItemToBasket(producto?.id)}
      background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
      color='white'
      borderRadius='50px'
      buttonWidth='100%'
      fontSize={isScreenBig ? '16px' : '14px'}
    />
  );

  const buyNowButton = (id: number) => (
    <ButtonBase
      text={GlobalService.uppercaseFirstLetter(t('pages.tienda.addToBasket'))}
      background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
      color={'white'}
      borderRadius={'50px'}
      buttonWidth='100%'
      onClick={() => addItemToBasket(id)}
    />
  );

  const desktopDocumentsTitle = (
    <div>
      <div className='flex text-left mb-2'>
        <img
          onClick={() => {
            navigate('/tienda');
          }}
          src={LeftArrow}
          alt='Back'
          className='Notificaciones-Arrow cursor-pointer'
        />
        <Title
          text={producto?.name as string}
          color='black'
          size={'24px'}
          margin={'0px 0px 0px 30px'}
        />
      </div>
    </div>
  );

  const imagesSlider =
    producto && producto?.image.length
      ? producto.image.map((imageLink: string, index: number) => {
          return (
            <img
              src={imageLink}
              className='w-24 h-24 my-1 cursor-pointer'
              alt=''
              key={index}
              onClick={() => {
                setImageSelected(imageLink);
              }}
            />
          );
        })
      : [];

  const sliderDesktop = (
    <div className='w-full overflow-hidden'>
      <Slider {...sliderDesktopSettings}>{imagesSlider}</Slider>
    </div>
  );

  const imagesSliderResponsive =
    producto && producto?.image.length
      ? producto.image.map((imageLink: string, index: number) => {
          return (
            <div className='flex flex-col w-full h-[315px]' key={index}>
              <img
                src={imageLink}
                alt=''
                className='product-item-image'
                onClick={() => {
                  setImageSelected(imageLink);
                }}
              />
            </div>
          );
        })
      : [];

  // Responsive JSX
  const sliderResponsive = (
    <Slider {...sliderResponsiveSettings} className='bg-white mt-16 max-h-80'>
      {imagesSliderResponsive}
    </Slider>
  );
  const productResponsiveDescription = (
    <div className='flex flex-col justify-around'>
      {sliderResponsive}
      <div className='w-full my-6'>
        <p className='text-sm'>{producto?.name}</p>
        <p className='text-sm mt-3' style={{ color: '#28337d ' }}>
          {producto?.formattedPrice}
        </p>
        <p className='py-3 mt-2 text-sm'>{producto?.description}</p>
        <div className='mb-24'>{addToBasketButton}</div>
      </div>
    </div>
  );

  const productDescription = (
    <div className='h-[420px] grid grid-cols-1 lg:grid-cols-2 gap-y-4'>
      <div className='flex h-[420px]'>
        <div className='w-2/12'>{sliderDesktop}</div>
        <div className='w-10/12 h-[420px] ml-6'>
          <img src={imageSelected} alt='' className='product-item-image' />
        </div>
      </div>
      <div className='flex flex-col mx-4'>
        <p className='text-3xl'>{producto?.name}</p>
        <p className='text-3xl mt-3' style={{ color: '#28337d ' }}>
          {producto?.formattedPrice}
        </p>
        <p className='py-3 mt-2'>{producto?.description}</p>
        <div className='flex flex-col gap-3'>{addToBasketButton}</div>
      </div>
    </div>
  );

  const productsFormated = productos.map((item: IShopItem, index: number) => {
    return (
      <div key={index}>
        {loadingRelated ? (
          <RegularSpinner />
        ) : (
          <div className='product-item-card mb-2'>
            <div
              className='product-card-inner-container'
              onClick={() => {
                handleNavigate(item.id.toString());
              }}
            >
              <img
                className='product-image'
                src={item?.image && item.image.length > 0 ? item?.image[0] : ''}
              />
              <div className='product-information mt-2'>
                <p>{item.name}</p>
                <p style={{ color: '#28337d' }}> {item?.formattedPrice}</p>
              </div>
              <div className='mt-2'>{buyNowButton(item.id)}</div>
            </div>
          </div>
        )}
      </div>
    );
  });

  /*******
   * JSX *
   *******/
  return (
    <div className={`w-full overflow-hidden ${isScreenBig ? '' : 'bg-white'}`}>
      {isScreenBig ? (
        <>
          <TopMenu
            title='Tienda'
            searchInput={true}
            searchValue={searchText}
            handleSearch={setSearchText}
            cartiItems={totalCartItems}
          />
          <div className='product-height flex flex-col gap-2 w-full p-5'>
            {loading ? (
              <RegularSpinner />
            ) : producto ? (
              <>
                {desktopDocumentsTitle}
                {productDescription}
                <div className='flex flex-row justify-between mt-56 lg:mt-8 mb-2'>
                  <Title
                    text={GlobalService.uppercaseFirstLetter(t('pages.tienda.buyMore'))}
                    color='black'
                    size={'20px'}
                  />
                </div>
                <div className='grid grid-cols-4 gap-8 justify-around'>
                  {productos.length ? productsFormated : noData}
                </div>
              </>
            ) : (
              noData
            )}
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title=''
            searchInput={true}
            searchValue={searchText}
            handleSearch={setSearchText}
            leftIcon={LeftArrowSmall}
            leftIconLink={'/tienda'}
            cartiItems={totalCartItems}
          ></TopMenu>
          <div className='product-height-mobile flex flex-col mx-4 my-8 bg-white mt-24'>
            {loading ? (
              <RegularSpinner />
            ) : producto ? (
              <div>{productResponsiveDescription}</div>
            ) : (
              noData
            )}
          </div>
        </>
      )}
      {showModal ? (
        <ModalInfo
          titleText={modalTitle}
          bodyText={modalMessage}
          buttonText={GlobalService.uppercaseFirstLetter(t('close'))}
          onClickOk={handleClickModal}
        />
      ) : null}
    </div>
  );
};

export default Producto;
