import { useAtomValue } from 'jotai';
import { paidAndUnpaidItemsAtom } from '../../../store/global/atoms';

export const useItems = () => {
  const paidAndUnpaidItems = useAtomValue(paidAndUnpaidItemsAtom);

  return {
    paidAndUnpaidItems,
    paidItems: paidAndUnpaidItems.paidItems,
    unpaiditems: paidAndUnpaidItems.unpaidItems,
  };
};
