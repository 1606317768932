import React from 'react';

interface Props {
    url: string;
    width: string;
    height?: string;
    borderRadius?: string;
}

export const Image: React.FC<Props> = ({ url, width, height = width, borderRadius }) => {
    return (
        <div
            style={{
                height: height,
                width: width,
                backgroundImage: `url("${url}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                borderRadius: borderRadius
            }}
        ></div>
    );
};
