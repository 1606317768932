import { UpdateDealParams } from 'src/ts';
import { updateDealService } from './update-deal.service';

export interface UpdateDealAfterPaymentParams extends Omit<UpdateDealParams, 'data'> {}

export const updateDealAfterPaymentService = async ({
  DealId,
  Deal_Name,
}: UpdateDealAfterPaymentParams) => {
  // TODO - check if pago final is OK
  const data = await updateDealService({
    DealId,
    Deal_Name,
    data: { Payment_In_Process: true },
  });

  return data;
};
