import styles from './notifications-button.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell as faRegularBell } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState, type FC } from 'react';
import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { INotification } from 'src/hexagonal-architecture-frontend-base/src/domain/models/INotification';
import Card from '../../../components/Cards/Card';
import { useClickOutside, useGetNotificationExtraInfo } from '../../../hooks';
import { Notification } from '../../../ts';

const cx = classNames.bind(styles);

interface NotificationsButtonProps {
  numberNotifications: number;
  notifications: INotification[];
  onClickNotificationLink: (id: number) => void;
}

export const NotificationsButton: FC<NotificationsButtonProps> = ({
  numberNotifications,
  notifications,
  onClickNotificationLink,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNotifications, setShowNotifications] = useState(false);
  const { t } = useTranslation();
  const notificationsButtonContainerRef = useRef(null);
  const { getNotificationExtraInfo } = useGetNotificationExtraInfo();

  useClickOutside(notificationsButtonContainerRef, () => setShowNotifications(false));

  const isDisabled = location.pathname === '/notificaciones';
  const isScreenBig = window.innerWidth > 768;

  const handleSeeAllNotifications = () => {
    localStorage.setItem('backRoute', location.pathname);
    navigate('/notificaciones');
  };

  const handleClick = () => {
    if (isDisabled) return;
    if (isScreenBig) {
      setShowNotifications(!showNotifications);
    } else {
      handleSeeAllNotifications();
    }
  };

  const handleClickNotification = (id: number) => {
    // console.log(id);
  };

  return (
    <div className={cx('notifications_button_container')} ref={notificationsButtonContainerRef}>
      <button
        className={cx(
          'bell_container',
          { 'bell_container-colorized': showNotifications },
          { 'bell_container-clicked': showNotifications },
          { 'bell_container-disabled': isDisabled },
        )}
        onClick={handleClick}
      >
        {showNotifications}
        {!showNotifications ? (
          <FontAwesomeIcon icon={faRegularBell} className={cx('bell_icon')} />
        ) : (
          <FontAwesomeIcon
            icon={faRegularBell}
            className={cx('bell_icon', 'bell_icon-colorized')}
          />
        )}

        {numberNotifications > 0 ? (
          <div
            className={cx(
              'notification-advice',
              {
                'notification-advice-100': numberNotifications >= 100,
              },
              'flex',
              'items-center',
              'justify-center',
            )}
          >
            <p>{numberNotifications < 100 ? numberNotifications : '99+'}</p>
          </div>
        ) : null}
      </button>

      {showNotifications ? (
        <div className={cx('notifications-container')}>
          <div className={cx('notifications-header')}>
            <p>
              <strong>
                {GlobalService.uppercaseFirstLetter(t('pages.topMenu.notifications'))}
              </strong>
            </p>
            <FontAwesomeIcon
              className={cx('close_icon')}
              icon={faXmark}
              onClick={() => setShowNotifications(false)}
            />
          </div>

          <div className={cx('notifications_body')}>
            {notifications.map((notification: Notification, index: number) => {
              const { title, message, path } = getNotificationExtraInfo(notification);

              return (
                <div key={index} className={cx('notification-container')}>
                  <Card
                    id={notification.id.toString()}
                    width={500}
                    numberItems={2}
                    padding='1rem'
                    borderRadius='10px'
                    backgroundColor='white'
                    borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                    border={`1px solid ${!notification.seen ? '#FC0E0E' : '#EEEEEE'}`}
                    action={() => handleClickNotification(notification.id)}
                  >
                    <div className='flex flex-col-2'>
                      <div className='grid grid-cols-1 gap-y-2'>
                        <p className='Notificaciones-Text'>{GlobalService.limitText(title, 30)}</p>
                        <p className='Notificaciones-Comment'>
                          {GlobalService.limitText(message, 100)}
                        </p>

                        {path == '' ? null : (
                          <a
                            className='Notificaciones-Link'
                            href={path}
                            target='_blank'
                            onClick={() => onClickNotificationLink(notification.id)}
                            rel='noreferrer'
                          >
                            {GlobalService.uppercaseFirstLetter(t('pages.topMenu.clickHere'))}
                          </a>
                        )}

                        <p className='Notificaciones-Date'>
                          {GlobalService.parseDateForNotification(
                            notification.createdAt,
                            t('pages.topMenu.lt1hourtime'),
                            t('pages.topMenu.1hourtime'),
                            t('pages.topMenu.morehourtime'),
                            t('pages.topMenu.at'),
                          )}
                        </p>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}

            <p onClick={handleSeeAllNotifications} className={cx('link-notificaciones')}>
              {GlobalService.uppercaseAllFirstsLetters(t('viewAll'))}
            </p>
            <div className='border-t-2 w-14 m-auto '></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
