import React from 'react';
import { Radio } from '../../components/Buttons/Radio';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import useToggle from '../../hooks/useToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faCheck } from '@fortawesome/free-solid-svg-icons';

const elem = `interface Props {
    status?: boolean;
    onToggle?: () => void;
    bgTrue: string;
    left?: ReactNode | string;
    right?: ReactNode | string;
}
export const Radio: React.FC<Props> = ({ status, onToggle, bgTrue, left, right }) => {
    return (
        <div className='px-3 py-2 text-center w-fit flex items-center '>
            {left ? <div className='pr-3'> {left}</div> : null}
            <div
                className={\`w-6 h-6 rounded-full top-0 left-0 cursor-pointer relative border-2 border-solid \${
                    !left && !right ? 'mx-4' : ''
                }\`}
                style={{ borderColor: bgTrue }}
                onClick={() => {
                    if (onToggle) onToggle();
                }}
            >
                <div
                    className={\`w-4 h-4 rounded-full absolute\`}
                    style={{ top: '0.125rem', left: '0.125rem', background: status ? bgTrue : 'transparent' }}
                ></div>
            </div>
            {right ? <div className='ml-3'> {right}</div> : null}
        </div>
    );
};`;
const Radios = () => {
    const a1 = useToggle();
    const a2 = useToggle(true);
    const a3 = useToggle();
    const a4 = useToggle(true);
    const a5 = useToggle();
    const a6 = useToggle(true);
    const a7 = useToggle();
    const cross = <FontAwesomeIcon icon={faX} />;
    const check = <FontAwesomeIcon icon={faCheck} />;
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Radio Buttons</div>
            <div className='text-lg mt-4'>A radio button is an element that lets you interact with a true/false statement</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg'>This element can be used hand in hand with the hook useToggle:</div>
            <CopyBlock
                language={'jsx'}
                text={`import { useState } from 'react';

        const useToggle = (initialState = false) => {
            const [value, setValue] = useState(initialState);
        
            function toggle() {
                setValue(!value);
            }
        
            return { value, toggle };
        };
                
export default useToggle;
                `}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='text-lg mt-4'>The useToggle hook can be imported and used as following:</div>
            <CopyBlock
                language={'jsx'}
                text={`import useToggle from '../../hooks/useToggle';`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <CopyBlock
                language={'jsx'}
                text={`const t = useToggle();
const t = useToggle(false);`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='text-lg mt-4'>Different colors can be set</div>
            <CopyBlock
                language={'jsx'}
                text={`<Radio status={a2.value} onToggle={a2.toggle} bgTrue='indianred' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Radio status={a1.value} onToggle={a1.toggle} bgTrue='pink' />
                <Radio status={a2.value} onToggle={a2.toggle} bgTrue='indianred' />
                <Radio status={a3.value} onToggle={a3.toggle} bgTrue='lightcoral' />
                <Radio status={a4.value} onToggle={a4.toggle} bgTrue='lavender' />
            </div>

            <div className='text-lg mt-4'>Text and or icons can be added to both left and right</div>
            <CopyBlock
                language={'jsx'}
                text={` const check = <FontAwesomeIcon icon={faCheck}
    <Radio status={a7.value} onToggle={a7.toggle} bgTrue='darkcyan' left={check} right='Some text on the right' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Radio status={a5.value} onToggle={a5.toggle} bgTrue='olive' left={cross} right={check} />
                <Radio status={a6.value} onToggle={a6.toggle} bgTrue='chocolate' left='Some text on the left' />
                <Radio status={a7.value} onToggle={a7.toggle} bgTrue='darkcyan' right='Some text on the right' />
            </div>
        </div>
    );
};

export default Radios;
