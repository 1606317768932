export const createAndClickTemporaryLink = (url: string, fileName?: string): void => {
  fileName ??= 'document.pdf';
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
