import { useSetAtom } from 'jotai';
import { selectedQuoteIdAtom } from '../../../store/global/atoms';

export const useSetSelectedQuote = () => {
  const setSelectedQuoteBase = useSetAtom(selectedQuoteIdAtom);

  const setSelectedQuote = (reportId: string) => {
    setSelectedQuoteBase(reportId);
  };

  const removeSelectedQuote = () => {
    setSelectedQuoteBase(null);
  };

  return {
    setSelectedQuote,
    removeSelectedQuote
  };
};
