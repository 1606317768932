import { personasRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';
import { personasServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';
import { IdToPatientExtraInfo, PatientDeal } from '../../../ts';

export const getPatientDealsService = async ({
  patientId,
}: {
  patientId: string;
}): Promise<IdToPatientExtraInfo<PatientDeal>> => {
  const lastContactDeal = await personasServices(personasRepositoryInstance).getPatientDealsById(
    patientId,
    1,
    200,
  );

  if (typeof lastContactDeal === 'string') throw new Error(lastContactDeal);

  return {
    [patientId]: [lastContactDeal],
  };
};
