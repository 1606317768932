/* eslint-disable */
import React, { useState } from 'react';
import Input from '../../components/Inputs/Input';
import InputFile from '../../components/Inputs/InputFile';
import InputSearch from '../../components/Inputs/InputSearch';
import InputSelector from '../../components/Inputs/InputSelector';
import InputPhone from '../../components/Inputs/InputPhone';
import InputMultiSearch from '../../components/Inputs/InputMultiSearch';
import InputTextArea from '../../components/Inputs/InputTextArea';
import InputDate from '../../components/Inputs/InputDate';
import InputCheckbox from '../../components/Buttons/InputCheckbox';
import Card from '../../components/Cards/Card';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import { phonePrefix } from '../../hexagonal-architecture-frontend-base/src/domain/models/PhonePrefix';

const elemBasicInput = `interface Props {
    type: 'text' | 'password' | 'email' | 'number';
    onChange: (e: any) => void;
    value: string | number;
    placeholder?: string;
    id: string;
    name: string;
    borderRadius?: string;
    isDisabled?: boolean;
}

const Input: React.FC<Props> = ({ type, onChange, value, id, name, placeholder, borderRadius, isDisabled }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const classname =
        'appearance-none flex items-center block w-full border border-gray-200 text-gray-700 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500';
    switch (type) {
        case 'text':
            return (
                <div className={classname} style={{ borderRadius: borderRadius }}>
                    <input
                        value={value}
                        type='text'
                        id={id}
                        name={name}
                        onChange={e => onChange(e.target.value)}
                        placeholder={placeholder}
                        style={{ borderRadius: borderRadius }}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                </div>
            );
        case 'number':
            return (
                <div className={classname} style={{ borderRadius: borderRadius }}>
                    <input
                        value={value}
                        type='number'
                        onChange={e => onChange(e.target.value)}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                </div>
            );
        case 'email':
            return (
                <div className={classname} style={{ borderRadius: borderRadius }}>
                    <input
                        value={value}
                        type='email'
                        onChange={e => onChange(e.target.value)}
                        placeholder={placeholder}
                        style={{ borderRadius: borderRadius }}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                </div>
            );
        case 'password':
            return (
                <div className={classname}>
                    <input
                        value={value}
                        type={showPassword ? 'text' : 'password'}
                        onChange={e => onChange(e.target.value)}
                        placeholder={placeholder}
                        style={{ borderRadius: borderRadius }}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                    <FontAwesomeIcon icon={faEye} size='xs' onClick={handleShowPassword} className='opacity-50 hover:cursor-pointer' />
                </div>
            );
        default:
            return (
                <div className={classname} style={{ borderRadius: borderRadius }}>
                    <input
                        value={value}
                        type='text'
                        onChange={e => onChange(e.target.value)}
                        placeholder={placeholder}
                        style={{ borderRadius: borderRadius }}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                </div>
            );
    }
};
`;

const elemBasicInputDetailText = `<Input
id='name'
name='name'
placeholder='Enter your name here'
type='text'
value={user.name}
onChange={e => setUser(user => ({ ...user, name: e }))}
borderRadius='6px'
isDisabled={true}
/>
`;

const elemBasicInputDetailNumber = `<Input
id='counter'
name='counter'
type='number'
value={user.counter}
onChange={e => setUser(user => ({ ...user, counter: e }))}
borderRadius='6px'
/>
`;
const elemBasicInputDetailEmail = `<Input
id='email'
name='email'
type='email'
placeholder='info.test@test.com'
value={user.email}
onChange={e => setUser(user => ({ ...user, email: e }))}
borderRadius='6px'
/>
`;
const elemBasicInputDetailPassword = `<Input
id='password'
name='password'
type='password'
placeholder='Enter your password'
value={user.password}
onChange={e => setUser(user => ({ ...user, password: e }))}
borderRadius='6px'
/>
`;
const elemInputFile = `interface Props {
    label: string;
    onChange: (e: object) => void;
    fileRequirements: string;
    emptyFile: string;
    fileName?: string;
    borderRadius?: string;
    isDisabled?: boolean;
}
const InputFile: React.FC<Props> = ({ label, onChange, fileRequirements, emptyFile, fileName = null, borderRadius, isDisabled }) => {
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            onChange(e.target.files[0]);
        }
    };

    return (
        <div
            className='appearance-none w-96 h-44  bg-white sm:px-10 border border-gray-200 leading-tight m-auto hover:bg-slate-50'
            style={{ borderRadius: borderRadius }}
        >
            <div className='text-center relative cursor-pointer p-6'>
                <FontAwesomeIcon icon={faUpload} className='opacity-50' />
                <h3 className='mt-3 '>{label}</h3>
                <p className='text-slate-400 text-xs mt-2'>{fileRequirements}</p>
                <div className='mt-4 mb-2 border border-dashed border-gray-200 rounded w-3/5 m-auto p-1'>
                    {fileName ? <p className='text-slate-600 '>{fileName}</p> : <p className='text-xs text-slate-400 '>{emptyFile}</p>}
                </div>
                <input
                    type='file'
                    onChange={handleFileChange}
                    disabled={isDisabled}
                    className='block absolute top-0 right-0 bottom-0 left-0 opacity-0 w-full h-full cursor-pointer'
                />
            </div>
        </div>
    );
};
`;

const elemInputFileImplementation = `<InputFile
    label='Upload Document'
    onChange={e => handleFile(e)}
    fileRequirements='(pdf, docs, max. 2MB)'
    emptyFile='No file yet'
    fileName={file?.name}
    />
`;

const elemInputSelector = `interface Props {
    placeholder: string;
    options?: Array<optionItem | phonePrefix>;
    selectedOption?: Array<optionItem | phonePrefix>;
    onChange?: (value: Array<optionItem | phonePrefix>) => void;
    selectedOptionBG: string;
    isMulti?: boolean;
    singleChoice?: boolean;
    borderStyle?: string;
    borderStyleRadius?: string;
    param: string;
    isDisabled?: boolean;
}

const InputSelector: React.FC<Props> = ({
    placeholder,
    options,
    selectedOption,
    onChange,
    selectedOptionBG,
    isMulti,
    singleChoice,
    borderStyle,
    borderStyleRadius,
    param,
    isDisabled
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [optionsArray, setOptionsArray] = useState(options);
    const arrayOptionsSelected: Array<optionItem | phonePrefix> | undefined = selectedOption;

    useEffect(() => {
        const handler = () => setShowOptions(false);
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    });

    const handleInputClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (isDisabled) return;
        e.stopPropagation();
        setShowOptions(!showOptions);
    };

    const isSelected = (option: string) => {
        return inputServices().isSelected(option, selectedOption!, param);
    };

    const handleOnChange = (option: optionItem | phonePrefix, isMulti: boolean | undefined, removeItem: boolean) => {
        onChange!(
            inputServices().handleOnChange(arrayOptionsSelected!, option, isMulti!, removeItem, singleChoice ? singleChoice : false, param)
        );
    };

    const onTagRemove = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, option: optionItem | phonePrefix) => {
        e.stopPropagation();
        handleOnChange(option, false, true);
    };

    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOptionsArray(inputServices().handleFilterSelector(e.target.value, options!, param));
    };

    return (
        <div
            className='w-full relative scrollbar-hidden text-left leading-tight'
            style={{ border: borderStyle, borderRadius: borderStyleRadius, backgroundColor: isDisabled ? 'rgb(226 232 240)' : 'wite' }}
        >
            <div onClick={handleInputClick} className='dropdown-input flex items-center justify-between select-none '>
                <div className='ml-2'>
                    {selectedOption?.length ? (
                        <div className='flex max-w-52 overflow-auto'>
                            {selectedOption.map((element: optionItem | phonePrefix, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className='m-0.5  pl-2 pr-2 text-sm rounded flex items-center'
                                        style={{ background: selectedOptionBG }}
                                    >
                                        <p className='overflow-hidden text-ellipsis whitespace-nowrap'>
                                            {element[param as keyof typeof element]}
                                        </p>
                                        <span onClick={e => onTagRemove(e, element)} className='pl-2'>
                                            <FontAwesomeIcon icon={faCircleXmark} size='xs' className='opacity-50 hover:cursor-pointer' />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <input
                            type='text'
                            placeholder={placeholder}
                            disabled={isDisabled}
                            onChange={handleFilter}
                            className='outline-0 w-full bg-inherit'
                        />
                    )}
                </div>
                <div>
                    <div className='p-2 pr-4'>
                        {!showOptions ? (
                            <FontAwesomeIcon icon={faAngleDown} className='opacity-50' />
                        ) : (
                            <FontAwesomeIcon icon={faAngleUp} className='opacity-50' />
                        )}
                    </div>
                </div>
                {showOptions && optionsArray?.length ? (
                    <div className='absolute w-96 top-9 mt-0.5 overflow-auto transform scale-4 border border-gray-200 rounded max-h-36 bg-white'>
                        {optionsArray?.map((option: optionItem | phonePrefix, index: number) => (
                            <div
                                key={index}
                                onClick={() => handleOnChange(option, isMulti, false)}
                                className='p-2 cursor-pointer hover:bg-slate-100'
                                style={{ background: isSelected(option[param as keyof typeof option]) ? selectedOptionBG : '' }}
                            >
                                {option[param as keyof typeof option]}
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
`;

const elemInputSelectorMultiImplementationMultiChoice = `<InputSelector
placeholder='Disabled selector...'
options={optionsArray}
selectedOption={valueSelector}
onChange={handleOnChangeSelector}
selectedOptionBG={'rgb(226 232 240)'}
isMulti={true}
singleChoice={false}
borderStyle='1px solid grey'
borderStyleRadius='6px'
param='label'
isDisabled={true}
/>
`;

const elemInputSelectorMultiImplementationSingleChoice = `<InputSelector
placeholder='Select an option...'
options={optionsArray}
selectedOption={valueSelector3}
onChange={handleOnChangeSelector3}
selectedOptionBG={'#ff6242'}
isMulti={true}
singleChoice={true}
borderStyle='1px solid grey'
borderStyleRadius='16px'
param='label'
/>
`;

const elemInputSelectorSingleImplementation = `<InputSelector
placeholder='Select an option...'
options={optionsArray}
selectedOption={valueSelector2}
onChange={handleOnChangeSelector2}
selectedOptionBG={'rgb(93 193 185)'}
isMulti={false}
borderStyle='1px solid grey'
borderStyleRadius='22px'
param='label'
/>
`;

const elemInputSearchbar = `interface Props {
    placeholder?: string;
    onChange: (value: string) => void;
    borderRadius?: string;
    buttonRadius?: string;
    iconBG: string;
    iconText?: string;
    iconTextColor?: string;
    isDisabled?: boolean;
}

const InputSearch: React.FC<Props> = ({
    placeholder,
    onChange,
    borderRadius,
    iconBG,
    buttonRadius,
    iconText,
    iconTextColor = 'black',
    isDisabled
}) => {
    let searchValue: string;
    const searchIcon = <FontAwesomeIcon icon={faMagnifyingGlass} className='opacity-50 pr-1 fa-sm' />;
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        searchValue = e.target.value;
    };
    const sendInfo = () => {
        onChange(searchValue);
    };
    return (
        <div
            className=' flex h-9 w-max border'
            style={{ borderRadius: borderRadius, backgroundColor: isDisabled ? 'rgb(226 232 240)' : 'white' }}
        >
            <div className='flex justify-between overflow-hidden items-center text-sm'>
                <input
                    type='text'
                    disabled={isDisabled}
                    onChange={handleSearch}
                    placeholder={placeholder}
                    className=' w-4/5 outline-0 pl-4'
                />
                <Button
                    text={iconText}
                    onclick={sendInfo}
                    background={iconBG}
                    color={iconTextColor}
                    iconLeft={searchIcon}
                    borderRadius={buttonRadius}
                />
            </div>
        </div>
    );
};
`;
const elemInputSearchImplementation1 = `<InputSearch
placeholder='Search item...'
onChange={setSearchValue}
borderRadius='16px'
buttonRadius='16px'
iconBG={'#ADD8E6'}
iconText='Search'
isDisabled={true}
/>`;
const elemInputSearchImplementation2 = `<InputSearch onChange={setSearchValue} borderRadius='12px' buttonRadius='6px' iconBG={'#E3E3E3'} iconText='Search' />

<InputSearch placeholder='Type what you want to search...' onChange={setSearchValue} borderRadius='12px' buttonRadius='6px' iconBG={'#ff6242'}/>

<InputSearch placeholder='Search...' onChange={setSearchValue} borderRadius='26px' buttonRadius='26px' iconBG={'orange'} iconText='Search!'/>

`;

const elemMultiSearchInput = `interface Props {
    firstPlaceholder: string;
    secondPlaceholder: string;
    thirdPlaceholder?: string;
    onChange: (value: any) => void;
    firstOnChangeSelector?: (value: Array<optionItem | phonePrefix>) => void;
    secondOnChangeSelector?: (value: Array<optionItem | phonePrefix>) => void;
    thirdOnChangeSelector?: (value: Array<optionItem | phonePrefix>) => void;
    borderRadius?: string;
    buttonRadius?: string;
    iconBG: string;
    iconText?: string;
    iconTextColor?: string;
    isTriple?: boolean;
    isFirstSelector?: boolean;
    isSecondSelector?: boolean;
    isThirdSelector?: boolean;
    options?: Array<optionItem | phonePrefix> | [];
    firstOptions?: Array<optionItem | phonePrefix> | [];
    firstSelectedOptions?: Array<optionItem | phonePrefix> | [];
    secondOptions?: Array<optionItem | phonePrefix> | [];
    secondSelectedOptions?: Array<optionItem | phonePrefix> | [];
    thirdOptions?: Array<optionItem | phonePrefix> | [];
    thirdSelectedOptions?: Array<optionItem | phonePrefix> | [];
    selectedOption?: Array<optionItem | phonePrefix> | [];
    firstSelectorIsMulti?: boolean;
    secondSelectorIsMulti?: boolean;
    thirdSelectorIsMulti?: boolean;
    isFirstFieldMandatory?: boolean;
    isSecondFieldMandatory?: boolean;
    isThirdFieldMandatory?: boolean;
    firstParam: string;
    secondParam: string;
    thirdParam: string;
    isFirstDisabled?: boolean;
    isSecondDisabled?: boolean;
    isThirdDisabled?: boolean;
}

interface optionItem {
    id: string | number;
    label: string;
    value: string;
    extra: any;
}
interface dataToFilter {
    firstValue: string;
    secondValue: string;
    thirdValue: string;
    firstSelectorValue?: Array<optionItem | phonePrefix>;
    secondSelectorValue?: Array<optionItem | phonePrefix>;
    thirdSelectorValue?: Array<optionItem | phonePrefix>;
}
const inputMultiSearch: React.FC<Props> = ({
    firstPlaceholder,
    secondPlaceholder,
    thirdPlaceholder,
    onChange,
    firstOnChangeSelector,
    secondOnChangeSelector,
    thirdOnChangeSelector,
    borderRadius,
    iconBG,
    buttonRadius,
    iconText,
    iconTextColor = 'black',
    isTriple,
    isFirstSelector,
    isSecondSelector,
    isThirdSelector,
    firstOptions,
    firstSelectedOptions,
    firstSelectorIsMulti,
    secondOptions,
    secondSelectedOptions,
    secondSelectorIsMulti,
    thirdOptions,
    thirdSelectedOptions,
    thirdSelectorIsMulti,
    isFirstFieldMandatory,
    isSecondFieldMandatory,
    isThirdFieldMandatory,
    firstParam,
    secondParam,
    thirdParam,
    isFirstDisabled,
    isSecondDisabled,
    isThirdDisabled
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [dataToFilter, setDataToFilter] = useState<dataToFilter>({
        firstValue: '',
        secondValue: '',
        thirdValue: '',
        firstSelectorValue: [],
        secondSelectorValue: [],
        thirdSelectorValue: []
    });

    useEffect(() => {
        if (isFirstFieldMandatory) {
            setIsDisabled(
                inputServices().handleMandatoryField(isFirstSelector, dataToFilter.firstSelectorValue?.length, dataToFilter.firstValue)
            );
        }
        if (isSecondFieldMandatory) {
            setIsDisabled(
                inputServices().handleMandatoryField(isSecondSelector, dataToFilter.secondSelectorValue?.length, dataToFilter.secondValue)
            );
        }
        if (isThirdFieldMandatory) {
            setIsDisabled(
                inputServices().handleMandatoryField(isThirdSelector, dataToFilter.thirdSelectorValue?.length, dataToFilter.thirdValue)
            );
        }
    }, [dataToFilter]);

    const searchIcon = <FontAwesomeIcon icon={faMagnifyingGlass} className='opacity-50 pr-1' />;

    const handleSaveValue = (e: ChangeEvent<HTMLInputElement>, value: number) => {
        switch (value) {
            case 1:
                e.target.value
                    ? setDataToFilter(dataToFilter => ({ ...dataToFilter, firstValue: e.target.value }))
                    : setDataToFilter(dataToFilter => ({ ...dataToFilter, firstValue: '' }));
                break;
            case 2:
                e.target.value
                    ? setDataToFilter(dataToFilter => ({ ...dataToFilter, secondValue: e.target.value }))
                    : setDataToFilter(dataToFilter => ({ ...dataToFilter, secondValue: '' }));
                break;
            case 3:
                e.target.value
                    ? setDataToFilter(dataToFilter => ({ ...dataToFilter, thirdValue: e.target.value }))
                    : setDataToFilter(dataToFilter => ({ ...dataToFilter, thirdValue: '' }));
                break;
        }
    };

    const handleSelectorChange = (value: Array<optionItem | phonePrefix>, selector: number) => {
        switch (selector) {
            case 1:
                setDataToFilter(dataToFilter => ({ ...dataToFilter, firstSelectorValue: value }));
                firstOnChangeSelector!(value);
                break;
            case 2:
                setDataToFilter(dataToFilter => ({ ...dataToFilter, secondSelectorValue: value }));
                secondOnChangeSelector!(value);
                break;
            case 3:
                setDataToFilter(dataToFilter => ({ ...dataToFilter, thirdSelectorValue: value }));
                thirdOnChangeSelector!(value);
                break;
        }
    };

    const setInfo = () => {
        if (isDisabled) return;
        onChange(dataToFilter);
    };

    return (
        <div className=' flex h-4/5 w-max border' style={{ borderRadius: borderRadius }}>
            <div className='flex justify-around items-center'>
                {isFirstSelector ? (
                    <>
                        <div className='border h-3/6'></div>
                        <div className='w-4/5'>
                            <InputSelector
                                placeholder='Select an option...'
                                options={firstOptions}
                                selectedOption={firstSelectedOptions}
                                onChange={e => handleSelectorChange(e, 1)}
                                isMulti={firstSelectorIsMulti}
                                selectedOptionBG='#D3D3D3'
                                param={firstParam}
                                isDisabled={isFirstDisabled}
                            />
                        </div>
                    </>
                ) : (
                    <input
                        type='text'
                        placeholder={firstPlaceholder}
                        onChange={e => handleSaveValue(e, 1)}
                        className=' w-4/5 outline-0 pl-3'
                        disabled={isFirstDisabled}
                        style={{ backgroundColor: isFirstDisabled ? 'rgb(226 232 240)' : 'wite', height: '100%' }}
                    />
                )}

                {isSecondSelector ? (
                    <>
                        <div className='border h-3/6'></div>
                        <div className='w-4/5'>
                            <InputSelector
                                placeholder='Select an option...'
                                options={secondOptions}
                                selectedOption={secondSelectedOptions}
                                onChange={e => handleSelectorChange(e, 2)}
                                isMulti={secondSelectorIsMulti}
                                selectedOptionBG='#D3D3D3'
                                param={secondParam}
                                isDisabled={isSecondDisabled}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {' '}
                        <div className='border h-3/6'></div>
                        <input
                            type='text'
                            placeholder={secondPlaceholder}
                            onChange={e => handleSaveValue(e, 2)}
                            className='w-4/5  outline-0 pl-3'
                            disabled={isSecondDisabled}
                            style={{ backgroundColor: isSecondDisabled ? 'rgb(226 232 240)' : 'wite', height: '100%' }}
                        />
                    </>
                )}
                {isTriple ? (
                    isThirdSelector ? (
                        <>
                            <div className='border h-3/6'></div>
                            <div className='w-4/5'>
                                <InputSelector
                                    placeholder='Select an option...'
                                    options={thirdOptions}
                                    selectedOption={thirdSelectedOptions}
                                    onChange={e => handleSelectorChange(e, 3)}
                                    isMulti={thirdSelectorIsMulti}
                                    selectedOptionBG='#D3D3D3'
                                    param={thirdParam}
                                    isDisabled={isThirdDisabled}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='border h-3/6'></div>
                            <input
                                type='text'
                                placeholder={thirdPlaceholder}
                                onChange={e => handleSaveValue(e, 3)}
                                className='w-4/5  outline-0 pl-3'
                                disabled={isThirdDisabled}
                                style={{ backgroundColor: isThirdDisabled ? 'rgb(226 232 240)' : 'wite', height: '100%' }}
                            />
                        </>
                    )
                ) : null}
            </div>
            <Button
                text={iconText}
                background={!isDisabled === true ? iconBG : 'grey'}
                color={iconTextColor}
                iconLeft={searchIcon}
                borderRadius={buttonRadius}
                onclick={setInfo}
            />
        </div>
    );
};`;

const elemInputMultiSearch = ` <InputMultiSearch
firstPlaceholder='Disabled...'
secondPlaceholder='Search...'
onChange={setValuesToFilter}
borderRadius='12px'
buttonRadius='6px'
iconBG={'#ff6242'}
firstParam='label'
secondParam='label'
thirdParam='label'
isFirstDisabled={true}
/>`;

const elemInputMultiSearchMandatory = `<InputMultiSearch
firstPlaceholder='Mandatory...'
secondPlaceholder='Type here something...'
thirdPlaceholder='Third value...'
onChange={setValuesToFilter2}
borderRadius='2px'
buttonRadius='12px'
iconBG={'green'}
iconText='Search now'
iconTextColor='white'
isTriple={true}
isFirstFieldMandatory={true}
firstParam='label'
secondParam='label'
thirdParam='label'
/>`;

const elemInputMultiSearchOneSelector = `<InputMultiSearch
firstPlaceholder='Value to search...'
secondPlaceholder='Type here...'
thirdPlaceholder='Type third value...'
onChange={setValuesToFilter3}
thirdOnChangeSelector={handleOnChangeSelector3}
borderRadius='26px'
buttonRadius='26px'
iconBG={'orange'}
iconText='Search!'
iconTextColor='black'
isTriple={true}
isThirdSelector={true}
thirdOptions={optionsArray}
thirdSelectedOptions={valueSelector3}
firstParam='label'
secondParam='label'
thirdParam='label'
/>`;

const elemInputMultiSearchTwoSelectors = `<InputMultiSearch
firstPlaceholder='Value to search...'
secondPlaceholder='Type here...'
thirdPlaceholder='Type third value...'
onChange={setValuesToFilter4}
firstOnChangeSelector={handleOnChangeSelector2}
secondOnChangeSelector={handleOnChangeSelector}
borderRadius='16px'
buttonRadius='16px'
iconBG={'blue'}
iconText='Search!'
iconTextColor='white'
isTriple={true}
isFirstSelector={true}
isSecondSelector={true}
firstOptions={optionsArray}
firstSelectedOptions={valueSelector2}
firstSelectorIsMulti={true}
secondOptions={optionsArray}
secondSelectedOptions={valueSelector}
firstParam='label'
secondParam='label'
thirdParam='label'
isSecondDisabled={true}
/>`;
const elemInputTextArea = `interface Props {
    placeholder: string;
    areaHeight?: string;
    borderRadius?: string;
    maxCharacters?: number;
    showRemainingCharacters?: boolean;
    onChange: (e: any) => void;
}
const InputTextArea: React.FC<Props> = ({
    placeholder,
    areaHeight = '120px',
    borderRadius,
    maxCharacters = 999999,
    showRemainingCharacters,
    onChange
}) => {
    const remainingCharacters = useRef<number>(maxCharacters);
    const parentStyle: CSSProperties = {
        height: areaHeight,
        paddingBottom: '12px',
        borderRadius: borderRadius
    };
    const textAreaStyle: CSSProperties = {
        width: '100%',
        height: '100%',
        resize: 'none',
        borderRadius: borderRadius
    };
    const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (maxCharacters > 0) {
            const actualCharacters = event.target.value.length;
            remainingCharacters.current = maxCharacters - actualCharacters;
        }
        onChange(event.target.value);
    };
    return (
        <div style={parentStyle} className='border relative'>
            <textarea
                placeholder={placeholder}
                onChange={onChangeHandler}
                style={textAreaStyle}
                rows={1}
                maxLength={maxCharacters}
                className='p-3 outline-0'
            />
            {showRemainingCharacters ? (
                <div className='-mt-4 flex items-center'>
                    <p className='m-auto text-xs text-slate-400'>
                        {remainingCharacters.current}/{maxCharacters}
                    </p>
                </div>
            ) : null}
        </div>
    );
};
`;
const elemInputTextAreaImplementationMax = `<InputTextArea
onChange={e => setUser(user => ({ ...user, description: e }))}
placeholder='Max. 50 characters...'
areaHeight='120px'
borderRadius='16px'
maxCharacters={50}
showRemainingCharacters={true}
/>`;

const elemInputTextAreaImplementation = ` <InputTextArea
onChange={e => setUser(user => ({ ...user, description: e }))}
placeholder='Type anything...'
areaHeight='400px'
borderRadius='6px'
/>
`;
const elemInputDate = `interface Props {
    onChange: (e: any) => void;
    minDate?: string;
    maxDate?: string;
    startingDate?: string;
    pickedDate?: string;
    borderRadius?: string;
}

const InputDate: React.FC<Props> = ({ onChange, minDate, maxDate, startingDate = '2023-03-15', pickedDate, borderRadius }) => {
    const handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <div className='border' style={{ borderRadius: borderRadius }}>
            <input
                type='date'
                value={pickedDate ? pickedDate : startingDate}
                min={minDate}
                max={maxDate}
                onChange={handleDate}
                className='p-2 w-full  outline-0'
            />
        </div>
    );
};
`;

const elemInputDateImplementation = `<InputDate
startingDate='2023-03-15'
pickedDate={user.date}
minDate='2023-01-01'
maxDate='2200-12-31'
onChange={e => setUser(user => ({ ...user, date: e }))}
borderRadius='6px'
/>
`;

const elemInputPhone = `interface Props {
    countryPreFix?: boolean;
    onChange: (value: any | undefined) => void;
    placeholder?: string;
    borderStyleRadius?: string;
    isDisabled?: boolean;
    isPrefixDisabled?: boolean;
}

const InputPhone: React.FC<Props> = ({ countryPreFix = false, onChange, placeholder, borderStyleRadius, isDisabled, isPrefixDisabled }) => {
    const [selectedPrefix, setSelectedPrefix] = useState<any>([]);
    const phoneNum = useRef<string>('');
    const finalPhoneNumber = useRef<string>('');
    const phoneData = phonePrefixData;
    const [maxLength, setMaxLength] = useState(15);
    const numberRegex = /^\d+$/;
    const [errorMessageRegex, setErrorMessageRegex] = useState(false);
    const [errorMessageLength, setErrorMessageLength] = useState(false);

    const handleChange = (e: any) => {
        if (!e.target) {
            if (!e.length) {
                if (errorMessageLength === true) setErrorMessageLength(false);
                finalPhoneNumber.current = phoneNum.current;
                setMaxLength(15);
                setSelectedPrefix(e);
            } else {
                if (finalPhoneNumber.current.length + (selectedPrefix[0].dial_code.length - 1) >= 16) {
                    setErrorMessageLength(true);
                    setSelectedPrefix([]);
                    return;
                } else {
                    const length = 15 - (selectedPrefix[0].dial_code.length - 1);
                    setMaxLength(length);
                    setErrorMessageLength(false);
                    finalPhoneNumber.current = selectedPrefix[0].dial_code + phoneNum.current;
                    setSelectedPrefix(e);
                }
            }
        } else if (e.target) {
            phoneNum.current = e.target.value;
            if (e.target.value.length && numberRegex.test(phoneNum.current) === false) {
                setErrorMessageRegex(true);
                return;
            } else {
                setErrorMessageRegex(false);
            }
            if (selectedPrefix[0]) {
                finalPhoneNumber.current = selectedPrefix[0].dial_code + phoneNum.current;
            } else {
                finalPhoneNumber.current = phoneNum.current;
            }
        }
        onChange(finalPhoneNumber.current);
    };

    return (
        <div className='h-10'>
            <div className='flex ' style={{ height: '38px' }}>
                {countryPreFix ? (
                    <div className='w-60'>
                        <InputSelector
                            placeholder='+34'
                            options={phoneData}
                            selectedOption={selectedPrefix}
                            selectedOptionBG={'rgb(226 232 240)'}
                            onChange={handleChange}
                            isMulti={false}
                            param={'dial_code'}
                            borderStyle={'1px solid rgb(226 232 240)'}
                            borderStyleRadius={borderStyleRadius}
                        />
                    </div>
                ) : null}
                <input
                    type='text'
                    placeholder={placeholder}
                    maxLength={maxLength}
                    className='pl-2 outline-0 border'
                    onChange={handleChange}
                    style={{ borderRadius: borderStyleRadius }}
                    disabled={isDisabled}
                />
            </div>
            {errorMessageRegex ? <p className='text-xs mt-2 italic text-red-500'>Telephone must have only numbers</p> : null}
            {errorMessageLength ? <p className='text-xs mt-2 italic text-red-500'>Phone number can't be longer than 15 digits</p> : null}
        </div>
    );
};`;

const elemInputPhoneImplementationPrefix = `<InputPhone
countryPreFix={true}
onChange={e => setUser(user => ({ ...user, phone: e }))}
placeholder='Use prefix'
borderStyleRadius='12px'
isDisabled={true}
isPrefixDisabled={true}
/>`;

const elemInputPhoneImplementation = `<InputPhone
onChange={e => setUser(user => ({ ...user, phone: e }))}
placeholder='Enter your phone number'
borderStyleRadius='6px'
/>`;

const elemInputCheckbox = `interface Props {
    type: string;
    options?: Array<optionItem | phonePrefix>;
    selectedOptions: Array<optionItem | phonePrefix>;
    onChange: any;
    maxChoice?: number;
    isSelected?: boolean;
    selectedOptionBG?: string;
    borderRadius?: string;
    optionBG?: string;
    param: string;
    flexDirection?: CSSProperties['flexDirection'] | undefined;
    showInput?: boolean;
    inputColor?: string;
}

const InputCheckbox: React.FC<Props> = ({
    type,
    options,
    selectedOptions,
    onChange,
    maxChoice,
    optionBG,
    selectedOptionBG,
    borderRadius,
    param,
    flexDirection,
    showInput = true,
    inputColor = 'grey'
}) => {
    const inputsContainer: CSSProperties = {
        display: 'flex',
        flexDirection: flexDirection ? flexDirection : 'column',
        flexWrap: 'wrap',
        gap: '12px'
    };

    const filterArray: Array<optionItem | phonePrefix> = selectedOptions;
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const handleCheck = (option: optionItem | phonePrefix) => {
        if (isSelected(option[param as keyof typeof option])) {
            onChange(inputServices().handleOnChange(filterArray!, option, false, true, false, 'label'));
        } else {
            onChange(inputServices().handleOnChange(filterArray!, option, true, false, false, 'label', maxChoice!));
        }
        forceUpdate();
    };

    const isSelected = (option: string) => {
        return inputServices().isSelected(option, filterArray, param);
    };
    return (
        <div>
            <div style={inputsContainer} className='w-auto hover:cursor-pointer'>
                {options?.map((option: optionItem | phonePrefix, index: number) => (
                    <div
                        key={index}
                        className='flex items-center'
                        onClick={() => handleCheck(option)}
                        style={{
                            background: inputServices().isSelected(option[param as keyof typeof option], filterArray, 'label')
                                ? selectedOptionBG
                                : optionBG,
                            borderRadius: borderRadius
                        }}
                    >
                        {showInput ? (
                            type === 'radio' ? (
                                <div className='-ml-4 -mr-6'>
                                    <Radio
                                        status={inputServices().isSelected(option[param as keyof typeof option], filterArray, 'label')}
                                        bgTrue={inputColor}
                                    />
                                </div>
                            ) : (
                                <div className='-mr-2'>
                                    <Checkbox
                                        status={inputServices().isSelected(option[param as keyof typeof option], filterArray, 'label')}
                                        bgTrue={inputColor}
                                    />
                                </div>
                            )
                        ) : null}
                        <p className='p-2' style={{ margin: showInput ? '' : 'auto' }}>
                            {option[param as keyof typeof option]}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};`;

const elemInputCheckboxImplementation = `<InputCheckbox
        type='checkbox'
        options={optionsArray}
        onChange={setValueCheckbox}
        selectedOptions={valueCheckbox}
        maxChoice={3}
        param='label'
        flexDirection='row'
/>`;
const elemInputCheckboxImplementation2 = `<InputCheckbox
        type='radio'
        options={optionsArray}
        onChange={setValueCheckbox2}
        selectedOptions={valueCheckbox2}
        optionBG={'#b3ecec'}
        selectedOptionBG={'rgb(189 032 232)'}
        borderRadius={'16px'}
        param='label'
        inputColor='blue'
/>`;
const elemInputCheckboxImplementation3 = `<InputCheckbox
        type='radio'
        options={optionsArray}
        onChange={setValueCheckbox3}
        selectedOptions={valueCheckbox3}
        selectedOptionBG={'orange'}
        borderRadius={'6px'}
        param='label'
        maxChoice={1}
        showInput={false}
/>`;

interface basicInputData {
    name: string;
    counter: number;
    email: string;
    password: string;
    phone: string;
    description: string;
    date: string | undefined;
}

interface optionItem {
    id: string | number;
    label: string;
    value: string;
    extra: object;
}
interface itemsForFilter {
    firstValue: string;
    firstSelectorValue: Array<optionItem>;
    secondValue: string;
    secondSelectorValue: Array<optionItem>;
    thirdValue: string;
    thirdSelectorValue: Array<optionItem>;
}
const InputsTest = () => {
    const optionsArray = [
        { id: 0, label: 'Option 1', value: 'option1', extra: {} },
        { id: 1, label: 'Option 2', value: 'option2', extra: {} },
        { id: 2, label: 'Option 3', value: 'option3', extra: {} },
        { id: 3, label: 'Option 4', value: 'option4', extra: {} },
        { id: 4, label: 'Option 5', value: 'option5', extra: {} },
        { id: 5, label: 'Option 6', value: 'option6', extra: {} },
        { id: 6, label: 'Option 11', value: 'option11', extra: {} },
        { id: 7, label: 'Option 12', value: 'option12', extra: {} },
        { id: 8, label: 'Option 21', value: 'option21', extra: {} }
    ];

    const [file, setFile] = useState<File>();
    const [user, setUser] = useState<basicInputData>({
        name: '',
        counter: 0,
        email: '',
        password: '',
        phone: '',
        description: '',
        date: undefined
    });
    const [searchValue, setSearchValue] = useState<string>();
    const [valuesToFilter, setValuesToFilter] = useState<itemsForFilter>({
        firstValue: '',
        firstSelectorValue: [],
        secondValue: '',
        secondSelectorValue: [],
        thirdValue: '',
        thirdSelectorValue: []
    });
    const [valuesToFilter2, setValuesToFilter2] = useState<itemsForFilter>({
        firstValue: '',
        firstSelectorValue: [],
        secondValue: '',
        secondSelectorValue: [],
        thirdValue: '',
        thirdSelectorValue: []
    });
    const [valuesToFilter3, setValuesToFilter3] = useState<itemsForFilter>({
        firstValue: '',
        firstSelectorValue: [],
        secondValue: '',
        secondSelectorValue: [],
        thirdValue: '',
        thirdSelectorValue: []
    });
    const [valuesToFilter4, setValuesToFilter4] = useState<itemsForFilter>({
        firstValue: '',
        firstSelectorValue: [],
        secondValue: '',
        secondSelectorValue: [],
        thirdValue: '',
        thirdSelectorValue: []
    });

    const [valueSelector, setValueSelector] = useState<Array<optionItem | phonePrefix>>([]);

    const [valueSelector2, setValueSelector2] = useState<Array<optionItem | phonePrefix>>([]);

    const [valueSelector3, setValueSelector3] = useState<Array<optionItem | phonePrefix>>([]);

    const [valueCheckbox, setValueCheckbox] = useState<Array<optionItem>>([]);
    const [valueCheckbox2, setValueCheckbox2] = useState<Array<optionItem>>([]);
    const [valueCheckbox3, setValueCheckbox3] = useState<Array<optionItem>>([]);
    const handleFile = (element: any = {}) => {
        setFile(element);
    };

    const handleOnChangeSelector = (value: Array<optionItem | phonePrefix>) => {
        setValueSelector(value);
    };
    const handleOnChangeSelector2 = (value: Array<optionItem | phonePrefix>) => {
        setValueSelector2(value);
    };
    const handleOnChangeSelector3 = (value: Array<optionItem | phonePrefix>) => {
        setValueSelector3(value);
    };

    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Inputs</div>
            <div className='text-lg mt-4'>The text must be passed translated and formatted in all the elements.</div>
            <div className='text-xl mt-4 underline'>Basic Input</div>
            <p>Used for text, numbers, email and password.</p>

            <CopyBlock language={'jsx'} text={elemBasicInput} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />

            <p className='text-lg mt-8'>Implementation of the Input with different types.</p>
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <div>
                    <p className='text-lg mt-2 mb-4'>Type text:</p>
                    <CopyBlock
                        language={'jsx'}
                        text={elemBasicInputDetailText}
                        showLineNumbers={true}
                        theme={atomOneDark}
                        wrapLines={true}
                        codeBlock
                    />
                    <p className='mt-4 mb-4 w-64'>Example:</p>
                    <Input
                        id='name'
                        name='name'
                        placeholder='Disabled field'
                        type='text'
                        value={user.name}
                        onChange={e => setUser(user => ({ ...user, name: e }))}
                        borderRadius='6px'
                        isDisabled={true}
                    />
                </div>
                <div>
                    <p className='text-lg mt-2 mb-4'>Type email:</p>
                    <CopyBlock
                        language={'jsx'}
                        text={elemBasicInputDetailEmail}
                        showLineNumbers={true}
                        theme={atomOneDark}
                        wrapLines={true}
                        codeBlock
                    />
                    <p className='mt-4 mb-4 w-64'>Example:</p>
                    <Input
                        id='email'
                        name='email'
                        type='email'
                        placeholder='info.test@test.com'
                        value={user.email}
                        onChange={e => setUser(user => ({ ...user, email: e }))}
                        borderRadius='6px'
                    />
                </div>
                <div>
                    <p className='text-lg mt-2 mb-4'>Type password:</p>
                    <CopyBlock
                        language={'jsx'}
                        text={elemBasicInputDetailPassword}
                        showLineNumbers={true}
                        theme={atomOneDark}
                        wrapLines={true}
                        codeBlock
                    />
                    <p className='mt-4 mb-4 w-64'>Example:</p>
                    <Input
                        id='password'
                        name='password'
                        type='password'
                        placeholder='Enter your password'
                        value={user.password}
                        onChange={e => setUser(user => ({ ...user, password: e }))}
                        borderRadius='6px'
                        enableShowPassword={true}
                    />
                </div>
                <div>
                    <p className='text-lg mt-2 mb-4'>Type number:</p>
                    <CopyBlock
                        language={'jsx'}
                        text={elemBasicInputDetailNumber}
                        showLineNumbers={true}
                        theme={atomOneDark}
                        wrapLines={true}
                        codeBlock
                    />
                    <p className='mt-4 mb-4 w-64'>Example:</p>
                    <Input
                        id='counter'
                        name='counter'
                        type='number'
                        value={user.counter}
                        onChange={e => setUser(user => ({ ...user, counter: e }))}
                        borderRadius='6px'
                    />
                </div>
            </div>
            <div className='text-xl mt-4 underline'>Checkbox/Radio Input</div>
            <p>Renders multi check options</p>
            <CopyBlock language={'jsx'} text={elemInputCheckbox} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <p className='text-lg mt-8'>Implementation of the Input.</p>
            <p className=' mt-8'>Implementation with no decoration, multi selection enabled and limited to 3 choices.</p>
            <CopyBlock
                language={'jsx'}
                text={elemInputCheckboxImplementation}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mt-4'>
                <InputCheckbox
                    type='checkbox'
                    options={optionsArray}
                    onChange={setValueCheckbox}
                    selectedOptions={valueCheckbox}
                    maxChoice={3}
                    param='label'
                    flexDirection='row'
                />
            </div>
            <p className=' mt-8'>
                Implementation with background color for the option and for the chosen option, with multi select and no limitation.
            </p>
            <CopyBlock
                language={'jsx'}
                text={elemInputCheckboxImplementation2}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mt-4 w-40'>
                <InputCheckbox
                    type='radio'
                    options={optionsArray}
                    onChange={setValueCheckbox2}
                    selectedOptions={valueCheckbox2}
                    optionBG={'#b3ecec'}
                    selectedOptionBG={'rgb(189 032 232)'}
                    borderRadius={'16px'}
                    param='label'
                    inputColor='blue'
                />
            </div>

            <p className=' mt-8'>Implementation with background color on selected item, single choice and without the input.</p>
            <CopyBlock
                language={'jsx'}
                text={elemInputCheckboxImplementation3}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mt-4 w-24'>
                <InputCheckbox
                    type='radio'
                    options={optionsArray}
                    onChange={setValueCheckbox3}
                    selectedOptions={valueCheckbox3}
                    selectedOptionBG={'orange'}
                    borderRadius={'6px'}
                    param='label'
                    maxChoice={1}
                    showInput={false}
                />
            </div>
            <div className='text-xl mt-10 underline'>Input Phone</div>
            <p>Phone input with te option to add country prefix.</p>
            <p>Prop 'countryPreFix' enables a selector with all country codes.</p>
            <p>Prop 'isDisabled' disabled the input text file, while 'isPrefixDisabled' disables the selector.</p>
            <CopyBlock language={'jsx'} text={elemInputPhone} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Implementations of the Input with prefix.</div>
            <CopyBlock
                language={'jsx'}
                text={elemInputPhoneImplementationPrefix}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mt-4 mb-16 w-96'>
                <p>Example:</p>
                <InputPhone
                    countryPreFix={true}
                    onChange={e => setUser(user => ({ ...user, phone: e }))}
                    placeholder='Use prefix'
                    borderStyleRadius='12px'
                />
            </div>
            <div className='text-lg mt-20'>Implementations of the Input without prefix.</div>
            <CopyBlock
                language={'jsx'}
                text={elemInputPhoneImplementation}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mt-4 w-96'>
                <p>Example:</p>
                <InputPhone
                    onChange={e => setUser(user => ({ ...user, phone: e }))}
                    placeholder='Enter your phone number'
                    borderStyleRadius='6px'
                />
            </div>
            <div className='text-xl mt-4 underline'>Text Area Input:</div>
            <p>Input with multiple lines.</p>
            <CopyBlock language={'jsx'} text={elemInputTextArea} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div>
                <p className='mt-3'>Implementation with limit of characters and showing characters remaining:</p>
                <CopyBlock
                    language={'jsx'}
                    text={elemInputTextAreaImplementationMax}
                    showLineNumbers={true}
                    theme={atomOneDark}
                    wrapLines={true}
                    codeBlock
                />
                <div className='mt-4 w-3/4'>
                    <InputTextArea
                        onChange={e => setUser(user => ({ ...user, description: e }))}
                        placeholder='Max. 50 characters...'
                        areaHeight='120px'
                        borderRadius='16px'
                        maxCharacters={50}
                        showRemainingCharacters={true}
                        isDisabled={true}
                    />
                </div>
                <p className='mt-3'>Implementation without limit of characters:</p>
                <CopyBlock
                    language={'jsx'}
                    text={elemInputTextAreaImplementation}
                    showLineNumbers={true}
                    theme={atomOneDark}
                    wrapLines={true}
                    codeBlock
                />
                <div className='mt-4 w-3/4'>
                    <InputTextArea
                        onChange={e => setUser(user => ({ ...user, description: e }))}
                        placeholder='Type anything...'
                        areaHeight='400px'
                        borderRadius='6px'
                    />
                </div>
            </div>
            <div className='text-xl mt-10 underline'>Input Date picker</div>
            <p>A basic date picker used in forms </p>
            <CopyBlock language={'jsx'} text={elemInputDate} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Implementations of the Input with minDate and maxDate.</div>
            <CopyBlock
                language={'jsx'}
                text={elemInputDateImplementation}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mt-8 w-60'>
                <InputDate
                    startingDate='2023-03-15'
                    pickedDate={user.date}
                    minDate='2023-01-01'
                    maxDate='2200-12-31'
                    onChange={e => setUser(user => ({ ...user, date: e }))}
                    borderRadius='6px'
                />
            </div>
            <div className='text-xl mt-10 underline'>Input File</div>
            <p>Used in a form to upload files.</p>
            <CopyBlock language={'jsx'} text={elemInputFile} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Implementations of the Input.</div>
            <CopyBlock
                language={'jsx'}
                text={elemInputFileImplementation}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mt-8'>
                <InputFile
                    label='Upload Document'
                    onChange={e => handleFile(e)}
                    fileRequirements='(pdf, docs, max. 2MB)'
                    emptyFile='No file yet'
                    fileName={file?.name}
                    borderRadius={'6px'}
                />
            </div>
            <div className='text-xl mt-10 underline'>Input Selector Drop</div>
            <p>Dropdown selector with possibility to chose a single or multiple values.</p>
            <p>Selector with funcionalitaty to filter options.</p>
            <p>In case you use the Dropwon Input, it is mandatory to pass the following props:</p>
            <ul className='list-disc'>
                <li className='m-8 mt-3'>
                    <b>(firstOptions, secondOptions, thirdOptions):</b> the corresponding options depending on wich Selector is used.
                </li>
                <li className='m-8'>
                    <b> (firstSelectedOptions, secondSelectedOptions, thirdSelectedOptions):</b> the corresponding selectedOption.
                </li>
                <li className='m-8'>
                    <b> (firstSelectorIsMulti, secondSelectorIsMulti, thirdSelectorIsMulti): </b>allows multi selection on the corresponding
                    Selector.
                </li>
                <li className='m-8'>
                    <b> (isFirstFieldMandatory, isSecondFieldMandatory, isThirdFieldMandatory): </b>allows to make a field mandatory to send
                    the data.
                </li>
                <li className='m-8'>
                    <b> (param): </b> to choose the key in the object used for the logic and to display the option (ex: 'name', 'age', 'id',
                    'label'...)
                </li>
            </ul>
            <CopyBlock language={'jsx'} text={elemInputSelector} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-12'>Implementations of the Input.</div>
            <div className='mt-4'>
                Implementations with multiple selections enabled and the possibility to choose the same option many times, with grey
                background:
            </div>
            <CopyBlock
                language={'jsx'}
                text={elemInputSelectorMultiImplementationMultiChoice}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mb-24 mt-4  w-2/4'>
                <InputSelector
                    placeholder='Disabled selector...'
                    options={optionsArray}
                    selectedOption={valueSelector}
                    onChange={handleOnChangeSelector}
                    selectedOptionBG={'rgb(226 232 240)'}
                    isMulti={true}
                    singleChoice={false}
                    borderStyle='1px solid grey'
                    borderStyleRadius='6px'
                    param='label'
                    isDisabled={true}
                />
            </div>
            <div className='mt-4'>
                Implementations with multiple selections enabled and each option can only be choosen once, with red background on selected
                options:
            </div>
            <CopyBlock
                language={'jsx'}
                text={elemInputSelectorMultiImplementationSingleChoice}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mb-24 mt-4  w-2/4'>
                <InputSelector
                    placeholder='Select an option...'
                    options={optionsArray}
                    selectedOption={valueSelector3}
                    onChange={handleOnChangeSelector3}
                    selectedOptionBG={'#ff6242'}
                    isMulti={true}
                    singleChoice={true}
                    borderStyle='1px solid grey'
                    borderStyleRadius='16px'
                    param='label'
                />
            </div>
            <div className='mt-4'>Implementations with single selection and turquoise background:</div>
            <CopyBlock
                language={'jsx'}
                text={elemInputSelectorSingleImplementation}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='mb-24 mt-4 w-2/4'>
                <InputSelector
                    placeholder='Select an option...'
                    options={optionsArray}
                    selectedOption={valueSelector2}
                    onChange={handleOnChangeSelector2}
                    selectedOptionBG={'rgb(93 193 185)'}
                    isMulti={false}
                    borderStyle='1px solid grey'
                    borderStyleRadius='22px'
                    param='label'
                />
            </div>

            <div className='text-xl mt-4 underline'>Serch Input</div>
            <p>Captures de value that will be send to the API to filter.</p>
            <CopyBlock language={'jsx'} text={elemInputSearchbar} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-12'>Implementations of the Input.</div>
            <CopyBlock
                language={'jsx'}
                text={elemInputSearchImplementation1}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='m-auto'>
                <div className='mb-2 '>
                    <InputSearch
                        placeholder='Search item...'
                        onChange={setSearchValue}
                        borderRadius='16px'
                        buttonRadius='16px'
                        iconBG={'#ADD8E6'}
                        iconText='Search'
                        isDisabled={true}
                    />
                </div>
                <Card
                    id='1'
                    width={250}
                    padding='2rem'
                    borderRadius='10px'
                    backgroundColor='lightgrey'
                    borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                    border='1px solid grey'
                    action={() => console.log('Card clicked!')}
                >
                    <h3 className='font-bold'>Value send to the API to filter:</h3>
                    <div className='mt-4'>{searchValue}</div>
                </Card>
            </div>

            <p className='mt-3'>
                Implementation with different background colors, placeholders, text on icons, button text, colors of text and border radius:
            </p>
            <CopyBlock
                language={'jsx'}
                text={elemInputSearchImplementation2}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex justify-around'>
                <InputSearch onChange={setSearchValue} borderRadius='12px' buttonRadius='6px' iconBG={'#E3E3E3'} iconText='Search' />
                <InputSearch
                    placeholder='Type what you want to search...'
                    onChange={setSearchValue}
                    borderRadius='12px'
                    buttonRadius='6px'
                    iconBG={'#ff6242'}
                />
                <InputSearch
                    placeholder='Search...'
                    onChange={setSearchValue}
                    borderRadius='26px'
                    buttonRadius='26px'
                    iconBG={'orange'}
                    iconText='Search!'
                    iconTextColor='white'
                />
            </div>

            <div className='text-xl mt-4 underline'>Multi Search Input</div>
            <p>Filters an array of options and returns an array with the filtered items based on multiple params (two or three).</p>
            <p>A field can be used as an Input to type text, or as a Dropdown Selector Input.</p>
            <p>The props isTriple is used to show a third field for the search</p>
            <p>In case you use the Dropwon Input, it is mandatory to pass the following props:</p>
            <ul className='list-disc'>
                <li className='m-8 mt-3'>
                    <b>(firstOptions, secondOptions, thirdOptions):</b> the corresponding options depending on wich Selector is used.
                </li>
                <li className='m-8'>
                    <b> (firstSelectedOptions, secondSelectedOptions, thirdSelectedOptions):</b> the corresponding selectedOption.
                </li>
                <li className='m-8'>
                    <b> (firstSelectorIsMulti, secondSelectorIsMulti, thirdSelectorIsMulti): </b>allows multi selection on the corresponding
                    Selector.
                </li>
                <li className='m-8'>
                    <b> (isFirstFieldMandatory, isSecondFieldMandatory, isThirdFieldMandatory): </b>allows to make a field mandatory to send
                    the data.
                </li>
                <li className='m-8'>
                    <b> (param): </b> to choose the key in the object used for the logic and to display the option (ex: 'name', 'age', 'id',
                    'label'...)
                </li>
            </ul>
            <CopyBlock language={'jsx'} text={elemMultiSearchInput} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <p className='mt-4'>Implementation with 2 values to search:</p>
            <CopyBlock language={'jsx'} text={elemInputMultiSearch} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div>
                <InputMultiSearch
                    firstPlaceholder='Disabled...'
                    secondPlaceholder='Search...'
                    onChange={setValuesToFilter}
                    borderRadius='12px'
                    buttonRadius='6px'
                    iconBG={'#ff6242'}
                    firstParam='label'
                    secondParam='label'
                    thirdParam='label'
                    isFirstDisabled={true}
                />
            </div>
            <Card
                id='4'
                width={500}
                padding='2rem'
                borderRadius='10px'
                backgroundColor='lightgrey'
                borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                border='1px solid grey'
                action={() => console.log('Card clicked!')}
            >
                <h3 className='font-bold'>Items to filter:</h3>
                <li className='mt-4'>First value: {valuesToFilter.firstValue}</li>
                <li className='mt-4'>Second value: {valuesToFilter.secondValue}</li>
            </Card>
            <p className='mt-4'>Implementation with 3 values to search and the first field mandatory to be filled:</p>
            <CopyBlock
                language={'jsx'}
                text={elemInputMultiSearchMandatory}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div>
                <InputMultiSearch
                    firstPlaceholder='Mandatory...'
                    secondPlaceholder='Type here something...'
                    thirdPlaceholder='Third value...'
                    onChange={setValuesToFilter2}
                    borderRadius='2px'
                    buttonRadius='12px'
                    iconBG={'green'}
                    iconText='Search now'
                    iconTextColor='white'
                    isTriple={true}
                    isFirstFieldMandatory={true}
                    firstParam='label'
                    secondParam='label'
                    thirdParam='label'
                />
            </div>
            <Card
                id='4'
                width={500}
                padding='2rem'
                borderRadius='10px'
                backgroundColor='lightgrey'
                borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                border='1px solid grey'
                action={() => console.log('Card clicked!')}
            >
                <h3 className='font-bold'>Items to filter:</h3>
                <li className='mt-4'>First value: {valuesToFilter2.firstValue}</li>
                <li className='mt-4'>Second value: {valuesToFilter2.secondValue}</li>
                <li className='mt-4'>Third value: {valuesToFilter2.thirdValue}</li>
            </Card>
            <p className='mt-4'>Implementation with 3 values to search and one selector:</p>
            <CopyBlock
                language={'jsx'}
                text={elemInputMultiSearchOneSelector}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div>
                <InputMultiSearch
                    firstPlaceholder='Value to search...'
                    secondPlaceholder='Type here...'
                    thirdPlaceholder='Type third value...'
                    onChange={setValuesToFilter3}
                    thirdOnChangeSelector={handleOnChangeSelector3}
                    borderRadius='26px'
                    buttonRadius='26px'
                    iconBG={'orange'}
                    iconText='Search!'
                    iconTextColor='black'
                    isTriple={true}
                    isThirdSelector={true}
                    thirdOptions={optionsArray}
                    thirdSelectedOptions={valueSelector3}
                    firstParam='label'
                    secondParam='label'
                    thirdParam='label'
                />
            </div>
            <Card
                id='4'
                width={500}
                padding='2rem'
                borderRadius='10px'
                backgroundColor='lightgrey'
                borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                border='1px solid grey'
                action={() => console.log('Card clicked!')}
            >
                <h3 className='font-bold'>Items to filter:</h3>
                <li className='mt-4'>First value: {valuesToFilter3.firstValue}</li>
                <li className='mt-4'>Second value: {valuesToFilter3.secondValue}</li>
                <li className='mt-4'>Third value: {valuesToFilter3.thirdSelectorValue[0]?.label}</li>
            </Card>
            <p className='mt-4'>Implementation with 3 values to search and two selectors:</p>
            <CopyBlock
                language={'jsx'}
                text={elemInputMultiSearchTwoSelectors}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div>
                <InputMultiSearch
                    firstPlaceholder='Value to search...'
                    secondPlaceholder='Type here...'
                    thirdPlaceholder='Type third value...'
                    onChange={setValuesToFilter4}
                    firstOnChangeSelector={handleOnChangeSelector2}
                    secondOnChangeSelector={handleOnChangeSelector}
                    borderRadius='16px'
                    buttonRadius='16px'
                    iconBG={'blue'}
                    iconText='Search!'
                    iconTextColor='white'
                    isTriple={true}
                    isFirstSelector={true}
                    isSecondSelector={true}
                    firstOptions={optionsArray}
                    firstSelectedOptions={valueSelector2}
                    firstSelectorIsMulti={true}
                    secondOptions={optionsArray}
                    secondSelectedOptions={valueSelector}
                    firstParam='label'
                    secondParam='label'
                    thirdParam='label'
                    isSecondDisabled={true}
                />
            </div>
            <Card
                id='4'
                width={500}
                padding='2rem'
                borderRadius='10px'
                backgroundColor='lightgrey'
                borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                border='1px solid grey'
                action={() => console.log('Card clicked!')}
            >
                <h3 className='font-bold'>Items to filter:</h3>
                <li className='mt-4'>
                    First value:
                    <p className='ml-8'>{valuesToFilter4.firstSelectorValue[0]?.label}</p>
                    <p className='ml-8'>{valuesToFilter4.firstSelectorValue[1]?.label}</p>
                    <p className='ml-8'>{valuesToFilter4.firstSelectorValue[2]?.label}</p>
                    <p className='ml-8'>{valuesToFilter4.firstSelectorValue[3]?.label}</p>
                    <p className='ml-8'>{valuesToFilter4.firstSelectorValue[4]?.label}</p>
                    <p className='ml-8'>{valuesToFilter4.firstSelectorValue[5]?.label}</p>
                </li>
                <li className='mt-4'>Second value: {valuesToFilter4.secondSelectorValue[0]?.label}</li>
                <li className='mt-4'>Third value: {valuesToFilter4.thirdValue}</li>
            </Card>
        </div>
    );
};

export default InputsTest;
