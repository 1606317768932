import { usePopupValue } from './use-popup-value';
import { useSetPopup } from './use-set-popup';

export const usePopup = () => {
  const popupOptions = usePopupValue();
  const setPopupOptions = useSetPopup();

  return {
    ...popupOptions,
    ...setPopupOptions,
  };
};
