import { useNavigate } from 'react-router-dom';
import { useResetPatients, useSetSelectedQuote, useSetSession } from '../../../hooks';

export const useLogOut = () => {
  const { removeSession } = useSetSession();
  const { resetPatients } = useResetPatients();
  const { removeSelectedQuote } = useSetSelectedQuote();
  const navigate = useNavigate();

  const logOut = (redirect?: boolean | string) => {
    removeSession();
    resetPatients();
    removeSelectedQuote();

    if (redirect) {
      const route = typeof redirect === 'string' ? redirect : '/login';
      navigate(route);
    }
  };

  return { logOut };
};
