import { useSetAtom } from 'jotai';
import { urlToReturnAtom } from '../../store/global/atoms';

export const useSetUrlToReturn = () => {
  const setUrlToReturnBase = useSetAtom(urlToReturnAtom);

  const setUrlToReturn = (url: string) => {
    setUrlToReturnBase(url);
  };

  const removeUrlToReturn = () => {
    setUrlToReturnBase('');
  };

  return { setUrlToReturn, removeUrlToReturn };
};
