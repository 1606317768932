import React, { useState, useRef } from 'react';
import InputSelector from './InputSelector';
import { phonePrefixData } from '../../hexagonal-architecture-frontend-base/src/infrastructure/localData/phonePrefixData';

interface Props {
    countryPreFix?: boolean;
    onChange: (value: any | undefined) => void;
    placeholder?: string;
    borderStyleRadius?: string;
    isDisabled?: boolean;
    isPrefixDisabled?: boolean;
}

const InputPhone: React.FC<Props> = ({ countryPreFix = false, onChange, placeholder, borderStyleRadius, isDisabled, isPrefixDisabled }) => {
    const [selectedPrefix, setSelectedPrefix] = useState<any>([]);
    const phoneNum = useRef<string>('');
    const finalPhoneNumber = useRef<string>('');
    const phoneData = phonePrefixData;
    const [maxLength, setMaxLength] = useState(15);
    const numberRegex = /^\d+$/;
    const [errorMessageRegex, setErrorMessageRegex] = useState(false);
    const [errorMessageLength, setErrorMessageLength] = useState(false);

    const handleChange = (e: any) => {
        if (!e.target) {
            if (!e.length) {
                if (errorMessageLength === true) setErrorMessageLength(false);
                finalPhoneNumber.current = phoneNum.current;
                setMaxLength(15);
                setSelectedPrefix(e);
            } else {
                if (finalPhoneNumber.current.length + (selectedPrefix[0].dial_code.length - 1) >= 16) {
                    setErrorMessageLength(true);
                    setSelectedPrefix([]);
                    return;
                } else {
                    const length = 15 - (selectedPrefix[0].dial_code.length - 1);
                    setMaxLength(length);
                    setErrorMessageLength(false);
                    finalPhoneNumber.current = selectedPrefix[0].dial_code + phoneNum.current;
                    setSelectedPrefix(e);
                }
            }
        } else if (e.target) {
            phoneNum.current = e.target.value;
            if (e.target.value.length && numberRegex.test(phoneNum.current) === false) {
                setErrorMessageRegex(true);
                return;
            } else {
                setErrorMessageRegex(false);
            }
            if (selectedPrefix[0]) {
                finalPhoneNumber.current = selectedPrefix[0].dial_code + phoneNum.current;
            } else {
                finalPhoneNumber.current = phoneNum.current;
            }
        }
        onChange(finalPhoneNumber.current);
    };

    return (
        <div className='h-10'>
            <div className='flex ' style={{ height: '38px' }}>
                {countryPreFix ? (
                    <div className='w-60'>
                        <InputSelector
                            placeholder='+34'
                            options={phoneData}
                            selectedOption={selectedPrefix}
                            selectedOptionBG={'rgb(226 232 240)'}
                            onChange={handleChange}
                            isDisabled={isPrefixDisabled}
                            isMulti={false}
                            param={'dial_code'}
                            borderStyle={'1px solid #E5E7EB'}
                            borderStyleRadius={borderStyleRadius}
                        />
                    </div>
                ) : null}
                <input
                    type='text'
                    placeholder={placeholder}
                    maxLength={maxLength}
                    className='pl-2 outline-0 border'
                    onChange={handleChange}
                    style={{ border: '1px solid #E5E7EB', borderRadius: borderStyleRadius }}
                    disabled={isDisabled}
                />
            </div>
            {errorMessageRegex ? <p className='text-xs mt-2 italic text-red-500'>Telephone must have only numbers</p> : null}
            {errorMessageLength ? <p className='text-xs mt-2 italic text-red-500'>Phone number can not be longer than 15 digits</p> : null}
        </div>
    );
};

export default InputPhone;
