import { TiendaRepository } from '../../domain/repositories/tienda.repository';
import { IOrder } from '../../domain/models/IPago';
import { IAddressData } from '../../domain/models/IAddressItem';
import { ICartItem } from '../../domain/models/ICartItem';

export const tiendaServices = (repository: TiendaRepository): TiendaRepository => ({
    getCategory: async (categoryId: number) => {
        return repository.getCategory(categoryId);
    },
    getCategories: async () => {
        return repository.getCategories();
    },
    getProductos: async (category?: number, name?: string, numberItems?: number, currentPage?: number) => {
        return repository.getProductos(category, name, numberItems, currentPage);
    },
    getProductosCount: async (category?: number, name?: string) => {
        return repository.getProductosCount(category, name);
    },
    getProducto: async id => {
        return repository.getProducto(id);
    },
    getBasketItems: async (reloadData?: boolean) => {
        return repository.getBasketItems(reloadData);
    },
    getBasketAddress: async (type: string) => {
        return repository.getBasketAddress(type);
    },
    updateBasketAddress: async address => {
        return repository.updateBasketAddress(address);
    },
    getServiciosItems: async (currentPage?: number, numberItems?: number) => {
        return repository.getServiciosItems(currentPage, numberItems);
    },
    getServiciosItemsCount: async () => {
        return repository.getServiciosItemsCount();
    },
    getSuscriptionsItems: async (numberItems?: number, currentPage?: number, selected?: string) => {
        return repository.getSuscriptionsItems(numberItems, currentPage, selected);
    },
    getSuscriptionsItemsCount: async (selected: string) => {
        return repository.getSuscriptionsItemsCount(selected);
    },
    getPedidosItems: async (numberItems?: number, currentPage?: number, status?: string) => {
        return repository.getPedidosItems(numberItems, currentPage, status);
    },
    getPedidosItemsCount: async (status: string) => {
        return repository.getPedidosItemsCount(status);
    },
    addItemToBasket: async (id?: number, quantity?: number) => {
        return repository.addItemToBasket(id, quantity);
    },
    removeItemBasket: async (id?: number) => {
        return repository.removeItemBasket(id);
    },
    removeAllItemsBasket: async () => {
        return repository.removeAllItemsBasket();
    },
    formatPrice: (price: number) => {
        return repository.formatPrice(price);
    },
    placeOrder: (id: number, items: ICartItem[], recurrent: boolean, frequency: number, address: IAddressData, subscriptionId?: string) => {
        return repository.placeOrder(id, items, recurrent, frequency, address, subscriptionId);
    },
    updateOrder(order: IOrder) {
        return repository.updateOrder(order);
    },
    cancelOrder: (orderId: number) => {
        return repository.cancelOrder(orderId);
    },
    checkoutPayment: (body: {
        id: string;
        amount: number;
        userId: number;
        stripeId: string;
        orderId: number;
        serviceId: string;
        type: string;
    }) => {
        return repository.checkoutPayment(body);
    },
    checkoutPaymentSubscription: (body: {
        id: string;
        stripeId: string;
        amount: number;
        billingCycle: number;
        userId: number;
        orderId: number;
    }) => {
        return repository.checkoutPaymentSubscription(body);
    },
    cancelStripeSubscription: (subscriptionId: string) => {
        return repository.cancelStripeSubscription(subscriptionId);
    },
    updateStripeSubscription: (months: number, subscriptionId: string) => {
        return repository.updateStripeSubscription(months, subscriptionId);
    },
    sendOrderByMail: (body: { mail: string; name: string; products: any[] }) => {
        return repository.sendOrderByMail(body);
    },
    sendTransfMail: (details: { body: string; mail: string; salut: string; subject: string }) => {
        return repository.sendTransfMail(details);
    }
});
