import { tiendaRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { PaymentCheckoutParams } from '../../ts';

export const paymentCheckoutService = async (body: PaymentCheckoutParams) => {
  const response = await tiendaServices(tiendaRepositoryInstance).checkoutPayment(body);

  if (response === 'errors.cardPaymentError' || response === 'errors.paymentError')
    throw new Error(response);

  return response;
};
