import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loaders/Loader';
import { generateUid } from '../../hexagonal-architecture-frontend-base/src/infrastructure/plugins/uid/uid';
import { ButtonBaseProps } from '../../ts';

export const ButtonBase: React.FC<ButtonBaseProps> = ({
  background,
  border,
  borderRadius = '0px',
  buttonHeight,
  buttonWidth,
  color,
  disabled = false,
  extraClass,
  fontSize = '16px',
  iconLeft,
  iconRight,
  iconWidth = '40px',
  loader = 'regular',
  loaderColor,
  loading = false,
  margin = '0px',
  minHeight,
  onClick,
  onClickNavigationRoute,
  paddingX,
  paddingY,
  text,
  textPosition = 'center',
  weight,
  children,
  ...rest
}) => {
  const navigate = useNavigate();

  const clickNavigation = (route: string) => {
    navigate(route);
  };

  const uuid = generateUid();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const el = document.getElementById(uuid);
    setHeight(el?.offsetHeight ? el?.offsetHeight : 0);
  }, []);

  const padX = paddingX || paddingX === 0 ? paddingX : 16;
  const padY = paddingY || paddingY === 0 ? paddingY : 6;
  return (
    <button
      className={`rounded-md text-center h-fit w-fit flex items-center ${extraClass} ${
        disabled ? 'cursor-normal' : 'cursor-pointer opacity-100 hover:opacity-75'
      }`}
      id={uuid}
      style={{
        background: disabled ? 'gray' : background,
        border: border,
        borderRadius: borderRadius,
        color: color ? color : 'black',
        height: buttonHeight ? buttonHeight : '',
        margin,
        minHeight: minHeight ? minHeight : height !== 0 ? `${height}px` : 'auto',
        padding: `${padY}px ${padX}px ${padY}px ${padX}px`,
        width: buttonWidth ? buttonWidth : '',
      }}
      onClick={e => {
        if (onClick) {
          !disabled && !loading ? onClick(e) : null;
        }
        if (onClickNavigationRoute) clickNavigation(onClickNavigationRoute);
      }}
      {...rest}
    >
      <div className={`flex items-center w-full justify-${textPosition}`}>
        {loading ? (
          <Loader
            type={loader}
            size='12px'
            border='8px'
            color='transparent'
            spinnerColor={loaderColor ? loaderColor : color ? color : 'black'}
          />
        ) : null}
        {iconLeft && !loading ? (
          <div
            className={`${text ? 'pr-3' : ''}`}
            style={{
              color: color ? color : 'black',
              fontWeight: weight,
              width: iconWidth,
              fontSize,
            }}
          >
            {' '}
            {iconLeft}
          </div>
        ) : null}
        {text && !loading ? (
          <>
            {typeof text === 'string' ? (
              <p
                style={{
                  color: color ? color : 'black',
                  whiteSpace: 'nowrap',
                  fontWeight: weight,
                  fontSize,
                  padding:
                    !iconLeft && !iconRight
                      ? `${padY - 2}px ${padX}px ${padY - 2}px ${padX}px`
                      : 'none',
                }}
              >
                {text}
              </p>
            ) : (
              text
            )}
          </>
        ) : null}
        {children}
        {iconRight && !loading ? (
          <div
            className={`${text ? 'pl-3' : ''}`}
            style={{ color: color ? color : 'black', fontWeight: weight, fontSize }}
          >
            {' '}
            {iconRight}
          </div>
        ) : null}
      </div>
    </button>
  );
};
