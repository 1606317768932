import React, { CSSProperties } from 'react';
import { ReactComponent as Tumble } from '../../img/tumbleweed.svg';
import { ReactComponent as Select } from '../../img/select.svg';

interface Props {
    title?: string;
    text: string;
    type?: string;
    color?: string;
    width?: string;
}

export const Fallback: React.FC<Props> = ({ title, text, type = 'empty', color = 'black', width = '200px' }) => {
    const svgStyle: CSSProperties = {
        fill: color,
        width: width,
        height: 'auto'
    };
    return (
        <div className='w-full items-center justify-center flex'>
            <div className=''>
                <div className='flex flex-row gap-6 text-red'>
                    {type === 'empty' ? <Tumble style={svgStyle} /> : null}
                    {type === 'select' ? <Select style={svgStyle} /> : null}

                    <div className='m-auto' style={{ color: color }}>
                        <div className='text-6xl font-semibold'>{title ? title : null}</div>
                        <div className='text-xl mt-3'>{text}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
