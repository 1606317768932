import React from 'react';
import { Fallback } from '../../components/Fallback/Fallback';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elem = `import React, { CSSProperties } from 'react';
import { ReactComponent as Tumble } from '../../img/tumbleweed.svg';
import { ReactComponent as Select } from '../../img/select.svg';

interface Props {
    title?: string;
    text: string;
    type?: string;
    color?: string;
    width?: string;
}

export const Fallback: React.FC<Props> = ({ title, text, type = 'empty', color = 'black', width = '200px' }) => {
    const svgStyle: CSSProperties = {
        fill: color,
        width: width,
        height: 'auto'
    };
    return (
        <div className='w-full items-center justify-center flex'>
            <div className=''>
                <div className='flex flex-row gap-6 text-red'>
                    {type === 'empty' ? <Tumble style={svgStyle} /> : null}
                    {type === 'select' ? <Select style={svgStyle} /> : null}

                    <div className='m-auto' style={{ color: color }}>
                        <div className='text-6xl font-semibold'>{title ? title : null}</div>
                        <div className='text-xl mt-3'>{text}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
`;

const FallBacks = () => {
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Fallbacks</div>
            <div className='text-lg mt-4'>A fallback is an element to fall back to when something happens</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>As of now there are only two types: empty & select</div>
            <div className='text-base mt-2'>
                Mandatory props: <b>text</b>
            </div>
            <div className='text-base mt-2'>
                Optional props: <b>color</b> (default: black), <b>title</b> (default: none), <b>width</b> (default: 200px) & <b>type</b>
                (default: empty).
            </div>
            <CopyBlock
                language={'jsx'}
                text={`<Fallback title='Ooops...' text='Seems like there is nothing here' color='SlateGray'/>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Fallback
                    title='Ooops...'
                    text='Seems like there is nothing here'
                    color='SlateGray
'
                />
            </div>
            <CopyBlock
                language={'jsx'}
                text={`<Fallback title='Select' text='an option to visualise' type='select' width='150px' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Fallback title='Select' text='an option to visualise' type='select' width='150px' />
            </div>
        </div>
    );
};

export default FallBacks;
