import { PopupOptionsSetter } from 'src/ts';
import { usePopup } from '../use-popup';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

export const useShowDownloadInvoicePopup = () => {
  const { t } = useTranslation();
  const { openPopup } = usePopup();

  const bodyText = GlobalService.uppercaseAfterPointFirstsLetters(
    t('pages.documentos.signedAuthorizationsFacturaHelp'),
  );
  const linkText = GlobalService.uppercaseFirstLetter(
    t('pages.documentos.signedAuthorizationsFacturaContact'),
  );
  const link = '/user/help';

  const downloadInvoicePopupOptions: PopupOptionsSetter = {
    title: GlobalService.uppercaseFirstLetter(t('information')),
    children: (
      <p
        dangerouslySetInnerHTML={{
          __html: GlobalService.uppercaseAndAddLink(bodyText, linkText, link),
        }}
      ></p>
    ),
    buttons: [
      {
        type: 'primary',
        children: GlobalService.uppercaseFirstLetter(t('pages.personas.ok')),
        fullWidth: true,
      },
    ],
  };

  const showDownloadInvoicePopup = () => {
    openPopup(downloadInvoicePopupOptions);
  };

  return { showDownloadInvoicePopup };
};
