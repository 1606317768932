import { DeletePatomediParams } from '../../../ts';
import { personasRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';
import { personasServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';

export const deletePatomediService = async ({
  dealId,
  contactId,
  fileId,
}: DeletePatomediParams) => {
  const response = await personasServices(personasRepositoryInstance).deletePatoMedi(
    dealId,
    contactId,
    fileId,
  );

  if (typeof response === 'string') throw new Error(response);

  return response.data;
};
