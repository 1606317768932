import React, { useState, useEffect } from 'react';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import Modal from '../../components/Modals/Modal';
import { ButtonBase } from '../../components/Buttons/button-base';

const Modals = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [isModalOpen6, setIsModalOpen6] = useState(false);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (
        isModalOpen ||
        isModalOpen2 ||
        isModalOpen3 ||
        isModalOpen4 ||
        isModalOpen5 ||
        isModalOpen6
      ) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'unset';
      }
    }
  }, [isModalOpen, isModalOpen2, isModalOpen3, isModalOpen4, isModalOpen5, isModalOpen6]);

  const useFunc = `useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            if (isModalOpen || isModalOpen2 || isModalOpen3 || isModalOpen4 || isModalOpen5 || isModalOpen6) {
                body.style.overflow = 'hidden';
            } else {
                body.style.overflow = 'unset';
            }
        }
    }, [isModalOpen, isModalOpen2, isModalOpen3, isModalOpen4, isModalOpen5, isModalOpen6]);`;

  const elem = `interface ModalProps {
        open: boolean;
        backgroundColor: string;
        backgroundColorParent: string;
        size?: string;
        width?: string;
        height?: string;
        borderRadius: string;
        borderColor: string;
        onClose: () => void;
        children: ReactNode;
        border?: string;
        padding: string;
        animationOut?: string;
        animationIn?: string;
        alignItemsParent: string;
        justifyContentParent: string;
        showButtons: boolean;
    }

    const Modal: FC<ModalProps> = ({
        backgroundColor,
        size,
        width,
        height,
        borderRadius,
        borderColor,
        onClose,
        children,
        backgroundColorParent,
        border,
        padding,
        animationOut = '',
        animationIn = '',
        open,
        alignItemsParent,
        justifyContentParent,
        showButtons
    }) => {
        const parentStyle: CSSProperties = {
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            justifyContent: justifyContentParent,
            alignItems: alignItemsParent,
            flexDirection: 'column',
            backgroundColor: backgroundColorParent,
            zIndex: 1000
        };

        const childStyle: CSSProperties = {
            backgroundColor,
            width: size ? size : width,
            height: size ? size : height ? height : 'auto',
            borderRadius,
            border: border ? \`1px solid \${borderColor}\` : 'none',
            padding: padding,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            animation: open ? (animationIn ? \`\${animationIn} 0.5s forwards\` : '') : animationOut ? \`\${animationOut} 1s forwards\` : ''
        };

        const headerStyle: CSSProperties = {
            display: 'flex',
            justifyContent: 'flex-end'
        };

        const footerStyle: CSSProperties = {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '1rem',
            gap: '10px'
        };

        return (
            <div style={parentStyle} onClick={onClose}>
                <div style={childStyle} onClick={event => event.stopPropagation()}>
                    <div style={headerStyle} className='modalHeader'>
                        <FontAwesomeIcon onClick={onClose} icon={faXmark} className='opacity-50' />
                    </div>
                    <div style={{ overflow: 'auto' }} className='modalBody'>
                        {children}
                    </div>
                    {showButtons ? (
                        <div style={footerStyle} className='modalFooter'>
                            <Button text='Cancel' onclick={onClose} background='none' color='#2F3447' border='2px solid #2F3447' />
                            <Button text='Accept' onclick={onClose} background='none' color='#2F3447' border='2px solid #2F3447' />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };
    `;

  return (
    <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
      <div className='text-2xl'>Modals</div>
      <div className='text-lg mt-4'>
        A modal is a dialog box or popup window that is displayed on top of the current page
        content, usually to provide additional information, gather user input, or confirm an action.
      </div>
      <div className='mb-4'>
        <h2 className='mt-4 text-xl'>Some features of the component</h2>
        <ul>
          <li className='text-lg mt-4'>
            The component has <strong>several props</strong> that it can receive, such as
            backgroundColor, size, borderRadius, borderColor, onClose, children, padding, and
            animationOut/animationIn. These props are used to style and configure the modal. Some of
            them are optional.
          </li>

          <li className='text-lg mt-4'>
            The component creates two styles, <strong>parentStyle</strong> and{' '}
            <strong>childStyle</strong>, which define the styles for the parent element that covers
            the entire page and the child element that contains the content placed in the parent.
          </li>

          <li className='text-lg mt-4'>
            The modal has three sections: a header, a body, and a footer. The header contains an
            FontAwesomeIcon element that displays a close button. The body contains the children
            prop that displays the content. The footer contains two buttons: &quotCancel&quot and
            &quotAccept&quot which trigger the onClose function when clicked.
          </li>
        </ul>
      </div>
      <CopyBlock
        language={'jsx'}
        text={elem}
        showLineNumbers={true}
        theme={atomOneDark}
        wrapLines={true}
        codeBlock
      />
      <p className='text-lg mt-4'>
        This <strong>useEffect hook</strong> sets the overflow property of the body element to
        &quothidden&quot when any of the specified isModalOpen states are true, and to
        &quotunset&quot when all of them are false. This is meant to prevent scrolling of the body
        content when a modal is open, so make sure to place this useEffect in the parent where you
        import your component.
      </p>
      <CopyBlock
        language={'jsx'}
        text={useFunc}
        showLineNumbers={true}
        theme={atomOneDark}
        wrapLines={true}
        codeBlock
      />

      <div className='text-xl mt-8 flex justify-center '>
        Some implementations and behaviour of the component
      </div>
      <div className='flex justify-center mt-4 flex-col gap-6 items-center'>
        <ButtonBase
          text='Open simple centered modal'
          onClick={() => setIsModalOpen(true)}
          background='none'
          color='#2F3447'
          border='2px solid #2F3447'
        />
        <CopyBlock
          language={'jsx'}
          text={`<Modal
                backgroundColorParent='rgba(0, 0, 0, 0.5)'
                backgroundColor='white'
                width='50%'
                borderRadius='10px'
                borderColor='none'
                onClose={() => setIsModalOpen(false)}
                padding='1em'
                open={isModalOpen}
                showButtons={true}
            >
                <h2>Hello World!</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                    sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </Modal>`}
          showLineNumbers={false}
          theme={atomOneDark}
          wrapLines={true}
          codeBlock
        />
      </div>

      <div className='flex justify-center mt-4 flex-col gap-6 items-center'>
        <ButtonBase
          text='Open modal at the bottom full width'
          onClick={() => setIsModalOpen2(true)}
          background='none'
          color='#2F3447'
          border='2px solid #2F3447'
        />
        <CopyBlock
          language={'jsx'}
          text={` <Modal
                    backgroundColorParent='rgba(0, 0, 0, 0.5)'
                    alignItemsParent='center'
                    justifyContentParent='flex-end'
                    backgroundColor='white'
                    width='100%'
                    borderRadius='00px'
                    borderColor='none'
                    onClose={() => setIsModalOpen2(false)}
                    padding='1em'
                    open={isModalOpen2}
                >
                    <h2>Hello World!</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </Modal>`}
          showLineNumbers={false}
          theme={atomOneDark}
          wrapLines={true}
          codeBlock
        />
      </div>
      <div className='flex justify-center mt-4 flex-col gap-6 items-center'>
        <ButtonBase
          text='Open modal right side'
          onClick={() => setIsModalOpen3(true)}
          background='none'
          color='#2F3447'
          border='2px solid #2F3447'
        />
        <CopyBlock
          language={'jsx'}
          text={` <Modal
                    backgroundColorParent='rgba(0, 0, 0, 0.5)'
                    backgroundColor='white'
                    alignItemsParent='flex-end'
                    justifyContentParent='flex-end'
                    width='30%'
                    height='100%'
                    borderRadius='0'
                    borderColor='none'
                    onClose={() => setIsModalOpen3(false)}
                    padding='1em'
                    open={isModalOpen3}
                    showButtons={true}
                >
                    <h2>Hello World!</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                        irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                        in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                </Modal>`}
          showLineNumbers={false}
          theme={atomOneDark}
          wrapLines={true}
          codeBlock
        />
      </div>
      <div className='flex justify-center mt-4 flex-col gap-6 items-center'>
        <ButtonBase
          text='Open modal right side with scrollable content'
          onClick={() => setIsModalOpen4(true)}
          background='none'
          color='#2F3447'
          border='2px solid #2F3447'
        />

        <CopyBlock
          language={'jsx'}
          text={`<Modal
                    backgroundColorParent='rgba(0, 0, 0, 0.5)'
                    backgroundColor='white'
                    alignItemsParent='flex-end'
                    justifyContentParent='flex-end'
                    width='30%'
                    height='100%'
                    borderRadius='0'
                    borderColor='none'
                    onClose={() => setIsModalOpen4(false)}
                    padding='1em'
                    open={isModalOpen4}
                    showButtons={true}
                >
                    <h2>Hello World!</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                        irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                        in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                        officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                        deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                        ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur.
                    </p>
                </Modal>`}
          showLineNumbers={false}
          theme={atomOneDark}
          wrapLines={true}
          codeBlock
        />
      </div>
      <div className='flex justify-center mt-4 flex-col gap-6 items-center'>
        <ButtonBase
          text='Open modal right side with animation slide-in'
          onClick={() => setIsModalOpen5(true)}
          background='none'
          color='#2F3447'
          border='2px solid #2F3447'
        />
        <CopyBlock
          language={'jsx'}
          text={`<Modal
                    backgroundColorParent='rgba(0, 0, 0, 0.5)'
                    backgroundColor='white'
                    alignItemsParent='flex-end'
                    justifyContentParent='flex-end'
                    width='30%'
                    height='100%'
                    borderRadius='0'
                    borderColor='none'
                    onClose={() => setIsModalOpen5(false)}
                    padding='1em'
                    animationIn='slide-in'
                    animationOut='slide-out'
                    open={isModalOpen5}
                    showButtons={true}
                >
                    <h2>Hello World!</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Excepteur sint
                        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                </Modal>`}
          showLineNumbers={false}
          theme={atomOneDark}
          wrapLines={true}
          codeBlock
        />
      </div>
      <div className='flex justify-center mt-4 flex-col gap-6 items-center'>
        <ButtonBase
          text='Customizing color appearance'
          onClick={() => setIsModalOpen6(true)}
          background='none'
          color='#2F3447'
          border='2px solid #2F3447'
        />
        <CopyBlock
          language={'jsx'}
          text={`<Modal
                    backgroundColorParent='rgba(0, 0, 0, 0.5)'
                    backgroundColor='lightblue'
                    alignItemsParent='flex-end'
                    justifyContentParent='flex-end'
                    width='50%'
                    borderRadius='30px'
                    borderColor='none'
                    onClose={() => setIsModalOpen6(false)}
                    padding='1em'
                    animationIn='fade-in'
                    animationOut='fade-out'
                    open={isModalOpen6}
                    showButtons={true}
                >
                    <h2>Hello World!</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </Modal>`}
          showLineNumbers={false}
          theme={atomOneDark}
          wrapLines={true}
          codeBlock
        />
      </div>

      {isModalOpen ? (
        <Modal
          backgroundColorParent='rgba(0, 0, 0, 0.5)'
          backgroundColor='white'
          width='50%'
          alignItemsParent='center'
          justifyContentParent='center'
          borderRadius='0px'
          borderColor='none'
          onClose={() => setIsModalOpen(false)}
          padding='1em'
          open={isModalOpen}
          showButtons={true}
        >
          <h2>Hello World!</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
        </Modal>
      ) : null}
      {isModalOpen2 ? (
        <Modal
          backgroundColorParent='rgba(0, 0, 0, 0.5)'
          alignItemsParent='center'
          justifyContentParent='flex-end'
          backgroundColor='white'
          width='100%'
          borderRadius='00px'
          borderColor='none'
          onClose={() => setIsModalOpen2(false)}
          padding='1em'
          open={isModalOpen2}
          showButtons={true}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
        </Modal>
      ) : null}
      {isModalOpen3 ? (
        <Modal
          backgroundColorParent='rgba(0, 0, 0, 0.5)'
          backgroundColor='white'
          alignItemsParent='flex-end'
          justifyContentParent='flex-end'
          width='30%'
          height='100%'
          borderRadius='0'
          borderColor='none'
          onClose={() => setIsModalOpen3(false)}
          padding='2em'
          open={isModalOpen3}
          showButtons={true}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
            qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur.
          </p>
        </Modal>
      ) : null}
      {isModalOpen4 ? (
        <Modal
          backgroundColorParent='rgba(0, 0, 0, 0.5)'
          backgroundColor='white'
          alignItemsParent='flex-end'
          justifyContentParent='flex-end'
          width='30%'
          height='100%'
          borderRadius='0'
          borderColor='none'
          onClose={() => setIsModalOpen4(false)}
          padding='2em'
          open={isModalOpen4}
          showButtons={true}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
            qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur.Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eu fugiat nulla pariatur.
          </p>
        </Modal>
      ) : null}
      {isModalOpen5 ? (
        <Modal
          backgroundColorParent='rgba(0, 0, 0, 0.5)'
          backgroundColor='white'
          alignItemsParent='flex-end'
          justifyContentParent='flex-end'
          width='30%'
          height='100%'
          borderRadius='0'
          borderColor='none'
          onClose={() => setIsModalOpen5(false)}
          padding='2em'
          animationIn='slide-in'
          animationOut='slide-out'
          open={isModalOpen5}
          showButtons={true}
        >
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.Excepteur sint occaecat cupidatat non proident, sunt in culpa
            qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Lorem ipsum dolor sit
            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur.
          </p>
        </Modal>
      ) : null}
      {isModalOpen6 ? (
        <Modal
          backgroundColorParent='rgba(0, 0, 0, 0.5)'
          backgroundColor='lightblue'
          alignItemsParent='flex-end'
          justifyContentParent='flex-end'
          width='50%'
          borderRadius='30px'
          borderColor='none'
          onClose={() => setIsModalOpen6(false)}
          padding='1em'
          animationIn='fade-in'
          animationOut='fade-out'
          open={isModalOpen6}
          showButtons={true}
        >
          <h2>Hello World!</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
        </Modal>
      ) : null}
    </div>
  );
};

export default Modals;
