import { Navigate, Outlet } from 'react-router-dom';
import { useSession, useShowUserErrorPopup } from '../hooks';
import { InitialLoadsLogged } from '../components/initial-loads';
import { useEffect } from 'react';

const PrivateRoutes = () => {
  const { isUserLogged } = useSession();
  const { showUserErrorPopup } = useShowUserErrorPopup();

  useEffect(() => {
    if (!isUserLogged) {
      showUserErrorPopup();
    }
  }, [isUserLogged]);

  return (
    <>
      {isUserLogged ? (
        <InitialLoadsLogged>
          <Outlet />
        </InitialLoadsLogged>
      ) : (
        <Navigate to='/blog' />
      )}
      {/*userIsLogged ? <Footer /> : null*/}
    </>
  );
};

export default PrivateRoutes;
