import { FC, useEffect } from 'react';
import RegularSpinner from './Spinner/RegularSpinner';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { ButtonBase } from './Buttons/button-base';
import { useTranslation } from 'react-i18next';
import { useShowEditAddressPopup, useUserAddress } from '../hooks';

interface Props {
  isScreenBig: boolean;
  title: string;
}

const AddressBlock: FC<Props> = ({ isScreenBig, title }) => {
  const { t } = useTranslation();
  const {
    userAddress,
    getUserAddress,
    isEmptyAndLoading: isAddressEmptyAndLoading,
  } = useUserAddress();
  const { showEditAddressPopup } = useShowEditAddressPopup();

  useEffect(() => {
    getUserAddress();
  }, []);

  return (
    <div
      className={`direction-container flex justify-between mb-6 ${!isScreenBig ? 'flex-col' : ''}`}
    >
      {isAddressEmptyAndLoading ? (
        <RegularSpinner />
      ) : (
        <>
          <div className={`${isScreenBig ? '' : 'text-sm'}`}>
            <p> {title}: </p>
            <p className='direction-text mt-2 '>
              {userAddress?.Direcci_n}
              {userAddress?.Codigo_postal ? ',' : ''} {userAddress?.Codigo_postal}
              {userAddress?.Ciudad ? ',' : ''} {userAddress?.Ciudad}
              {userAddress?.Pa_s ? ',' : ''} {userAddress?.Pa_s}
            </p>
          </div>

          <div className=''>
            <ButtonBase
              background='#FFFFFF'
              border='1px solid #28337D'
              borderRadius='50px'
              buttonHeight={isScreenBig ? '' : '25px'}
              buttonWidth={isScreenBig ? '87px' : '60px'}
              color='#28337D'
              extraClass='3xs:flex 3xs:justify-end 3xs:mt-2 edit-button'
              fontSize={isScreenBig ? '16px' : '12px'}
              onClick={() => showEditAddressPopup()}
              text={GlobalService.uppercaseFirstLetter(t('edit'))}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AddressBlock;
