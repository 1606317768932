import { optionItem } from '../../domain/models/Input';
import { FormConfig, FormDataResposeItem } from '../../domain/models/Form';

export const formServices = () => ({
    formData: (formConfig: FormConfig[], service?: any, id?: string): FormDataResposeItem[] => {
        // this function must be asyn when the real petition to back so the service waits for the data
        const data = id && service ? service(id) : undefined;
        const form: FormDataResposeItem[] = formConfig.map((input: FormConfig): FormDataResposeItem => {
            switch (input.type) {
                case 'select':
                    return {
                        key: input.key,
                        value: [],
                        required: input.required
                    };
                default:
                    return {
                        key: input.key,
                        value: '',
                        required: input.required
                    };
            }
        });
        if (data !== null && data !== undefined && service !== null && service !== undefined) {
            const values: any = Object.values(data);
            Object.keys(data).forEach((key: string, index: number) => {
                formConfig.forEach((input: FormConfig, i: number) => {
                    if (input.key === key) {
                        switch (input.type) {
                            case 'select':
                                form[i] = { ...form[i], value: [values[index]] };
                                break;
                            default:
                                form[i] = { ...form[i], value: values[index] };
                                break;
                        }
                    }
                });
            });
        }
        return form;
    },
    setFormData: (
        formData: FormDataResposeItem[],
        formInput: FormConfig,
        value: string | object | Array<optionItem> | undefined
    ): FormDataResposeItem[] => {
        return formData.map((input: FormDataResposeItem) => {
            if (input.key === formInput.key) {
                const obj: FormDataResposeItem = {
                    key: input.key,
                    value: value,
                    required: input.required
                };

                return obj;
            }
            return input;
        }); //.filter(input => input.value !== '');
    },

    ableToSubmit: (formData: FormDataResposeItem[]): [boolean, FormDataResposeItem[]] => {
        const missingValues = formData.filter((item: FormDataResposeItem) => {
            return typeof item.required === 'string' && (!item.value || (Array.isArray(item.value) && item.value.length === 0));
        });
        const totalRequiredInputs = formData.filter((item: FormDataResposeItem) => typeof item.required === 'string');
        const canSubmit = totalRequiredInputs.length === totalRequiredInputs.length - missingValues.length;
        return [canSubmit, missingValues];
    }
});
