import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonBase } from '../components/Buttons/button-base';
import { Pagination } from '../../src/components/Pagination';
import ModalInfo from '../components/Modals/ModalInfo';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import TopMenu from './Menus/TopMenu';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IModalInfoProps } from '../hexagonal-architecture-frontend-base/src/domain/models/IModals';
import { IPatientReport } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IPersona';

// CSS
import '../styles/Personas/Personas.css';

// Images
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useSetSelectedPatient, useSetUrlToReturn, useItems, useViewport } from '../hooks';

const Servicios = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { paidItems, unpaiditems } = useItems();

  // States
  const [selected, setSelected] = useState<string>('pending');
  const [numberItems] = useState<number>(6);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { viewportWidth, viewportHeight } = useViewport();
  const [errorText] = useState<string>('');
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const { setSelectedPatient } = useSetSelectedPatient();
  const { setUrlToReturn } = useSetUrlToReturn();

  const serviciosItems = selected === 'pending' ? unpaiditems : paidItems;

  /***********
   * Handlers *
   ***********/
  const handlePagination = (page: number) => {
    setCurrentPage(page);
    setLoading(true);
    const result = selected === 'pending' ? unpaiditems : paidItems;
    setTotalPages(Math.ceil(result.length / numberItems));
    setLoading(false);
  };

  const handleSelectType = (type: string) => {
    setLoading(true);
    setSelected(type);
    setCurrentPage(1);
    setLoading(false);
  };

  const handleOnCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleShowReport = (item: IPatientReport) => {
    setSelectedPatient(item.Contact_Name.id);
    setUrlToReturn('/servicios');
    navigate(`/informeDetallado/${item.Contact_Name.id}/${item.id}`);
  };

  /***********
   * Helpers *
   ***********/
  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const noData = <p>{GlobalService.uppercaseAllFirstsLetters(t('nodata'))}</p>;
  const topMenuProperties: any = {
    title: GlobalService.uppercaseFirstLetter(
      t(`pages.pagos.${isScreenBig ? 'title' : 'services'}`),
    ),
    fixed: !isScreenBig,
  };
  if (!isScreenBig) {
    topMenuProperties.leftIcon = LeftArrowSmall;
    topMenuProperties.leftIconLink = '/pagos';
  }

  const modalErrorOptions: IModalInfoProps = {
    bodyText: `${errorText}`,
    onClickOk: handleOnCloseErrorModal,
    showXCloseButton: false,
    titleText: 'error',
  };

  const modalError = <ModalInfo {...modalErrorOptions} />;

  // Desktop values
  const desktopSubTitle = (
    <>
      <div className='w-1/12'>
        {isScreenBig ? (
          <img
            onClick={() => {
              navigate('/pagos');
            }}
            src={LeftArrow}
            alt='Back'
            className='Notificaciones-Arrow cursor-pointer'
          />
        ) : (
          <img
            onClick={() => {
              navigate('/pagos');
            }}
            src={LeftArrowSmall}
            alt='Back'
            className='my-auto ml-2 cursor-pointer w-[26px] h-[28px]'
          />
        )}
      </div>

      <div className='w-11/12 text-center md:text-left'>
        <Title
          text={GlobalService.uppercaseFirstLetter(t('pages.pagos.services'))}
          color='black'
          size={'24px'}
        />
      </div>
    </>
  );

  const formatedServiciosItems = serviciosItems.length
    ? serviciosItems.map((item: any, index: any) => (
        <div key={index} className={`tratamiento-container`}>
          <div className='flex justify-between'>
            <div>
              <p>
                <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.treatmentOf'))}:</strong>
              </p>
              <p>{item.Subject}</p>
            </div>
            <p className='whitespace-nowrap' style={{ color: '#28337d' }}>
              <strong>
                {item.Grand_Total} {item.Currency}
              </strong>
            </p>
          </div>
          <div className='mt-4 flex justify-between'>
            <div>
              <p className='text-xs'>
                <strong>
                  {GlobalService.uppercaseFirstLetter(t('pages.pagos.paymentType'))}:{' '}
                </strong>
                {item.Tipo_de_Pago}
              </p>
              <p className='text-xs mt-1'>
                <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.state'))}: </strong>
                {item.Estado_del_pago}
              </p>
            </div>
            <ButtonBase
              background='none'
              border='1px solid #28337d'
              borderRadius='50px'
              buttonWidth={isScreenBig ? '122px' : '90px'}
              color='#28337d'
              extraClass='informe-button'
              fontSize={isScreenBig ? '16px' : '10px'}
              onClick={() => handleShowReport(item)}
              paddingX={6}
              paddingY={4}
              text={GlobalService.uppercaseFirstLetter(t('pages.pagos.seeReport'))}
            />
          </div>
        </div>
      ))
    : null;

  return (
    <div className='w-full'>
      <TopMenu {...topMenuProperties}></TopMenu>
      <div className={`w-full ${isScreenBig ? 'p-8' : 'pb-4 pr-2'}`}>
        <div className='3xs:hidden md:flex text-left'>{desktopSubTitle}</div>
        <div className={`grid 3xs:mt-20 3xs:mb-4 md:mt-6 md:mb-9 Personas-Visitas-Menu`}>
          <div className={`flex flex-row Personas-Title${isScreenBig ? '' : '-small pl-3 pt-3'} `}>
            {selected === 'pending' ? (
              <div>
                <p className='cursor-pointer pb-4 ml-2'>Pago Pendiente</p>
                <div className='Personas-Visitas-Menu-Selected'></div>
              </div>
            ) : (
              <p className='pb-4 cursor-pointer ml-2' onClick={() => handleSelectType('pending')}>
                Pago Pendiente
              </p>
            )}
            {selected === 'complete' ? (
              <div>
                <p className='ml-10 cursor-pointer pb-4'>Pago Completo</p>
                <div className='ml-5 Personas-Visitas-Menu-Selected'></div>
              </div>
            ) : (
              <p className='ml-10 pb-4 cursor-pointer' onClick={() => handleSelectType('complete')}>
                Pago Completo
              </p>
            )}
          </div>
        </div>
        {loading ? (
          <RegularSpinner />
        ) : (
          <div
            className={`${
              viewportHeight === 1180 && viewportWidth === 820
                ? 'services-height-med'
                : 'services-height'
            } grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-4 w-full`}
          >
            {serviciosItems.length ? formatedServiciosItems : noData}
          </div>
        )}
        {totalPages > 1 ? (
          loading ? null : (
            <div className='3xs:my-2 sm:my-4 lg:my-10 m-auto'>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onChange={handlePagination}
              />
            </div>
          )
        ) : null}
      </div>
      {showErrorModal ? modalError : null}
    </div>
  );
};

export default Servicios;
