import { NotificationsRepository } from '../../domain/repositories/notifications.repository ';
import { http } from '../plugins/http/http';
import { INotification } from '../../domain/models/INotification';

export const notificationsRepository = (): NotificationsRepository => ({
  getNotifications: async (numberItems = 5, currentPage = 1, seen) => {
    const url = `/notifications?per_page=${numberItems}&page=${currentPage}${seen ? `&seen=${seen}` : ``}`;
    return await http
      .get<INotification[] | string>(url)
      .then((notifications: INotification[] | string) => {
        return notifications;
      })
      .catch(() => {
        return 'errors.notificationNotFound';
      });
  },
  getNotificationsCount: async () => {
    const url = `/notifications/count`;
    return await http
      .get<number | string>(url)
      .then((notifications: number | string) => {
        return notifications;
      })
      .catch(() => {
        return 'errors.notificationNotFound';
      });
  },
  getNotificationsNonReadCount: async () => {
    const url = `/notifications/count?seen=false`;
    return await http
      .get<number | string>(url)
      .then((notifications: number | string) => {
        return notifications;
      })
      .catch(() => {
        return 'errors.notificationNotFound';
      });
  },
  markNotificationAsSeen: async id => {
    const url = `/notifications/${id}/seen`;
    return await http
      .put<number | string>(url, {}) // Add an empty object as the second argument
      .then((notifications: number | string) => {
        return notifications;
      })
      .catch(() => {
        return 'errors.notificationNotFound';
      });
  }
});
