import { PersonasRepository } from '../../domain/repositories/personas.repository';

// Services
import { http } from '../plugins/http/http';

// Interfaces
import {
  ICount,
  IPatient,
  IPatientDeal,
  IPatientDealReturn,
  IPatientReport,
  IPatientReportReturn,
  IPatientVisitReturn,
  IPersonasReturn
} from '../../domain/models/IPersona';
import { IArticle } from '../../domain/models/IArticle';
import { IProduct } from '../../domain/models/IProduct';
import { IUploadResponse } from '../../domain/models/IUploadResponse';
import { Attachment } from '../../../../ts';

const activePatient: IPatient = {
  dealDetails: {
    Archivo_Consentimientos: [],
    Autorizaciones_Firmadas: false,
    Deal_Name: '',
    id: '',
    Patomedi: false,
    Signed_SEPA: false,
    Created_Time: 0,
    Modified_Time: 0
  },
  id: '0',
  image: '',
  title: '',
  residence: '',
  email: '',
  eternalReviewAuth: false,
  birthDate: null
};

export const personasRepository = (): PersonasRepository => ({
  deleteConsent: async (data: { Deal_Name: string; fileId: string; recordId: string }): Promise<object | string> => {
    try {
      return await http.post('/patients/deleteconsent', data);
    } catch (error) {
      return 'invalidConsentDelete';
    }
  },
  deletePatoMedi: async (dealId: string, contactId: string, fileId: { deal: string }) => {
    try {
      const response = await http.cleanDelete('/patients/deletepatomedi', { dealId, contactId, fileId });
      return response;
    } catch (error) {
      return 'deleteError';
    }
  },
  deleteContactConsent: async (data: { fileId: string; patientId: string }): Promise<object | string> => {
    try {
      return await http.post('/patients/deletecontactconsent', data);
    } catch (error) {
      return 'invalidContactConsentDelete';
    }
  },
  getActivePatient: () => {
    return activePatient;
  },
  getFile: async (module: string, dealId: string, fileId: string, fileName: string) => {
    try {
      const response = await http.altGet('/patients/download', { module, dealId, fileId, fileName });
      return response;
    } catch (error) {
      return 'invalidFile';
    }
  },
  getPatients: async (userId: number): Promise<IPersonasReturn | string> => {
    const errorString = 'invalidPatientsList';
    const perPage = 200;
    let page = 1;
    let modifyData: IPatient[] = [];
    try {
      let response: any;

      let continueLoop = true;
      while (continueLoop) {
        response = await http.cleanGet('/patients', { id: userId, page, per_page: perPage });

        if (response.status !== 200) throw new Error('Error fetching patients');

        const data = response.data.data.map((person: any) => {
          const birthDate = person.Date_of_Birth ? new Date(person.Date_of_Birth) : null;
          const formattedPerson: IPatient = {
            dealDetails: {
              Archivo_Consentimientos: [],
              Autorizaciones_Firmadas: true,
              Deal_Name: '',
              id: '',
              Patomedi: false,
              Signed_SEPA: false,
              Created_Time: 0,
              Modified_Time: 0
            },
            id: person?.id || 0,
            image: person?.Record_Image || '',
            title: person?.Last_Name || '',
            residence: person?.Account_Name?.name || '',
            email: person?.Email || '',
            dni: person?.DNI || '',
            Consentimientos: person.Consentimientos || null,
            eternalReviewAuth: person.Eternal_Review_Auth || false,
            birthDate
          };

          return formattedPerson;
        });

        modifyData = [...modifyData, ...data];
        page++;
        if (data.length < perPage || page > 10) continueLoop = false;
      }

      return { data: modifyData, info: response?.data?.info };
    } catch (err) {
      return errorString;
    }
  },
  getAllPatientDeals: async (names: string[], page: number): Promise<IPatientDealReturn | string> => {
    try {
      const response = await http.cleanGet('/patients/alldeals', { names, page });
      return response.data;
    } catch (error) {
      return 'invalidDealData';
    }
  },
  getAllPatientReports: async (names: string[], page: number): Promise<IPatientReportReturn | string> => {
    try {
      const response = await http.cleanGet('/patients/allreports', { names, page });
      return response.data;
    } catch (error) {
      return 'invalidReportsData';
    }
  },
  getAllPatientVisits: async (names: string[], page: number): Promise<IPatientVisitReturn | string> => {
    try {
      const response = await http.cleanGet('/patients/allvisits', { names, page });
      return response.data;
    } catch (error) {
      return 'invalidVisitsData';
    }
  },
  getSinglePatientAttachedFiles: async (patientId: string) => {
    try {
      const response = await http.cleanGet('/patients/singlePatientAttachedFiles', { patientId });

      const attachments: Attachment[] = response.data.data.map((attachment: any) => {
        const parentId: Attachment['parentId'] = attachment.Parent_Id ?? {
          name: null,
          id: patientId
        };
        return {
          id: attachment.id,
          fileName: attachment.File_Name,
          parentId,
          size: attachment.Size
        } as Attachment;
      });

      return attachments;
    } catch (error) {
      console.log('error', error);
      return 'invalidAttachedFilesData';
    }
  },
  /**
   * Returns the latest deal of a patient
   * @param patientId
   * @param pagina
   * @param porPagina
   * @returns
   */
  getPatientDealsById: async (patientId: string, page: Number, per_page: number): Promise<IPatientDeal | string> => {
    try {
      const response = await http.cleanGet('/patients/deals', { id: patientId, page, per_page });
      const sortedData = response.data.data.sort((a: any, b: any) =>
        new Date(a.Created_Time).toISOString() > new Date(b.Created_Time).toISOString() ? -1 : 1
      );
      delete sortedData[0].Contact_Role;
      return sortedData[0];
    } catch (error) {
      return 'invalidDealData';
    }
  },
  // Returns the patient reports
  getPatientReportsById: async (patientId: string, page: number, per_page: number): Promise<IPatientReport[] | string> => {
    try {
      const response = await http.cleanGet('/patients/reports', { id: patientId, page, per_page });

      if (response.data.data) {
        return response.data.data;
      } else {
        return 'noReportsData';
      }
    } catch (error) {
      return 'invalidReportsData';
    }
  },
  // Return the patient visits
  getPatientVisitsById: async (patientId: string, page: Number, per_page: number): Promise<IPatientVisitReturn | string> => {
    try {
      const response = await http.cleanGet('/patients/visits', { id: patientId, page, per_page });

      if (response.data.data) {
        return response.data;
      } else {
        return 'noVisitsData';
      }
    } catch (error) {
      return 'invalidVisitsData';
    }
  },
  getProduct: async (articleId: string): Promise<IProduct[] | string> => {
    try {
      const response = await http.cleanGet('/patients/reports/product', { id: articleId });

      if (response.data.data) {
        return response.data.data;
      } else {
        return 'noProductsData';
      }
    } catch (error) {
      return 'invalidProductsData';
    }
  },
  getReportArticles: async (reportId: string): Promise<IArticle[] | string> => {
    try {
      const response = await http.cleanGet('/patients/reports/articles', { id: reportId });

      if (response.data.data) {
        return response.data.data;
      } else {
        return 'noArticlesData';
      }
    } catch (error) {
      return 'invalidArticlesData';
    }
  },
  // Returns the patients total (number)
  getTotalPatients: async (id: number): Promise<ICount | string> => {
    const returnValue = await http
      .cleanGet('/patients/count', { module: 'Contacts', filterValue: id })
      .then(response => {
        return { count: response.data.count };
      })
      .catch(() => 'invalidRecordCount');
    return returnValue as ICount | string;
  },
  sendSolicitud: async data => {
    return data;
  },
  updateContactConsent: async (data: { fileId: string; patientId: string }): Promise<object | string> => {
    try {
      return await http.post('/patients/updatecontactconsent', data);
    } catch (error) {
      return 'invalidPatoMediUpdate';
    }
  },
  updateConsent: async (data: { Deal_Name: string; fileId: string; recordId: string }): Promise<object | string> => {
    try {
      return await http.post('/patients/updateconsent', data);
    } catch (error) {
      return 'invalidPatoMediUpdate';
    }
  },
  updateDeal: async (Deal_Name: string, DealId: string, data: { [key: string]: any }): Promise<object | string> => {
    try {
      const dataToSend = { Deal_Name, DealId, data };
      return await http.post('/patients/updatedeal', dataToSend);
    } catch (error) {
      return 'invalidUpdateDeal';
    }
  },
  uploadPatoMedi: async (file: FormData, contactId: string, dealName: string): Promise<IUploadResponse | string> => {
    file.append('metadata', JSON.stringify({ contactId, dealName }));
    console.log('file', file);
    try {
      // ! Doesn't work because http has set JSON headers by default
      const response: any = await http.post('/patients/upload', file);
      return response.data;
    } catch (error) {
      console.log('error', error);
      return 'uploadFail';
    }
  },
  updateServiceAfterPayment: async (serviceId: string, subject: string, paidAmount: number): Promise<IUploadResponse | string> => {
    try {
      const response: any = await http.post(`/services/markAsPayed/${serviceId}`, { subject, paidAmount });
      return response;
    } catch (error) {
      return 'uploadFail';
    }
  }
});
