import { useEditProfile } from '../../hooks/profile/use-edit-profile';
import { useViewport } from '../../hooks/use-viewport';
import { useUpdateUser } from '../../hooks/use-user/use-update-user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorTitle } from './tutor-title/tutor-title';
import { TutorFields } from './tutor-fields/tutor-fields';
import { TutorButtons } from './tutor-buttons/tutor-buttons';
import { ModalCloseSession } from '../Modals/modal-close-session/modal-close-session';
import { ModalInfoError } from '../Modals/modal-info-error/modal-info-error';
import RegularSpinner from '../Spinner/RegularSpinner';
import TopMenu from '../../views/Menus/TopMenu';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { LanguageSelector } from '../../components/language';

export const UserProfile = () => {
  const {
    isEditing,
    isSaveTutorButtonDisabled,
    errorTutorMessage,
    handleStartEdit,
    handleCancelEdit,
    handleUpdateUser,
    tutorFieldsDefinition,
  } = useEditProfile();
  const { t } = useTranslation();
  const { user: userInfo, isLoading: isUserLoading } = useUpdateUser();
  const [errorText, setErrorText] = useState<string>('');
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [openSignOutModal, setOpenSignOutModal] = useState<boolean>(false);
  const { viewportHeight, viewportWidth } = useViewport();
  const isScreenBig = viewportWidth > 768;

  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('hi'))},`;

  const handleClickUpdateUser = async () => {
    const success = await handleUpdateUser();
    if (!success) {
      setErrorText('updateInvalid');
      setShowErrorModal(true);
    }
  };

  const handleOnClickSignOut = () => setOpenSignOutModal(true);

  const FormattedTutor = (
    <>
      <div className='flex justify-between p-3'>
        <TutorTitle />
        <LanguageSelector />
      </div>
      <div
        className={`rounded-lg w-full p-4 relative Personas-Card drop-shadow-lg ${
          isEditing ? (isScreenBig ? 'Personas-Card-Height' : 'Personas-Card-Height-Small') : ''
        }`}
      >
        <TutorFields
          tutorFieldsDefinition={tutorFieldsDefinition}
          isEditing={isEditing}
          errorTutorMessage={errorTutorMessage}
        />
        <TutorButtons
          isEditing={isEditing}
          isSaveTutorButtonDisabled={isSaveTutorButtonDisabled}
          handleClickEdit={handleStartEdit}
          handleOnClickSignOut={handleOnClickSignOut}
          handleCancelEdit={handleCancelEdit}
          handleClickUpdateUser={handleClickUpdateUser}
        />
      </div>
    </>
  );

  const bigScreenUserName = userInfo
    ? userInfo?.name.length > 14
      ? userInfo?.name.substring(0, 14) + '...'
      : userInfo?.name
    : '';

  const userToShow = isScreenBig ? bigScreenUserName : userInfo?.name;

  return (
    <div
      className={`w-full relative ${isScreenBig ? '' : 'bg-gray h-full'}`}
      style={{ minHeight: viewportHeight }}
    >
      <TopMenu title={pageTitle} user={userToShow} />
      {isScreenBig ? (
        <div className='grid grid-cols-1 p-5 w-full maxAltura'>
          <div className='maxAltura'>
            {isUserLoading ? <RegularSpinner /> : userInfo?.name ? FormattedTutor : null}
          </div>
          {openSignOutModal ? (
            <ModalCloseSession onClickNo={() => setOpenSignOutModal(false)} />
          ) : null}
          {showErrorModal ? (
            <ModalInfoError errorText={errorText} onClickOk={() => setShowErrorModal(false)} />
          ) : null}
        </div>
      ) : (
        <div className='w-full my-24 pb-4 px-2'>
          <div
            className='md:ml-10 md:mr-2 md:w-full'
            style={{ height: viewportHeight - 165 + 'px', overflow: 'scroll' }}
          >
            <div className='mx-3 maxAltura'>
              {isUserLoading ? <RegularSpinner /> : userInfo?.id ? FormattedTutor : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
