import {
    List,
    ListItem,
    ListSirion,
    ListItemSirion,
    ListFibra,
    ListItemFibra,
    ListItemComment,
    ListComments
} from '../../domain/models/List';
import { Row } from '../../domain/models/Row';
import { ListRepository } from '../../domain/repositories/list.repository';

export const listServices = (repository: ListRepository) => ({
    getList: async () => {
        const data: List = await repository.getList();
        return data;
    },

    getListMappedTable: async () => {
        const data: List = await repository.getList();
        return data.map((row: ListItem): Row[] => [
            {
                name: 'id',
                title: 'ID',
                showing: false,
                type: 'text',
                value: row.id,
                sortable: false
            },
            {
                name: 'username',
                title: 'Username',
                showing: true,
                type: 'text',
                value: row.name,
                sortable: false
            },
            {
                name: 'age',
                title: 'Age',
                showing: true,
                type: 'text',
                value: row.age.toString(),
                sortable: false
            },
            {
                name: 'company',
                title: 'Company',
                showing: true,
                type: 'text',
                value: row.company,
                sortable: false
            },
            {
                name: 'position',
                title: 'Position',
                showing: true,
                type: 'text',
                value: row.position,
                sortable: false
            },
            {
                name: 'action',
                title: 'Action',
                showing: true,
                type: 'custom',
                value: `/users/${row.id}`,
                link: `/users/${row.id}`,
                props: {
                    text: 'Ver perfil',
                    background: 'transparent',
                    color: '#D74121',
                    onClickNavigationRoute: `/perfiles/${row.id}`
                },
                sortable: false
            }
        ]);
    },
    getListMappedSirion: async () => {
        const data: ListSirion = await repository.getListSirion();
        return data.map((row: ListItemSirion): Row[] => [
            {
                name: 'id',
                title: 'ID',
                showing: false,
                type: 'text',
                value: row.id,
                sortable: false
            },
            {
                name: 'username',
                title: 'Username',
                showing: true,
                type: 'text',
                value: row.name,
                sortable: false
            },
            {
                name: 'age',
                title: 'Age',
                showing: true,
                type: 'text',
                value: row.age.toString(),
                sortable: false
            },
            {
                name: 'company',
                title: 'Company',
                showing: true,
                type: 'text',
                value: row.company,
                sortable: false
            },
            {
                name: 'position',
                title: 'Position',
                showing: true,
                type: 'text',
                value: row.position,
                sortable: false
            },
            {
                name: 'action',
                title: 'Action',
                showing: true,
                type: 'custom',
                value: 'Ver',
                link: `/users/${row.id}`,
                props: {
                    text: 'Ver perfil',
                    background: 'transparent',
                    color: '#D74121',
                    onClickNavigationRoute: `/perfiles/${row.id}`,
                    paddingX: 0,
                    paddingY: 0
                },
                sortable: false
            }
        ]);
    },
    getListMappedFibra: async (perPage: number, currentPage: number, sortValue?: string, sortOrder?: number, search?: string) => {
        const data: ListFibra = await repository.getListFibra(perPage, currentPage, sortValue, sortOrder, search);
        return data.map((row: ListItemFibra): Row[] => [
            {
                name: 'id',
                title: 'ID',
                showing: true,
                type: 'text',
                value: row.id,
                sortable: true
            },
            {
                name: 'nombre',
                title: 'Nombre',
                showing: true,
                type: 'text',
                value: row.name,
                sortable: false
            },
            {
                name: 'nif',
                title: 'NIF',
                showing: true,
                type: 'text',
                value: row.nif,
                sortable: false
            },
            {
                name: 'dirección',
                title: 'Dirección',
                showing: true,
                type: 'text',
                value: row.address,
                sortable: false
            },
            {
                name: 'teléfono',
                title: 'Teléfono',
                showing: true,
                type: 'text',
                value: row.phone,
                sortable: false
            },
            {
                name: 'acciones',
                title: 'Acciones',
                showing: true,
                type: 'custom',
                value: `/clients/${row.id}`,
                link: `/clients/${row.id}`,
                props: {
                    text: 'Ver perfil',
                    background: 'transparent',
                    color: '#D74121',
                    onclick: 'custom',
                    paddingX: 0,
                    paddingY: 0
                },
                sortable: false
            },
            {
                name: 'status',
                title: 'Status',
                showing: true,
                type: 'custom',
                value: 'Ver',
                link: ``,
                props: {
                    text: row.state === 1 ? 'Alta' : row.state === 2 ? 'Pending' : 'Baja',
                    background: 'transparent',
                    color: row.state === 1 ? '#14CA1B' : row.state === 2 ? '#FA7F0F' : '#DE121E',
                    paddingX: 0,
                    paddingY: 0
                },
                sortable: false
            }
        ]);
    },
    getListMappedComments: async (perPage: number, currentPage: number, sortValue?: string, sortOrder?: number, filter?: string) => {
        const data: ListComments = await repository.getListComments(
            perPage,
            currentPage,
            sortValue ? sortValue : undefined,
            sortOrder ? sortOrder : undefined,
            filter ? filter : filter
        );
        return data.map((row: ListItemComment): Row[] => [
            {
                name: 'id',
                title: 'ID',
                showing: true,
                type: 'text',
                value: row.id,
                sortable: true
            },
            {
                name: 'date',
                title: 'Fecha y hora',
                showing: true,
                type: 'custom',
                value: row.date,
                props: {
                    date: row.date,
                    type: 'regular_with_time'
                },
                sortable: false
            },
            {
                name: 'user',
                title: 'Usuario',
                showing: true,
                type: 'text',
                value: row.user,
                sortable: true
            },
            {
                name: 'reason',
                title: 'Motivo',
                showing: true,
                type: 'text',
                value: row.reason,
                sortable: true
            },
            {
                name: 'comment',
                title: 'Comentario',
                showing: true,
                type: 'text',
                value: row.comment,
                sortable: true
            }
        ]);
    }
});
