import React from 'react';
import { Form } from '../../components/Form/Form';
import { FormConfig } from '../../hexagonal-architecture-frontend-base/src/domain/models/Form';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elem = `interface Props {
    config: FormConfig[];
    flex: 'row' | 'column';
    id?: string;
    justifyForm?: 'flex-start' | 'flex-end' | 'center';
    justifyButtonSubmit?: 'flex-start' | 'flex-end' | 'center';
    onFormSubmit: (data: any) => void;
    dataService?: any;
    cancel?: boolean;
}

export const Form: React.FC<Props> = ({
    config,
    flex,
    onFormSubmit,
    justifyForm,
    justifyButtonSubmit,
    cancel = false,
    dataService,
    id
}) => {
    const [formData, setFormData] = useState<FormDataResposeItem[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const basicFormData: FormDataResposeItem[] = dataService
            ? id
                ? formServices().formData(config, dataService, id)
                : formServices().formData(config, dataService)
            : formServices().formData(config);
        setFormData(basicFormData);
    }, []);

    const changeFormData = (formInput: FormConfig, value: string | object | Array<optionItem> | undefined) => {
        const auxFormData = formServices().setFormData(formData, formInput, value);
        setFormData(auxFormData);
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: flex,
                    gap: '20px',
                    margin: '20px 0',
                    justifyContent: justifyForm ? justifyForm : 'center',
                    alignItems: 'center'
                }}
            >
                {config.length && formData.length
                    ? config.map((input: FormConfig, index: number) => {
                          return (
                              <FormItem
                                  key={index}
                                  title={input.label}
                                  type={input.type}
                                  width={input.width}
                                  required={input.required}
                                  disabled={Boolean(input.disabled)}
                                  form={input}
                                  formResponse={formData}
                                  index={index}
                                  details={input.details}
                                  text={input.text}
                                  singleChoice={Boolean(input.singleChoice)}
                                  isMulti={Boolean(input.isMulti)}
                                  options={input.options ? input.options : []}
                                  selectedOptionBG={input.selectedOptionBG ? input.selectedOptionBG : ''}
                                  changeFormData={changeFormData}
                              />
                          );
                      })
                    : null}
            </div>
            <div className='flex' style={{ justifyContent: justifyButtonSubmit ? justifyButtonSubmit : 'center' }}>
                {cancel ? (
                    <Button
                        onclick={() => {
                            navigate(-1);
                        }}
                        text='Cancelar'
                        background='transparent'
                        color='black'
                    />
                ) : null}

                <Button
                    onclick={() => {
                        formServices().ableToSubmit(formData) ? onFormSubmit(formData) : null;
                    }}
                    text='Submit'
                    background='#D74121'
                    color='white'
                    disabled={!formServices().ableToSubmit(formData)}
                />
            </div>
        </div>
    );
};`;

const elemFormItem = `interface Props {
    index: number;
    label: string;
    type: 'text' | 'password' | 'email' | 'number' | 'phone' | 'date' | 'file' | 'select' | 'checkbox' | 'radiobox' | 'space' | 'textarea';
    width: string;
    placeholder?: string;
    required?: string;
    disabled: boolean;
    options?: Array<optionItem>;
    isMulti?: boolean;
    singleChoice?: boolean;
    selectedOptionBG?: string;
    minDate?: string;
    maxDate?: string;
    startingDate?: string;
    countryPreFix?: boolean;
    isPrefixDisabled?: boolean;
    details?: 'button' | 'tag' | 'toggle';
    text?: string;
    form: FormConfig;
    formResponse: FormDataResposeItem[];
    changeFormData: (e: FormConfig, value: string | object | Array<optionItem> | undefined) => void;
}

export const FormItem: React.FC<Props> = ({
    index,
    label,
    type,
    width,
    placeholder,
    required,
    disabled,
    options,
    isMulti,
    singleChoice,
    minDate,
    maxDate,
    startingDate,
    isPrefixDisabled,
    countryPreFix,
    selectedOptionBG,
    details = '',
    text = '',
    form,
    formResponse,
    changeFormData
}) => {
    let content;
    switch (type) {
        case 'text':
        case 'password':
        case 'email':
        case 'number':
            content = (
                <Input
                    id={label}
                    name={label}
                    placeholder={placeholder}
                    type={type}
                    isDisabled={disabled}
                    onChange={e => changeFormData(form, e)}
                    value={formResponse[index].value!.toString()}
                    borderRadius='6px'
                />
            );
            break;
        case 'phone':
            content = (
                <InputPhone
                    placeholder={placeholder}
                    borderStyleRadius='6px'
                    onChange={e => changeFormData(form, e)}
                    isDisabled={disabled}
                    countryPreFix={countryPreFix}
                    isPrefixDisabled={isPrefixDisabled}
                />
            );
            break;
        case 'date':
            content = (
                <InputDate
                    onChange={e => changeFormData(form, e)}
                    minDate={minDate}
                    maxDate={maxDate}
                    startingDate={startingDate}
                    pickedDate={formResponse[index].value!.toString()}
                    isDisabled={disabled}
                    borderRadius='6px'
                />
            );
            break;
        case 'file':
            content = (
                <InputFile
                    label={placeholder ? placeholder : ''}
                    onChange={e => changeFormData(form, e)}
                    emptyFile='There is no file yet'
                    fileName={formResponse[index].value!.toString()}
                    fileRequirements=''
                />
            );
            break;
        case 'select':
            content = (
                <InputSelector
                    param='label'
                    placeholder={placeholder ? placeholder : ''}
                    options={options ? options : []}
                    onChange={e => changeFormData(form, e)}
                    selectedOption={formResponse[index].value as Array<optionItem>}
                    selectedOptionBG={selectedOptionBG ? selectedOptionBG : 'none'}
                    isMulti={Boolean(isMulti)}
                    isDisabled={disabled}
                    singleChoice={singleChoice}
                    borderStyle='1px solid #E5E7EB'
                    borderStyleRadius='6px'
                />
            );
            break;
        case 'checkbox':
            break;
        case 'radiobox':
            break;
        case 'space':
            break;
        case 'textarea':
            content = <InputTextArea width={width} placeholder='Info adicional' onChange={e => changeFormData(form, e)} />;
            break;
        default:
            break;
    }

    let extra;
    switch (details) {
        case 'button':
            extra = <Button background='' text={text} paddingX={1} paddingY={1} />;
            break;
        case 'tag':
            extra = <Tag text={text} color='black' background='' paddingX={1} paddingY={1} />;
            break;
        case 'toggle':
            extra = <Toggle bgTrue='red' bgFalse='green' />;
            break;
        default:
            extra = null;
            break;
    }

    return (
        <div style={{ width: width }}>
            <div className='flex justify-between mb-5'>
                <h3>
                    {label} {required}
                </h3>
                {extra}
            </div>
            {content}
        </div>
    );
};`;

const firstForm = `<Form
    onFormSubmit={e => {
        console.log('FORM', e);
    }}
    config={firstExample}
    flex='column'
/>`;

const firstExampleString = `[
    {
        key: 'name',
        label: 'Nombre',
        placeholder: 'nombre',
        required: '*',
        type: 'text',
        width: '50%'
    },
    {
        key: 'surname',
        label: 'Apellidos',
        placeholder: 'apellido',
        type: 'text',
        width: '50%'
    },
    {
        key: 'phone',
        label: 'Mobil',
        disabled: true,
        type: 'text',
        width: '30%'
    },
    {
        key: 'password',
        label: 'Constraseña',
        required: '(obligatorio)',
        type: 'password',
        width: '30%'
    }
];`;

const Forms = () => {
    const firstExample: FormConfig[] = [
        {
            key: 'name',
            label: 'Nombre',
            placeholder: 'nombre',
            required: '*',
            type: 'text',
            width: '50%'
        },
        {
            key: 'surname',
            label: 'Apellidos',
            placeholder: 'apellido',
            disabled: true,
            type: 'text',
            width: '50%'
        },
        {
            key: 'phone',
            label: 'Mobil',
            type: 'phone',
            width: '50%'
        },
        {
            key: 'birthday',
            label: 'Fecha de nacimiento',
            required: '(obligatorio)',
            type: 'date',
            width: '50%'
        },
        {
            key: 'password',
            label: 'Constraseña',
            required: '(obligatorio)',
            type: 'password',
            width: '50%'
        }
    ];

    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Forms</div>
            <div className='text-lg mt-4'>
                A form is a configurable element mabe by FormItems that given a structure generates the different inputs with its lables.
            </div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>A FormItem is the element that allows the different input displays.</div>
            <CopyBlock language={'jsx'} text={elemFormItem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>
                The FormItem renders a different element base on the type. Next different implementations of the text, password, email and
                number types.
            </div>
            <ul className='text-base'>
                The configuration has different parts:
                <li className='list-disc ml-8'>key: it allows the population of the input based on the model field name</li>
                <li className='list-disc ml-8'>name: it is the name of the input</li>
                <li className='list-disc ml-8'>type: in this case text, password, email and number</li>
                <li className='list-disc ml-8'>label: the text displayed on top of the input</li>
                <li className='list-disc ml-8'>placeholder?: the input text</li>
                <li className='list-disc ml-8'>
                    required?: allows blocking the submit button till it is filled and also is the text displayed next to the label
                </li>
                <li className='list-disc ml-8'>disabled: the input is either editable or not editable</li>
                <li className='list-disc ml-8'>value: the input is either editable or not editable</li>
                <li className='list-disc ml-8'>onChange: the input is either editable or not editable</li>
            </ul>
            <div className='text-base mt-4'>
                The form has no lenght limit and it includes at least a submit button. The first example is a form with three text elements
                (name, surname and phone) and a password. The form is a flexbox so the direction of the flex has to be chosen either row or
                column And finally the other mandatory filed is the function done when submiting the form.
            </div>
            <CopyBlock language={'jsx'} text={firstForm} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <ul className='text-base'>
                The configuration has different parts:
                <li className='list-disc ml-8'>key: which allows the population of the form based on the model field name</li>
                <li className='list-disc ml-8'>label: which is the text displayed on top of the input</li>
                <li className='list-disc ml-8'>placeholder?: the input text</li>
                <li className='list-disc ml-8'>
                    required?: allows blocking the submit button till it is filled and also it is the string displayed next to the label
                </li>
                <li className='list-disc ml-8'>type: refers to the input specific type</li>
                <li className='list-disc ml-8'>disabled: the input is either editable or not editable</li>
                <li className='list-disc ml-8'>width: the input width to custom display the elements in the form</li>
            </ul>
            <CopyBlock language={'jsx'} text={firstExampleString} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <Form
                onFormSubmit={e => {
                    console.log('FORM', e);
                }}
                config={firstExample}
                flex='column'
            />
        </div>
    );
};

export default Forms;
