import { optionItem } from '../../domain/models/Input';
import { phonePrefix } from '../../domain/models/PhonePrefix';

export const inputServices = () => ({
    isSelected: (option: string, selectedOption: Array<optionItem | phonePrefix>, param: string): boolean => {
        if (!selectedOption.length) {
            return false;
        } else {
            let isSelected = false;
            selectedOption.map((element: optionItem | phonePrefix) => {
                if (element[param as keyof typeof element] === option) isSelected = true;
            });
            return isSelected;
        }
    },
    handleOnChange: (
        arrayOptionsSelected: Array<optionItem | phonePrefix>,
        option: optionItem | phonePrefix,
        isMulti: boolean,
        removeItem: boolean,
        singleChoice: boolean,
        param: string,
        maxChoice?: number
    ): Array<optionItem | phonePrefix> => {
        if (removeItem) {
            const itemIndex = arrayOptionsSelected.findIndex(
                (o: optionItem | phonePrefix) => o[param as keyof typeof o] === option[param as keyof typeof option]
            );
            return arrayOptionsSelected.filter((o: optionItem | phonePrefix, index: number) => index !== itemIndex);
        } else {
            if (isMulti) {
                if (singleChoice && arrayOptionsSelected.length) {
                    const isInArray = arrayOptionsSelected.filter(
                        (element: optionItem | phonePrefix) =>
                            element[param as keyof typeof element] === option[param as keyof typeof option]
                    );
                    if (isInArray.length > 0) return arrayOptionsSelected;
                    else arrayOptionsSelected.push(option);
                } else if (maxChoice && arrayOptionsSelected.length === maxChoice) {
                    arrayOptionsSelected.shift();
                    arrayOptionsSelected.push(option);
                } else {
                    arrayOptionsSelected.push(option);
                }
            } else {
                arrayOptionsSelected.pop();
                arrayOptionsSelected.push(option);
            }
            return arrayOptionsSelected;
        }
    },
    handleMandatoryField: (isSelector: boolean | undefined, firstValue: number | undefined, secondValue: string): any => {
        if (isSelector && firstValue === 0) {
            return true;
        } else if (!isSelector && secondValue === '') {
            return true;
        } else {
            return false;
        }
    },
    handleFilterSelector: (value: string, options: Array<optionItem | phonePrefix>, param: string): Array<optionItem | phonePrefix> => {
        const parsedValue = value;
        const filteredOptions = options?.filter((option: any) => option[param as keyof typeof option].toLowerCase().includes(parsedValue));
        return filteredOptions;
    }
});
