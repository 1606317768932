import { Client } from '../../domain/models/Client';

const clients: Client[] = [
    {
        id: '1',
        name: 'Carlos',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '2',
        name: 'Laura',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '3',
        name: 'Carla',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '4',
        name: 'Carla',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '5',
        name: 'Carla',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '6',
        name: 'Carla',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '7',
        name: 'Carla',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '8',
        name: 'Carla',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '9',
        name: 'Carla',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    },
    {
        id: '10',
        name: 'Carla',
        surname: 'García',
        birthdate: '10/10/1990',
        nif: '123456789J',
        phone: '+34 939729090',
        mobilephone: '+34 629729090',
        direction: 'una calle muy bonita',
        email: 'carlos.garcia@siriondev.com',
        cp: '08871',
        city: { id: 1, label: 'Gavà', value: 'option2', extra: {} },
        province: { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
        country: 'España',
        iban: 'ES8737373737373773YY737'
    }
];

export const clientServiceMock = () => ({
    getClient: (id: string): Client => {
        return clients.filter(client => client.id === id)[0];
    },
    createClient: (client: Client): Client => {
        clients.push(client);
        return client;
    },
    updateClient: (client: Client): Client => {
        clients.forEach(c => (c.id === client.id ? client : c));
        return client;
    },
    deleteClient: (id: string): Client => {
        return clients.filter(client => client.id === id)[0];
    }
});
