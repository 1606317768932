import React from 'react';
import { Time } from '../../components/Time';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elem = `interface Props {
    date: string | number;
    type?: string;
    color?: string;
    font?: string;
    weight?: string;
}

export const Time: React.FC<Props> = ({ date, type = 'regular', color, font, weight }) => {
    const { hour, hours, time, minutes } = GlobalService.parseTime(date);
    const style = {
        color: color,
        fontSize: font,
        fontWeight: weight
    };
    switch (type) {
        case 'standard':
            return <div style={style}>{hours:minutes}</div>;
        case 'british':
            return <div style={style}>{hour:minutes time}</div>;
        default:
            return <div style={style}>{hours:minutes}</div>;
    }
};`;

const Times = () => {
    // const date = '2023-03-10T04:23:45.678Z';
    const date = 1678411425678;
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Time</div>
            <div className='text-lg mt-4'>
                The time element transforms a string timestamp or unix numeric value to one of the following formats
            </div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Next some implementations of the element</div>
            <div className='text-base mt-2'>Default time.</div>
            <CopyBlock
                language={'jsx'}
                text={`<Time date={date} />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Time date={date} />
            </div>
            <div className='text-base mt-2'>Default time british format.</div>
            <CopyBlock
                language={'jsx'}
                text={`<Time date={date} type='british' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Time date={date} type='british' />
            </div>
            <div className='text-base mt-2'>Different color, font size and font weight .</div>
            <CopyBlock
                language={'jsx'}
                text={`<Date date={date} type='abb' color='gray' font='15px' weight='200' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex flex-col gap-4 my-2 h-auto w-auto'>
                <Time date={date} color='gray' font='15px' weight='200' />
                <Time date={date} color='gray' font='15px' weight='600' />
                <Time date={date} color='#E06C75' font='20px' weight='400' />
                <Time date={date} color='#E06C75' font='20px' weight='800' />
            </div>
        </div>
    );
};

export default Times;
