import React from 'react';

interface Props {
    // the text has to be translated and formatted
    // in case always capitalized add capitilized to div className
    background?: string;
    border?: string;
    borderRadius?: string;
    color: string;
    extraClass?: string;
    fontSize?: string;
    paddingBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
    paddingX?: number;
    paddingY?: number;
    text: string;
    weight?: string;
}

export const Tag: React.FC<Props> = ({
    background,
    border,
    borderRadius,
    color,
    extraClass,
    fontSize,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    text,
    weight
}) => {
    const padL = paddingLeft || paddingLeft === 0 ? paddingLeft : paddingX || paddingX === 0 ? paddingX : 32;
    const padR = paddingRight || paddingRight === 0 ? paddingRight : paddingX || paddingX === 0 ? paddingX : 32;
    const padT = paddingTop || paddingTop === 0 ? paddingTop : paddingY || paddingY === 0 ? paddingY : 6;
    const padB = paddingBottom || paddingBottom === 0 ? paddingBottom : paddingY || paddingY === 0 ? paddingY : 6;
    const style: any = {
        background: background,
        border: border,
        borderRadius: borderRadius,
        padding: `${padT}px ${padR}px ${padB}px ${padL}px`
    };
    if (fontSize) {
        style['fontSize'] = fontSize;
    }

    return (
        <div className={`text-center w-fit h-fit whitespace-nowrap ${extraClass}`} style={style}>
            <p style={{ color: color, fontWeight: weight }}>{text}</p>
        </div>
    );
};
