// Services
import { userRepositoryInstance } from '../../infrastructure/instances/userRepository';
import { userServices } from '../../infrastructure/services/user.service';

// Interfaces
import { IUserData } from '../models/ITutor';

const emptyUser: IUserData = {
  errorPrisma: '',
  Prisma: {
    dni: '',
    email: '',
    id: 0,
    name: '',
    patientsAccess: false,
    phone: '',
    stripeId: ''
  }
};

let activeUser = emptyUser;

export const ActiveUserService = {
  getActiveUser: (): IUserData => {
    return activeUser;
  },

  initData: async (reload?: boolean): Promise<IUserData> => {
    if (!activeUser.Prisma.id || reload) {
      activeUser = await userServices(userRepositoryInstance)
        .getActiveUser()
        .then((response: IUserData) => response);
    }
    return activeUser;
  },
  reloadCache: () => {
    activeUser = emptyUser;
  },
  setActiveUser: (newActiveUser: IUserData) => {
    Object.assign(activeUser, newActiveUser);
  }
};
