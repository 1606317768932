export const starRatingService = () => ({
    round: (numb: number): number => {
        const floor: number = Math.floor(numb);
        const num: number = floor + (numb - floor < 0.25 ? 0.0 : numb - floor > 0.75 ? 1 : 0.5);
        return num;
    },
    onMouseMove: (pageX: number, currentTarget: number, width: number): number => {
        const x = pageX - currentTarget;
        const calc = 100 - (width - x) * (100 / width);
        return calc;
    }
});
