import { atomEffect } from 'jotai-effect';
import { patientsAtom } from '../patients-atoms';
import { getSinglePatientAttachedFilesAtom } from '../getters';

export const assignAttachedFilesToPatientAtomEffect = atomEffect((get, set) => {
  const getPatientAttachedFiles = get(getSinglePatientAttachedFilesAtom);
  const patients = get.peek(patientsAtom);

  // TODO - test if returning when it's loading makes it not assign a value when multiple fetches are made
  if (getPatientAttachedFiles.data == null) return;

  const patientToUpdate = patients.find(
    patient => patient.id === getPatientAttachedFiles.data[0].parentId.id,
  );

  if (patientToUpdate == null) return;

  const newPatients = patients.map(patient => {
    if (patient.id !== patientToUpdate.id) return patient;

    return {
      ...patient,
      attachments: getPatientAttachedFiles.data,
    };
  });

  set(patientsAtom, newPatients);
});
