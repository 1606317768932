import { useAtomValue } from 'jotai';
import {
  allQuotesAtom,
  getPatientsDealsAtom,
  getPatientsQuotesAtom,
  getPatientsVisitsAtom,
  patientsAtom,
} from '../../../store/global/atoms';
import { getAllPatientsAtom, isGettingAllPatientsAtom } from '../../../store/global/atoms';
import { getExtraInfoArray } from '../../../utils';

export const usePatientsValue = () => {
  const patients = useAtomValue(patientsAtom);
  const getAllPatients = useAtomValue(getAllPatientsAtom);
  const isGettingAllPatients = useAtomValue(isGettingAllPatientsAtom);
  const getPatientsDeals = useAtomValue(getPatientsDealsAtom);
  const getPatientsQuotes = useAtomValue(getPatientsQuotesAtom);
  const getPatientsVisits = useAtomValue(getPatientsVisitsAtom);
  const allQuotes = useAtomValue(allQuotesAtom);

  const isLoading = getAllPatients.isPending || isGettingAllPatients;
  const isError = getAllPatients.isError;
  const errorReason = getAllPatients.failureReason;

  const numberOfPatients = patients.length;

  return {
    patients,
    allDeals: getExtraInfoArray(getPatientsDeals.data),
    allQuotes,
    allVisits: getExtraInfoArray(getPatientsVisits.data),
    patientIdToDeals: getPatientsDeals.data,
    patientIdToQuotes: getPatientsQuotes.data,
    patientIdToVisits: getPatientsVisits.data,
    isLoading,
    isDealsLoading: getPatientsDeals.isPending,
    isQuotesLoading: getPatientsQuotes.isPending,
    isVisitsLoading: getPatientsVisits.isPending,
    isError,
    errorReason,
    numberOfPatients,
    dealsError: getPatientsDeals.error,
    quotesError: getPatientsQuotes.error,
    visitsError: getPatientsVisits.error,
  };
};
