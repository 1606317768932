import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import Input from '../components/Inputs/Input';
import { ButtonBase } from '../components/Buttons/button-base';

// Services
import { http } from '../hexagonal-architecture-frontend-base/src/infrastructure/plugins/http/http';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Helpers
import { validate } from '../hexagonal-architecture-frontend-base/src/domain/services/user.service';

// Interfaces
import {
  UserChangePassword,
  UserSignupErrors,
} from '../hexagonal-architecture-frontend-base/src/domain/models/User';

// CSS
import '../styles/Signup.css';

// Images
import Image from '../img/forgotPassword.png';
import Logo from '../img/logolandscape.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = useParams();

  /**********
   * States *
   **********/
  const [buttonText, setButtonText] = useState<string>('resetPassword');
  const [errorMessage, setErrorMessage] = useState<UserSignupErrors>({
    password: '',
    passwordsMatch: '',
  });
  const [msgToShow, setMsgToShow] = useState<string>('');
  const [showInputFields, setShowInputFields] = useState<boolean>(true);
  const [titleText, setTitleText] = useState<string>('title');
  const [user, setUser] = useState<UserChangePassword>({ password: '', passwordConfirmation: '' });
  const { viewportWidth } = useViewport();

  /********************
   * Helper functions *
   ********************/
  const setASError = () => {
    setShowInputFields(false);
    setMsgToShow('askAdmin');
    setButtonText('login');
    setTitleText('askAdminTitle');
  };

  const setAsInvalidToken = () => {
    setShowInputFields(false);
    setMsgToShow('invalidToken');
    setButtonText('newCode');
    setTitleText('invalidTokenTitle');
  };

  const setAsOk = () => {
    setShowInputFields(false);
    setMsgToShow('passwordChanged');
    setButtonText('login');
    setTitleText('changeDone');
  };

  /************
   * Handlers *
   ************/
  const handleChange = (field: string, e: string) => {
    const errorToShow: string = inputsDefinition.filter(def => def.id === field)[0].error;
    const errorToSet = t(errorToShow);

    const isValid = validate(e, 'password');

    if (field !== 'passwordConfirmation') {
      if (e && !isValid) {
        setErrorMessage(error => ({ ...error, [field]: errorToSet }));
      } else {
        setErrorMessage(error => ({ ...error, [field]: '' }));
      }
      setUser(user => ({ ...user, [field]: e }));
    } else {
      if (user.password !== e) {
        setErrorMessage(error => ({ ...error, passwordsMatch: errorToSet }));
      } else {
        setErrorMessage(error => ({ ...error, passwordsMatch: '' }));
      }
      setUser(user => ({ ...user, passwordConfirmation: e }));
    }
  };

  const handleSubmit = () => {
    if (buttonText === 'resetPassword') {
      http
        .cleanPost('/password/reset', {
          token: token.token,
          newPassword: user.password,
        })
        .then((response: any) => {
          if (response.status === 200 || response.status === 201) setAsOk();
        })
        .catch(error => {
          if (error.response.data.message === 'Invalid token') setAsInvalidToken();
          else setASError();
        });
    } else if (buttonText === 'login') {
      navigate('/login');
    } else if (buttonText === 'newCode') {
      navigate('/forgotPassword');
    }
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  let isButtonEnabled = true;
  Object.keys(user).forEach(key => {
    if (!user[key as keyof UserChangePassword]) {
      isButtonEnabled = false;
    }
  });
  if (isButtonEnabled) {
    Object.keys(errorMessage).forEach(key => {
      if (errorMessage[key as keyof UserSignupErrors] !== '') {
        isButtonEnabled = false;
      }
    });
  }

  const inputsDefinition = [
    {
      id: 'password',
      text: 'password',
      error: 'errors.passwordInvalid',
      value: user.password,
      onChange: (e: string) => handleChange('password', e),
    },
    {
      id: 'passwordConfirmation',
      text: 'passwordconfirmation',
      error: 'errors.passwordsNotMatch',
      value: user.passwordConfirmation,
      onChange: (e: string) => handleChange('passwordConfirmation', e),
    },
  ];

  const userInputs = inputsDefinition.map((item, index) => {
    return (
      <div key={index} className={index ? 'mt-4' : ''}>
        <label htmlFor={item.id} className='block text-sm font-medium text-gray-700 purple-text'>
          {item.id !== 'dni'
            ? GlobalService.uppercaseFirstLetter(t(item.text))
            : t(item.text).toUpperCase()}
        </label>
        <div className='mt-1'>
          <Input
            id={item.id}
            name={item.id}
            placeholder={
              item.id !== 'dni'
                ? GlobalService.uppercaseFirstLetter(t(item.text))
                : t(item.text).toUpperCase()
            }
            type={item.id.toLowerCase().includes('password') ? 'password' : 'text'}
            value={item.value as string}
            customClass='login-input'
            onChange={item.onChange}
            enableShowPassword={item.id.toLowerCase().includes('password')}
          />
          {errorMessage ? (
            <p className='error-message my-2 mx-4'>
              {
                errorMessage[
                  item.id === 'passwordConfirmation'
                    ? 'passwordsMatch'
                    : (item.id as keyof UserSignupErrors)
                ]
              }
            </p>
          ) : null}
        </div>
      </div>
    );
  });

  return (
    <div className='main-container-forgot-password'>
      {isScreenBig ? (
        <div className='logo-div'>
          <img src={Logo} />
        </div>
      ) : null}
      <div className={isScreenBig ? 'form-div' : 'form-div-forgot-password-small'}>
        <div
          className={
            viewportWidth >= 640 ? 'form-container-forgot-password' : 'form-container-small'
          }
        >
          <h2 className={`form-title-forgot-password${isScreenBig ? '' : '-small'}`}>
            {GlobalService.uppercaseFirstLetter(t(`pages.resetPassword.${titleText}`))}
          </h2>
          {showInputFields ? userInputs : t(`pages.resetPassword.${msgToShow}`)}
          <div className='mt-14'>
            <ButtonBase
              text={GlobalService.uppercaseFirstLetter(t(`pages.resetPassword.${buttonText}`))}
              background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
              color={'white'}
              borderRadius={'50px'}
              onClick={handleSubmit}
              buttonWidth='100%'
              disabled={!isButtonEnabled}
            />
          </div>
        </div>
      </div>

      <div className='hidden left-image md:flex content-center'>
        <img src={Image} />
      </div>
    </div>
  );
};

export default ResetPassword;
