import { useAtomValue } from 'jotai';
import { updateDealAfterPaymentAtom } from '../../../store/global/atoms';

export const useUpdateDealAfterPayment = () => {
  const updateDealAfterPayment = useAtomValue(updateDealAfterPaymentAtom);

  return {
    result: updateDealAfterPayment.data,
    updateDealAfterPayment: updateDealAfterPayment.mutate,
    updateDealAfterPaymentAsync: updateDealAfterPayment.mutateAsync,
    error: updateDealAfterPayment.error,
    isLoading: updateDealAfterPayment.isPending,
  };
};
