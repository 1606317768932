import { SignRepository } from '../../domain/repositories/sign.repository';
import { ISignTemplate } from '../../domain/models/IZohoSign';

export const signServices = (repository: SignRepository): SignRepository => ({
    getDocInfo: async (docId: string) => {
        return repository.getDocInfo(docId);
    },
    getSignUrl: async (params: { data: ISignTemplate; docId: string }) => {
        return repository.getSignUrl(params);
    },
    getTemplateInfo: async (docId: string) => {
        return repository.getTemplateInfo(docId);
    },
    uploadSignedFile: async (docId: string, reqId: string, docName: string) => {
        return repository.uploadSignedFile(docId, reqId, docName);
    }
});
