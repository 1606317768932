import React, { useEffect, useState } from 'react';
import Loader from '../Loaders/Loader';
import '../../styles/StarRating.css';
import { starRatingService } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/starRating.services';
import { generateUid } from '../../hexagonal-architecture-frontend-base/src/infrastructure/plugins/uid/uid';

interface CommonProps {
    stars: 5 | 10;
    color?: string;
    loader?: string;
}
type ConditionalProps =
    | {
          disabled?: boolean;
          loading?: never;
          saveRating?: never;
          rating?: number;
      }
    | {
          disabled?: never;
          loading?: boolean;
          saveRating?: (num: number) => Promise<number>;
          rating?: number;
      }
    | {
          disabled?: never;
          loading?: never;
          saveRating?: (num: number) => Promise<number>;
          rating?: number;
      };
type Props = CommonProps & ConditionalProps;
export const StarRating: React.FC<Props> = ({
    rating = 0,
    saveRating,
    disabled = false,
    color = 'black',
    loader = 'regular',
    loading = false,
    stars
}) => {
    const uuid = generateUid();
    const [hover, setHover] = useState(0);
    const [value, setValue] = useState(0);
    const [inside, setInside] = useState(false);
    const five = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
    const ten = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10];
    const setRating = () => {
        const num = starRatingService().round(hover * (stars / 100));
        saveRating
            ? saveRating(num).then((res: number) => {
                  setValue(res);
              })
            : null;
    };
    useEffect(() => {
        stars === 5 ? (five.includes(rating) ? setValue(rating) : null) : ten.includes(rating) ? setValue(rating) : null;
        const example: any = document.getElementById(`${uuid}`);
        example.onmousemove = (e: any) => {
            const calc = starRatingService().onMouseMove(e.pageX, e.currentTarget.offsetLeft, example.offsetWidth);
            setHover(calc);
            setInside(true);
        };
        example.onmouseleave = () => {
            setInside(false);
        };
    }, []);
    return (
        <div className='px-3 py-2 text-center w-fit flex items-center '>
            <div
                className={`stars-outer-five ${disabled || loading ? 'cursor-normal' : 'cursor-pointer opacity-100 hover:opacity-60'}`}
                onClick={() => {
                    !disabled && !loading ? setRating() : null;
                }}
                id={uuid}
                style={{ color: color }}
            >
                {inside && !disabled && !loading ? (
                    <div className='stars-inner-five' style={{ width: `${hover}%`, color: color }}></div>
                ) : (
                    <div className={`stars-inner-five`} style={{ width: `${value * (100 / stars)}%`, color: color }}></div>
                )}
            </div>
            {loading ? (
                <div className='ml-4'>
                    <Loader type={loader} size='12px' border='8px' color='transparent' spinnerColor={color} />
                </div>
            ) : (
                <div className='ml-4' style={{ color: color }}>
                    {value}
                </div>
            )}
        </div>
    );
};
