import { Row } from '../../domain/models/Row';
import { SirionItemDTO } from '../dto/tableDTO';

export const dataExampleSirion: Row[][] = [
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 1,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Cliente XXXXX',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '25',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Sirion',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Front Vue',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: '/users/1',
            props: {
                text: 'Ver perfil',
                paddingX: 0,
                paddingY: 0,
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/1'
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 2,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Cliente XXXXX',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '29',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Sirion',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Front React',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: '/users/2',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/2',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 3,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Cliente XXXXX',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '23',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Sirion',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'CTO',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: '/users/3',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/3',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 4,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Cliente XXXXX',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '23',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Sirion',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Front React',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: '/users/4',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/4',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 5,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Cliente XXXXX',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '30',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Sirion',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Front React',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: '/users/5',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/5',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ]
];

export const listSirion: SirionItemDTO[] = [
    {
        id: 1,
        name: 'Cliente XXXXX',
        age: 25,
        email: 'cliente@gmail.com',
        position: 'Front Vue',
        company: 'Sirion',
        state: 1
    },
    {
        id: 2,
        name: 'Cliente XXXXX',
        age: 29,
        email: 'cliente@gmail.com',
        position: 'Front React',
        company: 'Sirion',
        state: 2
    },
    {
        id: 3,
        name: 'Cliente XXXXX',
        age: 23,
        email: 'cliente@gmail.com',
        position: 'CTO',
        company: 'Sirion',
        state: 3
    },
    {
        id: 4,
        name: 'Cliente XXXXX',
        age: 23,
        email: 'cliente@gmail.com',
        position: 'Front React',
        company: 'Sirion',
        state: 2
    },
    {
        id: 5,
        name: 'Cliente XXXXX',
        age: 30,
        email: 'cliente@gmail.com',
        position: 'Front React',
        company: 'Sirion',
        state: 2
    }
];

export const getSirionList = <T>() => {
    return new Promise(res => {
        setTimeout(() => {
            res(listSirion);
        }, 1000);
    }) as T;
};
