import { useShowErrorPopup } from './use-show-error-popup';

export const useShowQuoteProductsError = () => {
  const { showErrorPopup } = useShowErrorPopup();

  const showQuoteProductsError = (errorLocaleText = 'noProductsData') => {
    showErrorPopup(errorLocaleText);
  };

  return { showQuoteProductsError };
};
