import { useTranslation } from 'react-i18next';
import { usePopup } from '../use-popup';
import { PopupOptionsSetter } from '../../../ts';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

export const useShowAddressUpdateSuccessPopup = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = usePopup();

  const showAddressUpdateSuccessPopup = () => {
    const addressUpdateSuccessPopupOptions: PopupOptionsSetter = {
      title: GlobalService.uppercaseFirstLetter(t('updateSuccess')),
      children: GlobalService.uppercaseAfterPointFirstsLetters(t('messages.addressUpdateSuccess')),
      buttons: [
        {
          type: 'primary',
          children: GlobalService.uppercaseFirstLetter(t('pages.personas.ok')),
          onClick: () => closePopup(),
        },
      ],
    };

    openPopup(addressUpdateSuccessPopupOptions);
  };

  return { showAddressUpdateSuccessPopup };
};
