import { atom } from 'jotai';
import { PatientInfo } from '../../../../ts';
import { patientsAtom } from './patients-atoms';
import { defaultSelectedPatient, notFoundPatient } from '../../../../consts';
import { selectAtom } from 'jotai/vanilla/utils';
import deepEquals from 'fast-deep-equal';

export const selectedPatientIdAtom = atom<PatientInfo['id'] | null>(null);

const selectedPatientFinderAtom = atom<PatientInfo>(get => {
  const patients = get(patientsAtom);
  const selectedPatientId = get(selectedPatientIdAtom);
  if (patients.length === 0 || selectedPatientId == null) return defaultSelectedPatient;

  const newSelectedPatient =
    patients.find(patient => patient.id === selectedPatientId) || notFoundPatient;

  return newSelectedPatient;
});

// This atom makes a deep comparison between the previous and the new selected patient
// This is helpful to not update state when the selectedPatientIdAtom or the patientsAtom change but the selected patient keeps the same properties
export const selectedPatientAtom = selectAtom(
  selectedPatientFinderAtom,
  sp => sp,
  (a, b) => deepEquals(a, b),
);
