import { DownloadFileNoModuleParams } from '../../../ts';
import { downloadFileService } from './download-file.service';

export const downloadDealFileService = async ({
  moduleEntryId: dealId,
  fileId,
  fileName,
}: DownloadFileNoModuleParams) => {
  const blob = await downloadFileService({
    module: 'Deals',
    moduleEntryId: dealId,
    fileId,
    fileName,
  });
  return blob;
};
