import { Row } from '../../domain/models/Row';
import { ListItemDTO, ListItemFibraDTO } from '../dto/listDTO';
import { CommentDTO } from '../dto/commentDTO';

export const dataExampleComments: Row[][] = [
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 1,
            sortable: true
        },
        {
            name: 'date',
            title: 'Fecha y hora',
            showing: true,
            type: 'custom',
            value: Date.now(),
            props: {
                date: Date.now(),
                type: 'regular_with_time'
            },
            sortable: true
        },
        {
            name: 'user',
            title: 'Usuario',
            showing: true,
            type: 'text',
            value: 'John Doe',
            sortable: true
        },
        {
            name: 'reason',
            title: 'Motivo',
            showing: true,
            type: 'text',
            value: 'Cambio datos cliente',
            sortable: true
        },
        {
            name: 'comment',
            title: 'Comentario',
            showing: true,
            type: 'text',
            value: 'Cambio de dirección de facturación',
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 2,
            sortable: true
        },
        {
            name: 'date',
            title: 'Fecha y hora',
            showing: true,
            type: 'custom',
            value: Date.now(),
            props: {
                date: Date.now(),
                type: 'regular_with_time'
            },
            sortable: true
        },
        {
            name: 'user',
            title: 'Usuario',
            showing: true,
            type: 'text',
            value: 'John Doe',
            sortable: true
        },
        {
            name: 'reason',
            title: 'Motivo',
            showing: true,
            type: 'text',
            value: 'Otro motivo',
            sortable: true
        },
        {
            name: 'comment',
            title: 'Comentario',
            showing: true,
            type: 'text',
            value: 'Comprobación productos contratados',
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 3,
            sortable: true
        },
        {
            name: 'date',
            title: 'Fecha y hora',
            showing: true,
            type: 'custom',
            value: Date.now(),
            props: {
                date: Date.now(),
                type: 'regular_with_time'
            },
            sortable: true
        },
        {
            name: 'user',
            title: 'Usuario',
            showing: true,
            type: 'text',
            value: 'John Doe',
            sortable: true
        },
        {
            name: 'reason',
            title: 'Motivo',
            showing: true,
            type: 'text',
            value: 'Otro motivo',
            sortable: true
        },
        {
            name: 'comment',
            title: 'Comentario',
            showing: true,
            type: 'text',
            value: 'Comprobación productos contratados',
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 4,
            sortable: true
        },
        {
            name: 'date',
            title: 'Fecha y hora',
            showing: true,
            type: 'custom',
            value: Date.now(),
            props: {
                date: Date.now(),
                type: 'regular_with_time'
            },
            sortable: true
        },
        {
            name: 'user',
            title: 'Usuario',
            showing: true,
            type: 'text',
            value: 'John Doe',
            sortable: true
        },
        {
            name: 'reason',
            title: 'Motivo',
            showing: true,
            type: 'text',
            value: 'Me he equivocado',
            sortable: true
        },
        {
            name: 'comment',
            title: 'Comentario',
            showing: true,
            type: 'text',
            value: '-',
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 5,
            sortable: true
        },
        {
            name: 'date',
            title: 'Fecha y hora',
            showing: true,
            type: 'custom',
            value: Date.now(),
            props: {
                date: Date.now(),
                type: 'regular_with_time'
            },
            sortable: true
        },
        {
            name: 'user',
            title: 'Usuario',
            showing: true,
            type: 'text',
            value: 'John Doe',
            sortable: true
        },
        {
            name: 'reason',
            title: 'Motivo',
            showing: true,
            type: 'text',
            value: 'Cambio datos cliente',
            sortable: true
        },
        {
            name: 'comment',
            title: 'Comentario',
            showing: true,
            type: 'text',
            value: 'Cambio de dirección de facturación',
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 6,
            sortable: true
        },
        {
            name: 'date',
            title: 'Fecha y hora',
            showing: true,
            type: 'custom',
            value: Date.now(),
            props: {
                date: Date.now(),
                type: 'regular_with_time'
            },
            sortable: true
        },
        {
            name: 'user',
            title: 'Usuario',
            showing: true,
            type: 'text',
            value: 'John Doe',
            sortable: true
        },
        {
            name: 'reason',
            title: 'Motivo',
            showing: true,
            type: 'text',
            value: 'Alta de producto',
            sortable: true
        },
        {
            name: 'comment',
            title: 'Comentario',
            showing: true,
            type: 'text',
            value: 'Alta de fibra',
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 7,
            sortable: true
        },
        {
            name: 'date',
            title: 'Fecha y hora',
            showing: true,
            type: 'custom',
            value: Date.now(),
            props: {
                date: Date.now(),
                type: 'regular_with_time'
            },
            sortable: true
        },
        {
            name: 'user',
            title: 'Usuario',
            showing: true,
            type: 'text',
            value: 'John Doe',
            sortable: true
        },
        {
            name: 'reason',
            title: 'Motivo',
            showing: true,
            type: 'text',
            value: 'Cambio datos cliente',
            sortable: true
        },
        {
            name: 'comment',
            title: 'Comentario',
            showing: true,
            type: 'text',
            value: 'Cambio de dirección de facturación',
            sortable: true
        }
    ]
];

export const dataExample: Row[][] = [
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 0,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Pepe',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '45',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Google',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Becario',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: '/users/0',
            link: '/users/0',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/0'
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 1,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Pepe',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '45',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Google',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Becario',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: '/users/1',
            link: '/users/1',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/1'
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 2,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Pepe',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '45',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Google',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Becario',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: '/users/2',
            link: '/users/2',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/2'
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 3,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Pepe',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '45',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Google',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Becario',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: '/users/3',
            link: '/users/3',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/3'
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 4,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Pepe',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '45',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Google',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Becario',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: '/users/4',
            link: '/users/4',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/4'
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 5,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Pepe',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '45',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Google',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Becario',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: '/users/5',
            link: '/users/5',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/5'
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: false,
            type: 'text',
            value: 6,
            sortable: true
        },
        {
            name: 'username',
            title: 'Username',
            showing: true,
            type: 'text',
            value: 'Pepe',
            sortable: true
        },
        {
            name: 'age',
            title: 'Age',
            showing: true,
            type: 'text',
            value: '45',
            sortable: true
        },
        {
            name: 'company',
            title: 'Company',
            showing: true,
            type: 'text',
            value: 'Google',
            sortable: true
        },
        {
            name: 'position',
            title: 'Position',
            showing: true,
            type: 'text',
            value: 'Becario',
            sortable: true
        },
        {
            name: 'action',
            title: 'Action',
            showing: true,
            type: 'custom',
            value: '/users/6',
            link: '/users/6',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onClickNavigationRoute: '/perfiles/6'
            },
            sortable: true
        }
    ]
];

export const dataExampleRefactored: Row[][] = [
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 1,
            sortable: true
        },
        {
            name: 'nombre',
            title: 'Nombre',
            showing: true,
            type: 'text',
            value: 'Client XXXX',
            sortable: true
        },
        {
            name: 'nif',
            title: 'NIF',
            showing: true,
            type: 'text',
            value: 'H323232323',
            sortable: true
        },
        {
            name: 'dirección',
            title: 'Dirección',
            showing: true,
            type: 'text',
            value: 'C/Calle 50 Polígono 20',
            sortable: true
        },
        {
            name: 'teléfono',
            title: 'Teléfono',
            showing: true,
            type: 'text',
            value: '654789556',
            sortable: true
        },
        {
            name: 'acciones',
            title: 'Acciones',
            showing: true,
            type: 'custom',
            value: '/clients/1',
            link: '/clients/1',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        },
        {
            name: 'status',
            title: 'Status',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: ``,
            props: {
                text: 'Alta',
                background: 'transparent',
                color: '#14CA1B',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 2,
            sortable: true
        },
        {
            name: 'nombre',
            title: 'Nombre',
            showing: true,
            type: 'text',
            value: 'Client XXXX',
            sortable: true
        },
        {
            name: 'nif',
            title: 'NIF',
            showing: true,
            type: 'text',
            value: 'H323232323',
            sortable: true
        },
        {
            name: 'dirección',
            title: 'Dirección',
            showing: true,
            type: 'text',
            value: 'C/Calle 50 Polígono 20',
            sortable: true
        },
        {
            name: 'teléfono',
            title: 'Teléfono',
            showing: true,
            type: 'text',
            value: '654789556',
            sortable: true
        },
        {
            name: 'acciones',
            title: 'Acciones',
            showing: true,
            type: 'custom',
            value: '/clients/2',
            link: '/clients/2',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        },
        {
            name: 'status',
            title: 'Status',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: ``,
            props: {
                text: 'Alta',
                background: 'transparent',
                color: '#14CA1B',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 3,
            sortable: true
        },
        {
            name: 'nombre',
            title: 'Nombre',
            showing: true,
            type: 'text',
            value: 'Client XXXX',
            sortable: true
        },
        {
            name: 'nif',
            title: 'NIF',
            showing: true,
            type: 'text',
            value: 'H323232323',
            sortable: true
        },
        {
            name: 'dirección',
            title: 'Dirección',
            showing: true,
            type: 'text',
            value: 'C/Calle 50 Polígono 20',
            sortable: true
        },
        {
            name: 'teléfono',
            title: 'Teléfono',
            showing: true,
            type: 'text',
            value: '654789556',
            sortable: true
        },
        {
            name: 'acciones',
            title: 'Acciones',
            showing: true,
            type: 'custom',
            value: '/clients/3',
            link: '/clients/3',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        },
        {
            name: 'status',
            title: 'Status',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: ``,
            props: {
                text: 'Alta',
                background: 'transparent',
                color: '#14CA1B',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 4,
            sortable: true
        },
        {
            name: 'nombre',
            title: 'Nombre',
            showing: true,
            type: 'text',
            value: 'Client XXXX',
            sortable: true
        },
        {
            name: 'nif',
            title: 'NIF',
            showing: true,
            type: 'text',
            value: 'H323232323',
            sortable: true
        },
        {
            name: 'dirección',
            title: 'Dirección',
            showing: true,
            type: 'text',
            value: 'C/Calle 50 Polígono 20',
            sortable: true
        },
        {
            name: 'teléfono',
            title: 'Teléfono',
            showing: true,
            type: 'text',
            value: '654789556',
            sortable: true
        },
        {
            name: 'acciones',
            title: 'Acciones',
            showing: true,
            type: 'custom',
            value: '/clients/4',
            link: '/clients/4',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        },
        {
            name: 'status',
            title: 'Status',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: ``,
            props: {
                text: 'Pending',
                background: 'transparent',
                color: '#FA7F0F',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 5,
            sortable: true
        },
        {
            name: 'nombre',
            title: 'Nombre',
            showing: true,
            type: 'text',
            value: 'Client XXXX',
            sortable: true
        },
        {
            name: 'nif',
            title: 'NIF',
            showing: true,
            type: 'text',
            value: 'H323232323',
            sortable: true
        },
        {
            name: 'dirección',
            title: 'Dirección',
            showing: true,
            type: 'text',
            value: 'C/Calle 50 Polígono 20',
            sortable: true
        },
        {
            name: 'teléfono',
            title: 'Teléfono',
            showing: true,
            type: 'text',
            value: '654789556',
            sortable: true
        },
        {
            name: 'acciones',
            title: 'Acciones',
            showing: true,
            type: 'custom',
            value: '/clients/5',
            link: '/clients/5',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        },
        {
            name: 'status',
            title: 'Status',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: ``,
            props: {
                text: 'Baja',
                background: 'transparent',
                color: '#DE121E',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 6,
            sortable: true
        },
        {
            name: 'nombre',
            title: 'Nombre',
            showing: true,
            type: 'text',
            value: 'Client XXXX',
            sortable: true
        },
        {
            name: 'nif',
            title: 'NIF',
            showing: true,
            type: 'text',
            value: 'H323232323',
            sortable: true
        },
        {
            name: 'dirección',
            title: 'Dirección',
            showing: true,
            type: 'text',
            value: 'C/Calle 50 Polígono 20',
            sortable: true
        },
        {
            name: 'teléfono',
            title: 'Teléfono',
            showing: true,
            type: 'text',
            value: '654789556',
            sortable: true
        },
        {
            name: 'acciones',
            title: 'Acciones',
            showing: true,
            type: 'custom',
            value: '/clients/6',
            link: '/clients/6',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        },
        {
            name: 'status',
            title: 'Status',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: ``,
            props: {
                text: 'Pending',
                background: 'transparent',
                color: '#FA7F0F',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ],
    [
        {
            name: 'id',
            title: 'ID',
            showing: true,
            type: 'text',
            value: 7,
            sortable: true
        },
        {
            name: 'nombre',
            title: 'Nombre',
            showing: true,
            type: 'text',
            value: 'Client XXXX',
            sortable: true
        },
        {
            name: 'nif',
            title: 'NIF',
            showing: true,
            type: 'text',
            value: 'H323232323',
            sortable: true
        },
        {
            name: 'dirección',
            title: 'Dirección',
            showing: true,
            type: 'text',
            value: 'C/Calle 50 Polígono 20',
            sortable: true
        },
        {
            name: 'teléfono',
            title: 'Teléfono',
            showing: true,
            type: 'text',
            value: '654789556',
            sortable: true
        },
        {
            name: 'acciones',
            title: 'Acciones',
            showing: true,
            type: 'custom',
            value: '/clients/7',
            link: '/clients/7',
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        },
        {
            name: 'status',
            title: 'Status',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: ``,
            props: {
                text: 'Pending',
                background: 'transparent',
                color: '#FA7F0F',
                paddingX: 0,
                paddingY: 0
            },
            sortable: true
        }
    ]
];

export const list: ListItemDTO[] = [
    {
        id: 0,
        name: 'Pepe',
        age: 45,
        position: 'Becario',
        company: 'Google'
    },
    {
        id: 1,
        name: 'Pepe',
        age: 45,
        position: 'Becario',
        company: 'Google'
    },
    {
        id: 2,
        name: 'Pepe',
        age: 45,
        position: 'Becario',
        company: 'Google'
    },
    {
        id: 3,
        name: 'Pepe',
        age: 45,
        position: 'Becario',
        company: 'Google'
    },
    {
        id: 4,
        name: 'Pepe',
        age: 45,
        position: 'Becario',
        company: 'Google'
    },
    {
        id: 5,
        name: 'Pepe',
        age: 45,
        position: 'Becario',
        company: 'Google'
    },
    {
        id: 6,
        name: 'Pepe',
        age: 45,
        position: 'Becario',
        company: 'Google'
    }
];

export const listFibra: ListItemFibraDTO[] = [
    {
        id: 1,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 2,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 3,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 4,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 2
    },
    {
        id: 5,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 3
    },
    {
        id: 6,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 2
    },
    {
        id: 7,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 2
    },
    {
        id: 8,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 9,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 3
    },
    {
        id: 10,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 11,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 2
    },
    {
        id: 12,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 3
    },
    {
        id: 13,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 14,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 2
    },
    {
        id: 15,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 2
    },
    {
        id: 16,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 17,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 18,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 1
    },
    {
        id: 19,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 3
    },
    {
        id: 20,
        name: 'Client XXXX',
        nif: 'H323232323',
        email: 'correo@gmail.com',
        address: 'C/Calle 50 Polígono 20',
        phone: '654789556',
        state: 3
    }
];

export const commentsList: CommentDTO[] = [
    {
        id: 1,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Cambio datos cliente',
        comment: 'Cambio de dirección de facturación'
    },
    {
        id: 2,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Otro motivo',
        comment: 'Comprobación productos contratados'
    },
    {
        id: 3,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Otro motivo',
        comment: 'Comprobación productos contratados'
    },
    {
        id: 4,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Me he equivocado',
        comment: '-'
    },
    {
        id: 5,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Cambio datos cliente',
        comment: 'Cambio de dirección de facturación'
    },
    {
        id: 6,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Alta de producto',
        comment: 'Alta de fibra'
    },
    {
        id: 7,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Cambio datos cliente',
        comment: 'Cambio de dirección de facturación'
    },
    {
        id: 8,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Cambio datos cliente',
        comment: 'Cambio de dirección de facturación'
    },
    {
        id: 9,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Otro motivo',
        comment: 'Comprobación productos contratados'
    },
    {
        id: 10,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Otro motivo',
        comment: 'Comprobación productos contratados'
    },
    {
        id: 11,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Me he equivocado',
        comment: '-'
    },
    {
        id: 12,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Cambio datos cliente',
        comment: 'Cambio de dirección de facturación'
    },
    {
        id: 13,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Alta de producto',
        comment: 'Alta de fibra'
    },
    {
        id: 14,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Cambio datos cliente',
        comment: 'Cambio de dirección de facturación'
    },
    {
        id: 15,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Me he equivocado',
        comment: '-'
    },
    {
        id: 16,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Cambio datos cliente',
        comment: 'Cambio de dirección de facturación'
    },
    {
        id: 17,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Alta de producto',
        comment: 'Alta de fibra'
    },
    {
        id: 18,
        date: Date.now(),
        user: 'John Doe',
        reason: 'Cambio datos cliente',
        comment: 'Cambio de dirección de facturación'
    }
];

export const getCommentsList = <T>(perPage: number, currentPage: number, sortValue?: string, sortOrder?: number, filter?: string) => {
    let data: CommentDTO[] = commentsList;

    if (filter) {
        const searchString = filter.toLowerCase();
        data = data.filter(item => {
            // You can add more properties to the search filter if needed
            return item.comment.toLowerCase().includes(searchString) || item.reason.toLowerCase().includes(searchString);
        });
    } else if (sortValue && sortOrder) {
        sortMockedList(data, sortValue, sortOrder, 'comment');
        data = data.slice(handlePagination(currentPage, perPage, 18).index, handlePagination(currentPage, perPage, 18).offSet);
    } else {
        data = commentsList.slice(handlePagination(currentPage, perPage, 18).index, handlePagination(currentPage, perPage, 18).offSet);
    }
    return new Promise(res => {
        setTimeout(() => {
            res(data);
        }, 1000);
    }) as T;
};

export const getList = <T>() => {
    return new Promise(res => {
        setTimeout(() => {
            res(list);
        }, 1000);
    }) as T;
};

export const getListFibra = <T>(perPage: number, currentPage: number, sortValue?: string, sortOrder?: number, search?: string) => {
    let data: ListItemFibraDTO[] = listFibra;

    if (search) {
        const searchString = search.toLowerCase();
        data = data.filter(item => {
            // You can add more properties to the search filter if needed
            return item.name.toLowerCase().includes(searchString) || item.email.toLowerCase().includes(searchString);
        });
    } else if (sortValue && sortOrder) {
        sortMockedList(listFibra, sortValue, sortOrder, 'customer');
        data = data.slice(handlePagination(currentPage, perPage, 18).index, handlePagination(currentPage, perPage, 18).offSet);
    } else {
        data = listFibra.slice(handlePagination(currentPage, perPage, 18).index, handlePagination(currentPage, perPage, 18).offSet);
    }
    return new Promise(res => {
        setTimeout(() => {
            res(data);
        }, 1000);
    }) as T;
};

export const sortMockedList = (list: ListItemFibraDTO[] | CommentDTO[], sortValue: string, sortOrder: number, type: string) => {
    if (sortValue && sortOrder) {
        sortValue = sortValue?.toLowerCase();

        if (sortValue === 'username' && type === 'comment') sortValue = 'user';
        if (sortValue === 'username' && type === 'customer') sortValue = 'name';
        if (sortOrder === 1) {
            list.sort((a: any, b: any) => {
                return sortItems(a, b, sortValue, true);
            });
        } else if (sortOrder === 2) {
            list.sort((a: any, b: any) => {
                return sortItems(a, b, sortValue, false);
            });
        }
    }
};
export const sortItems = (a: any, b: any, param: string, ascent: boolean) => {
    const valA = a[param as keyof typeof a];
    const valB = b[param as keyof typeof b];
    if (typeof valA === 'number' && typeof valB === 'number') {
        if (ascent) {
            return valA - valB;
        } else {
            return valB - valA;
        }
    } else if (typeof valA === 'string' && typeof valB === 'string') {
        if (ascent) {
            return valA.localeCompare(valB);
        } else {
            return valB.localeCompare(valA);
        }
    } else {
        // Handle other types here if needed
        return 0;
    }
};
export const handlePagination = (currentPage: number, itemsPerPage: number, dataLength: number) => {
    let offSet;
    let index;
    if (currentPage === 1) {
        index = 0;
        offSet = itemsPerPage;
    } else if (currentPage > dataLength) {
        index = currentPage - 1;
        offSet = dataLength;
    } else {
        index = currentPage * itemsPerPage - itemsPerPage;
        offSet = index + itemsPerPage;
    }
    return { index, offSet };
};
