import classNames from 'classnames/bind';
import styles from './lura-care-logo.module.css';

const cx = classNames.bind(styles);

interface LuraCareLogoProps {
  width?: string;
  height?: string;
  className?: {
    svg?: string;
    mainText?: string;
    footerText?: string;
  };
  hideFooterText?: boolean;
  onlyLogo?: boolean;
  styles?: React.CSSProperties;
}

export const LuraCareLogo = ({
  width,
  height = '4.5rem',
  className,
  hideFooterText,
  onlyLogo,
  styles,
  ...rest
}: LuraCareLogoProps) => {
  const viewbox = onlyLogo === true ? '0 0 480 840' : '0 0 3172.91 832.61';
  // const viewbox = '0 0 3172.91 832.61';

  return (
    <svg
      id='Capa_1'
      data-name='Capa 1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox={viewbox}
      width={width}
      height={height}
      className={cx(className?.svg, { no_footer_text: hideFooterText })}
      style={styles}
      {...rest}
    >
      <defs>
        <linearGradient
          id='Nueva_muestra_de_degradado_15'
          data-name='Nueva muestra de degradado 15'
          x1='239.82'
          y1='650.96'
          x2='239.82'
          y2='0'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor='#eb6d81'></stop>
          <stop offset='1' stopColor='#29327d'></stop>
        </linearGradient>
      </defs>
      {onlyLogo !== true ? (
        <g className={cx(className?.mainText, 'main_text')}>
          <path
            className={cx('cls-2')}
            style={{ strokeWidth: '0px' }}
            d='m672.04,653.22c-19.48,0-36.75-5.22-51.79-15.64-15.06-10.43-26.8-24.66-35.2-42.7-8.41-18.03-12.62-38.78-12.62-62.25V176.72c0-9.56,3.09-17.38,9.29-23.47,6.2-6.09,14.16-9.13,23.91-9.13s17.71,3.04,23.91,9.13c6.2,6.09,9.3,13.91,9.3,23.47v355.91c0,16.09,3.09,29.33,9.3,39.77,6.19,10.43,14.16,15.64,23.9,15.64h16.6c8.85,0,16.04,3.04,21.59,9.12,5.52,6.09,8.29,13.92,8.29,23.47s-4.21,17.39-12.62,23.47c-8.41,6.09-19.25,9.13-32.54,9.13h-1.33Z'
          ></path>
          <path
            className={cx('cls-2')}
            style={{ strokeWidth: '0px' }}
            d='m897.83,656.47c-30.55,0-57.9-6.4-82.02-19.23-24.13-12.81-43.16-31.61-57.11-56.38-13.95-24.77-20.92-55.19-20.92-91.26v-160.36c0-9.56,3.2-17.49,9.63-23.79,6.41-6.3,14.49-9.45,24.24-9.45s17.82,3.15,24.24,9.45c6.42,6.3,9.63,14.24,9.63,23.79v160.36c0,24.34,4.65,44.44,13.95,60.29,9.3,15.87,21.92,27.6,37.85,35.2,15.94,7.6,33.87,11.41,53.79,11.41s35.96-3.69,50.8-11.08c14.82-7.38,26.67-17.37,35.53-29.99,8.85-12.6,13.28-26.72,13.28-42.36h41.84c0,26.94-6.76,51.28-20.25,73-13.51,21.74-31.88,38.9-55.12,51.5-23.25,12.61-49.71,18.9-79.35,18.9Zm146.75-3.26c-9.74,0-17.82-3.15-24.24-9.45-6.42-6.29-9.63-14.23-9.63-23.79v-290.72c0-9.99,3.21-18.03,9.63-24.12,6.42-6.08,14.49-9.12,24.24-9.12s18.37,3.04,24.58,9.12c6.19,6.09,9.29,14.13,9.29,24.12v290.72c0,9.56-3.1,17.5-9.29,23.79-6.21,6.31-14.39,9.45-24.58,9.45Z'
          ></path>
          <path
            className={cx('cls-2')}
            style={{ strokeWidth: '0px' }}
            d='m1178.06,653.22c-10.63,0-18.83-2.71-24.58-8.15-5.76-5.43-8.63-13.58-8.63-24.45v-291.37c0-10.43,2.87-18.46,8.63-24.12,5.75-5.64,13.95-8.47,24.58-8.47s19.36,2.72,24.9,8.15c5.53,5.44,8.3,13.59,8.3,24.45v291.37c0,10.43-2.77,18.48-8.3,24.12-5.54,5.65-13.83,8.47-24.9,8.47Zm-.67-222.93c0-26.08,6.53-49.43,19.59-70.08,13.05-20.64,30.65-37.04,52.8-49.21,22.13-12.16,46.49-18.26,73.04-18.26s46.37,4.25,59.43,12.72c13.06,8.47,18.04,18.57,14.95,30.31-1.33,6.09-3.88,10.75-7.63,14.01-3.77,3.26-8.08,5.33-12.96,6.19-4.87.88-10.19.66-15.94-.65-28.34-5.64-53.79-6.09-76.37-1.3-22.58,4.78-40.41,13.69-53.46,26.72-13.06,13.03-19.59,29.56-19.59,49.54h-33.87Z'
          ></path>
          <path
            className={cx('cls-2')}
            style={{ strokeWidth: '0px' }}
            d='m1559.24,655.83c-32.32,0-61.33-7.93-87-23.79-25.68-15.86-45.94-37.37-60.76-64.54-14.84-27.15-22.25-57.9-22.25-92.24s8.08-65.18,24.24-92.56c16.16-27.37,38.07-48.99,65.75-64.85,27.66-15.86,58.77-23.8,93.3-23.8s65.51,7.94,92.97,23.8c27.44,15.86,49.25,37.48,65.41,64.85,16.16,27.38,24.24,58.25,24.24,92.56h-25.9c0,34.34-7.42,65.09-22.25,92.24-14.83,27.17-35.09,48.67-60.76,64.54-25.68,15.86-54.68,23.79-86.99,23.79Zm13.28-58.67c22.58,0,42.72-5.31,60.43-15.97,17.7-10.65,31.64-25.2,41.83-43.68,10.19-18.46,15.28-39.21,15.28-62.25s-5.09-44.43-15.28-62.9c-10.19-18.46-24.13-33.02-41.83-43.68-17.72-10.64-37.85-15.97-60.43-15.97s-42.17,5.33-60.1,15.97c-17.92,10.66-32.1,25.21-42.5,43.68-10.4,18.47-15.61,39.43-15.61,62.9s5.2,43.79,15.61,62.25c10.4,18.48,24.58,33.03,42.5,43.68,17.93,10.66,37.96,15.97,60.1,15.97Zm148.75,56.71c-9.74,0-17.82-3.15-24.24-9.44-6.42-6.3-9.63-14.24-9.63-23.8v-99.73l12.62-69.1,55.12,23.47v145.36c0,9.56-3.21,17.5-9.62,23.8-6.43,6.3-14.51,9.44-24.25,9.44Z'
          ></path>
          <path
            className={cx('cls-1')}
            style={{ strokeWidth: '0px' }}
            d='m1980.91,655.83c-35.42,0-66.97-7.93-94.63-23.79-27.68-15.86-49.37-37.37-65.08-64.54-15.72-27.15-23.58-57.9-23.58-92.24s7.75-66.49,23.25-93.86c15.49-27.38,36.74-48.78,63.75-64.21,27-15.42,57.99-23.14,92.97-23.14,26.11,0,50.02,4.89,71.71,14.67,21.69,9.78,41.18,24.45,58.44,44,6.19,6.96,8.41,14.12,6.64,21.51-1.78,7.4-6.64,13.92-14.62,19.55-6.2,4.36-13.06,5.77-20.58,4.25-7.54-1.52-14.4-5.32-20.59-11.41-21.69-22.59-48.71-33.89-81.01-33.89-22.58,0-42.5,5.11-59.77,15.32-17.26,10.21-30.78,24.45-40.5,42.7-9.74,18.25-14.62,39.76-14.62,64.53,0,23.47,4.98,44.33,14.95,62.58,9.96,18.25,23.79,32.7,41.5,43.35,17.71,10.66,38.29,15.97,61.76,15.97,15.49,0,29.11-1.84,40.84-5.54,11.72-3.68,22.47-9.44,32.21-17.27,7.08-5.64,14.38-8.8,21.91-9.45,7.53-.65,14.16,1.42,19.92,6.2,7.53,6.09,11.73,12.93,12.62,20.53.89,7.61-1.77,14.46-7.96,20.53-31.88,29.12-71.72,43.68-119.54,43.68Z'
          ></path>
          <path
            className={cx('cls-1')}
            style={{ strokeWidth: '0px' }}
            d='m2326.9,655.83c-32.32,0-61.33-7.93-87-23.79-25.68-15.86-45.94-37.37-60.76-64.54-14.84-27.15-22.25-57.9-22.25-92.24s8.08-65.18,24.24-92.56c16.16-27.37,38.07-48.99,65.75-64.85,27.66-15.86,58.77-23.8,93.3-23.8s65.51,7.94,92.97,23.8c27.44,15.86,49.25,37.48,65.41,64.85,16.16,27.38,24.24,58.25,24.24,92.56h-25.9c0,34.34-7.42,65.09-22.25,92.24-14.83,27.17-35.09,48.67-60.76,64.54-25.68,15.86-54.68,23.79-86.99,23.79Zm13.28-58.67c22.58,0,42.72-5.31,60.43-15.97,17.7-10.65,31.64-25.2,41.83-43.68,10.19-18.46,15.28-39.21,15.28-62.25s-5.09-44.43-15.28-62.9c-10.19-18.46-24.13-33.02-41.83-43.68-17.72-10.64-37.85-15.97-60.43-15.97s-42.17,5.33-60.1,15.97c-17.92,10.66-32.1,25.21-42.5,43.68-10.4,18.47-15.61,39.43-15.61,62.9s5.2,43.79,15.61,62.25c10.4,18.48,24.58,33.03,42.5,43.68,17.93,10.66,37.96,15.97,60.1,15.97Zm148.75,56.71c-9.74,0-17.82-3.15-24.24-9.44-6.42-6.3-9.63-14.24-9.63-23.8v-99.73l12.62-69.1,55.12,23.47v145.36c0,9.56-3.21,17.5-9.62,23.8-6.43,6.3-14.51,9.44-24.25,9.44Z'
          ></path>
          <path
            className={cx('cls-1')}
            style={{ strokeWidth: '0px' }}
            d='m2617.1,653.22c-10.63,0-18.83-2.71-24.58-8.15-5.76-5.43-8.63-13.58-8.63-24.45v-291.37c0-10.43,2.87-18.46,8.63-24.12,5.75-5.64,13.95-8.47,24.58-8.47s19.36,2.72,24.9,8.15c5.53,5.44,8.3,13.59,8.3,24.45v291.37c0,10.43-2.77,18.48-8.3,24.12-5.54,5.65-13.83,8.47-24.9,8.47Zm-.67-222.93c0-26.08,6.53-49.43,19.59-70.08,13.05-20.64,30.65-37.04,52.8-49.21,22.13-12.16,46.49-18.26,73.04-18.26s46.37,4.25,59.43,12.72c13.06,8.47,18.04,18.57,14.95,30.31-1.33,6.09-3.88,10.75-7.63,14.01-3.77,3.26-8.08,5.33-12.96,6.19-4.87.88-10.19.66-15.94-.65-28.34-5.64-53.79-6.09-76.37-1.3-22.58,4.78-40.41,13.69-53.46,26.72-13.06,13.03-19.59,29.56-19.59,49.54h-33.87Z'
          ></path>
          <path
            className={cx('cls-1')}
            style={{ strokeWidth: '0px' }}
            d='m3017.52,655.83c-36.75,0-69.39-7.71-97.95-23.14-28.55-15.42-50.91-36.71-67.08-63.88-16.16-27.16-24.24-58.34-24.24-93.54s7.64-67.03,22.91-94.19c15.28-27.15,36.41-48.45,63.42-63.88,27-15.42,57.99-23.14,92.97-23.14s64.18,7.5,88.99,22.5c24.79,14.99,43.72,35.63,56.78,61.92,13.06,26.31,19.59,56.61,19.59,90.94,0,8.26-2.88,15.1-8.63,20.53-5.76,5.44-13.06,8.15-21.91,8.15h-267.63v-52.15h265.63l-27.22,18.25c-.44-21.72-4.88-41.16-13.28-58.34-8.41-17.16-20.37-30.74-35.87-40.74-15.5-9.99-34.31-14.99-56.45-14.99-25.24,0-46.82,5.44-64.75,16.3-17.92,10.87-31.54,25.74-40.84,44.65-9.29,18.91-13.95,40.31-13.95,64.2s5.53,45.2,16.6,63.89c11.06,18.68,26.34,33.47,45.83,44.33,19.47,10.87,41.83,16.29,67.07,16.29,13.72,0,27.78-2.49,42.17-7.49,14.38-5,26-10.76,34.87-17.28,6.63-4.77,13.83-7.27,21.58-7.49,7.74-.21,14.49,2.07,20.25,6.84,7.53,6.52,11.51,13.69,11.96,21.51.43,7.82-3.1,14.56-10.63,20.2-15.05,11.74-33.76,21.3-56.11,28.68-22.36,7.39-43.72,11.08-64.09,11.08Z'
          ></path>
        </g>
      ) : null}
      {hideFooterText !== true || onlyLogo !== true ? (
        <g className={cx(className?.footerText, 'footer_text')}>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m589.62,830.7c-1.49,0-2.77-.48-3.83-1.43s-1.59-2.18-1.59-3.69v-82.8c0-1.51.48-2.74,1.42-3.69.95-.95,2.16-1.43,3.65-1.43h28.49c4.86,0,9.24,1.03,13.13,3.09,3.88,2.07,6.96,4.87,9.24,8.4,2.28,3.53,3.41,7.65,3.41,12.33,0,4.21-1.12,7.94-3.35,11.2-2.24,3.26-5.17,5.8-8.77,7.62,5.18,1.36,9.38,3.96,12.59,7.81,3.22,3.85,4.83,8.8,4.83,14.83,0,5.48-1.23,10.28-3.71,14.41-2.47,4.13-5.89,7.38-10.24,9.77-4.36,2.38-9.36,3.57-15.01,3.57h-30.26Zm4.71-54.44h23.43c4.63,0,8.44-1.27,11.42-3.81,2.98-2.54,4.47-6.2,4.47-10.96s-1.49-8.22-4.47-10.6c-2.98-2.38-6.79-3.57-11.42-3.57h-23.43v28.95Zm0,44.79h25.55c3.69,0,6.96-.73,9.83-2.21,2.87-1.47,5.12-3.53,6.77-6.19,1.64-2.66,2.47-5.9,2.47-9.71,0-3.49-.82-6.51-2.47-9.05-1.65-2.55-3.91-4.49-6.77-5.84-2.87-1.36-6.14-2.03-9.83-2.03h-25.55v35.03Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m672.16,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43h21.77c5.73,0,10.86,1.24,15.37,3.69,4.51,2.46,8.04,5.86,10.59,10.19,2.54,4.33,3.82,9.35,3.82,15.07,0,5.4-1.28,10.2-3.82,14.41-2.55,4.21-6.09,7.51-10.59,9.89-4.52,2.39-9.64,3.57-15.37,3.57h-16.72v31.1c0,1.51-.47,2.74-1.41,3.69-.95.95-2.16,1.43-3.65,1.43Zm5.05-45.99h16.72c3.85,0,7.29-.77,10.31-2.32,3.01-1.55,5.39-3.69,7.12-6.44,1.72-2.74,2.59-5.86,2.59-9.35,0-3.81-.87-7.17-2.59-10.07-1.73-2.9-4.11-5.16-7.12-6.79-3.02-1.63-6.46-2.45-10.31-2.45h-16.72v37.4Zm44.04,45.99c-1.02,0-1.88-.22-2.6-.66-.7-.43-1.29-1.09-1.76-1.96l-22.25-35.86,10-2.63,20.6,33.24c1.26,1.99,1.48,3.79.65,5.42-.82,1.63-2.37,2.44-4.65,2.44Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m745.38,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43s2.7.48,3.65,1.43c.94.95,1.41,2.18,1.41,3.69v82.8c0,1.51-.47,2.74-1.41,3.69-.95.95-2.16,1.43-3.65,1.43Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m779.05,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43s2.86.64,3.88,1.91l54.74,73.03v-69.81c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.65-1.43s2.61.48,3.6,1.43c.98.95,1.47,2.18,1.47,3.69v82.8c0,1.51-.49,2.74-1.47,3.69-.98.95-2.18,1.43-3.6,1.43-.7,0-1.41-.14-2.12-.42-.7-.27-1.29-.73-1.76-1.37l-54.74-73.03v69.69c0,1.51-.49,2.74-1.47,3.69-.98.95-2.18,1.43-3.59,1.43Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m912.66,831.89c-6.36,0-12.31-1.24-17.84-3.69-5.53-2.46-10.4-5.88-14.6-10.25-4.19-4.37-7.46-9.44-9.77-15.19-2.32-5.76-3.47-11.98-3.47-18.64s1.15-12.77,3.47-18.53c2.31-5.75,5.55-10.8,9.71-15.13,4.16-4.32,9.01-7.74,14.55-10.24,5.53-2.51,11.52-3.76,17.95-3.76,5.72,0,10.86.82,15.42,2.45,4.55,1.63,8.98,4.27,13.3,7.92.7.56,1.15,1.18,1.35,1.85.2.67.2,1.33,0,1.96-.19.64-.49,1.19-.88,1.67-.16.88-.61,1.57-1.35,2.09-.75.51-1.65.78-2.71.78s-2.06-.4-3-1.19c-2.98-2.7-6.2-4.71-9.65-6.02-3.46-1.31-7.62-1.97-12.48-1.97s-9.6,1.01-13.96,3.04c-4.35,2.02-8.18,4.78-11.47,8.28-3.3,3.49-5.87,7.54-7.71,12.16-1.85,4.6-2.77,9.49-2.77,14.65s.92,10.28,2.77,14.89c1.84,4.6,4.41,8.65,7.71,12.15,3.29,3.49,7.12,6.24,11.47,8.22,4.36,1.99,9.01,2.98,13.96,2.98,4.16,0,8.12-.72,11.89-2.15s7.37-3.45,10.83-6.08c1.1-.87,2.21-1.23,3.36-1.07,1.13.15,2.12.65,2.94,1.48.82.84,1.24,1.97,1.24,3.4,0,.71-.1,1.35-.3,1.9-.2.56-.57,1.15-1.12,1.79-4.08,3.66-8.57,6.27-13.48,7.86-4.91,1.59-10.03,2.39-15.36,2.39Zm30.26-13.94l-9.77-2.03v-23.23h-17.78c-1.42,0-2.61-.42-3.6-1.25-.98-.84-1.47-1.93-1.47-3.27,0-1.27.49-2.34,1.47-3.22.98-.87,2.18-1.3,3.6-1.3h22.6c1.42,0,2.6.48,3.53,1.42.95.95,1.42,2.19,1.42,3.7v29.19Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m967.17,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43s2.7.48,3.65,1.43c.94.95,1.41,2.18,1.41,3.69v82.8c0,1.51-.47,2.74-1.41,3.69-.95.95-2.16,1.43-3.65,1.43Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1000.84,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43s2.86.64,3.88,1.91l54.74,73.03v-69.81c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.65-1.43s2.61.48,3.6,1.43c.98.95,1.47,2.18,1.47,3.69v82.8c0,1.51-.49,2.74-1.47,3.69-.98.95-2.18,1.43-3.6,1.43-.7,0-1.41-.14-2.12-.42-.7-.27-1.29-.73-1.76-1.37l-54.74-73.03v69.69c0,1.51-.49,2.74-1.47,3.69s-2.18,1.43-3.59,1.43Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1134.45,831.89c-6.36,0-12.31-1.24-17.84-3.69-5.53-2.46-10.4-5.88-14.6-10.25-4.19-4.37-7.46-9.44-9.77-15.19-2.32-5.76-3.47-11.98-3.47-18.64s1.15-12.77,3.47-18.53c2.31-5.75,5.55-10.8,9.71-15.13,4.16-4.32,9.01-7.74,14.55-10.24,5.53-2.51,11.52-3.76,17.95-3.76,5.72,0,10.86.82,15.42,2.45,4.55,1.63,8.98,4.27,13.3,7.92.7.56,1.15,1.18,1.35,1.85.2.67.2,1.33,0,1.96-.19.64-.49,1.19-.88,1.67-.16.88-.61,1.57-1.35,2.09-.75.51-1.65.78-2.71.78s-2.06-.4-3-1.19c-2.98-2.7-6.2-4.71-9.65-6.02-3.46-1.31-7.62-1.97-12.48-1.97s-9.6,1.01-13.96,3.04c-4.35,2.02-8.18,4.78-11.47,8.28-3.3,3.49-5.87,7.54-7.71,12.16-1.85,4.6-2.77,9.49-2.77,14.65s.92,10.28,2.77,14.89c1.84,4.6,4.41,8.65,7.71,12.15,3.29,3.49,7.12,6.24,11.47,8.22,4.36,1.99,9.01,2.98,13.96,2.98,4.16,0,8.12-.72,11.89-2.15,3.77-1.43,7.37-3.45,10.83-6.08,1.1-.87,2.21-1.23,3.36-1.07,1.13.15,2.12.65,2.94,1.48.82.84,1.24,1.97,1.24,3.4,0,.71-.1,1.35-.3,1.9-.2.56-.57,1.15-1.12,1.79-4.08,3.66-8.57,6.27-13.48,7.86-4.91,1.59-10.03,2.39-15.36,2.39Zm30.26-13.94l-9.77-2.03v-23.23h-17.78c-1.42,0-2.61-.42-3.6-1.25-.98-.84-1.47-1.93-1.47-3.27,0-1.27.49-2.34,1.47-3.22.98-.87,2.18-1.3,3.6-1.3h22.6c1.42,0,2.6.48,3.53,1.42.95.95,1.42,2.19,1.42,3.7v29.19Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1223.46,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43s2.7.48,3.65,1.43c.94.95,1.41,2.18,1.41,3.69v36.22h56.63v-36.22c0-1.51.47-2.74,1.42-3.69.94-.95,2.15-1.43,3.65-1.43,1.42,0,2.59.48,3.53,1.43.94.95,1.41,2.18,1.41,3.69v82.8c0,1.51-.47,2.74-1.41,3.69-.95.95-2.12,1.43-3.53,1.43-1.49,0-2.71-.48-3.65-1.43-.95-.95-1.42-2.18-1.42-3.69v-37.4h-56.63v37.4c0,1.51-.47,2.74-1.41,3.69-.95.95-2.16,1.43-3.65,1.43Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1323.76,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43h54.62c1.41,0,2.59.46,3.53,1.37.95.91,1.42,2.08,1.42,3.51,0,1.36-.47,2.48-1.42,3.39-.94.92-2.12,1.37-3.53,1.37h-49.57v31.69h35.91c1.49,0,2.71.48,3.65,1.42.95.95,1.42,2.11,1.42,3.46,0,1.42-.47,2.6-1.42,3.51-.94.91-2.15,1.37-3.65,1.37h-35.91v32.28h49.57c1.41,0,2.59.46,3.53,1.37.95.91,1.42,2.05,1.42,3.39,0,1.43-.47,2.6-1.42,3.51-.94.91-2.12,1.37-3.53,1.37h-54.62Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1395.8,830.7c-1.96,0-3.4-.81-4.3-2.44-.9-1.63-1-3.28-.3-4.94l34.15-82.21c1.02-2.3,2.67-3.45,4.94-3.45s3.85,1.15,4.71,3.45l34.26,82.32c.78,1.76.69,3.4-.3,4.94-.98,1.55-2.37,2.33-4.18,2.33-1.02,0-1.96-.28-2.82-.84-.87-.55-1.54-1.31-2.01-2.26l-31.31-77.67h3.53l-31.78,77.67c-.4,1.03-1.02,1.81-1.88,2.32-.87.52-1.77.78-2.71.78Zm7.29-21.69l3.89-8.69h47.21l4,8.69h-55.1Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1488.22,830.7c-1.42,0-2.61-.48-3.6-1.43-.98-.95-1.47-2.18-1.47-3.69v-82.8c0-1.43.49-2.64,1.47-3.63.98-1,2.18-1.49,3.6-1.49s2.61.49,3.59,1.49c.98.99,1.47,2.2,1.47,3.63v78.03h44.97c1.42,0,2.61.48,3.59,1.43.98.95,1.48,2.11,1.48,3.45,0,1.43-.49,2.62-1.48,3.57s-2.17,1.43-3.59,1.43h-50.03Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1537.43,747.07c-1.42,0-2.55-.43-3.42-1.31-.86-.87-1.29-1.98-1.29-3.33,0-1.43.43-2.58,1.29-3.45.87-.88,2.01-1.31,3.42-1.31h64.39c1.42,0,2.54.43,3.41,1.31.86.87,1.29,2.02,1.29,3.45,0,1.36-.43,2.46-1.29,3.33-.87.88-2,1.31-3.41,1.31h-64.39Zm32.13,83.63c-1.41,0-2.61-.49-3.59-1.49-.98-.99-1.47-2.2-1.47-3.63v-87.92h10.12v87.92c0,1.43-.47,2.64-1.41,3.63-.95,1-2.16,1.49-3.66,1.49Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1625.13,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43s2.7.48,3.65,1.43c.94.95,1.41,2.18,1.41,3.69v36.22h56.63v-36.22c0-1.51.47-2.74,1.42-3.69.94-.95,2.15-1.43,3.65-1.43,1.42,0,2.59.48,3.53,1.43.94.95,1.41,2.18,1.41,3.69v82.8c0,1.51-.47,2.74-1.41,3.69-.95.95-2.12,1.43-3.53,1.43-1.49,0-2.71-.48-3.65-1.43-.95-.95-1.42-2.18-1.42-3.69v-37.4h-56.63v37.4c0,1.51-.47,2.74-1.41,3.69-.95.95-2.16,1.43-3.65,1.43Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1749.56,747.07c-1.42,0-2.55-.43-3.42-1.31-.86-.87-1.29-1.98-1.29-3.33,0-1.43.43-2.58,1.29-3.45.87-.88,2.01-1.31,3.42-1.31h64.39c1.42,0,2.54.43,3.41,1.31.86.87,1.29,2.02,1.29,3.45,0,1.36-.43,2.46-1.29,3.33-.87.88-2,1.31-3.41,1.31h-64.39Zm32.13,83.63c-1.41,0-2.61-.49-3.59-1.49-.98-.99-1.47-2.2-1.47-3.63v-87.92h10.12v87.92c0,1.43-.47,2.64-1.41,3.63-.95,1-2.16,1.49-3.66,1.49Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1868.8,831.89c-6.83,0-13.1-1.19-18.84-3.58-5.72-2.38-10.69-5.69-14.88-9.94-4.2-4.25-7.44-9.3-9.72-15.13-2.28-5.84-3.41-12.22-3.41-19.12s1.14-13.26,3.41-19.07c2.28-5.8,5.51-10.82,9.72-15.06,4.19-4.25,9.16-7.57,14.88-9.95,5.73-2.39,12.01-3.58,18.84-3.58s13.09,1.19,18.78,3.58c5.69,2.38,10.65,5.69,14.89,9.95,4.24,4.25,7.49,9.27,9.77,15.06,2.28,5.8,3.41,12.16,3.41,19.07s-1.14,13.28-3.41,19.12c-2.28,5.83-5.53,10.88-9.77,15.13-4.25,4.25-9.21,7.56-14.89,9.94-5.7,2.39-11.95,3.58-18.78,3.58Zm0-9.53c5.34,0,10.26-.97,14.78-2.92,4.51-1.94,8.43-4.65,11.77-8.1,3.33-3.45,5.91-7.5,7.71-12.16,1.81-4.65,2.71-9.67,2.71-15.06s-.9-10.43-2.71-15.07c-1.81-4.65-4.38-8.7-7.71-12.16-3.33-3.45-7.26-6.14-11.77-8.04-4.52-1.91-9.44-2.86-14.78-2.86s-10.28.95-14.83,2.86c-4.55,1.9-8.48,4.59-11.77,8.04-3.3,3.45-5.87,7.51-7.71,12.16-1.85,4.65-2.77,9.67-2.77,15.07s.92,10.42,2.77,15.06c1.84,4.65,4.41,8.7,7.71,12.16,3.29,3.45,7.22,6.15,11.77,8.1,4.55,1.95,9.5,2.92,14.83,2.92Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m1995.95,830.7c-1.34,0-2.51-.49-3.53-1.49-1.02-.99-1.53-2.2-1.53-3.63v-29.19l-31.78-50.75c-.87-1.35-1.2-2.62-1-3.81.19-1.18.74-2.18,1.64-2.97.9-.79,1.98-1.19,3.24-1.19,1.1,0,1.96.24,2.59.72.63.48,1.22,1.12,1.77,1.91l31.19,50.62h-4.83l31.31-50.62c.55-.88,1.18-1.53,1.88-1.97.71-.43,1.61-.66,2.71-.66,1.18,0,2.15.42,2.94,1.25.78.84,1.25,1.87,1.42,3.09.16,1.24-.16,2.45-.95,3.63l-32.02,50.75v29.19c0,1.43-.49,2.64-1.48,3.63-.98,1-2.17,1.49-3.59,1.49Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m2082.94,831.89c-6.83,0-13.1-1.19-18.84-3.58-5.72-2.38-10.69-5.69-14.88-9.94-4.2-4.25-7.44-9.3-9.72-15.13-2.28-5.84-3.41-12.22-3.41-19.12s1.14-13.26,3.41-19.07c2.28-5.8,5.51-10.82,9.72-15.06,4.19-4.25,9.16-7.57,14.88-9.95,5.73-2.39,12.01-3.58,18.84-3.58s13.09,1.19,18.78,3.58c5.69,2.38,10.65,5.69,14.89,9.95,4.24,4.25,7.49,9.27,9.77,15.06,2.28,5.8,3.41,12.16,3.41,19.07s-1.14,13.28-3.41,19.12c-2.28,5.83-5.53,10.88-9.77,15.13-4.25,4.25-9.21,7.56-14.89,9.94-5.7,2.39-11.95,3.58-18.78,3.58Zm0-9.53c5.34,0,10.26-.97,14.78-2.92,4.51-1.94,8.43-4.65,11.77-8.1,3.33-3.45,5.91-7.5,7.71-12.16,1.81-4.65,2.71-9.67,2.71-15.06s-.9-10.43-2.71-15.07c-1.81-4.65-4.38-8.7-7.71-12.16-3.33-3.45-7.26-6.14-11.77-8.04-4.52-1.91-9.44-2.86-14.78-2.86s-10.28.95-14.83,2.86c-4.55,1.9-8.48,4.59-11.77,8.04-3.3,3.45-5.87,7.51-7.71,12.16-1.85,4.65-2.77,9.67-2.77,15.07s.92,10.42,2.77,15.06c1.84,4.65,4.41,8.7,7.71,12.16,3.29,3.45,7.22,6.15,11.77,8.1,4.55,1.95,9.5,2.92,14.83,2.92Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m2186.07,832.61c-7.22,0-13.65-1.4-19.3-4.18-5.65-2.78-10.05-6.65-13.18-11.61-3.14-4.96-4.71-10.62-4.71-16.98v-57.06c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43s2.7.48,3.65,1.43c.94.95,1.41,2.18,1.41,3.69v57.06c0,4.53,1.14,8.54,3.42,12.04,2.28,3.49,5.47,6.23,9.59,8.22,4.12,1.99,8.81,2.98,14.07,2.98s9.75-1,13.72-2.98c3.96-1.99,7.08-4.71,9.35-8.16,2.28-3.45,3.42-7.48,3.42-12.1v-57.06c0-1.51.49-2.74,1.47-3.69s2.18-1.43,3.59-1.43c1.57,0,2.8.48,3.72,1.43.89.95,1.35,2.18,1.35,3.69v57.06c0,6.36-1.56,12.02-4.71,16.98-3.13,4.96-7.46,8.83-12.95,11.61-5.5,2.78-11.81,4.18-18.96,4.18Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m2251.29,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43h21.77c5.73,0,10.86,1.24,15.37,3.69,4.51,2.46,8.04,5.86,10.59,10.19,2.54,4.33,3.82,9.35,3.82,15.07,0,5.4-1.28,10.2-3.82,14.41-2.55,4.21-6.09,7.51-10.59,9.89-4.52,2.39-9.64,3.57-15.37,3.57h-16.72v31.1c0,1.51-.47,2.74-1.41,3.69-.95.95-2.16,1.43-3.65,1.43Zm5.05-45.99h16.72c3.85,0,7.29-.77,10.31-2.32,3.01-1.55,5.39-3.69,7.12-6.44,1.72-2.74,2.59-5.86,2.59-9.35,0-3.81-.87-7.17-2.59-10.07-1.73-2.9-4.11-5.16-7.12-6.79-3.02-1.63-6.46-2.45-10.31-2.45h-16.72v37.4Zm44.04,45.99c-1.02,0-1.88-.22-2.6-.66-.7-.43-1.29-1.09-1.76-1.96l-22.25-35.86,10-2.63,20.6,33.24c1.26,1.99,1.48,3.79.65,5.42-.82,1.63-2.37,2.44-4.65,2.44Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m2359.01,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43s2.7.48,3.65,1.43c.94.95,1.41,2.18,1.41,3.69v36.22h56.63v-36.22c0-1.51.47-2.74,1.42-3.69.94-.95,2.15-1.43,3.65-1.43,1.42,0,2.59.48,3.53,1.43.94.95,1.41,2.18,1.41,3.69v82.8c0,1.51-.47,2.74-1.41,3.69-.95.95-2.12,1.43-3.53,1.43-1.49,0-2.71-.48-3.65-1.43-.95-.95-1.42-2.18-1.42-3.69v-37.4h-56.63v37.4c0,1.51-.47,2.74-1.41,3.69-.95.95-2.16,1.43-3.65,1.43Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m2496.74,831.89c-6.83,0-13.1-1.19-18.84-3.58-5.72-2.38-10.69-5.69-14.88-9.94-4.2-4.25-7.44-9.3-9.72-15.13-2.28-5.84-3.41-12.22-3.41-19.12s1.14-13.26,3.41-19.07c2.28-5.8,5.51-10.82,9.72-15.06,4.19-4.25,9.16-7.57,14.88-9.95,5.73-2.39,12.01-3.58,18.84-3.58s13.09,1.19,18.78,3.58c5.69,2.38,10.65,5.69,14.89,9.95,4.24,4.25,7.49,9.27,9.77,15.06,2.28,5.8,3.41,12.16,3.41,19.07s-1.14,13.28-3.41,19.12c-2.28,5.83-5.53,10.88-9.77,15.13-4.25,4.25-9.21,7.56-14.89,9.94-5.7,2.39-11.95,3.58-18.78,3.58Zm0-9.53c5.34,0,10.26-.97,14.78-2.92,4.51-1.94,8.43-4.65,11.77-8.1,3.33-3.45,5.91-7.5,7.71-12.16,1.81-4.65,2.71-9.67,2.71-15.06s-.9-10.43-2.71-15.07c-1.81-4.65-4.38-8.7-7.71-12.16-3.33-3.45-7.26-6.14-11.77-8.04-4.52-1.91-9.44-2.86-14.78-2.86s-10.28.95-14.83,2.86c-4.55,1.9-8.48,4.59-11.77,8.04-3.3,3.45-5.87,7.51-7.71,12.16-1.85,4.65-2.77,9.67-2.77,15.07s.92,10.42,2.77,15.06c1.84,4.65,4.41,8.7,7.71,12.16,3.29,3.45,7.22,6.15,11.77,8.1,4.55,1.95,9.5,2.92,14.83,2.92Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m2567.73,830.7c-1.42,0-2.61-.49-3.6-1.49-.98-.99-1.47-2.2-1.47-3.63v-82.8c0-1.51.49-2.74,1.47-3.69.98-.95,2.18-1.43,3.6-1.43,2.03,0,3.53.88,4.47,2.63l38.84,78.26h-3.88l38.03-78.26c1.02-1.75,2.51-2.63,4.47-2.63,1.41,0,2.59.48,3.53,1.43.95.95,1.42,2.18,1.42,3.69v82.8c0,1.43-.47,2.64-1.42,3.63-.94,1-2.12,1.49-3.53,1.49-1.49,0-2.73-.49-3.71-1.49-.98-.99-1.48-2.2-1.48-3.63v-69.22l2.12-.12-33.55,69.34c-.95,1.67-2.4,2.51-4.36,2.51-2.12,0-3.69-1.03-4.71-3.1l-33.56-68.02,2.35-.6v69.22c0,1.43-.49,2.64-1.47,3.63-.98,1-2.18,1.49-3.59,1.49Z'
          ></path>
          <path
            className={cx('cls-3')}
            style={{ strokeWidth: '0px' }}
            d='m2683.21,830.7c-1.49,0-2.71-.48-3.66-1.43-.94-.95-1.41-2.18-1.41-3.69v-82.8c0-1.51.47-2.74,1.41-3.69.95-.95,2.16-1.43,3.66-1.43h54.62c1.41,0,2.59.46,3.53,1.37.95.91,1.42,2.08,1.42,3.51,0,1.36-.47,2.48-1.42,3.39-.94.92-2.12,1.37-3.53,1.37h-49.57v31.69h35.91c1.49,0,2.71.48,3.65,1.42.95.95,1.42,2.11,1.42,3.46,0,1.42-.47,2.6-1.42,3.51-.94.91-2.15,1.37-3.65,1.37h-35.91v32.28h49.57c1.41,0,2.59.46,3.53,1.37.95.91,1.42,2.05,1.42,3.39,0,1.43-.47,2.6-1.42,3.51-.94.91-2.12,1.37-3.53,1.37h-54.62Z'
          ></path>
        </g>
      ) : null}
      <path
        className={cx('cls-1')}
        style={{ strokeWidth: '0px' }}
        d='m38.47,736.39c4.78-10.97,13.21-18.93,23.08-25.4,14.2-9.31,25.97-13.79,39.25-18.26,8.2-2.76,20.97-6.21,25.08-7.26,13.58-3.49,21.2-4.61,26.55,3.97,1.9,3.04,2.84,4.74,4.95,9.06,3.92,8.03.36,13.94-16.78,17.88-7.32,1.68-34.32,9.13-54.09,19.86-4.66,2.53-9.12,5.43-12.72,9.35-4.09,4.46-4.06,7.95.15,12.35,6.22,6.52,14.15,10.53,22.29,14.15,20.62,9.16,42.43,14.28,64.64,17.88,39.23,6.36,78.74,7.23,118.31,4.56,32.47-2.19,64.47-7.23,95.12-18.6,9.72-3.61,19.14-7.83,27.41-14.14,9.75-7.44,9.65-13.23-.28-20.28-16.14-11.47-34.75-17.13-53.68-22.11-9.97-2.63-2.54-.53-12.74-3.42-17.13-4.86-15.23-10.77-12.11-18.12,1.43-3.39,2.01-4.1,4.18-7.34,6.14-9.19,15.93-7.48,27.6-4.49,22.94,5.87,26.4,6.85,48.76,16.34,13.54,5.74,25.53,14.31,33.78,26.76,11.19,16.87,9.61,35.45-4.01,50.5-11.42,12.62-26.11,20.48-41.7,26.76-28.26,11.39-57.95,17.1-88.15,20.64-34.37,4.02-68.82,4.76-103.32,2.23-38.2-2.81-75.83-8.61-111.62-22.82-14.13-5.61-27.37-12.78-38.45-23.3-6.63-6.3-11.43-13.72-13.74-22.56-2.54-9.71-.89-16.99,2.23-24.16Z'
      ></path>
      <path
        className={cx('cls-4')}
        style={{ strokeWidth: '0px' }}
        d='m464.52,153.25c-30.72-78.59-92.08-124.77-158.67-144.27-23.94-7.01-57.91-11.19-91.67-7.74-43.76,4.47-84.81,20.26-119.67,46.84C33.72,94.43-.25,160.38,0,236.29c.15,44.36,11.41,83.3,36.92,126.41,50.93,86.08,101.41,172.41,152.18,258.58,2.36,4.01,4.89,8.04,7.99,11.5,25.07,27.95,71.9,22.95,91.57-9.86,15.51-25.86,30.68-51.92,45.95-77.92,38.17-65,77.26-129.47,114.58-194.92,35.9-62.97,38.79-136.79,15.32-196.83Zm-164.46,207.06c-15.33,13.61-31.17,27.68-47.57,43.08-3.52,3.31-8.06,4.96-12.61,4.96s-8.84-1.57-12.33-4.72c-14.07-12.69-27.56-24.48-40.24-35.58-65.14-57-112.22-98.19-112.22-151.12,0-58.68,40.2-104.65,91.51-104.65,34.51,0,58.9,16.05,73.28,29.51,14.38-13.46,38.76-29.51,73.28-29.51,51.31,0,91.51,45.97,91.51,104.66,0,50.45-46.17,91.45-104.62,143.37Z'
      ></path>
    </svg>
  );
};
