import styles from './documents-card.module.css';
import classNames from 'classnames/bind';
import Card from '../../../components/Cards/Card';
import RegularSpinner from '../../../components/Spinner/RegularSpinner';
import { useTranslation } from 'react-i18next';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { DocumentCard } from '../../../ts';
import { DocumentsCardIcon } from './documents-card-icon';

const cx = classNames.bind(styles);

export const DocumentsCard = ({ isLoading, title, icons, notification }: DocumentCard) => {
  const { t } = useTranslation();

  return (
    <Card
      id='4'
      width={500}
      numberItems={2}
      childrenExtraClass={cx('card-content')}
      className={cx('card')}
    >
      <div className={cx('title-container')}>
        <h5 className={cx('title-container--text')}>
          {GlobalService.uppercaseFirstLetter(t(title))}
        </h5>
      </div>

      <div className={cx('icons-container')}>
        {isLoading ? (
          <RegularSpinner />
        ) : (
          icons.map(icon => (
            <DocumentsCardIcon
              key={icon.title}
              image={icon.image}
              onClick={icon.onClick}
              title={GlobalService.uppercaseFirstLetter(t(icon.title))}
            />
          ))
        )}
      </div>

      {notification ? <div className={cx('notification-icon')}></div> : null}
    </Card>
  );
};
