import { QUERY_KEYS } from '../../../../../consts';
import { downloadQuotePdfService } from '../../../../../services';
import { createAndClickTemporaryLink, getAtomWithMutation } from '../../../../../utils';

export const downloadQuotePdfAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.QUOTES.DOWNLOAD_PDF,
  mutationFn: () => downloadQuotePdfService,
  onSuccess:
    () =>
    ({ url, fileName }) => {
      createAndClickTemporaryLink(url, fileName);
    },
});
