import { atomEffect } from 'jotai-effect';
import {
  getAllPatientsAtom,
  getPatientsDealsAtom,
  getPatientsQuotesAtom,
  getPatientsVisitsAtom,
} from '../..';
import { isGettingAllPatientsAtom } from '../get-patients-extra-info-atom-effect';
import { accumulatedPatientsAtom } from '../../patients-atoms';
import { assignQuotesToPatients } from '../../../../../../utils';

export const assignQuotesToPatientsAtomEffect = atomEffect((get, set) => {
  const getAllPatients = get.peek(getAllPatientsAtom);
  const getPatientsReports = get(getPatientsQuotesAtom);

  set(isGettingAllPatientsAtom, true);

  const setIsNotLoading = () => {
    const getAllPatients = get.peek(getAllPatientsAtom);
    const isDealsLoading = get.peek(getPatientsDealsAtom).isPending;
    const isVisitsLoading = get.peek(getPatientsVisitsAtom).isPending;
    if (!getAllPatients.isPending && !isDealsLoading && !isVisitsLoading) {
      set(isGettingAllPatientsAtom, false);
    }
  };

  if (getAllPatients.isPending || getPatientsReports.isPending) return;

  if (getAllPatients.isError || getAllPatients.data == null || getPatientsReports.isError) {
    setIsNotLoading();
    return;
  }

  const reports = getPatientsReports.data ?? {};
  const accumulatedPatients = get.peek(accumulatedPatientsAtom);
  const listToUse = accumulatedPatients.length === 0 ? getAllPatients.data : accumulatedPatients;
  const listWithReports = assignQuotesToPatients(listToUse, reports);
  set(accumulatedPatientsAtom, listWithReports);

  setIsNotLoading();
});
