import type { DefaultError } from '@tanstack/query-core';
import { atomWithMutation } from 'jotai-tanstack-query';
import { GetAtomWithMutation } from '../../ts/types';
import { GetAtomWithMutationOptions } from '../../ts';

export const getAtomWithMutation: GetAtomWithMutation = <
  TData = unknown,
  TVariables = unknown,
  TError = DefaultError,
  TContext = unknown,
>({
  gcTime = 1000 * 60 * 5, // default value for garbage collection time
  networkMode = 'online',
  retry = 3,
  retryDelay = attemps => 500 * (attemps * 1.5),
  mutationFn,
  onMutate,
  onSuccess,
  onError,
  onSettled,
  mutationKey,
  ...mutationOptions
}: GetAtomWithMutationOptions<TData, TVariables, TError, TContext>) => {
  return atomWithMutation<TData, TVariables, TError, TContext>(get => ({
    ...mutationOptions,
    mutationFn: mutationFn != null ? mutationFn(get) : undefined,
    onMutate: onMutate != null ? onMutate(get) : undefined,
    onSuccess: onSuccess != null ? onSuccess(get) : undefined,
    onError: onError != null ? onError(get) : undefined,
    onSettled: onSettled != null ? onSettled(get) : undefined,
    mutationKey: [mutationKey],
    gcTime,
    networkMode,
    retry,
    retryDelay,
  }));
};
