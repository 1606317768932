import axios from 'axios';

interface DownloadQuotePdfServiceParams {
  quoteId: string;
}

export const downloadQuotePdfService = async ({ quoteId }: DownloadQuotePdfServiceParams) => {
  let response;
  try {
    response = await axios.get(`/patients/download-quote-pdf?quoteId=${quoteId}`, {
      responseType: 'blob',
    });
  } catch (error) {
    throw new Error('downloadFailed');
  }

  const blob: Blob = response.data;
  const fileName = response.headers['x-file-name'];
  const url = window.URL.createObjectURL(blob);
  return { url, fileName };
};
