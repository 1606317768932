import React, { FC } from 'react';

// Components
import Modal from './Modal';
import PaymentForm from '../Form/PaymentForm';

// Stripe
import { loadStripe, PaymentIntent, PaymentIntentResult } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Interfaces
import { IAddressData } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IAddressItem';
import { IUserDataPayment } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IPago';

const key = process.env.REACT_APP_PUBLIC_STRIPE_KEY;

interface Props {
  onClose: () => void;
  handlePaymentResult: (paymentIntent: PaymentIntent | string, subscriptionId?: string) => void;
  userData: IUserDataPayment | undefined;
  totalAmount: number;
  addressData: IAddressData | undefined;
  isLoading: boolean;
  orderId?: number;
  billingCycle?: number;
  isSubscription?: boolean;
  service?: string;
}
const ModalPayment: FC<Props> = ({
  onClose,
  userData,
  totalAmount,
  addressData,
  handlePaymentResult,
  isLoading,
  orderId,
  billingCycle = 1,
  isSubscription = false,
  service = '',
}) => {
  let stripePromise = null;
  if (key) stripePromise = loadStripe(key);

  const modalOptions: any = {
    addTitle: true,
    alignItemsParent: 'flex-end',
    backgroundColor: 'white',
    backgroundColorParent: 'rgba(0, 0, 0, 0.5)',
    borderColor: 'none',
    borderRadius: '25px',
    justifyContent: 'start',
    justifyContentParent: 'flex-end',
    marginChildren: 'auto',
    onClose: onClose,
    overflow: 'hidden',
    padding: '2rem',
    showButtons: false,
    showCloseButton: true,
    titleMargin: 'auto',
    titleText: 'Pagos',
    width: '440px',
  };

  return (
    <Modal {...modalOptions}>
      {userData && addressData && key ? (
        <div>
          <Elements stripe={stripePromise}>
            <PaymentForm
              userData={userData}
              addressData={addressData}
              totalAmount={totalAmount}
              handlePaymentResult={handlePaymentResult}
              isLoading={isLoading}
              billingCycle={billingCycle}
              isSubscription={isSubscription}
              orderId={orderId}
              service={service}
            />
          </Elements>
        </div>
      ) : null}
    </Modal>
  );
};

export default ModalPayment;
