import React, { CSSProperties } from 'react';

interface Props extends Omit<React.HTMLProps<HTMLHeadingElement>, 'size'> {
  color: string;
  fontWeight?: string | number;
  margin?: string | number;
  size?: string | number;
  text: string;
}

const Title: React.FC<Props> = ({ color, fontWeight, margin = 0, size, text, ...rest }) => {
  const titleStyle: CSSProperties = {
    color: color,
    margin: margin,
    fontSize: size,
    fontWeight: fontWeight,
  };

  return (
    <h2 style={titleStyle} {...rest}>
      {text}
    </h2>
  );
};

export default Title;
