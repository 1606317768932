import React, { FC, useEffect, useState } from 'react';

// Components
import { ButtonBase } from '../Buttons/button-base';
import Modal from './Modal';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IModalYesNoProps } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IModals';

// CSS
import './Modal.css';

// Icons
import shortLeftArrow from '../../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../../hooks/use-viewport';

const ModalYesNo: FC<IModalYesNoProps> = ({
  bodyQuestion = '',
  bodyText,
  buttonNoText,
  buttonYesText,
  hideQuestionSign = false,
  highlightYesButton,
  onClickNo,
  onClickYes,
  showNoYesOrder = false,
  showTitleIcon = false,
  showXCloseButton = true,
  titleIcon,
  titleText,
}) => {
  const { t } = useTranslation();
  buttonNoText = buttonNoText || GlobalService.uppercaseFirstLetter(t('no'));
  buttonYesText = buttonYesText || GlobalService.uppercaseFirstLetter(t('yes'));

  /**********
   * States *
   **********/
  const { viewportHeight, viewportWidth } = useViewport();

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const isScreenTiny = viewportWidth < 321;

  titleIcon = titleIcon || isScreenBig ? '' : shortLeftArrow;

  const responsiveStyle = {
    alignItems: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: viewportHeight - 10,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 25,
  };

  const modalOptions: any = {
    addTitle: true,
    alignItemsParent: 'flex-end',
    backgroundColor: 'white',
    backgroundColorParent: 'rgba(0, 0, 0, 0.5)',
    borderColor: 'none',
    borderRadius: '25px',
    justifyContent: 'start',
    justifyContentParent: 'flex-end',
    marginChildren: 'auto',
    onClose: onClickNo,
    overflow: 'hidden',
    padding: isScreenBig ? '2rem' : '1rem',
    showButtons: false,
    showCloseButton: showXCloseButton,
    showTitleIcon,
    titleIcon,
    titleMargin: 'auto',
    titleText,
    width: '440px',
  };

  if (!isScreenBig) {
    modalOptions['forcedChildWidth'] = '90%';
    modalOptions['parentStyle'] = responsiveStyle;
  }

  const modalNoButton = (
    <ButtonBase
      text={GlobalService.uppercaseFirstLetter(t(buttonNoText))}
      background={
        highlightYesButton ? 'none' : 'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'
      }
      border='1px solid #28337d'
      borderRadius='15px'
      color={highlightYesButton ? '#28337d' : '#fff'}
      paddingX={5}
      paddingY={6}
      buttonWidth={isScreenBig ? '100%' : '60%'}
      onClick={onClickNo}
    />
  );

  const modalYesButton = (
    <ButtonBase
      text={GlobalService.uppercaseFirstLetter(t(buttonYesText))}
      background={
        highlightYesButton ? 'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)' : 'none'
      }
      border='1px solid #28337d'
      color={highlightYesButton ? '#fff' : '#28337d'}
      borderRadius={'50px'}
      paddingY={6}
      buttonWidth={isScreenBig ? '100%' : '60%'}
      onClick={onClickYes}
    />
  );

  /*******
   * JSX *
   *******/
  return (
    <Modal {...modalOptions}>
      <div className='flex flex-col justify-center items-center'>
        {bodyText ? (
          <p className='text-center'>{GlobalService.uppercaseFirstLetter(t(bodyText))}</p>
        ) : null}
        <p className='mt-2 text-center'>
          <strong>{`${GlobalService.uppercaseFirstLetter(t(bodyQuestion))}${
            hideQuestionSign ? '' : '?'
          }`}</strong>
        </p>
        <div
          className={`flex justify-between gap-2 w-full mt-6 ${
            isScreenTiny ? 'flex-col items-center tiny-screen-yes-no-buttons-width' : ''
          }`}
        >
          {showNoYesOrder ? (
            <>
              {modalNoButton} {modalYesButton}
            </>
          ) : (
            <>
              {modalYesButton} {modalNoButton}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalYesNo;
