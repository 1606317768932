import React from 'react';
import {
  FormConfig,
  FormDataResposeItem,
} from '../../hexagonal-architecture-frontend-base/src/domain/models/Form';
import { optionItem } from '../../hexagonal-architecture-frontend-base/src/domain/models/Input';
import { ButtonBase } from '../Buttons/button-base';
import { Toggle } from '../Buttons/Toggle';
import Input from '../Inputs/Input';
import InputDate from '../Inputs/InputDate';
import InputFile from '../Inputs/InputFile';
import InputPhone from '../Inputs/InputPhone';
import InputSelector from '../Inputs/InputSelector';
import InputTextArea from '../Inputs/InputTextArea';
import { Tag } from '../Tags/Tag';

interface Props {
  index: number;
  label: string;
  type:
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'phone'
    | 'date'
    | 'file'
    | 'select'
    | 'checkbox'
    | 'radiobox'
    | 'space'
    | 'textarea'
    | 'date';
  width: string;
  placeholder?: string;
  required?: string;
  disabled: boolean;
  options?: Array<optionItem>;
  isMulti?: boolean;
  singleChoice?: boolean;
  selectedOptionBG?: string;
  minDate?: string;
  maxDate?: string;
  startingDate?: string;
  countryPreFix?: boolean;
  isPrefixDisabled?: boolean;
  details?: 'button' | 'tag' | 'toggle';
  text?: string;
  form: FormConfig;
  formResponse: FormDataResposeItem[];
  changeFormData: (e: FormConfig, value: string | object | Array<optionItem> | undefined) => void;
}

export const FormItem: React.FC<Props> = ({
  index,
  label,
  type,
  width,
  placeholder,
  required,
  disabled,
  options,
  isMulti,
  singleChoice,
  minDate,
  maxDate,
  startingDate,
  isPrefixDisabled,
  countryPreFix,
  selectedOptionBG,
  details = '',
  text = '',
  form,
  formResponse,
  changeFormData,
}) => {
  let content;
  switch (type) {
    case 'text':
    case 'password':
    case 'email':
    case 'number':
      content = (
        <Input
          id={label}
          name={label}
          placeholder={placeholder}
          type={type}
          isDisabled={disabled}
          onChange={e => changeFormData(form, e)}
          value={formResponse[index].value!.toString()}
          borderRadius='6px'
        />
      );
      break;
    case 'phone':
      content = (
        <InputPhone
          placeholder={placeholder}
          borderStyleRadius='6px'
          onChange={e => changeFormData(form, e)}
          isDisabled={disabled}
          countryPreFix={countryPreFix}
          isPrefixDisabled={isPrefixDisabled}
        />
      );
      break;
    case 'date':
      content = (
        <InputDate
          onChange={e => changeFormData(form, e)}
          minDate={minDate}
          maxDate={maxDate}
          startingDate={startingDate}
          pickedDate={formResponse[index].value!.toString()}
          isDisabled={disabled}
          borderRadius='6px'
        />
      );
      break;
    case 'file':
      content = (
        <InputFile
          label={placeholder ? placeholder : ''}
          onChange={e => changeFormData(form, e)}
          emptyFile='There is no file yet'
          fileName={formResponse[index].value!.toString()}
          fileRequirements=''
        />
      );
      break;
    case 'select':
      content = (
        <InputSelector
          param='label'
          placeholder={placeholder ? placeholder : ''}
          options={options ? options : []}
          onChange={e => changeFormData(form, e)}
          selectedOption={formResponse[index].value as Array<optionItem>}
          selectedOptionBG={selectedOptionBG ? selectedOptionBG : 'none'}
          isMulti={Boolean(isMulti)}
          isDisabled={disabled}
          singleChoice={singleChoice}
          borderStyle='1px solid #E5E7EB'
          borderStyleRadius='6px'
        />
      );
      break;
    case 'checkbox':
      break;
    case 'radiobox':
      break;
    case 'space':
      break;
    case 'textarea':
      content = (
        <InputTextArea
          width={width}
          placeholder='Info adicional'
          onChange={e => changeFormData(form, e)}
        />
      );
      break;
    default:
      break;
  }

  let extra;
  switch (details) {
    case 'button':
      extra = <ButtonBase background='' text={text} paddingX={1} paddingY={1} />;
      break;
    case 'tag':
      extra = <Tag text={text} color='black' background='' paddingX={1} paddingY={1} />;
      break;
    case 'toggle':
      extra = <Toggle bgTrue='red' bgFalse='green' />;
      break;
    default:
      extra = null;
      break;
  }

  return (
    <div style={{ width: width }}>
      <div className='flex justify-between mb-5'>
        <h3>
          {label} {required}
        </h3>
        {extra}
      </div>
      {content}
    </div>
  );
};
