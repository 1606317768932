import React, { useState, useEffect, CSSProperties } from 'react';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import { Row } from 'src/hexagonal-architecture-frontend-base/src/domain/models/Row';
import { Fallback } from '../Fallback/Fallback';
import { Pagination } from '../Pagination';
import { useViewport } from '../../hooks/use-viewport';

interface SpecialColumn {
  column: number;
  element: string;
  module: string;
}

interface TableProps {
  setRowValue?: (id: string) => void;
  setModal?: (value: boolean) => void;
  modalState?: boolean;
  borderTable?: string;
  borderRow?: string;
  padding?: string;
  textAlignTable?: CSSProperties['textAlign'] | undefined;
  backgroundRow?: string;
  backgroundTable?: string;
  textPrimary?: string;
  textSecondary?: string;
  width: string;
  serviceCall: any;
  specialColumns: SpecialColumn[];
  loadingRows?: number;
  loadingCols?: number;
  currentPage?: number;
  filter?: string;
}

const TableWrapper = ({
  setRowValue,
  setModal,
  modalState,
  borderTable,
  borderRow,
  padding,
  textAlignTable,
  backgroundRow,
  backgroundTable,
  textPrimary,
  textSecondary,
  width,
  serviceCall,
  specialColumns,
  loadingRows = 10,
  loadingCols = 7,
  filter
}: TableProps): JSX.Element => {
  const [tableData, setTableData] = useState<Row[][]>([]);
  const { viewportWidth } = useViewport();
  const [loading, setLoading] = useState(true);
  const [noR, setnoR] = useState(false);
  const [orderSort, setOrderSort] = useState(1);
  const [paramSort, setParamSort] = useState('');
  const [totalPages] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);

  useEffect(() => {
    const fetchTableData = async () => {
      const data = await serviceCall(perPage, 1, undefined, 1, filter);
      setTableData(data);
      return data;
    };
    setLoading(true);
    fetchTableData()
      .then(data => {
        setLoading(false);
        data.length ? setnoR(false) : setnoR(true);
      })
      .catch(() => {
        setnoR(true);
        setLoading(false);
      });
  }, [filter]);

  const columnTitles = tableData.length
    ? tableData[0].filter(cell => cell.showing).map(cell => cell)
    : null;

  const tableStyles: CSSProperties = {
    padding: viewportWidth <= 1024 ? '0em' : '1em',
    overflowX: 'auto',
    border: borderTable ? borderTable : 'unset',
    whiteSpace: 'nowrap',
    display: viewportWidth <= 1024 ? 'block' : 'auto',
    backgroundColor: backgroundTable ? backgroundTable : 'transparent',
    width: viewportWidth <= 1024 ? '100%' : width
  };

  const handleSort = (column: Row, order: number) => {
    setParamSort(column.name);
    if (order === 1) {
      setOrderSort(2);
    } else {
      setOrderSort(1);
    }
    handlePagination(currentPage, column.title, order, filter);
  };

  const handlePagination = (e: number, sortValue?: string, order?: number, filter?: string) => {
    setCurrentPage(e);
    if (paramSort) sortValue = paramSort;
    if (orderSort && !order) order = orderSort === 1 ? 2 : 1;
    const fetchTableData = async () => {
      const data = await serviceCall(perPage, e, sortValue, order, filter);
      setTableData(data);
    };
    setLoading(true);
    fetchTableData()
      .then(() => {
        setLoading(false);
        setnoR(false);
      })
      .catch(() => {
        setLoading(false);
        setnoR(true);
      });
  };
  return (
    <>
      {noR ? (
        <div
          className=' m-auto flex flex-col justify-center items-center'
          style={{ height: '28.5rem' }}
        >
          <Fallback title='Ooops...' text='Seems like there is nothing here' color='SlateGray' />
        </div>
      ) : (
        <>
          <table style={tableStyles}>
            <TableHeader
              columns={
                !loading && tableData.length && columnTitles?.length ? columnTitles : loadingCols
              }
              borderRow={borderRow}
              padding={padding}
              textAlignTable={textAlignTable}
              backgroundRow={backgroundRow}
              textPrimary={textPrimary}
              handleSort={handleSort}
              sortOrder={orderSort}
              paramSort={paramSort}
            />
            <tbody>
              {!loading && tableData.length && columnTitles?.length
                ? tableData.map((row, index) => (
                    <TableRows
                      setRowValue={setRowValue}
                      setModal={setModal}
                      modalState={modalState}
                      borderRow={borderRow}
                      key={index}
                      columns={columnTitles}
                      row={row}
                      padding={padding}
                      textAlignTable={textAlignTable}
                      backgroundRow={backgroundRow}
                      textSecondary={textSecondary}
                      width={width}
                      specialColumns={specialColumns}
                    />
                  ))
                : loading
                ? Array.from({ length: loadingRows }, (_, index) => (
                    <TableRows
                      setRowValue={setRowValue}
                      setModal={setModal}
                      modalState={modalState}
                      borderRow={borderRow}
                      key={index}
                      columns={loadingCols}
                      row={loadingRows}
                      padding={padding}
                      textAlignTable={textAlignTable}
                      backgroundRow={backgroundRow}
                      textSecondary={textSecondary}
                      width={width}
                      specialColumns={specialColumns}
                    />
                  ))
                : null}
            </tbody>
          </table>
          {totalPages > 1 ? (
            <div className=' m-auto'>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onChange={handlePagination}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default TableWrapper;
