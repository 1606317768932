// Components
import { ButtonBase } from '../components/Buttons/button-base';
import TopMenu from './Menus/TopMenu';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Help/Help.css';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faStopwatch,
  faDisplay,
  faUser,
  faEnvelope,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// Images
import Callcenter from '../img/HelpImg/helpLuracare.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';
import { useSettings, useShowSettingsErrorPopup } from '../hooks';
import { AvailableSetting } from '../ts/types/settings/available-setting';

import { AVAILABLE_SETTINGS, PHONE_PREFIX } from '../consts';

const Help = () => {
  const { t } = useTranslation();
  const { getSettingKey } = useSettings();
  const { showSingleSettingErrorPopup } = useShowSettingsErrorPopup();
  const textBase = 'pages.help';
  const { viewportWidth } = useViewport();

  /************
   * Handlers *
   ************/
  const handleClick = (key: AvailableSetting) => {
    const link = getLink(key);
    if (typeof link === 'string') {
      if (link.includes('https')) {
        window.open(link, '_blank');
      } else {
        window.open(link, '_self');
      }
    }
  };

  const handleFooterClick = (item: number) => {
    const link1 = getLink(AVAILABLE_SETTINGS.AVISO_LEGAL) ?? null;
    const link2 = getLink(AVAILABLE_SETTINGS.PRIVACIDAD) ?? null;
    const links = [link1, link2];
    const linkValue = links[item];
    if (linkValue) {
      window.open(linkValue, '_blank');
    }
  };

  /***********
   * Helpers *
   ***********/
  const getLink = (key: AvailableSetting): string | undefined => {
    const link = getSettingKey(key);
    if (link !== 'settingNotFound') {
      if (key === AVAILABLE_SETTINGS.CALL) return `tel:${PHONE_PREFIX}${link.replaceAll(' ', '')}`;
      return link;
    } else {
      showSingleSettingErrorPopup();
    }
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t(`${textBase}.title`))}`;
  const isScreenBig = viewportWidth > 768;
  const isScreenBigHeight = viewportWidth > 600;
  // Icons definitions
  const iconsDefinitions: IconDefinition[] = [
    faPhone,
    faWhatsapp,
    faStopwatch,
    faDisplay,
    faEnvelope,
  ];

  // Help buttons text definitions
  const helpButtonsTextDefinition = [
    AVAILABLE_SETTINGS.CALL,
    AVAILABLE_SETTINGS.WHATSAPP,
    AVAILABLE_SETTINGS.SCHEDULE_CALL,
    AVAILABLE_SETTINGS.VIDEO_CALL,
    AVAILABLE_SETTINGS.SEND_EMAIL,
  ];

  // Footer buttons text definitions
  const footerButtonsTextDefinition = [`${textBase}.legalNotice`, `${textBase}.privacyPolicy`];

  // The icons used by the help buttons
  const icons = iconsDefinitions.map((icon, index) => {
    if (icon === faDisplay) {
      return (
        <span className='fa-layers fa-fw' key={index}>
          <FontAwesomeIcon color='#E96E81' icon={faDisplay} />
          <FontAwesomeIcon color='#E96E81' icon={faUser} size='xs' transform='shrink-4' />
        </span>
      );
    }
    return <FontAwesomeIcon key={index} color='#E96E81' icon={icon} />;
  });

  // The help buttons
  const helpButtons = icons.map((icon, index) => (
    <div key={index} className='mt-4'>
      <ButtonBase
        background='#FFFFFF'
        border='1px solid #E96E81'
        borderRadius='50px'
        buttonWidth='100%'
        color='black'
        extraClass='mt-3'
        iconLeft={icon}
        key={index}
        minHeight={'43px'}
        onClick={() => handleClick(helpButtonsTextDefinition[index] as AvailableSetting)}
        text={GlobalService.uppercaseAllFirstsLetters(
          t(`${textBase}.${helpButtonsTextDefinition[index]}`),
        )}
        textPosition='left'
      />
    </div>
  ));

  // The footer buttons
  const footerButtons = footerButtonsTextDefinition.map((text: string, index: number) => (
    <ButtonBase
      text={GlobalService.uppercaseFirstLetter(t(text))}
      color='#808080'
      extraClass='underline decoration-solid'
      key={index}
      buttonHeight='25px'
      minHeight='25px'
      paddingX={5}
      paddingY={5}
      onClick={() => handleFooterClick(index)}
    />
  ));

  const topMenuProperties: any = {
    title: GlobalService.uppercaseFirstLetter(t(`${textBase}.title`)),
    fixed: !isScreenBig,
  };

  /*******
   * JSX *
   *******/
  return (
    <div className='relative w-full'>
      <TopMenu {...topMenuProperties}></TopMenu>
      <div
        className={`relative ${
          isScreenBigHeight ? 'help-height' : 'help-height-mobile'
        } grid grid-cols-1 lg:grid-cols-2 gap-x-10 mt-[80px] md:my-8 mx-8 xl:mx-24`}
      >
        <div className='2xs:mt-0 md:mt-10'>
          <p className='2xs:Help-Title-1-small 2xs:text-center 2xs:mb-2 md:Help-Title-1 md:mb-8'>
            {GlobalService.uppercaseFirstLetter(t(`${textBase}.firstText`))}
          </p>
          {helpButtons}
          <p className='2xs:Help-Title-1-small 2xs:text-center 2xs:mb-2 md:Help-Title-2 md:mx-6 mt-4'>
            {GlobalService.uppercaseFirstLetter(t(`${textBase}.secondText`))}
          </p>
        </div>
        <div className='w-[497px] h-[300px] mt-20 xl:m-20 mb-24 hidden lg:flex'>
          <img src={Callcenter} width='100%' alt='help image' />
        </div>
      </div>
      <div className='flex flex-col sm:flex-row w-full items-center justify-center gap-2'>
        {footerButtons}
      </div>
    </div>
  );
};

export default Help;
