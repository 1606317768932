export const ETERNAL_AUTH_SURVEY_PARAMS = {
  ID: 'id',
  CARE_HOME_NAME: 'care_home_name',
  PATIENT_NAME: 'patient_last_name',
  PATIENT_DNI: 'patient_dni',
  PATIENT_BIRTH_DATE: 'patient_birth_date',
  TUTOR_LAST_NAME: 'tutor_last_name',
  TUTOR_DNI: 'tutor_dni',
  MOBILE: 'mobile',
  EMAIL: 'email',
} as const;
