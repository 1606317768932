import React from 'react';
import Loader from '../../components/Loaders/Loader';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elem = `interface Props {
    type: string;
    size: string;
    border: string;
    color: string;
    spinnerColor: string;
}

const Loader: React.FC<Props> = ({ type, size, border, color, spinnerColor }) => {
    switch (type) {
        case 'regular':
            return (
                <div
                    style={{
                        width: size,
                        height: size,
                        borderColor: color,
                        borderRightColor: spinnerColor,
                        borderRadius: '50%',
                        border: '<border> solid',
                        animation: 'loader 1.5s infinite linear'
                    }}
                ></div>
            );
        case 'gradient':
            return (
                <div
                    style={{
                        width: size,
                        height: size,
                        background: 'conic-gradient(#0000 10%,<spinnerColor>)',
                        WebkitMask: 'radial-gradient(farthest-side,#0000 calc(100% - <border>),#000 0)',
                        borderRadius: '50%',
                        animation: 'loader 1.5s infinite linear'
                    }}
                ></div>
            );
        case 'dot':
            return (
                <div
                    style={{
                        width: size,
                        height: size,
                        background: 'radial-gradient(farthest-side,<spinnerColor> 95%,#0000) 50% 1px/12px 12px no-repeat, radial-gradient(farthest-side,#0000 calc(100% - 14px),<color> 0)',
                        borderRadius: '50%',
                        animation: 'loader 1.5s infinite linear'
                    }}
                ></div>
            );
        case 'roundGradient':
            return (
                <div
                    style={{
                        width: size,
                        height: size,
                        background: 'radial-gradient(farthest-side,<spinnerColor> 94%,#0000) top/8px 8px no-repeat, conic-gradient(#0000 30%,<spinnerColor>)',
                        WebkitMask: 'radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0)',
                        borderRadius: '50%',
                        animation: 'loader 1.5s infinite linear'
                    }}
                ></div>
            );
        default:
            return (
                <div
                    style={{
                        width: size,
                        height: size,
                        borderColor: color,
                        borderRightColor: spinnerColor,
                        borderRadius: '50%',
                        border: '<border> solid',
                        animation: 'loader 1.5s infinite linear'
                    }}
                ></div>
            );
    }
};

export default Loader;`;

const Loaders = () => {
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Loaders</div>
            <div className='text-lg mt-4'>A loaders is an element that spins around.</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Next some implementations of the element</div>
            <div className='text-base mt-2'>Default loader</div>
            <CopyBlock language={'jsx'} text={`<Loader />`} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Loader size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />
            </div>
            <div className='text-base mt-2'>Different loaders defined by their type: regular | dot | gradient | roundGradient</div>
            <CopyBlock
                language={'jsx'}
                text={`<Loader type='regular' size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Loader type='regular' size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />
                <Loader type='dot' size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />
                <Loader type='gradient' size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />
                <Loader type='roundGradient' size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />
            </div>
            <div className='text-base mt-2'>Different colors for the loader</div>
            <CopyBlock
                language={'jsx'}
                text={`<Loader size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Loader size='50px' border='8px' color='black' spinnerColor='white' />
                <Loader size='50px' border='8px' color='white' spinnerColor='black' />
                <Loader size='50px' border='8px' color='#DADADA' spinnerColor='black' />
                <Loader size='50px' border='8px' color='#DADADA' spinnerColor='white' />
                <Loader size='50px' border='8px' color='#DADADA' spinnerColor='#E06C75' />
                <Loader size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />
            </div>
            <div className='text-base mt-2'>Different sizes changing the size and the border size</div>
            <CopyBlock
                language={'jsx'}
                text={`<Loader size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Loader size='25px' border='4px' color='#DADADA' spinnerColor='#008B8B' />
                <Loader size='50px' border='8px' color='#DADADA' spinnerColor='#008B8B' />
                <Loader size='75px' border='12px' color='#DADADA' spinnerColor='#008B8B' />
                <Loader size='100px' border='16px' color='#DADADA' spinnerColor='#008B8B' />
            </div>
        </div>
    );
};

export default Loaders;
