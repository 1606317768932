import { Dispatch, SetStateAction } from 'react';

export const makeObservable = <T>(target: T) => {
  let listeners: ((value: T) => void)[] = [];
  let value = target;

  function get() {
    return value;
  }

  function set(newValue: T | ((curVal: T) => T)) {
    if (value === newValue) return;
    value = newValue instanceof Function ? newValue(value) : newValue;
    listeners.forEach(l => l(value));
  }

  function subscribe(listenerFunc: Dispatch<SetStateAction<T>>) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc: (value: T) => void) {
    listeners = listeners.filter(l => l !== listenerFunc);
  }

  return { get, set, subscribe };
};
