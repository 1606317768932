import { FC } from 'react';
import { AVAILABLE_LANGUAGES } from '../../../consts';
import { SpainFlag } from './spain-flag';
import { UnitedKingdomFlag } from './united-kingdom-flag';
import type { IconProps, AvailableLanguage } from 'src/ts';

const langToFlag = {
  [AVAILABLE_LANGUAGES.ES]: SpainFlag,
  [AVAILABLE_LANGUAGES.EN]: UnitedKingdomFlag
};

interface LanguageFlag extends IconProps {
  language: AvailableLanguage;
}
export const LanguageFlag: FC<LanguageFlag> = ({ language, width, height, className }) => {
  const Flag = langToFlag[language];

  return <Flag width={width} height={height} className={className} />;
};
