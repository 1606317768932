import { useAtomValue } from 'jotai';
import { makeQuotePaymentCheckoutAtom } from '../../../store/global/atoms';

export const useMakeQuotePaymentCheckout = () => {
  const makeQuotePaymentCheckout = useAtomValue(makeQuotePaymentCheckoutAtom);

  return {
    makeQuotePaymentCheckout: makeQuotePaymentCheckout.mutate,
    makeQuotePaymentCheckoutAsync: makeQuotePaymentCheckout.mutateAsync,
    isLoading: makeQuotePaymentCheckout.isPending,
    error: makeQuotePaymentCheckout.error,
  };
};
