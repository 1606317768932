import { useSetAtom } from 'jotai';
import { userInfoAtom, authAtom } from '../../../store/global/atoms';
import type { Auth, UserInfo } from '../../../ts';
import { RESET } from 'jotai/vanilla/utils';
import { ActiveUserService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/activeUser.service';

export const useSetSession = () => {
  const setAuth = useSetAtom(authAtom);
  const setUserInfo = useSetAtom(userInfoAtom);

  const setSession = (auth: Auth, user: UserInfo) => {
    setAuth(auth);
    setUserInfo(user);
  };

  const removeSession = () => {
    const itemsToRemove: string[] = ['backRoute', 'tutorId', 'tutorName', 'token'];
    itemsToRemove.forEach(item => window.localStorage.removeItem(item));
    setAuth(RESET);
    setUserInfo(RESET);
  };

  const refreshUser = async () => {
    const response = await ActiveUserService.initData(true);
    setUserInfo(response.Prisma);
  };

  return { setAuth, setUserInfo, setSession, removeSession, refreshUser };
};
