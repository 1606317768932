import { useEffect, useState } from 'react';
import { makeObservable } from '../../utils/make-observable';
import { useSession } from '../session';
import { TutorInfo, UserInfo } from 'src/ts';
import { userServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/user.service';
import { userRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/userRepository';
import { convertUserToTutor } from '../../utils';

const isLoadingStore = makeObservable(false);

export const useUpdateUser = () => {
  const { userInfo, setUserInfo, refreshUser } = useSession();
  const [isLoading, setIsLoading] = useState(isLoadingStore.get());

  useEffect(() => {
    return isLoadingStore.subscribe(setIsLoading);
  }, [isLoading, setIsLoading]);

  const reloadUser = async () => {
    isLoadingStore.set(true);
    refreshUser();
    isLoadingStore.set(false);
  };

  const handleUpdateUser = async ({
    newDni,
    newName,
    newPhone,
  }: {
    newDni: UserInfo['dni'];
    newName: UserInfo['name'];
    newPhone: UserInfo['phone'];
  }) => {
    if (userInfo == null) return;
    setIsLoading(true);
    const newUser: UserInfo = structuredClone(userInfo);
    newUser.dni = newDni;
    newUser.name = newName;
    newUser.phone = newPhone;

    setUserInfo(newUser);

    const newUserAsTutor = convertUserToTutor(newUser);

    const response = (await userServices(userRepositoryInstance).updateUser(newUserAsTutor)) as
      | TutorInfo
      | string;

    if (typeof response === 'string') {
      console.log('here');
      setIsLoading(false);
      return false;
    }

    const updatedUser: UserInfo = {
      ...newUser,
      dni: response.dni as string,
      name: response.name as string,
      phone: response.phone as string,
      email: response.email as string,
    };

    setUserInfo(updatedUser);
    reloadUser();
    setIsLoading(false);

    return true;
  };

  return {
    user: userInfo,
    isLoading,
    handleUpdateUser,
  };
};
