import { useAtomValue } from 'jotai';
import { selectedPatientAtom } from '../../../store/global/atoms';
import { useEffect } from 'react';
import { useShowSelectedPatientErrorPopup } from '../../../hooks';

export const useSelectedPatientValue = () => {
  const selectedPatient = useAtomValue(selectedPatientAtom);
  const { showSelectedPatientErrorPopup } = useShowSelectedPatientErrorPopup();

  const error = selectedPatient.id === '-1' ? 'patientNotFound' : null;

  useEffect(() => {
    if (error != null) {
      showSelectedPatientErrorPopup();
    }
  }, [selectedPatient]);

  const pendingVisits = selectedPatient.visitsDetails?.filter(
    visit =>
      visit.Estado_de_la_visita.toLocaleLowerCase().includes('visita pendiente') &&
      new Date(visit.Fecha_de_la_visita_raw) >= new Date(),
  );

  const nonPendingVisits = selectedPatient.visitsDetails?.filter(
    visit =>
      !visit.Estado_de_la_visita.toLocaleLowerCase().includes('visita pendiente') ||
      new Date(visit.Fecha_de_la_visita_raw) < new Date(),
  );

  return { selectedPatient, pendingVisits, nonPendingVisits, error };
};
