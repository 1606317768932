import { useSetPatients } from '../use-patients';
import { useSetSelectedPatient } from '../use-selected-patient';

export const useResetPatients = () => {
  const { resetPatientsList } = useSetPatients();
  const { removeSelectedPatient } = useSetSelectedPatient();

  const resetPatients = () => {
    resetPatientsList();
    removeSelectedPatient();
  };

  return {
    resetPatients,
  };
};
