import styles from './navbar-login.module.css';
import { LanguageSelector } from '../../../components/language';
import { LuraCareLogo } from '../../../components/logos';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

const cx = classNames.bind(styles);

export const NavbarLogin = () => {
  const [onlyLogo, setOnlyLogo] = useState(false);

  useEffect(() => {
    const handleOnlyLogo = () => {
      if (window.innerWidth <= 500) {
        if (onlyLogo) return;
        setOnlyLogo(true);
        return;
      }
      if (!onlyLogo) return;
      setOnlyLogo(false);
    };

    handleOnlyLogo();

    window.addEventListener('resize', handleOnlyLogo);

    return () => window.removeEventListener('resize', handleOnlyLogo);
  }, [onlyLogo]);

  return (
    <div className={cx('login_navbar')}>
      <div className={cx('login_navbar_inner_container')}>
        <LuraCareLogo
          onlyLogo={onlyLogo}
          className={{
            svg: cx('lura_care_logo'),
            mainText: cx('logo_main_text'),
            footerText: cx('logo_footer_text')
          }}
        />

        <LanguageSelector />
      </div>
    </div>
  );
};
