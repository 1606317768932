import { QUERY_KEYS } from '../../../../../consts';
import { getPatientsDealsService } from '../../../../../services';
import { ExtraInfoMutationProps, IdToPatientExtraInfo, PatientDeal } from '../../../../../ts';
import { getAtomWithMutation } from '../../../../../utils';

export const getPatientsDealsAtom = getAtomWithMutation<
  IdToPatientExtraInfo<PatientDeal>,
  ExtraInfoMutationProps
>({
  mutationKey: QUERY_KEYS.PATIENTS.ALL_PATIENTS_DEALS,
  mutationFn: getPatientsDealsService,
});
