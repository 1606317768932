import styles from './select.module.css';
import { MouseEvent, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import type { SelectProps } from 'src/ts/interfaces/components';
import { useClickOutside } from '../../hooks';
import { CaretDown } from '../icons';

const cx = classNames.bind(styles);

export const Select = <T extends string>({
  label,
  defaultValue,
  setValue,
  options
}: SelectProps<T>) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue ?? null);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const toggleIsOpen = (forceState?: boolean) => {
    setIsOpen(curVal => (forceState != null ? forceState : !curVal));
  };

  useClickOutside(selectRef, () => toggleIsOpen(false));

  const getOptionContent = (value: T | null) =>
    value == null ? null : options.find(opt => opt.value === value)?.content ?? null;

  const handleClickOption = (newVal: T, e: MouseEvent<HTMLDivElement>) => {
    setValue(newVal, e);

    setSelectedValue(newVal);
    toggleIsOpen(false);
  };

  return (
    <div
      className={cx('dropdown')}
      role='combox'
      aria-expanded='false'
      aria-haspopup='listbox'
      aria-label={label}
      ref={selectRef}
    >
      <div
        className={cx('dropdown_header', 'content_container', { 'dropdown_header-open': isOpen })}
        tabIndex={0}
        aria-controls='dropdownList'
        onClick={() => toggleIsOpen()}
      >
        {getOptionContent(selectedValue) ?? label}
        <CaretDown width='20' />
      </div>

      {isOpen && (
        <div className={cx('dropdown_list', { 'dropdown_list-hidden': !isOpen })} role='listbox'>
          {options.map(opt => (
            <div
              key={opt.value}
              className={cx('dropdown_item', 'content_container', {
                'dropdown_item-selected': opt.value === selectedValue
              })}
              role='option'
              data-value={opt.value}
              tabIndex={-1}
              onClick={e => handleClickOption(opt.value, e)}
            >
              {opt.content}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
