import { PopupOptionsSetter, SetBufferAction } from '../../ts';

export const createNewBuffer = (
  prevBuffer: PopupOptionsSetter[],
  newBuffer: PopupOptionsSetter[],
  action?: SetBufferAction,
) => {
  if (action == null || action === 'replace') {
    return newBuffer;
  }
  if (action === 'unshift') {
    return [...newBuffer, ...prevBuffer];
  }
  if (action === 'push') {
    return [...prevBuffer, ...newBuffer];
  }
};
