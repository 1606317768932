export const AVAILABLE_SETTINGS = {
  SCHEDULE_CALL: 'scheduleCall',
  VIDEO_CALL: 'videoCall',
  PDF_IT: 'pdfKeyIT',
  PDF_BOCA_SANA: 'pdfKeyBocaSana',
  PDF_RO: 'pdfKeyRO',
  PDF_NO_COLABORA: 'pdfKeyNoColabora',
  ETERNAL_REVIEW_AUTH_SURVEY: 'eternalReviewAuthSurvey',
  CALL: 'Call',
  WHATSAPP: 'Whatsapp',
  SEND_EMAIL: 'sendEmail',
  AVISO_LEGAL: 'avisoLegal',
  PRIVACIDAD: 'Privacidad',
  SERVICE_REQUEST_SURVEY: 'solicitarServicioSurvey',
  CANCELLATION_REQUEST_SURVEY: 'solicitarCancelacionSurvey',
  CHANGE_VISIT_SURVEY: 'cambiarVisitaSurvey',
  DOCUMENTS: 'documents',
  TRANSFER_ACC: 'transfAccount',
  HIDE_SHOP: 'hideShop',
} as const;
