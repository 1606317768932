import { FC } from 'react';
import { useSession } from '../../hooks';
import MobileNavigationLogged from '../Navigation/MobileNavigationLogged';
import MobileNavigationUnLogged from '../Navigation/MobileNavigationUnLogged';
import NavigationBar from '../Navigation/Navbar';
import { Popup } from '../popup/popup';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { isUserLogged } = useSession();

  return (
    <div className='flex w-full h-full'>
      {isUserLogged ? <MobileNavigationLogged /> : <MobileNavigationUnLogged />}
      <NavigationBar />
      {children}
      <Popup />
    </div>
  );
};
