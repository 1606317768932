import { atom } from 'jotai';

export const helpModalIsVisibleAtom = atom(false);
export const infoModalTitleAtom = atom('');
export const infoModalTextAtom = atom('');
export const infoModalButtonTextAtom = atom('');
export const infoModalIsVisibleAtom = atom(false);
export const openLinkAtom = atom('');
export const showUploadModalAtom = atom(false);
export const errorModalIsVisibleAtom = atom(false);
export const goBackAtom = atom(false);
export const errorTextAtom = atom('');
export const askForNewFileAtom = atom(false);
export const showPatoMediLoaderAtom = atom(false);
export const modalHelpTextAtom = atom('');
