import React from 'react';

interface Props {
    url: string;
    size: string;
}

export const Avatar: React.FC<Props> = ({ url, size }) => {
    return (
        <div
            className='rounded-full'
            style={{
                height: size,
                width: size,
                backgroundImage: `url("${url}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat'
            }}
        ></div>
    );
};
