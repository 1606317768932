import axios from 'axios';
import { AddressItem } from '../../../ts';

export const getUserAddressService = async () => {
  const type = 'addressenviamentcomanda';
  try {
    const response = await axios.get<AddressItem>(`/addresses${type ? `?types=${type}` : ''}`);

    return response.data.data[0];
  } catch (error) {
    throw new Error('errors.addressesNotFound');
  }
};
