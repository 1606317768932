import { IdToPatientExtraInfo, PatientDeal, PatientInfo } from '../../ts';

export const assignDealsToPatients = (
  patientsList: PatientInfo[],
  deals: IdToPatientExtraInfo<PatientDeal>,
) => {
  const newPatientsList: PatientInfo[] = [];
  for (const patient of patientsList) {
    const { id } = patient;
    const patientDeals = deals[id] || [];

    if (patientDeals.length === 0) {
      const newPatient: PatientInfo = { ...patient, pendingDocs: false };
      newPatientsList.push(newPatient);
      continue;
    }

    const sortedPatientDeals = patientDeals.sort((a, b) => {
      const aCreatedTime = new Date(a.Created_Time).toISOString();
      const bCreatedTime = new Date(b.Created_Time).toISOString();
      const aLastEdit = new Date(a.Modified_Time).toISOString();
      const bLastEdit = new Date(b.Modified_Time).toISOString();
      const isSameDeal = a.Deal_Name === b.Deal_Name && aCreatedTime === bCreatedTime;

      if (isSameDeal) {
        return aLastEdit > bLastEdit ? -1 : 1;
      }

      return aCreatedTime > bCreatedTime ? -1 : 1;
    });

    const latestDeal = sortedPatientDeals[0];

    const pendingDocs =
      !latestDeal.Archivo_Consentimientos ||
      !latestDeal.Autorizaciones_Firmadas ||
      !latestDeal.Patomedi;

    const newPatient: PatientInfo = {
      ...patient,
      pendingDocs,
      dealDetails: {
        id: latestDeal.id,
        Archivo_Consentimientos: latestDeal.Archivo_Consentimientos,
        Archivo_Patomedi: latestDeal.Archivo_Patomedi,
        Autorizaciones_Firmadas: latestDeal.Autorizaciones_Firmadas,
        Deal_Name: latestDeal.Deal_Name,
        Pago_Inicial: latestDeal.Pago_Inicial,
        Pago_Final: latestDeal.Pago_Final,
        Payment_In_Process: latestDeal.Payment_In_Process,
        Patomedi: latestDeal.Patomedi,
        SEPA_File: latestDeal.SEPA_File,
        Signed_SEPA: latestDeal.Signed_SEPA,
        Created_Time: latestDeal.Created_Time,
        Modified_Time: latestDeal.Modified_Time,
      },
    };

    newPatientsList.push(newPatient);
  }

  return newPatientsList;
};
