import React, { FC, CSSProperties, useEffect, useState, useRef } from 'react';

// Components
import { ButtonBase } from '../Buttons/button-base';
import Title from '../../components/Title';

// Interfaces
import { IModalProps } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IModals';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useViewport, useClickOutside } from '../../hooks';

const Modal: FC<IModalProps> = ({
  addTitle = false,
  alignItemsParent,
  animationIn = '',
  animationOut = '',
  backgroundColor,
  backgroundColorParent,
  border,
  borderColor,
  borderRadius,
  children,
  forcedChildHeight,
  height,
  justifyContent = 'space-between',
  justifyContentParent,
  marginChildren = '0',
  modalBodyHeight,
  onClose,
  open,
  overflow = 'auto',
  padding,
  zIndex = 55,
  parentStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: justifyContentParent,
    alignItems: alignItemsParent,
    flexDirection: 'column',
    backgroundColor: backgroundColorParent,
    zIndex: zIndex,
  },
  showButtons,
  showCloseButton,
  showTitleIcon,
  size,
  titleColor = 'black',
  titleIcon,
  titleMargin = '0px',
  titleSize = '20px',
  titleText = '',
  className = '',
}) => {
  /**********
   * States *
   **********/
  const { viewportWidth } = useViewport();
  const modalRef = useRef<HTMLDivElement>(null);
  useClickOutside(modalRef, onClose);

  const childStyle: CSSProperties = {
    position: 'relative',
    backgroundColor,
    height: forcedChildHeight ? forcedChildHeight : size ? size : height ? height : 'auto',
    borderRadius,
    border: border ? `1px solid ${borderColor}` : 'none',
    padding: padding,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContent,
    margin: marginChildren,
    animation: open
      ? animationIn
        ? `${animationIn} 0.5s forwards`
        : ''
      : animationOut
      ? `${animationOut} 1s forwards`
      : '',
  };

  const headerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const footerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem',
    gap: '10px',
  };

  const bodyStyle: any = {
    overflow,
  };
  if (modalBodyHeight) {
    bodyStyle.height = modalBodyHeight;
  }

  return (
    <div style={parentStyle}>
      <div
        className={`modal ${className}`}
        style={childStyle}
        ref={modalRef}
        onClick={event => event.stopPropagation()}
      >
        <div style={headerStyle} className='modalHeader'>
          {addTitle ? (
            titleIcon && showTitleIcon ? (
              <>
                <img src={titleIcon} alt='Back' className='title-icon' onClick={onClose} />
                <Title text={titleText} color={titleColor} size={titleSize} margin={titleMargin} />
              </>
            ) : (
              <Title text={titleText} color={titleColor} size={titleSize} margin={titleMargin} />
            )
          ) : null}
          <FontAwesomeIcon
            onClick={onClose}
            style={{ cursor: 'pointer', margin: viewportWidth > 767 ? '' : '9px' }}
            icon={faXmark}
            className={`${
              viewportWidth > 767
                ? ''
                : titleText && showCloseButton
                ? ''
                : showCloseButton
                ? ''
                : 'hidden'
            } opacity-50`}
          />
        </div>
        <div style={bodyStyle} className='modalBody'>
          {children}
        </div>
        {showButtons ? (
          <div style={footerStyle} className='modalFooter'>
            <ButtonBase
              text='Cancel'
              onClick={onClose}
              background='none'
              color='#2F3447'
              border='2px solid #2F3447'
            />
            <ButtonBase
              text='Accept'
              onClick={onClose}
              background='none'
              color='#2F3447'
              border='2px solid #2F3447'
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
