import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

// Components
import { ButtonBase } from '../components/Buttons/button-base';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { http } from '../hexagonal-architecture-frontend-base/src/infrastructure/plugins/http/http';

// Helpers
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Signup.css';

// Images
import Image from '../img/forgotPassword.png';
import Logo from '../img/LuraCare_LogoLandscape.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';
import Loader from '../components/Loaders/Loader';
import { Button } from '../components/Buttons/button/button';

const VERIFY_EMAIL_RESPONSES = {
  SUCCESS: 'Email verified',
  ALREADY_VERIFIED: 'Already verified',
};

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useParams();
  const [searchParams] = useSearchParams();
  const { viewportWidth } = useViewport();

  /**********
   * States *
   **********/
  const [msgToShow, setMsgToShow] = useState<string>('explanation');
  const [titleText, setTitleText] = useState<string>('title');
  const [isVerifying, setIsVerifying] = useState<boolean>(true);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  /************
   * Handlers *
   ************/
  const verifyEmail = () => {
    const code = searchParams.get('code');
    http
      .cleanPost(`/users/${userId}/verify`, { code: code })
      .then((response: any) => {
        const { message } = response.data;

        if (message === VERIFY_EMAIL_RESPONSES.SUCCESS) {
          setMsgToShow('ok');
        } else if (message === VERIFY_EMAIL_RESPONSES.ALREADY_VERIFIED) {
          setMsgToShow('alreadyVerified');
        }

        setTitleText('verified');
        setIsVerified(true);
      })
      .catch(() => {
        setMsgToShow('error');
      })
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const goToLogin = () => {
    navigate('/login');
  };

  useLayoutEffect(() => {
    verifyEmail();
  }, []);

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;

  /*******
   * JSX *
   *******/
  return (
    <div className={`main-container-forgot-password${isScreenBig ? '' : '-responsive'}`}>
      <div className={`logo-div${isScreenBig ? '' : '-responsive'}`}>
        <img src={Logo} />
      </div>

      <div className={viewportWidth >= 640 ? 'form-div' : 'form-div-forgot-password-small'}>
        <div
          className={
            viewportWidth >= 640 ? 'form-container-forgot-password' : 'form-container-small'
          }
        >
          {isVerifying ? (
            <>
              <h2 className={`form-title-forgot-password${viewportWidth >= 640 ? '' : '-small'}`}>
                {GlobalService.uppercaseFirstLetter(t(`pages.verifyEmail.verifyingEmail`))}
              </h2>
              <Loader />
            </>
          ) : (
            <>
              <h2 className={`form-title-forgot-password${viewportWidth >= 640 ? '' : '-small'}`}>
                {GlobalService.uppercaseFirstLetter(t(`pages.verifyEmail.${titleText}`))}
              </h2>

              <p className='form-explanation'>
                {GlobalService.uppercaseAfterPointFirstsLetters(
                  t(`pages.verifyEmail.${msgToShow}`),
                )}
              </p>

              {isVerified ? (
                <div className='mt-14'>
                  <Button type='primary' onClick={goToLogin} fullWidth={true}>
                    {GlobalService.uppercaseFirstLetter(t(`pages.verifyEmail.okButton`))}
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>

      <div className='hidden left-image md:flex content-center'>
        <img src={Image} />
      </div>
    </div>
  );
};

export default VerifyEmail;
