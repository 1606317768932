// Services
import { TiendaRepository } from '../../domain/repositories/tienda.repository';
import { http } from '../plugins/http/http';

// Interfaces
import { IAddressData, IAddressItem, IAddressUpsert, IAddressUpsertResp } from '../../domain/models/IAddressItem';
import { ICart } from '../../domain/models/ICart';
import { ICartItem } from '../../domain/models/ICartItem';
import { ICategory } from '../../domain/models/ICategory';
import { IOrder, ISubscriptionRes, IServiceListReturn } from '../../domain/models/IPago';
import { IPatient } from '../../domain/models/IPersona';
import { IShopItem } from '../../domain/models/IShopItem';

let productos: IShopItem[] = [];
const categories: ICategory[] = [
  {
    id: 0,
    name: 'Todo',
    createdAt: '',
    updatedAt: ''
  }
];

let storedCart: ICart = {
  items: [],
  details: {
    totalItems: 0,
    totalPriceItems: 0,
    totalPriceOrder: 0,
    vat: 0
  }
};

let lastUsedCategory: number | null = null;
let lastUsedSearchText: string | null = '';
let basketAddress: IAddressItem | null = null;

export const tiendaRepository = (): TiendaRepository => ({
  formatPrice: (price: number): string => {
    return price.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });
  },
  //CATEGORIES
  getCategory: async (categoryId): Promise<ICategory | string> => {
    return await http
      .get<ICategory>(`/categories/${categoryId}`)
      .then((item: ICategory) => {
        return item;
      })
      .catch(() => {
        return 'errors.categoriesNotFound';
      });
  },
  getCategories: async (): Promise<ICategory[] | string> => {
    if (categories.length > 1) {
      return categories;
    }
    let errorMessage = '';
    await http
      .get<ICategory[]>(`/categories`)
      .then((items: ICategory[]) => {
        items.forEach((item: ICategory) => categories.push(item));
      })
      .catch(() => {
        errorMessage = 'errors.categoriesNotFound';
      });

    if (errorMessage.length) return errorMessage;
    else return categories;
  },
  //PRODUCTS
  getProductos: async (category?: number, name?: string, numberItems = 8, currentPage = 1): Promise<IShopItem[] | string> => {
    if (productos && lastUsedCategory === category && lastUsedSearchText === name) return productos;
    else {
      let errorMessage = '';

      lastUsedCategory = category ? category : null;
      lastUsedSearchText = name ? name : null;
      const url = `/products?per_page=${numberItems}&page=${currentPage}${category ? `&categories=${category}` : ''}${
        name ? `&name=${name}` : ''
      }`;

      await http
        .get<IShopItem[]>(url)
        .then((items: IShopItem[]) => {
          productos = items;
        })
        .catch(() => {
          errorMessage = 'errors.productsNotFound';
        });
      if (errorMessage.length) return errorMessage;
      else return productos;
    }
  },
  getProductosCount: async (category?: number, name?: string): Promise<number | string> => {
    const url = `/products/count${category ? `?categories=${category}${name ? `&name=${name}` : ''}` : `${name ? `?name=${name}` : ''}`}`;
    let returnValue: string | number = 0;
    await http
      .get<number>(url)
      .then((res: number) => {
        returnValue = res;
      })
      .catch(() => {
        returnValue = 'errors.genericError';
      });
    return returnValue;
  },
  getProducto: async (id: number): Promise<IShopItem | string> => {
    let returnValue: IShopItem | string = '';
    await http
      .get<IShopItem>(`/products/product/${id}`)
      .then((product: IShopItem) => {
        returnValue = product;
      })
      .catch(() => {
        returnValue = 'errors.productNotFound';
      });
    return returnValue;
  },
  //CART
  getBasketItems: async (reloadData?: boolean): Promise<ICart | string> => {
    let returnValue: ICart | string = {
      items: [],
      details: {
        totalItems: 0,
        totalPriceItems: 0,
        totalPriceOrder: 0,
        vat: 0
      }
    };
    if (!reloadData && storedCart.items.length) {
      returnValue = storedCart;
    } else {
      returnValue = await http
        .get<ICart>('/cart')
        .then((res: ICart) => {
          storedCart = res;
          return res;
        })
        .catch(() => 'errors.userDataInvalid');
    }
    return returnValue;
  },
  addItemToBasket: async (id?: number, quantity = 1): Promise<string> => {
    let message = '';
    if (id) {
      await http
        .patch<ICartItem>(`/cart/products/${id}`, { quantity: quantity })
        .then(() => {
          message = 'pages.tienda.addedToBasket';
        })
        .catch(() => {
          message = 'errors.addToBasketFailed';
        });
    }
    return message;
  },
  removeAllItemsBasket: async (): Promise<string> => {
    let res = '';
    await http
      .delete<string>('/cart/delete-cart')
      .then((response: string) => {
        res = response;
      })
      .catch(() => {
        res = 'errors.genericError';
      });

    return res;
  },
  removeItemBasket: async (id: number): Promise<string> => {
    let res = '';
    await http
      .delete<string>(`/cart/products/${id}`)
      .then((response: string) => {
        res = response;
      })
      .catch(() => {
        res = 'errors.productNotFound';
      });

    return res;
  },
  //ADDRESSS
  getBasketAddress: async (type: string): Promise<IAddressItem | string> => {
    if (basketAddress) return basketAddress;
    const res = await http
      .get<IAddressItem>(`/addresses${type ? `?types=${type}` : ''}`)
      .then(response => {
        basketAddress = response;
        return response;
      })
      .catch(() => {
        return 'errors.addressesNotFound';
      });

    return res;
  },
  updateBasketAddress: async (address: IAddressUpsert): Promise<IAddressUpsertResp | string> => {
    const data = {
      userId: address.userId,
      name: address.data.Name,
      direction: address.data.Direcci_n,
      cp: address.data.Codigo_postal,
      city: address.data.Ciudad,
      pais: address.data.Pa_s
    };

    const dataAux = {
      data: [
        {
          Pa_s: address.data.Pa_s,
          Codigo_postal: address.data.Codigo_postal,
          Direcci_n: address.data.Direcci_n,
          id: address.data.id,
          Ciudad: address.data.Ciudad,
          Name: address.data.Name
        }
      ]
    };

    const res = await http
      .post<IAddressUpsertResp>(`/addresses`, data)
      .then(response => {
        basketAddress = dataAux;
        return response;
      })
      .catch(() => {
        return 'errors.addressesNotFound';
      });

    return res;
  },
  getServiciosItems: async (page = 1, per_page = 8): Promise<IServiceListReturn | string> => {
    const url = `/services?page=${page}&per_page=${per_page}`;
    return await http
      .get<IServiceListReturn | string>(url)
      .then((services: IServiceListReturn | string) => {
        if (typeof services !== 'string') {
          const result = services.list.map((person: any) => {
            const formattedPerson: IPatient = {
              dealDetails: {
                Archivo_Consentimientos: [],
                Autorizaciones_Firmadas: true,
                Deal_Name: '',
                id: '',
                Patomedi: false,
                Signed_SEPA: false,
                Created_Time: 0,
                Modified_Time: 0
              },
              id: '0',
              image: '',
              title: '',
              residence: '',
              email: '',
              eternalReviewAuth: false,
              birthDate: null
            };
            formattedPerson.id = person?.id || 0;
            formattedPerson.image = person?.Record_Image || '';
            formattedPerson.title = person?.Last_Name || '';
            formattedPerson.residence = person?.Account_Name?.name || '';
            formattedPerson.email = person?.Email || '';
            return formattedPerson;
          });
          services.list = result;
        }
        return services;
      })
      .catch(() => {
        return 'errors.servicesNotFound';
      });
  },
  getServiciosItemsCount: async () => {
    const url = `/services/count`;
    return await http
      .get<number | string>(url)
      .then((services: number | string) => {
        return services;
      })
      .catch(() => {
        return 'errors.productNotFound';
      });
  },
  getSuscriptionsItems: async (numberItems = 8, currentPage = 1, status) => {
    const url = `/orders?per_page=${numberItems}&page=${currentPage}&recurrent=true${status ? `&status=${status}` : ''}`;
    return await http
      .get<IOrder[] | string>(url)
      .then((orders: IOrder[] | string) => {
        return orders;
      })
      .catch(() => {
        return 'errors.productNotFound';
      });
  },
  getSuscriptionsItemsCount: async status => {
    const url = `/orders/count?recurrent=true${status ? `&status=${status}` : ``}`;
    return await http
      .get<number | string>(url)
      .then((orders: number | string) => {
        return orders;
      })
      .catch(() => {
        return 'errors.productNotFound';
      });
  },
  getPedidosItems: async (numberItems = 8, currentPage = 1, status: string) => {
    const url = `/orders?per_page=${numberItems}&page=${currentPage}&recurrent=false${status ? `&status=${status}` : ``}`;
    return await http
      .get<IOrder[] | string>(url)
      .then((orders: IOrder[] | string) => {
        return orders;
      })
      .catch(() => {
        return 'errors.productNotFound';
      });
  },
  getPedidosItemsCount: async (status: string) => {
    const url = `/orders/count?recurrent=false${status ? `&status=${status}` : ``}`;
    return await http
      .get<number | string>(url)
      .then((orders: number | string) => {
        return orders;
      })
      .catch(() => {
        return 'errors.productNotFound';
      });
  },
  //ORDERS
  placeOrder: async (
    id: number,
    items: ICartItem[],
    recurrent = false,
    frequency = 1,
    address: IAddressData,
    subscriptionId = ''
  ): Promise<IOrder | string> => {
    let res: IOrder | string = '';
    const orderDetails = {
      userId: id,
      items,
      recurrent,
      frequency,
      address,
      subscriptionId
    };
    await http
      .post<string>(`/orders`, orderDetails)
      .then((response: IOrder | string) => {
        res = response;
      })
      .catch((error: { code: number; message: string }) => {
        if (error.message === 'User not found') res = 'errors.userNotFound';
        if (error.message === 'Order not found') res = 'errors.orderNotFound';
      });

    return res;
  },
  updateOrder: async (order: IOrder): Promise<string> => {
    let res: IOrder | string = '';

    await http
      .put<{ message: string }>(`/orders/${order.id}`, order)
      .then((response: { message: string }) => {
        res = response.message;
      })
      .catch((error: { code: number; message: string }) => {
        if (error.message === 'User not found') res = 'errors.userNotFound';
        if (error.message === 'Order not found') res = 'errors.orderNotFound';
      });
    return res;
  },
  cancelOrder: async (orderId: number): Promise<string> => {
    let res = '';
    await http
      .delete<{ message: string }>(`/orders/delete-subscription/${orderId}`)
      .then((response: { message: string }) => {
        res = response.message;
      })
      .catch(() => {
        res = 'errors.orderNotFound';
      });
    return res;
  },
  sendOrderByMail: async (body: { mail: string; name: string; products: any[] }): Promise<string> => {
    return await http
      .post<{ name: string; products: any[] }>('/orders/send', body)
      .then(() => {
        return 'Ok';
      })
      .catch(error => {
        console.error('error: ', error);
        return 'Error!';
      });
  },
  sendTransfMail: async (details: { body: string; mail: string; salut: string; subject: string }): Promise<string> => {
    return await http
      .cleanPost('/orders/sendtransfermail', details)
      .then(() => {
        return 'Ok';
      })
      .catch(error => {
        console.error('error: ', error);
        return 'Error!';
      });
  },

  //STRIPE
  checkoutPayment: async (body: {
    id: string;
    amount: number;
    userId: number;
    stripeId: string;
    orderId?: number;
    serviceId?: string;
    type: string;
  }): Promise<string> => {
    let res = '';
    await http
      .post<{ message: string; clientSecret: string }>(`/stripe/payment`, body)
      .then((response: { message: string; clientSecret: string }) => {
        console.log('response', response);
        res = response.clientSecret;
      })
      .catch((err: any) => {
        if (err.response.data.message === 'Card Error') res = 'errors.cardPaymentError';
        else res = 'errors.paymentError';
      });

    return res;
  },

  checkoutPaymentSubscription: async (body: {
    id: string;
    stripeId: string;
    amount: number;
    billingCycle: number;
    userId: number;
    orderId: number;
  }): Promise<ISubscriptionRes | string> => {
    let res: ISubscriptionRes | string = '';
    await http
      .post<ISubscriptionRes>(`/stripe/payment-subscription`, body)
      .then((response: ISubscriptionRes) => {
        res = response;
      })
      .catch((err: any) => {
        if (err.response.data.message === 'Card Error') res = 'errors.cardPaymentError';
        else res = 'errors.paymentError';
      });

    return res;
  },
  cancelStripeSubscription: async (subscriptionId: string): Promise<string> => {
    let res = '';
    await http
      .post<{ message: string }>('/stripe/cancel-subscription', { subscriptionId })
      .then((response: { message: string }) => {
        res = response.message;
      })
      .catch(() => {
        res = 'errors.orderNotFound';
      });

    return res;
  },
  updateStripeSubscription: async (months: number, subscriptionId: string): Promise<string> => {
    let res = '';
    await http
      .post<{ message: string }>('/stripe/update-subscription', { months, subscriptionId })
      .then((response: { message: string }) => {
        res = response.message;
      })
      .catch(() => {
        res = 'errors.orderNotFound';
      });

    return res;
  }
});
