import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonBase } from '../../components/Buttons/button-base';
import Input from '../../components/Inputs/Input';

// Services
import { authServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/auth.services';
import { http } from '../../hexagonal-architecture-frontend-base/src/infrastructure/plugins/http/http';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  matchPasswords,
  validate,
} from '../../hexagonal-architecture-frontend-base/src/domain/services/user.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import {
  UserSignup,
  UserSignupErrors,
} from '../../hexagonal-architecture-frontend-base/src/domain/models/User';

// CSS
import '../../styles/Signup.css';
import styles from './signup.module.css';
import classNames from 'classnames/bind';

// Images
import Image from '../../img/signup.png';
import Logo from '../../img/LuraCare_LogoLandscape.png';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { Button } from '../../components/Buttons/button/button';

const cx = classNames.bind(styles);

const Signup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********
   * States *
   **********/
  const [cancelButtonText, setCancelButtonText] = useState<string>('cancel');
  const [errorMessage, setErrorMessage] = useState<UserSignupErrors>({
    dni: '',
    email: '',
    password: '',
    passwordsMatch: '',
    phone: '',
    name: '',
  });
  const [msgToShow, setMsgToShow] = useState<string>('');
  const [okButtonText, setOkButtonText] = useState<string>('register');
  const [showInputs, setShowInputs] = useState<boolean>(true);
  const [signUpError, setSignUpError] = useState<string>('');
  const [titleToShow, setTitleToShow] = useState<string>('title');
  const [user, setUser] = useState<UserSignup>({
    name: '',
    email: '',
    password: '',
    phone: '',
    dni: '',
    passwordConfirmation: '',
  });
  const { viewportWidth } = useViewport();

  /************
   * Handlers *
   ************/
  const handleCancel = () => {
    if (cancelButtonText === 'cancel') {
      navigate('/login');
    } else {
      http
        .cleanPost('/users/resend-verification', { email: user.email })
        .then((response: any) => {
          if (response.status === 200 || response.status === 201) {
            setTitleToShow('mailReSent');
            setMsgToShow('mailReSentExplanation');
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleSubmitForm = () => {
    console.log('submitting');
    if (okButtonText === 'register') {
      authServices()
        .signup(user)
        .then((response: string) => {
          if (response === 'ok') {
            setShowInputs(false);
            setTitleToShow('mailSent');
            setMsgToShow('explanation');
            setOkButtonText('understood');
            setCancelButtonText('sendAgain');
          } else {
            setSignUpError(response);
          }
        });
    } else {
      navigate('/login');
    }
  };

  const handleValidation = (field: string, e: string) => {
    const errorToShow: string = inputsDefinition.filter(def => def.id === field)[0].error;
    const errorToSet =
      field !== 'dni' ? GlobalService.uppercaseFirstLetter(t(errorToShow)) : t(errorToShow);
    let validateFails = false;

    if (field !== 'passwordConfirmation') {
      if (e && !validate(e, (field as 'name') || 'email' || 'phone' || 'dni' || 'password')) {
        validateFails = true;
        setErrorMessage(error => ({ ...error, [field]: errorToSet }));
      } else {
        validateFails = false;
        setErrorMessage(error => ({ ...error, [field]: '' }));
      }
      if (field !== 'name') {
        setUser(user => ({ ...user, [field]: e }));
      } else {
        if (e.length < 50) {
          setUser(user => ({ ...user, name: e }));
        }
      }
      // Check if passwords match
      if (field === 'password' && user.passwordConfirmation) {
        if (!matchPasswords(user.passwordConfirmation as string, e)) {
          if (!validateFails) {
            setErrorMessage(error => ({
              ...error,
              passwordsMatch: GlobalService.uppercaseFirstLetter(t('errors.passwordsNotMatch')),
            }));
          }
        } else {
          if (!validateFails) {
            setErrorMessage(error => ({ ...error, password: '' }));
            setErrorMessage(error => ({ ...error, passwordsMatch: '' }));
          }
        }
      }
    } else {
      validateFails = !validate(e, 'password');
      if (!matchPasswords(user.password as string, e)) {
        setErrorMessage(error => ({ ...error, passwordsMatch: errorToSet }));
      } else {
        if (!validateFails) {
          setErrorMessage(error => ({ ...error, password: '' }));
        }
        setErrorMessage(error => ({ ...error, passwordsMatch: '' }));
      }
      setUser(user => ({ ...user, passwordConfirmation: e }));
    }
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t('pages.signup.title'))}`;
  const isScreenBig = viewportWidth > 768;

  let isButtonEnabled = true;

  const inputsDefinition = [
    {
      id: 'name',
      text: 'name',
      error: 'errors.noNumbersSpecCharsAllowed',
      value: user.name,
      onChange: (e: string) => handleValidation('name', e),
    },
    {
      id: 'email',
      text: 'email',
      error: 'errors.emailInvalid',
      value: user.email,
      onChange: (e: string) => handleValidation('email', e),
    },
    {
      id: 'phone',
      text: 'phoneno',
      error: 'errors.phoneInvalid',
      value: user.phone,
      onChange: (e: string) => handleValidation('phone', e),
    },
    {
      id: 'dni',
      text: 'tutorId',
      error: 'errors.dniInvalid',
      value: user.dni,
      onChange: (e: string) => handleValidation('dni', e),
    },
    {
      id: 'password',
      text: 'password',
      error: 'errors.passwordInvalid',
      value: user.password,
      onChange: (e: string) => handleValidation('password', e),
    },
    {
      id: 'passwordConfirmation',
      text: 'passwordconfirmation',
      error: 'errors.passwordsNotMatch',
      value: user.passwordConfirmation,
      onChange: (e: string) => handleValidation('passwordConfirmation', e),
    },
  ];

  const userInputs = inputsDefinition.map((item, index) => {
    return (
      <div key={index} className={cx('input--container')}>
        <label htmlFor={item.id} className={cx('input--label')}>
          {item.id !== 'dni'
            ? GlobalService.uppercaseFirstLetter(t(item.text))
            : t(item.text).toUpperCase()}
        </label>

        <div className={cx('input--input-container')}>
          <Input
            id={item.id}
            name={item.id}
            placeholder={
              item.id !== 'dni'
                ? GlobalService.uppercaseFirstLetter(t(item.text))
                : t(item.text).toUpperCase()
            }
            type={item.id.toLowerCase().includes('password') ? 'password' : 'text'}
            value={item.value as string}
            customClass={'login-input'}
            onChange={item.onChange}
            enableShowPassword={item.id.toLowerCase().includes('password')}
          />

          {errorMessage ? (
            <p className={cx('error-text')}>
              {
                errorMessage[
                  item.id === 'passwordConfirmation'
                    ? 'passwordsMatch'
                    : (item.id as keyof UserSignupErrors)
                ]
              }
            </p>
          ) : null}
        </div>
      </div>
    );
  });

  // Signup button is disabled if any field is empty
  Object.keys(user).forEach(key => {
    if (!user[key as keyof UserSignup]) {
      isButtonEnabled = false;
    }
  });
  // Signup button is disabled if there is any error
  if (isButtonEnabled) {
    Object.keys(errorMessage).forEach(key => {
      if (errorMessage[key as keyof UserSignupErrors] !== '') {
        isButtonEnabled = false;
      }
    });
  }

  /*******
   * JSX *
   *******/
  return (
    <div className={cx('main-container')}>
      <div className={cx('inner-container')}>
        <div className={cx('form-container')}>
          <h2 className={cx('form-title')}>
            {GlobalService.uppercaseFirstLetter(t(`pages.signup.${titleToShow}`))}
          </h2>
          {showInputs ? (
            userInputs
          ) : (
            <p className='form-explanation'>
              {GlobalService.uppercaseAndChangeValue(t(`pages.signup.${msgToShow}`), user.email)}
            </p>
          )}

          <div className={cx('button-container')}>
            {signUpError && showInputs ? (
              <p className={cx('error-text')}>{t(signUpError)}</p>
            ) : null}

            <Button
              type='primary'
              onClick={handleSubmitForm}
              disabled={!isButtonEnabled}
              fullWidth={true}
            >
              {GlobalService.uppercaseFirstLetter(t(`pages.signup.${okButtonText}`))}
            </Button>
          </div>

          <div className='flex justify-center gap-1'>
            <p className='forgot-text'>
              {GlobalService.uppercaseFirstLetter(t('pages.signup.haveAnAccount'))}?
            </p>
            <p className='forgot-link' onClick={() => navigate('/login')}>
              {GlobalService.uppercaseFirstLetter(t('pages.signup.login'))}
            </p>
          </div>
        </div>

        <div className={cx('image-container')}>
          <img src={Image} />
        </div>
      </div>

      <div className={cx('logo-container')}>
        <img src={Logo} style={{ width: '11rem' }} />
      </div>
    </div>
  );
};

export default Signup;
