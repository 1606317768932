import React, { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

interface Props {
    label: string;
    onChange: (e: object) => void;
    fileRequirements: string;
    emptyFile: string;
    fileName?: string;
    borderRadius?: string;
    isDisabled?: boolean;
}
const InputFile: React.FC<Props> = ({ label, onChange, fileRequirements, emptyFile, fileName = null, borderRadius, isDisabled }) => {
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            onChange(e.target.files[0]);
        }
    };

    return (
        <div
            className='appearance-none w-96 h-44  bg-white sm:px-10 border border-gray-200 leading-tight m-auto hover:bg-slate-50'
            style={{ borderRadius: borderRadius }}
        >
            <div className='text-center relative cursor-pointer p-6'>
                <FontAwesomeIcon icon={faUpload} className='opacity-50' />
                <h3 className='mt-3 '>{label}</h3>
                <p className='text-slate-400 text-xs mt-2'>{fileRequirements}</p>
                <div className='mt-4 mb-2 border border-dashed border-gray-200 rounded w-3/5 m-auto p-1'>
                    {fileName ? <p className='text-slate-600 '>{fileName}</p> : <p className='text-xs text-slate-400 '>{emptyFile}</p>}
                </div>
                <input
                    type='file'
                    onChange={handleFileChange}
                    disabled={isDisabled}
                    className='block absolute top-0 right-0 bottom-0 left-0 opacity-0 w-full h-full cursor-pointer'
                />
            </div>
        </div>
    );
};

export default InputFile;
