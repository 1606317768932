import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Input from '../components/Inputs/Input';
import { ButtonBase } from '../components/Buttons/button-base';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

// Helpers
import { useTranslation } from 'react-i18next';
import { validate } from '../hexagonal-architecture-frontend-base/src/domain/services/user.service';

// Interfaces
import {
  UserChangePassword,
  UserSignupErrors,
} from '../hexagonal-architecture-frontend-base/src/domain/models/User';

// CSS
import '../styles/Signup.css';

// Images
import Image from '../img/forgotPassword.png';
import Logo from '../img/logolandscape.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';

const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**********
   * States *
   **********/
  const [user, setUser] = useState<UserChangePassword>({ password: '', passwordConfirmation: '' });
  const [errorMessage, setErrorMessage] = useState<UserSignupErrors>({
    password: '',
    passwordsMatch: '',
  });
  const { viewportWidth } = useViewport();

  /************
   * Handlers *
   ************/
  const handleChange = (field: string, e: string) => {
    const errorToShow: string = inputsDefinition.filter(def => def.id === field)[0].error;
    const errorToSet =
      field !== 'dni' ? GlobalService.uppercaseFirstLetter(t(errorToShow)) : t(errorToShow);

    const isValid = validate(e, 'password');

    if (field !== 'passwordConfirmation') {
      if (e && !isValid) {
        setErrorMessage(error => ({ ...error, [field]: errorToSet }));
      } else {
        setErrorMessage(error => ({ ...error, [field]: '' }));
      }
      setUser(user => ({ ...user, [field]: e }));
    } else {
      if (user.password !== e) {
        setErrorMessage(error => ({ ...error, passwordsMatch: errorToSet }));
      } else {
        setErrorMessage(error => ({ ...error, passwordsMatch: '' }));
      }
      setUser(user => ({ ...user, passwordConfirmation: e }));
    }
  };

  const handleSubmit = () => {
    alert(GlobalService.uppercaseFirstLetter(t('pages.forgotPassword.passwordChanged')));
    navigate('/login');
  };

  /**************
   * JSX Values *
   **************/
  let isButtonEnabled = true;
  Object.keys(user).forEach(key => {
    if (!user[key as keyof UserChangePassword]) {
      isButtonEnabled = false;
    }
  });
  if (isButtonEnabled) {
    Object.keys(errorMessage).forEach(key => {
      if (errorMessage[key as keyof UserSignupErrors] !== '') {
        isButtonEnabled = false;
      }
    });
  }

  const inputsDefinition = [
    {
      id: 'password',
      text: 'password',
      error: 'errors.passwordInvalid',
      value: user.password,
      onChange: (e: string) => handleChange('password', e),
    },
    {
      id: 'passwordConfirmation',
      text: 'passwordconfirmation',
      error: 'errors.passwordsNotMatch',
      value: user.passwordConfirmation,
      onChange: (e: string) => handleChange('passwordConfirmation', e),
    },
  ];

  const userInputs = inputsDefinition.map((item, index) => {
    return (
      <div key={index} className={index ? 'mt-4' : ''}>
        <label htmlFor={item.id} className='block text-sm font-medium text-gray-700 purple-text'>
          {item.id !== 'dni'
            ? GlobalService.uppercaseFirstLetter(t(item.text))
            : t(item.text).toUpperCase()}
        </label>
        <div className='mt-1'>
          <Input
            id={item.id}
            name={item.id}
            placeholder={
              item.id !== 'dni'
                ? GlobalService.uppercaseFirstLetter(t(item.text))
                : t(item.text).toUpperCase()
            }
            type={item.id.toLowerCase().includes('password') ? 'password' : 'text'}
            value={item.value as string}
            customClass='login-input'
            onChange={item.onChange}
            enableShowPassword={item.id.toLowerCase().includes('password')}
          />
          {errorMessage ? (
            <p className='error-message my-2 mx-4'>
              {
                errorMessage[
                  item.id === 'passwordConfirmation'
                    ? 'passwordsMatch'
                    : (item.id as keyof UserSignupErrors)
                ]
              }
            </p>
          ) : null}
        </div>
      </div>
    );
  });

  /*******
   * JSX *
   *******/
  return (
    <div className='main-container-forgot-password'>
      <div className='logo-div'>
        <img src={Logo} />
      </div>
      <div className={viewportWidth >= 640 ? 'form-div' : 'form-div-forgot-password-small'}>
        <div
          className={
            viewportWidth >= 640 ? 'form-container-forgot-password' : 'form-container-small'
          }
        >
          <h2 className={`form-title${viewportWidth >= 640 ? '' : '-small'}`}>
            {GlobalService.uppercaseFirstLetter(t('pages.forgotPassword.changePassword'))}
          </h2>
          {userInputs}
          <div className='mt-14'>
            <ButtonBase
              text={GlobalService.uppercaseFirstLetter(t('pages.forgotPassword.changePassword'))}
              background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
              color={'white'}
              borderRadius={'50px'}
              onClick={handleSubmit}
              buttonWidth='100%'
              disabled={!isButtonEnabled}
            />
          </div>
        </div>
      </div>

      <div className='hidden left-image md:flex content-center'>
        <img src={Image} />
      </div>
    </div>
  );
};

export default ChangePassword;
