import { useSelectedPatientValue } from './use-selected-patient-value';
import { useSetSelectedPatient } from './use-set-selected-patient';

export const useSelectedPatient = () => {
  const { selectedPatient, pendingVisits, nonPendingVisits, error } = useSelectedPatientValue();
  const { setSelectedPatient, removeSelectedPatient } = useSetSelectedPatient();

  return {
    selectedPatient,
    pendingVisits,
    nonPendingVisits,
    error,
    setSelectedPatient,
    removeSelectedPatient
  };
};
