import { personasRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';
import { personasServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';
import { DownloadFileParams } from '../../../ts';

// TODO - Create the atom
export const downloadFileService = async ({
  module,
  moduleEntryId,
  fileId,
  fileName,
}: DownloadFileParams) => {
  const response = await personasServices(personasRepositoryInstance).getFile(
    module,
    moduleEntryId,
    fileId,
    fileName,
  );

  if (typeof response === 'string') throw new Error(response);

  const url = window.URL.createObjectURL(new Blob([response.data]));

  return url;
};
