import { FC, useState } from 'react';

// Components
import { ButtonBase } from '../Buttons/button-base';
import RegularSpinner from '../Spinner/RegularSpinner';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { tiendaRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { useTranslation } from 'react-i18next';

// Stripe
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

// Interfaces
import { IAddressData } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IAddressItem';
import {
  ISubscriptionRes,
  IUserDataPayment,
} from '../../hexagonal-architecture-frontend-base/src/domain/models/IPago';

// Hooks
import {
  useMakeQuotePaymentCheckout,
  useSelectedPatientValue,
  useSelectedQuoteValue,
  useViewport,
} from '../../hooks';

// CSS
import '../../styles/Tienda/Tienda.css';
import { PaymentIntent } from '@stripe/stripe-js';

interface Props {
  addressData: IAddressData;
  billingCycle: number;
  handlePaymentResult: (paymentIntent: PaymentIntent | string, subscriptionId?: string) => void;
  isLoading: boolean;
  isSubscription: boolean;
  orderId?: number;
  service?: string;
  totalAmount: number;
  userData: IUserDataPayment;
}

interface IEmptyInputs {
  card: boolean;
  cvc: boolean;
  date: boolean;
}

const PaymentForm: FC<Props> = ({
  addressData,
  billingCycle,
  handlePaymentResult,
  isLoading,
  isSubscription,
  orderId,
  service,
  totalAmount,
  userData,
}) => {
  const { t } = useTranslation();
  const elements = useElements();
  const formattedTotalAmount = (totalAmount * 1000) / 10;
  const stripe = useStripe();
  const { makeQuotePaymentCheckout } = useMakeQuotePaymentCheckout();
  const { selectedPatient } = useSelectedPatientValue();
  const { selectedQuote } = useSelectedQuoteValue();

  /**********
   * States *
   **********/
  const [inputCardError, setInputCardError] = useState<string>('');
  const [inputCVCError, setInputCVCError] = useState<string>('');
  const [inputDateError, setInputDateError] = useState<string>('');
  const [emptyInputs, setEmptyInputs] = useState<IEmptyInputs>({
    card: true,
    date: true,
    cvc: true,
  });
  const [showLoader, setShowLoader] = useState<boolean>(isLoading);
  const { viewportWidth } = useViewport();

  /************
   * Handlers *
   ************/
  const handleInputChange = (e: any) => {
    if (e.elementType === 'cardNumber') {
      if (e.error && e.error.message) setInputCardError(e.error.message);
      else if (e.elementType === 'cardNumber' && !e.error) setInputCardError('');

      if (e.empty === false) setEmptyInputs({ ...emptyInputs, card: false });
      else setEmptyInputs({ ...emptyInputs, card: true });
    }

    if (e.elementType === 'cardExpiry') {
      if (e.error && e.error.message) setInputDateError(e.error.message);
      else if (e.elementType === 'cardExpiry' && !e.error) setInputDateError('');

      if (e.empty === false) setEmptyInputs({ ...emptyInputs, date: false });
      else setEmptyInputs({ ...emptyInputs, date: true });
    }

    if (e.elementType === 'cardCvc') {
      if (e.error && e.error.message) setInputCVCError(e.error.message);
      else if (e.elementType === 'cardCvc' && !e.error) setInputCVCError('');

      if (e.empty === false) setEmptyInputs({ ...emptyInputs, cvc: false });
      else setEmptyInputs({ ...emptyInputs, cvc: true });
    }
  };

  const handleSubmit = () => {
    if (isSubscription) submitSubscriptionPayment();
    else submitPayment();
  };

  /*************
   * Helpers *
   *************/
  const submitSubscriptionPayment = async () => {
    setShowLoader(true);
    if (!stripe || !elements || !orderId) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          address: {
            city: addressData.Ciudad,
            line1: addressData.Direcci_n,
            postal_code: addressData.Codigo_postal,
          },
        },
      });

      if (!error) {
        const { id } = paymentMethod;
        await tiendaServices(tiendaRepositoryInstance)
          .checkoutPaymentSubscription({
            id,
            amount: formattedTotalAmount,
            billingCycle: billingCycle,
            userId: userData.id,
            orderId: orderId ? orderId : 0,
            stripeId: userData.stripeId,
          })
          .then(async (res: ISubscriptionRes | string) => {
            if (typeof res === 'string') {
              handlePaymentResult(res);
              return;
            }

            const confirmPayment = await stripe.confirmCardPayment(res.clientSecret, {
              payment_method: { card: cardElement },
            });
            const { paymentIntent, error } = confirmPayment;

            if (res.paymentStatus === 'succeeded')
              if (paymentIntent) handlePaymentResult(paymentIntent, res.subscriptionId);
              else {
                if (paymentIntent) handlePaymentResult(paymentIntent, res.subscriptionId);
                else if (error && error.code === 'card_declined')
                  handlePaymentResult('errors.cardPaymentError');
                else if (error && error.code === 'payment_intent_authentication_failure')
                  handlePaymentResult('errors.paymentConfirmationError');
              }
          });
      }
    }
    setShowLoader(false);
  };

  const submitPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return;
    }

    setShowLoader(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        address: {
          city: addressData.Ciudad,
          line1: addressData.Direcci_n,
          postal_code: addressData.Codigo_postal,
        },
      },
    });

    if (error) {
      if (error.code === 'incorrect_number') {
        setInputCardError(GlobalService.uppercaseFirstLetter(t('errors.wrongCardNumber')));
      }
      return;
    }

    const { id } = paymentMethod;

    if (selectedQuote == null) {
      setShowLoader(false);
      console.log('no selected quote');
      return;
    }

    makeQuotePaymentCheckout(
      {
        id,
        amount: formattedTotalAmount,
        orderId,
        serviceId: service,
        type: service?.length ? 'service' : 'product',
        patientName: selectedPatient.title,
        quoteId: selectedQuote.id,
        dealId: selectedPatient.dealDetails.id,
        dealName: selectedPatient.dealDetails.Deal_Name,
      },
      {
        onSuccess: async res => {
          const confirmPayment = await stripe.confirmCardPayment(res, {
            payment_method: { card: cardElement },
          });

          const { paymentIntent, error } = confirmPayment;

          if (error) {
            if (error.code === 'card_declined') handlePaymentResult('errors.cardPaymentError');
            else if (error.code === 'payment_intent_authentication_failure')
              handlePaymentResult('errors.paymentConfirmationError');
            return;
          }
          if (paymentIntent) handlePaymentResult(paymentIntent);
        },
        onError: error => {
          const { message } = error;
          handlePaymentResult(message);
        },
        onSettled: () => setShowLoader(false),
      },
    );
  };

  const isDisabled = () => {
    if (
      inputCardError ||
      inputDateError ||
      inputCVCError ||
      emptyInputs.card === true ||
      emptyInputs.date === true ||
      emptyInputs.cvc === true
    )
      return true;
  };

  /**************
   * JSX Values *
   **************/
  const isScreenTiny = viewportWidth < 321;

  const cardInput = (
    <div className='input-container-top'>
      <div className='card-payment-input'>
        <CardNumberElement onChange={e => handleInputChange(e)} />
      </div>
      {inputCardError ? <p className='error-message'>{inputCardError}</p> : null}
    </div>
  );

  const cvcInput = (
    <div className='input-container'>
      <label className='card-payment-label'>{t('pages.tienda.cvc').toUpperCase()}</label>
      <div className='card-payment-input w-20'>
        <CardCvcElement onChange={handleInputChange} />
      </div>
      {inputCVCError ? <p className='error-message'>{inputCVCError}</p> : null}
    </div>
  );

  const dateInput = (
    <div className='input-container'>
      <label className='card-payment-label'>
        {GlobalService.uppercaseFirstLetter(t('pages.tienda.expiryDate'))}
      </label>
      <div className='card-payment-input w-20'>
        <CardExpiryElement onChange={handleInputChange} />
      </div>
      {inputDateError ? <p className='error-message'>{inputDateError}</p> : null}
    </div>
  );

  const modalButton = (
    <div className='d-flex justify-center items-center'>
      <ButtonBase
        text='Pagar'
        background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
        color={'white'}
        borderRadius={'50px'}
        onClick={handleSubmit}
        disabled={isDisabled()}
      />
    </div>
  );

  return (
    <div>
      <div>
        <label className='card-payment-label'>
          {GlobalService.uppercaseFirstLetter(t('pages.tienda.cardNumber'))}
        </label>
        {cardInput}
        <div className={`card-inputs-flex-container${isScreenTiny ? '-Tiny' : ''}`}>
          {dateInput}
          {cvcInput}
        </div>
        <div className='flex justify-center w-full mt-6'>
          {showLoader ? <RegularSpinner /> : modalButton}
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
