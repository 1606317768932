import React from 'react';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

interface Props {
    date: string | number;
    type?: string;
    color?: string;
    font?: string;
    weight?: string;
}

export const Time: React.FC<Props> = ({ date, type = 'regular', color, font, weight }) => {
    const { hour, hours, time, minutes } = GlobalService.parseTime(date);
    const style = {
        color: color,
        fontSize: font,
        fontWeight: weight
    };
    switch (type) {
        case 'standard':
            return <div style={style}>{`${hours}:${minutes}`}</div>;
        case 'british':
            return <div style={style}>{`${hour}:${minutes} ${time}`}</div>;
        default:
            return <div style={style}>{`${hours}:${minutes}`}</div>;
    }
};
