import { Provider } from 'jotai';
import { globalStore } from './store';
import { QueryClientProviderGlobal } from './query-client-provider';
import { HydrateAtomsGlobal } from './hydrate-atoms';

export const GlobalProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <QueryClientProviderGlobal>
      <Provider store={globalStore}>
        <HydrateAtomsGlobal>{children}</HydrateAtomsGlobal>
      </Provider>
    </QueryClientProviderGlobal>
  );
};
