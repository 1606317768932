import React from 'react';
import { Toggle } from '../../components/Buttons/Toggle';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import useToggle from '../../hooks/useToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faCheck } from '@fortawesome/free-solid-svg-icons';

const elem = `interface Props {
    status?: boolean;
    onToggle?: () => void;
    bgTrue: string;
    bgFalse: string;
    left?: ReactNode | string;
    right?: ReactNode | string;
}
export const Toggle: React.FC<Props> = ({ status, onToggle, bgTrue, bgFalse, left, right }) => {
    return (
        <div className='px-3 py-2 text-center w-fit flex items-center '>
            {left ? <div className='pr-3'> {left}</div> : null}
            <div
                className={\`w-12 h-6 rounded-full top-0 left-0 cursor-pointer relative \${
                    status ? \`top-0 left-none right-0 border-none\` : \`\`
                } \${!left && !right ? 'px-4' : ''}\`}
                style={{ background: status ? bgTrue : bgFalse }}
                onClick={() => {
                    if (onToggle) onToggle();
                }}
            >
                <div className={\`w-4 h-4 rounded-full absolute bg-white top-1 \${status ? 'right-1' : 'left-1'}\`}></div>
            </div>
            {right ? <div className='pl-3'> {right}</div> : null}
        </div>
    );
};`;
const Toggles = () => {
    const a1 = useToggle(false);
    const a2 = useToggle(true);
    const a3 = useToggle(true);
    const a4 = useToggle(true);
    const a5 = useToggle(true);
    const a6 = useToggle(true);
    const a7 = useToggle(true);
    const cross = <FontAwesomeIcon icon={faX} />;
    const check = <FontAwesomeIcon icon={faCheck} />;
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Toggle</div>
            <div className='text-lg mt-4'>A toggle is an element that lets you interact with a true/false statement</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg'>This element can be used hand in hand with the hook useToggle:</div>
            <CopyBlock
                language={'jsx'}
                text={`import { useState } from 'react';

        const useToggle = (initialState = false) => {
            const [value, setValue] = useState(initialState);
        
            function toggle() {
                setValue(!value);
            }
        
            return { value, toggle };
        };
                
export default useToggle;
                `}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='text-lg mt-4'>The useToggle hook can be imported and used as following:</div>
            <CopyBlock
                language={'jsx'}
                text={`import useToggle from '../../hooks/useToggle';`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <CopyBlock
                language={'jsx'}
                text={`const t = useToggle();
const t = useToggle(false);`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='text-lg mt-4'>Different colors can be set</div>
            <CopyBlock
                language={'jsx'}
                text={`<Toggle status={t.value} onToggle={t.toggle} bgTrue='pink' bgFalse='#E3E3E3' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Toggle status={a1.value} onToggle={a1.toggle} bgTrue='pink' bgFalse='#E3E3E3' />
                <Toggle status={a2.value} onToggle={a2.toggle} bgTrue='indianred' bgFalse='#E3E3E3' />
                <Toggle status={a3.value} onToggle={a3.toggle} bgTrue='lightcoral' bgFalse='#E3E3E3' />
                <Toggle status={a4.value} onToggle={a4.toggle} bgTrue='lavender' bgFalse='gray' />
            </div>

            <div className='text-lg mt-4'>Text and or icons can be added to both left and right</div>
            <CopyBlock
                language={'jsx'}
                text={`<Toggle status={t.value} onToggle={t.toggle} bgTrue='pink' bgFalse='#E3E3E3' left={cross} right={check}/>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Toggle status={a5.value} onToggle={a5.toggle} bgTrue='olive' bgFalse='#E3E3E3' left={cross} right={check} />
                <Toggle status={a6.value} onToggle={a6.toggle} bgTrue='chocolate' bgFalse='#E3E3E3' left='Some text on the left' />
                <Toggle status={a7.value} onToggle={a7.toggle} bgTrue='darkcyan' bgFalse='#E3E3E3' right='Some text on the right' />
            </div>
        </div>
    );
};

export default Toggles;
