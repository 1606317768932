import { atom } from 'jotai';
import { QUERY_KEYS } from '../../../../../consts';
import { getPatientsQuotesService } from '../../../../../services';
import { ExtraInfoMutationProps, IdToPatientExtraInfo, PatientQuote } from '../../../../../ts';
import { getAtomWithMutation, getExtraInfoArray } from '../../../../../utils';

export const getPatientsQuotesAtom = getAtomWithMutation<
  IdToPatientExtraInfo<PatientQuote>,
  ExtraInfoMutationProps
>({
  mutationKey: QUERY_KEYS.PATIENTS.ALL_PATIENTS_QUOTES,
  mutationFn: getPatientsQuotesService,
});

export const allQuotesAtom = atom(get => {
  const getPatientsQuotes = get(getPatientsQuotesAtom);

  if (getPatientsQuotes.isPending || getPatientsQuotes.isError || getPatientsQuotes.data == null)
    return null;

  return getExtraInfoArray(getPatientsQuotes.data) ?? null;
});
