import { FC } from 'react';
import ModalInfo from '../ModalInfo';
import { IModalInfoProps } from '../../../hexagonal-architecture-frontend-base/src/domain/models/IModals';

interface ModalInfoErrorProps {
  errorText: string;
  onClickOk: IModalInfoProps['onClickOk'];
}

export const ModalInfoError: FC<ModalInfoErrorProps> = ({ errorText, onClickOk }) => {
  const modalErrorOptions: IModalInfoProps = {
    bodyText: `errors.${errorText}`,
    onClickOk: onClickOk,
    showXCloseButton: false,
    titleText: 'error'
  };

  return <ModalInfo {...modalErrorOptions} />;
};
