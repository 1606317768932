import { atom } from 'jotai';
import { PatientQuote } from 'src/ts';
import { selectAtom } from 'jotai/vanilla/utils';
import deepEquals from 'fast-deep-equal';
import { allQuotesAtom } from '../patients-atoms';
import { getQuoteProductsAtom } from './getters';

export const selectedQuoteIdAtom = atom<string | null>(null);

const selectedQuoteFinderAtom = atom<PatientQuote | null>(get => {
  const allReports = get(allQuotesAtom);
  const selectedReportId = get(selectedQuoteIdAtom);

  if (allReports == null) return null;

  const selectedReport = allReports.find(report => report.id === selectedReportId) ?? null;

  const getQuoteProducts = get(getQuoteProductsAtom);

  if (getQuoteProducts.isError || getQuoteProducts.data == null) return selectedReport;

  return { ...selectedReport, Articles: getQuoteProducts.data } as PatientQuote;
});

export const selectedQuoteAtom = selectAtom(selectedQuoteFinderAtom, atom => atom, deepEquals);
