import { useAtomValue } from 'jotai';
import { deletePatomediAtom } from '../../../store/global/atoms';

export const useDeletePatomedi = () => {
  const deletePatomedi = useAtomValue(deletePatomediAtom);

  return {
    deletePatomediResponse: deletePatomedi.data,
    deletePatomedi: deletePatomedi.mutate,
    deletePatomediAsync: deletePatomedi.mutateAsync,
    deletePatomediError: deletePatomedi.error,
    isLoading: deletePatomedi.isPending,
  };
};
