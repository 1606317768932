import React, { ReactNode, CSSProperties } from 'react';
import { useViewport } from '../../hooks/use-viewport';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  action?: () => void;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  borderShadow?: string;
  children: ReactNode;
  childrenExtraClass?: string;
  height?: number;
  id: string;
  numberItems?: number;
  padding?: string;
  topImage?: string;
  width?: number;
}
const Card: React.FC<Props> = ({
  action,
  backgroundColor,
  border,
  borderRadius,
  borderShadow,
  children,
  childrenExtraClass = '',
  height,
  id,
  numberItems,
  padding,
  topImage,
  width,
  ...rest
}) => {
  const { viewportWidth } = useViewport();

  const parentStyle: CSSProperties = {
    width: viewportWidth <= 1024 ? '100%' : numberItems ? 'auto' : width,
    height: 'auto',
    minHeight: height ? `${height}px` : 'auto',
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    padding: topImage ? '0' : padding,
    boxShadow: borderShadow ? borderShadow : 'none',
    border,
  };

  return (
    <div
      style={parentStyle}
      key={id}
      className='flex flex-col item-blog'
      onClick={() => {
        action?.();
      }}
      {...rest}
    >
      <div>
        {topImage ? (
          <div className='card-image'>
            <img
              src={topImage}
              width='100%'
              className='blog-image'
              style={{
                height: height ? height : '211',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
              }}
              alt='card image'
            />
          </div>
        ) : null}
      </div>
      <div
        className={`card-content ${childrenExtraClass}`}
        style={{ padding: topImage ? padding : '0' }}
      >
        {children}
      </div>
    </div>
  );
};
export default Card;
