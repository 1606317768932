//import { http } from '../plugins/http/http';
import { ListRepository } from '../../domain/repositories/list.repository';
import { getList, getListFibra, getCommentsList } from '../mocks/list.mock';
import { ListItem, ListItemSirion, ListItemFibra, ListItemComment } from '../../domain/models/List';
import { ListItemDTO, ListItemFibraDTO } from '../dto/listDTO';
import { getSirionList } from '../mocks/table.mock';
import { SirionItemDTO } from '../dto/tableDTO';
import { CommentDTO } from '../dto/commentDTO';

export const listRepository = (): ListRepository => ({
    getList: async () => {
        //const response = await http.get<any>('');
        const response: ListItemDTO[] = await getList<ListItemDTO[]>();
        return response.map(
            (item: ListItemDTO): ListItem => ({
                id: item.id,
                name: item.name,
                age: item.age,
                company: item.company,
                position: item.position
            })
        );
    },

    getListSirion: async () => {
        const response: SirionItemDTO[] = await getSirionList<SirionItemDTO[]>();
        return response.map(
            (item: SirionItemDTO): ListItemSirion => ({
                id: item.id,
                name: item.name,
                age: item.age,
                company: item.company,
                position: item.position,
                email: item.email,
                state: item.state
            })
        );
    },
    getListFibra: async (perPage: number, currentPage: number, sortValue?: string, sortOrder?: number, search?: string) => {
        const response: ListItemFibraDTO[] = await getListFibra<ListItemFibraDTO[]>(perPage, currentPage, sortValue, sortOrder, search);
        return response.map(
            (item: ListItemFibraDTO): ListItemFibra => ({
                id: item.id,
                name: item.name,
                nif: item.nif,
                email: item.email,
                address: item.address,
                phone: item.phone,
                state: item.state
            })
        );
    },
    getListComments: async (perPage: number, currentPage: number, sortValue?: string, sortOrder?: number, search?: string) => {
        const response: CommentDTO[] = await getCommentsList<CommentDTO[]>(
            perPage,
            currentPage,
            sortValue ? sortValue : undefined,
            sortOrder ? sortOrder : undefined,
            search ? search : undefined
        );
        return response.map(
            (item: CommentDTO): ListItemComment => ({
                id: item.id,
                date: item.date,
                user: item.user,
                reason: item.reason,
                comment: item.comment
            })
        );
    }
});
