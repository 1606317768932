import { useEffect } from 'react';
import { useSession, useSetPatients, useSettings } from '../../hooks';

export const InitialLoadsLogged = ({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { userInfo } = useSession();
  const { getAllPatients } = useSetPatients();
  const { getSettings } = useSettings();

  useEffect(() => {
    getAllPatients();
  }, [userInfo]);

  useEffect(() => {
    getSettings();
  }, []);

  return <>{children}</>;
};
