import React, { CSSProperties, useReducer } from 'react';
import { optionItem } from '../../hexagonal-architecture-frontend-base/src/domain/models/Input';
import { phonePrefix } from '../../hexagonal-architecture-frontend-base/src/domain/models/PhonePrefix';
import { inputServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/input.services';
import { Checkbox } from './Checkbox';
import { Radio } from './Radio';

interface Props {
    type: string;
    options?: Array<optionItem | phonePrefix>;
    selectedOptions: Array<optionItem | phonePrefix>;
    onChange: any;
    maxChoice?: number;
    isSelected?: boolean;
    selectedOptionBG?: string;
    borderRadius?: string;
    optionBG?: string;
    param: string;
    flexDirection?: CSSProperties['flexDirection'] | undefined;
    showInput?: boolean;
    inputColor?: string;
}

const InputCheckbox: React.FC<Props> = ({
    type,
    options,
    selectedOptions,
    onChange,
    maxChoice,
    optionBG,
    selectedOptionBG,
    borderRadius,
    param,
    flexDirection,
    showInput = true,
    inputColor = 'grey'
}) => {
    const inputsContainer: CSSProperties = {
        display: 'flex',
        flexDirection: flexDirection ? flexDirection : 'column',
        flexWrap: 'wrap',
        gap: '12px'
    };

    const filterArray: Array<optionItem | phonePrefix> = selectedOptions;
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const handleCheck = (option: optionItem | phonePrefix) => {
        if (isSelected(option[param as keyof typeof option])) {
            onChange(inputServices().handleOnChange(filterArray, option, false, true, false, 'label'));
        } else {
            onChange(inputServices().handleOnChange(filterArray, option, true, false, false, 'label', maxChoice));
        }
        forceUpdate();
    };

    const isSelected = (option: string) => {
        return inputServices().isSelected(option, filterArray, param);
    };
    return (
        <div>
            <div style={inputsContainer} className='w-auto hover:cursor-pointer'>
                {options?.map((option: optionItem | phonePrefix, index: number) => (
                    <div
                        key={index}
                        className='flex items-center'
                        onClick={() => handleCheck(option)}
                        style={{
                            background: inputServices().isSelected(option[param as keyof typeof option], filterArray, 'label')
                                ? selectedOptionBG
                                : optionBG,
                            borderRadius: borderRadius
                        }}
                    >
                        {showInput ? (
                            type === 'radio' ? (
                                <div className='-ml-4 -mr-6'>
                                    <Radio
                                        status={inputServices().isSelected(option[param as keyof typeof option], filterArray, 'label')}
                                        bgTrue={inputColor}
                                    />
                                </div>
                            ) : (
                                <div className='-mr-2'>
                                    <Checkbox
                                        status={inputServices().isSelected(option[param as keyof typeof option], filterArray, 'label')}
                                        bgTrue={inputColor}
                                    />
                                </div>
                            )
                        ) : null}
                        <p className='p-2' style={{ margin: showInput ? '' : 'auto' }}>
                            {option[param as keyof typeof option]}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InputCheckbox;
