const weekDays = () => {
  const lang = window.localStorage.getItem('lang');
  switch (lang) {
    case 'en':
      return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    case 'es':
      return ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    default:
      return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  }
};

const weekDaysAbb = () => {
  const lang = window.localStorage.getItem('lang');
  switch (lang) {
    case 'en':
      return ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];
    case 'es':
      return ['Lun', 'Mar', 'Miér', 'Juev', 'Vier', 'Sáb', 'Dom'];
    default:
      return ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];
  }
};

const months = () => {
  const lang = window.localStorage.getItem('lang');
  switch (lang) {
    case 'en':
      return ['January', 'February', 'March', 'April', 'Mai', 'June', 'July', 'September', 'October', 'November', 'December'];
    case 'es':
      return ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    default:
      return ['January', 'February', 'March', 'April', 'Mai', 'June', 'July', 'September', 'October', 'November', 'December'];
  }
};

const monthsAbb = () => {
  const lang = window.localStorage.getItem('lang');
  switch (lang) {
    case 'en':
      return ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Sep', 'Oct', 'Nov', 'Dec'];
    case 'es':
      return ['En', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Agto', 'Sept', 'Oct', 'Nov', 'Dic'];
    default:
      return ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Sep', 'Oct', 'Nov', 'Dec'];
  }
};

/**
 *
 * @param text strign
 * @returns string
 * Uppercases the first letter and leaves the rest as received
 */
const upperFirstLetter = (text: string): string => {
  const sentence = text.split('');
  return sentence[0] === '¿' || sentence[0] === '¡'
    ? sentence[0] + sentence[1].toUpperCase() + text.substring(2)
    : sentence[0].toUpperCase() + text.substring(1);
};

export class GlobalService {
  static reloadTopMenu = 0; // Changing the value of this will reload TopMenu component
  static reloadNotificationsEvery5Minutes = true;

  /**
   * Parses a date and returns every possible variable as an object
   * @param date The date to transform
   * @returns object
   */
  static parseDate(date: string | number) {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const weekDay = weekDays()[d.getDay() - 1];
    const weekDayAbb = weekDaysAbb()[d.getDay() - 1];
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const monthName = months()[d.getMonth()];
    const monthNameAbb = monthsAbb()[d.getMonth()];
    const year = d.getFullYear().toString();
    return { d, day, weekDay, weekDayAbb, month, monthName, monthNameAbb, year };
  }

  /**
   * Parses a date and adds more days, months or years if needed
   * @param date The date to transform
   * @param dayAux The day(s) to add to the date
   * @param monthAux The month(s) to add to the date
   * @param yearAux The year(s) to add to the date
   * @returns object
   */
  static addTimeToDate(date: string | number | Date, dayAux?: number, monthAux?: number, yearAux?: number) {
    let d = new Date();
    if (date !== '') d = new Date(date);
    if (dayAux) d = new Date(d.setDate(d.getDay() + dayAux));
    if (monthAux) d = new Date(d.setMonth(d.getMonth() + monthAux));
    if (yearAux) d = new Date(d.setFullYear(d.getFullYear() + yearAux));
    const day = d.getDate().toString().padStart(2, '0');
    const weekDay = weekDays()[d.getDay() - 1];
    const weekDayAbb = weekDaysAbb()[d.getDay() - 1];
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const monthName = months()[d.getMonth()];
    const monthNameAbb = monthsAbb()[d.getMonth()];
    const year = d.getFullYear().toString();
    return { d, day, weekDay, weekDayAbb, month, monthName, monthNameAbb, year };
  }

  /**
   * Parses a date and returns all time related variables as an object
   * @param date The date to transform
   * @returns object
   */
  static parseTime(date: string | number) {
    const d = new Date(date);
    const hour = (d.getHours() % 12).toString();
    const hours = d.getHours().toString().padStart(2, '0');
    const time = d.getHours() < 12 ? 'am' : 'pm';
    const minutes = d.getMinutes().toString().padStart(2, '0');
    return { hour, hours, time, minutes };
  }

  /**
   * Parses a date and returns the time passed until now
   * @param date The date to transform
   * @returns object
   */
  static parseTimeforNotifications(date: string | number) {
    const now = new Date();
    const d = new Date(date);
    const resta = now.getTime() - d.getTime();

    return resta;
  }

  /**
   * Uppercases the first character of each word after a point and the first letter of the sentence
   * @param data The sentence to transform
   * @returns string
   */
  static uppercaseAfterPointFirstsLetters(data: string): string {
    const sentence = data.toLowerCase().split('. ');
    for (let index = 0; index < sentence.length; index++) {
      sentence[index] = this.uppercaseFirstLetter(sentence[index]);
    }
    return sentence.join('. ');
  }

  /**
   * Uppercases the first character of each word of the sentence
   * @param data The sentence to transform
   * @returns string
   */
  static uppercaseAllFirstsLetters(data: string): string {
    const sentence = data.toLowerCase().split(' ');
    for (let index = 0; index < sentence.length; index++) {
      sentence[index] = this.uppercaseFirstLetter(sentence[index]);
      if (index < sentence.length - 1) sentence[index] += ' ';
    }
    return sentence.join('');
  }

  /**
   * Limits the sentence to the number of characters given
   * @param text The sentence to transform
   * @param value The number of characters allowed
   * @returns string
   */
  static uppercaseAllFirstLettersPlusReplace(text: string, replace: string): string {
    const sentence = text.toLowerCase().split(replace);
    for (let index = 0; index < sentence.length; index++) {
      sentence[index] = sentence[index][0].toUpperCase() + sentence[index].substring(1);
      if (index < sentence.length - 1) sentence[index] += '_';
    }
    return sentence.join('');
  }

  /**
   * Uppercases the first character of the sentence, leaving the rest of the sentence as it is
   * @param data The sentence to transform
   * @returns string
   */
  static uppercaseJustFirstLetter(data: string): string {
    return upperFirstLetter(data);
  }

  /**
   * Uppercases the first character of sentence and lowercases the rest of the sentence
   * @param data The sentence to transform
   * @returns string
   */
  static uppercaseFirstLetter(data: string, lowecaseRest = true): string {
    const fullText = lowecaseRest ? data.toLowerCase() : data;
    const sentence = fullText.split('');
    return sentence[0] === '¿' || sentence[0] === '¡'
      ? sentence[0] + sentence[1].toUpperCase() + fullText.substring(2)
      : sentence[0].toUpperCase() + fullText.substring(1);
  }

  /**
   * Uppercases the first character of sentence adding a link
   * @param data The sentence to transform
   * @param linkText The sentence for the link
   * @param link The link
   * @returns string
   */
  static uppercaseAndAddLink(data: string, linkText: string, link: string): string {
    const linkedSentence = upperFirstLetter(data).split(' ');
    let result = '';
    linkedSentence.forEach((sentence: string) => {
      if (sentence === '{{link}}') {
        sentence = `<a style="color: #0000ff;" href="${link}" >${linkText}</a>`;
        result += sentence + ' ';
      } else {
        result += sentence + ' ';
      }
    });
    return result;
  }

  /**
   * Uppercases the first character of sentence changing a {{value}}
   * @param data The sentence to transform
   * @param value The value to be replaced
   * @returns string
   */
  static uppercaseAndChangeValue(data: string, value: string): string {
    const dataAux = upperFirstLetter(data);
    return dataAux.replace('{{value}}', value);
  }

  /**
   * Limits the sentence to the number of characters given
   * @param text The sentence to transform
   * @param value The number of characters allowed
   * @returns string
   */
  static limitText(text: string, value: number): string {
    return text.substring(0, value) + (text.length > value ? '...' : '');
  }

  /**
   * Uppercases the first character of sentence and lowercases the rest of the sentence adding a link
   * @param date The date to transform
   * @param hourtime The literal used to define less than 1 hour
   * @param morehourtime The literal used to define X hours ago
   * @param at The literal
   * @returns string
   */
  static parseDateForNotification(date: string, hourtime: string, onehourtime: string, morehourtime: string, at: string): string {
    const timeAux = GlobalService.parseTimeforNotifications(date);
    if (timeAux < 1000 * 60 * 60) return hourtime;
    if (timeAux < 1000 * 60 * 60 * 2) return onehourtime;
    if (timeAux < 1000 * 60 * 60 * 24)
      return GlobalService.uppercaseAndChangeValue(morehourtime, Math.ceil(timeAux / (1000 * 60 * 60)).toString());
    const infoDate = GlobalService.parseDate(date);
    const infoTime = GlobalService.parseTime(date);
    return `${infoDate.day} ${infoDate.monthNameAbb}, ${infoDate.year} ${at} ${infoTime.hours}:${infoTime.minutes}`;
  }
}
