import styles from './documents-card-icon.module.css';
import classNames from 'classnames/bind';
import Add from '../../../../img/PersonaImg/plus.svg';
import Download from '../../../../img/PersonaImg/file-download.svg';
import { FC } from 'react';
import { DocumentCardIcon } from '../../../../ts';

const cx = classNames.bind(styles);

export const DocumentsCardIcon: FC<DocumentCardIcon> = ({ image, onClick, title }) => {
  const imageToUse = image === 'download' ? Download : Add;

  return (
    <img
      src={imageToUse}
      className={cx('document-card-icon')}
      onClick={onClick}
      title={title}
      alt='tratamiento'
    />
  );
};
