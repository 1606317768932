import { useAtomValue } from 'jotai';
import { loginAtom } from '../../store/global/atoms';

export const useLogin = () => {
  const login = useAtomValue(loginAtom);

  return {
    loginData: login.data,
    login: login.mutate,
    loginAsync: login.mutateAsync,
    isLoading: login.isPending,
    error: login.error,
  };
};
