import { useAtomValue } from 'jotai';
import { getUserAddressAtom, updateUserAddressAtom } from '../../../store/global/atoms';

export const useUserAddress = () => {
  const getUserAddress = useAtomValue(getUserAddressAtom);
  const updateUserAddress = useAtomValue(updateUserAddressAtom);

  const isLoading =
    getUserAddress.isLoading || getUserAddress.isFetching || getUserAddress.isPending;

  return {
    userAddress: getUserAddress.data,
    getUserAddress: getUserAddress.refetch,
    isLoading,
    isEmptyAndLoading: getUserAddress.data.id === '' && isLoading,
    error: getUserAddress.error,
    updateUserAddress: updateUserAddress.mutate,
    isUpdating: updateUserAddress.isPending,
    updateError: updateUserAddress.error,
  };
};
