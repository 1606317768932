// ! File not in use
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonBase } from '../components/Buttons/button-base';
import Title from '../components/Title';
import TopMenu from './Menus/TopMenu';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import '../styles/Personas/Personas.css';
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import PagoConsentimiento from '../img/pagoConsentimiento.png';
import { useViewport, usePatientsValue, useSelectedPatient, useSettings } from '../hooks';

const Visitas = () => {
  const { patientId, reportId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patients, errorReason } = usePatientsValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();

  /**********
   * States *
   **********/
  const [errorText, setErrorText] = useState<string>('');

  const { viewportWidth, viewportHeight } = useViewport();

  useEffect(() => {
    if (patients.length === 0) return;
    setSelectedPatient(patientId as string);
  }, [patients]);

  useEffect(() => {
    if (errorReason != null) {
      setErrorText(errorReason.message);
    }
  }, [selectedPatient]);

  const isScreenBig = viewportWidth > 768;
  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.mainTitle'))}:`;

  const desktopTopMenu = <TopMenu title={pageTitle} user={selectedPatient.title}></TopMenu>;
  const desktopTitle = (
    <div className='flex text-left'>
      <img
        onClick={() => {
          navigate(`/informeDetallado/${patientId}/${reportId}`);
        }}
        src={LeftArrow}
        alt='Back'
        className='Notificaciones-Arrow cursor-pointer'
      />
      <Title
        text={`${GlobalService.uppercaseFirstLetter(t('pages.pagos.signatureTitle'))}`}
        color='black'
        size={'24px'}
        margin={'0px 0px 0px 30px'}
      />
    </div>
  );

  const responsivePageTitle = GlobalService.uppercaseAllFirstsLetters(
    t('pages.pagos.signatureTitle'),
  );

  return (
    <div className={`w-full flex flex-col h-full ${isScreenBig ? '' : 'bg-white'}`}>
      {isScreenBig ? (
        <>
          {desktopTopMenu}
          <div className='w-full h-full m-8 flex flex-col justify-between'>
            {desktopTitle}
            <div className='w-full h-full flex flex-col gap-8 items-center justify-around'>
              <p className='text-center max-w-xl font-bold'>
                {`${GlobalService.uppercaseFirstLetter(t('pages.pagos.payTextInfo'))}`}
              </p>
              <img
                className='h-auto w-full max-w-xs'
                src={PagoConsentimiento}
                alt='pago_consentimiento_img'
              />
              <ButtonBase
                background='linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'
                color={'white'}
                border='1px solid #28337D'
                borderRadius='50px'
                fontSize='16px'
                onClick={() => {
                  navigate(`/informeDetallado/${patientId}/${reportId}/signature`);
                }}
                text={`${GlobalService.uppercaseFirstLetter(t('pages.pagos.payTextButton'))}`}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='w-full h-full flex flex-col justify-between'>
            <TopMenu
              title={responsivePageTitle}
              leftIcon={LeftArrowSmall}
              leftIconLink={`/informeDetallado/${patientId}/${reportId}`}
            ></TopMenu>
            <div
              className='mt-[85px] md:mt-0 3xs:mx-2 md:ml-10 md:mr-2 md:w-full'
              style={{ height: viewportHeight - 180 + 'px', overflow: 'scroll' }}
            >
              <div>
                <p className='text-left max-w-sm px-8 font-bold text-sm'>
                  {`${GlobalService.uppercaseFirstLetter(t('pages.pagos.payTextInfo'))}`}
                </p>
                <div className='flex flex-col items-center'>
                  <img
                    className='h-auto w-full max-w-xs'
                    src={PagoConsentimiento}
                    alt='pago_consentimiento_img'
                  />
                  <ButtonBase
                    background='linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'
                    color={'white'}
                    border='1px solid #28337D'
                    borderRadius='50px'
                    fontSize='16px'
                    onClick={() => {
                      navigate(`/informeDetallado/${patientId}/${reportId}/signature`);
                    }}
                    text={`${GlobalService.uppercaseFirstLetter(t('pages.pagos.payTextButton'))}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Visitas;
