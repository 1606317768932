import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

// CSS
import './Navbar.css';

// Images
import ayudaColor from '../../img/Menu/ayuda-color.png';
import ayudaGrey from '../../img/Menu/ayuda-grey.png';
import blogColor from '../../img/Menu/blog-color.png';
import blogGrey from '../../img/Menu/blog-grey.png';
import pagosColor from '../../img/Menu/pagos-color.png';
import pagosGrey from '../../img/Menu/pagos-grey.png';
import personasColor from '../../img/Menu/personas-color.png';
import personasGrey from '../../img/Menu/personas-grey.png';
import tiendaGrey from '../../img/Menu/tienda-grey.png';
import tiendaColor from '../../img/Menu/tienda-color.png';
import { LuraCareLogo } from '../logos';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { useSessionValue, useSettings } from '../../hooks';
import { AVAILABLE_SETTINGS } from '../../consts/settings/available-settings';

interface NavbarOptions {
  isVisible: boolean;
  isActive: boolean;
  to: string;
  icon: string;
  label: string;
}

const NavigationBar: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { getSettingKey } = useSettings();
  const fromAllPatients = localStorage.getItem('AllPatients');
  const activeStyle = {
    color: '#28337D',
    background: '#F5E1E580',
  };

  const style = {
    color: 'rgba(39, 50, 124, 0.49)',
  };
  const { isUserLogged, userInfo } = useSessionValue();
  const patientsAccess = isUserLogged ? userInfo?.patientsAccess : false;

  /**************
   * JSX Values *
   **************/
  const isMenuPersonasActive: boolean =
    location.pathname === '/allPatients' ||
    location.pathname === '/personas' ||
    location.pathname.includes('datosPersona') ||
    location.pathname.includes('datosDocumentos') ||
    location.pathname.includes('documentos') ||
    location.pathname.includes('facturas') ||
    location.pathname.includes('informeDetallado') ||
    location.pathname.includes('informes') ||
    location.pathname.includes('visitas');

  const isMenuBlogActive =
    location.pathname === '/user/blog' ||
    location.pathname === '/blog' ||
    location.pathname === '/';

  const isMenuShopActive: boolean =
    location.pathname === '/tienda' ||
    location.pathname.includes('carrito') ||
    location.pathname.includes('producto');

  const showMenuShop: boolean = getSettingKey(AVAILABLE_SETTINGS.HIDE_SHOP) === 'false';

  const isMenuPagosActive: boolean =
    location.pathname === '/pagos' ||
    location.pathname.includes('servicios') ||
    location.pathname.includes('subscripciones') ||
    location.pathname.includes('pedidos');

  const isMenuHelpActive = location.pathname === '/user/help' || location.pathname === '/help';

  const { viewportWidth } = useViewport();

  const isScreenBig = viewportWidth > 1400;

  const navbarOptions: NavbarOptions[] = [
    {
      isVisible: isUserLogged,
      isActive: isMenuPersonasActive,
      to: fromAllPatients ? '/allPatients' : '/personas',
      icon: isMenuPersonasActive ? personasColor : personasGrey,
      label: patientsAccess
        ? GlobalService.uppercaseFirstLetter(t('pages.personas.title'))
        : GlobalService.uppercaseFirstLetter(t('pages.profile.title')),
    },
    {
      isVisible: true,
      isActive: isMenuBlogActive,
      to: isUserLogged ? '/user/blog' : '/blog',
      icon: isMenuBlogActive ? blogColor : blogGrey,
      label: GlobalService.uppercaseFirstLetter(t('pages.blog.title')),
    },
    {
      isVisible: isUserLogged && showMenuShop,
      isActive: isMenuShopActive,
      to: '/tienda',
      icon: isMenuShopActive ? tiendaColor : tiendaGrey,
      label: GlobalService.uppercaseFirstLetter(t('pages.tienda.title')),
    },
    {
      isVisible: isUserLogged,
      isActive: isMenuPagosActive,
      to: '/pagos',
      icon: isMenuPagosActive ? pagosColor : pagosGrey,
      label: GlobalService.uppercaseFirstLetter(t('pedidos')),
    },
    {
      isVisible: true,
      isActive: isMenuHelpActive,
      to: isUserLogged ? '/user/help' : '/help',
      icon: isMenuHelpActive ? ayudaColor : ayudaGrey,
      label: GlobalService.uppercaseFirstLetter(t('help')),
    },
  ];

  /*******
   * JSX *
   *******/
  if (
    location.pathname === '/login' ||
    location.pathname === '/signup' ||
    location.pathname === '/forgotPassword' ||
    location.pathname.includes('resetPassword') ||
    location.pathname.includes('/verify') ||
    location.pathname === '/changePassword'
  ) {
    return null;
  }

  return (
    <>
      <div
        className='navbar_container h-screen text-white fixed md:relative md:block lg:w-1/6 flex md:flex-col'
        id='navbar'
      >
        <div className={`navbar_lc_logo_container w-${isScreenBig ? '72' : '56'} logo-padding`}>
          <LuraCareLogo className={{ svg: 'navbar_lc_logo' }} />
        </div>

        <div className='options_container mt-14'>
          <ul className='text-base font-medium flex flex-col justify-between gap-2 px-6'>
            {navbarOptions.map(option =>
              option.isVisible ? (
                <li key={option.to} className='mb-4'>
                  <NavLink
                    to={option.to}
                    className='option hover:text-gray-400 hover:bg-rose-300 flex gap-2 items-center h-12 px-2'
                    style={({ isActive }) => (isActive || option.isActive ? activeStyle : style)}
                  >
                    <div className='w-9'>
                      <img src={option.icon} className='m-auto' alt='' />
                    </div>
                    {option.label}
                  </NavLink>
                </li>
              ) : null,
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavigationBar;
