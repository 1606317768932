import { atomWithQuery } from 'jotai-tanstack-query';
import { QUERY_KEYS } from '../../../consts/query';
import { getSettingsService } from '../../../services';
import { Setting } from '../../../ts';
// import { getAtomWithMutation } from 'src/utils';

// export const getSettingsAtom = getAtomWithMutation<Setting[]>({
//   mutationKey: QUERY_KEYS.SETTINGS,
//   mutationFn: getSettingsService
// });

export const getSettingsAtom = atomWithQuery<Setting[]>(() => ({
  queryKey: [QUERY_KEYS.SETTINGS],
  queryFn: getSettingsService(),
  refetchInterval: false,
  refetchOnMount: true,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
  retry: 3,
  enabled: true,
  retryOnMount: true,
  retryDelay: 500,
  staleTime: Infinity
}));
