// CSS
import '../../styles/Login.css';
import styles from './login.module.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
// Components
import Input from '../../components/Inputs/Input';
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import { NavbarLogin } from '../../components/login';
import { LuraCareLogo } from '../../components/logos';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { http } from '../../hexagonal-architecture-frontend-base/src/infrastructure/plugins/http/http';
import { isValidEmail } from '../../hexagonal-architecture-frontend-base/src/domain/services/user.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { LoginErrors } from '../../hexagonal-architecture-frontend-base/src/domain/models/User';
import { UserLogin } from '../../hexagonal-architecture-frontend-base/src/domain/models/User';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { useSession, useShowErrorPopup } from '../../hooks';

import { Button } from '../../components';
import { useLogin } from '../../hooks';

const cx = classNames.bind(styles);

const Login = () => {
  // Constants
  const { t } = useTranslation();
  const navigate = useNavigate();
  // TODO - Implement useSession hook
  const { setAuth, setUserInfo } = useSession();
  const { viewportWidth } = useViewport();
  const { showErrorPopup } = useShowErrorPopup();
  const { login } = useLogin();

  const [errorMessage, setErrorMessage] = useState<LoginErrors>({
    validEmail: true,
    validPassword: true,
  });
  const [isTryingToLogin, setIsTryingToLogin] = useState<boolean>(false);
  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const [showResendButton, setShowResendButton] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('title');
  const [user, setUser] = useState<UserLogin>({ username: '', password: '' });
  const [usernameError, setUsernameError] = useState('');

  /************
   * Handlers *
   ************/

  const handleSendVerifyCode = () => {
    setUser({ username: '', password: '' });
    setUsernameError('');
    setShowResendButton(false);
    http
      .cleanPost('/users/resend-verification', { email: user.username })
      .then((response: any) => {
        if (response.status === 200 || response.status === 201) {
          setTitle('mailSent');
          setShowExplanation(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsTryingToLogin(true);

    login(user, {
      onSuccess: loginResponse => {
        setAuth(loginResponse.auth);
        setUserInfo(loginResponse.userInfo);
        navigate('/personas');
      },
      onError: error => {
        showErrorPopup(error.message);
      },
      onSettled: () => setIsTryingToLogin(false),
    });
  };

  const handleUnderstoodButton = () => {
    setTitle('title');
    setShowExplanation(false);
  };

  const handleValidation = (field: string, e: string) => {
    if (e) {
      setUsernameError('');
      if (field === 'username') {
        setErrorMessage(error => ({ ...error, validEmail: isValidEmail(e) }));
      } else {
        setErrorMessage(error => ({ ...error, validPassword: true }));
      }
    }
    setUser(user => ({ ...user, [field]: e }));
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t('pages.login.title'))}`;

  let isButtonEnabled = true;

  const fieldsDefinition = [
    {
      enableShowPassword: false,
      errorField: 'validEmail',
      errorMessage: 'errors.emailInvalid',
      id: 'username',
      placeholder: 'email',
      type: 'text',
    },
    {
      enableShowPassword: true,
      errorField: 'validPassword',
      errorMessage: 'errors.passwordInvalid',
      id: 'password',
      placeholder: 'password',
      type: 'password',
    },
  ];

  Object.keys(user).forEach(key => {
    if (!user[key as keyof UserLogin]) {
      isButtonEnabled = false;
    }
  });

  if (isButtonEnabled) {
    Object.keys(errorMessage).forEach(key => {
      if (!errorMessage[key as keyof LoginErrors]) {
        isButtonEnabled = false;
      }
    });
  }
  if (isButtonEnabled && usernameError !== '') {
    isButtonEnabled = false;
  }

  /****************
   * JSX Controls *
   ****************/
  const inputsFormatted = fieldsDefinition.map((item: any, index: number) => (
    <div className='login_input_container' key={index}>
      <label htmlFor={item.id} className='block text-sm font-medium text-gray-700 purple-text mb-4'>
        {GlobalService.uppercaseFirstLetter(t(item.placeholder))}{' '}
      </label>
      <div className='mt-1'>
        <Input
          enableShowPassword={item.enableShowPassword}
          id={item.id}
          name={item.id}
          placeholder={GlobalService.uppercaseFirstLetter(t(item.placeholder))}
          type={item.type}
          value={user[item.id as 'username' | 'password']}
          customClass='focus:hidden login-input'
          onChange={e => handleValidation(item.id, e)}
        />
        {item.enableShowPassword ? (
          <p
            className='flex forgot-link mt-2 justify-end'
            onClick={() => {
              navigate('/forgotpassword');
            }}
          >
            {GlobalService.uppercaseJustFirstLetter(t('pages.login.forgotPassword'))}
          </p>
        ) : null}
        {!errorMessage[item.errorField as 'validEmail' | 'validPassword'] ? (
          <p className='error-message my-2 mx-4'>
            {GlobalService.uppercaseFirstLetter(t(item.errorMessage))}
          </p>
        ) : null}
      </div>
    </div>
  ));

  const resendButton = (
    <div className={`space-y-6 ${viewportWidth >= 640 ? 'input-container-desktop' : ''}`}>
      <div className='md:mx-8'>
        <button
          className={'w-full flex justify-center login-button'}
          onClick={handleSendVerifyCode}
        >
          {GlobalService.uppercaseFirstLetter(t('pages.login.sendVerifyCode'))}
        </button>
      </div>
    </div>
  );

  /*******
   * JSX *
   *******/
  return (
    <div className='main_login_container' style={{ zIndex: 0 }}>
      <NavbarLogin />

      <div className='login_wrapper'>
        <div className='login_container'>
          <div className='video_container'>
            <video
              autoPlay
              muted
              playsInline
              className={'video'}
              loop
              src={'https://luracare.es/wp-content/uploads/2023/09/AdobeStock_240914212.mov'}
              // data-object-fit='cover'
            />
          </div>

          <div className='login_textContent_container'>
            {showExplanation ? (
              <div className={`space-y-6 ${viewportWidth > 640 ? 'input-container-desktop' : ''}`}>
                <div className='md:mx-8'>
                  <div className='md:mx-8'>
                    <p className='form-explanation'>
                      {t('pages.signup.explanation', { value: 'email bro' })}
                    </p>
                    <button
                      className={'w-full flex justify-center login-button'}
                      onClick={handleUnderstoodButton}
                    >
                      {GlobalService.uppercaseFirstLetter(t('pages.login.understood'))}
                    </button>
                    <button
                      className={'w-full flex justify-center login-button'}
                      onClick={handleSendVerifyCode}
                    >
                      {GlobalService.uppercaseFirstLetter(t('pages.login.sendAgain'))}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='login_title_container'>
                  {/* {!isScreenBig && showLogoOnMobile ? (
                    <img className='w-2/5' src={LogoNoText} />
                  ) : null} */}
                  <LuraCareLogo className={{ svg: 'lura_care_logo' }} hideFooterText={true} />
                  <h2 className='login-text'>
                    {GlobalService.uppercaseFirstLetter(t(`pages.login.${title}`))}
                  </h2>
                </div>

                <div className='login_form_container'>
                  <form className='login_form' onSubmit={handleSubmit}>
                    {usernameError ? (
                      <div className='text-red-500 text-sm'>{t(usernameError)}</div>
                    ) : null}
                    {inputsFormatted}
                    <div>
                      <Button type='primary' disabled={!isButtonEnabled} fullWidth={true}>
                        {isTryingToLogin ? (
                          <RegularSpinner size='24px' border='5px' />
                        ) : (
                          GlobalService.uppercaseFirstLetter(t('pages.login.title'))
                        )}
                      </Button>

                      <div className='flex flex-row justify-center gap-1 mt-3'>
                        <p className='forgot-text pr-2'>
                          {GlobalService.uppercaseFirstLetter(t('pages.login.noAccount'))}?
                        </p>
                        <p className='forgot-link' onClick={() => navigate('/signup')}>
                          {GlobalService.uppercaseFirstLetter(t('pages.signup.register'))}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>

                {showResendButton ? resendButton : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
