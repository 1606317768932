import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { ButtonBase } from '../components/Buttons/button-base';
import ModalInfo from '../components/Modals/ModalInfo';
import { Pagination } from '../../src/components/Pagination';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import TopMenu from './Menus/TopMenu';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IModalInfoProps } from '../hexagonal-architecture-frontend-base/src/domain/models/IModals';
import { IPatientVisit } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IPersona';

// CSS
import '../styles/Personas/Personas.css';

// Images
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';
import { usePatientsValue, useSelectedPatient } from '../hooks';
import { AvailableSetting } from '../ts';

const Visitas = () => {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patients, visitsError, isLoading } = usePatientsValue();
  const {
    selectedPatient,
    pendingVisits,
    nonPendingVisits,
    setSelectedPatient,
    error: selectedPatientError,
  } = useSelectedPatient();

  /**********
   * States *
   **********/
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageDone, setCurrentPageDone] = useState(1);
  const [errorText, setErrorText] = useState<string>('');
  const [errorTitle, setErrorTitle] = useState<string>('error');
  const [longView, setLongView] = useState<string[]>([]);

  const [showPendingVisits, setShowPendingVisits] = useState<boolean>(true);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const { viewportWidth } = useViewport();

  const visitas = (showPendingVisits ? pendingVisits : nonPendingVisits) ?? [];

  console.log('pendingVisits', pendingVisits);

  console.log('nonPendingVisits', nonPendingVisits);

  useEffect(() => {
    if (visitsError != null) {
      setErrorText(visitsError.message);
      setErrorTitle('information');
      setShowErrorModal(true);
    }
  }, [visitsError]);

  /*********
   * Hooks *
   *********/

  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patients]);

  /************
   * Handlers *
   ************/
  const handleOnCloseInfoModal = () => {
    setShowErrorModal(false);
    setErrorTitle('error');
  };

  const handlePagination = (e: number) => {
    if (showPendingVisits) setCurrentPage(e);
    else setCurrentPageDone(e);
    // setLoading(true);
  };

  const handleRequestServiceClick = (key: AvailableSetting) => {
    navigate('/user/help');
    // const link = getSettingKey(key);
    // if (link && link.length && link !== 'settingNotFound') window.open(link, '_blank');
    // else {
    //   setErrorText('urlRedirectionNotFound');
    //   setShowErrorModal(true);
    // }
  };

  /***********
   * Helpers *
   ***********/
  const setVisitVisibility = (index: string) => {
    if (longView.includes(index)) {
      setLongView(longView.filter(item => item !== index));
    } else {
      setLongView([...longView, index]);
    }
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const isScreenTiny = viewportWidth < 321;

  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('historyOf'))}:`;
  const pendingLabel = GlobalService.uppercaseFirstLetter(t('pending'));
  const doneLabel = GlobalService.uppercaseFirstLetter(t('done'));
  const noData = <p>{GlobalService.uppercaseAllFirstsLetters(t('nodata'))}</p>;

  const pendingDoneLabels = (
    <div className={`flex flex-row Personas-Title space-between`}>
      {showPendingVisits ? (
        <div>
          <p className={`cursor-pointer ${isScreenBig ? 'ml-2' : isScreenTiny ? 'ml-4' : 'ml-11'}`}>
            {pendingLabel}
          </p>
          <div
            className={`Personas-Visitas-Menu-Selected ${
              isScreenBig ? '' : isScreenTiny ? 'ml-2' : 'ml-5'
            }`}
          />
        </div>
      ) : (
        <p
          className={`cursor-pointer ${isScreenBig ? 'ml-2' : isScreenTiny ? 'ml-4' : 'ml-11'}`}
          onClick={() => setShowPendingVisits(true)}
        >
          {pendingLabel}
        </p>
      )}
      {!showPendingVisits ? (
        <div>
          <p className={`${isScreenTiny ? 'ml-14' : '3xs:ml-20 md:ml-6'} cursor-pointer`}>
            {doneLabel}
          </p>
          <div
            className={`Personas-Visitas-Menu-Selected${isScreenTiny ? '-Tiny' : ''} ${
              isScreenBig ? 'ml-3' : isScreenTiny ? 'ml-12' : 'ml-10'
            }`}
          />
        </div>
      ) : (
        <p
          className={`${isScreenTiny ? 'ml-14' : '3xs:ml-20 md:ml-6'} cursor-pointer`}
          onClick={() => setShowPendingVisits(false)}
        >
          {doneLabel}
        </p>
      )}
    </div>
  );
  const requestServiceButton = (
    <ButtonBase
      text={GlobalService.uppercaseFirstLetter(t('pages.visit.requestService'))}
      background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
      color={'white'}
      borderRadius={'50px'}
      extraClass={`w-full ${isScreenBig ? '' : 'mb-4'}`}
      onClick={() => handleRequestServiceClick('solicitarServicioSurvey')}
    />
  );
  const visitasTitlesValues = [
    ['date', 'Fecha_de_la_visita'],
    ['specialist', 'Doctor'],
    ['type', 'Tipo_de_visita'],
    ['treatment', 'Tratamiento'],
    ['status', 'Estado_de_la_visita'],
    ['comments', 'Notas_del_dentista'],
  ];
  const visitaButtons = (
    <div className='flex flex-row gap-4 mt-6 w-full'>
      <ButtonBase
        background={'white'}
        border={'1px solid #28337D'}
        borderRadius={'50px'}
        color={'#28337D'}
        extraClass={'w-full'}
        fontSize={isScreenBig ? '16px' : '10px'}
        minWidth={`${isScreenBig ? '' : 'auto'}`}
        text={GlobalService.uppercaseFirstLetter(t('pages.visit.requestCancellation'))}
        onClick={() => handleRequestServiceClick('solicitarCancelacionSurvey')}
      />
      <ButtonBase
        background={'#28337D'}
        borderRadius={'50px'}
        color={'white'}
        extraClass='w-full'
        fontSize={isScreenBig ? '16px' : '10px'}
        minWidth={`${isScreenBig ? '' : 'auto'}`}
        text={GlobalService.uppercaseFirstLetter(t('pages.visit.changeVisit'))}
        onClick={() => handleRequestServiceClick('cambiarVisitaSurvey')}
      />
    </div>
  );

  const formattedLongVisita = (visita: IPatientVisit) => {
    return visitasTitlesValues.map((item: string[], index: number) => {
      const value =
        item[1] === 'Doctor'
          ? visita.Doctor.name
          : item[1] === 'Tratamiento'
          ? visita.Tratamiento
          : GlobalService.uppercaseFirstLetter(t('pages.visit.nodata'));
      return (
        <div key={`field${index}`} className={index ? 'mt-3' : ''}>
          <p className='Personas-Visitas'>
            {GlobalService.uppercaseFirstLetter(t(`pages.visit.${item[0]}`))}:{' '}
          </p>
          <p className={`${isScreenBig ? '' : 'text-base'}`}>
            <strong>{value}</strong>
          </p>
        </div>
      );
    });
  };

  const formattedVisitas =
    visitas.length > 0
      ? visitas.map((visita: IPatientVisit, visitaIndex: number) => (
          <div
            key={visita.VisitId}
            className={`bg-white p-4 relative ${
              isScreenBig
                ? 'w-full rounded-md'
                : 'Personas-Card-Gray rounded-lg ml-2 mb-3 mr-2 drop-shadow-lg text-left text-sm'
            }`}
          >
            {formattedLongVisita(visita)}

            {isScreenBig && showPendingVisits
              ? visitaButtons
              : longView.includes(visita.VisitId)
              ? visitaButtons
              : ''}

            {isScreenBig || !showPendingVisits ? (
              ''
            ) : (
              <div className='text-center p-3 underline underline-offset-4'>
                <p onClick={() => setVisitVisibility(visita.VisitId)}>
                  {GlobalService.uppercaseFirstLetter(
                    t(longView.includes(visita.VisitId) ? 'seeLess' : 'seeMore'),
                  )}
                </p>
              </div>
            )}
          </div>
        ))
      : noData;

  // Desktop values
  const desktopTopMenu = <TopMenu title={pageTitle} user={selectedPatient.title}></TopMenu>;
  const desktopTitle = (
    <div className='flex text-left'>
      <img
        onClick={() => {
          navigate(`/datosDocumentos/${patientId}/1`);
        }}
        src={LeftArrow}
        alt='Back'
        className='Notificaciones-Arrow cursor-pointer'
      />
      <Title
        text={`${GlobalService.uppercaseFirstLetter(t('pages.visit.title'))}:`}
        color='black'
        size={'24px'}
        margin={'0px 0px 0px 30px'}
      />
    </div>
  );

  const modalErrorOptions: IModalInfoProps = {
    bodyText: `errors.${errorText}`,
    onClickOk: handleOnCloseInfoModal,
    showXCloseButton: false,
    titleText: errorTitle,
  };

  const modalError = <ModalInfo {...modalErrorOptions} />;

  // Responsive values
  const responsivePageTitle = GlobalService.uppercaseAllFirstsLetters(
    t('pages.visit.responsiveTitle'),
  );

  return (
    <div className={`w-full ${isScreenBig ? '' : 'bg-white'}`}>
      {isScreenBig ? (
        <>
          {desktopTopMenu}
          <div className='w-full p-8'>
            {desktopTitle}
            <div className='grid border-b mt-6 mb-9 w-1/2 Personas-Visitas-Menu'>
              {pendingDoneLabels}
              <div className='absolute right-8 w-1/5'>{requestServiceButton}</div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6 w-full'>
              {isLoading ? <RegularSpinner /> : visitas?.length ? formattedVisitas : noData}
            </div>
            {totalPages > 1 && showPendingVisits ? (
              <div className='m-auto'>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onChange={handlePagination}
                />
              </div>
            ) : null}
            {totalPages > 1 && !showPendingVisits ? (
              <div className='m-auto'>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPageDone}
                  onChange={handlePagination}
                />
              </div>
            ) : null}
          </div>
          {showErrorModal ? modalError : null}
        </>
      ) : (
        <>
          <TopMenu
            title={responsivePageTitle}
            leftIcon={LeftArrowSmall}
            leftIconLink={`/datosDocumentos/${patientId}/1`}
          ></TopMenu>
          <div className='ml-1 mr-1 mb-36 mt-36 bg-white'>
            <div
              className={`grid border-b top-16 ${
                isScreenTiny ? 'pt-8' : 'pt-6'
              } z-10 fixed mb-2 bg-white w-full Personas-Visitas-Menu`}
            >
              {pendingDoneLabels}
            </div>
            <div className='visitas-height-mobile 3xs:mx-2 3xs:mt-14 md:ml-10 md:mr-2 md:w-full'>
              <div className='w-full'>
                {isLoading ? (
                  <div className='bg-white p-4 mt-36'>
                    <RegularSpinner />
                  </div>
                ) : visitas?.length ? (
                  formattedVisitas
                ) : (
                  noData
                )}
              </div>
              {isLoading ? null : (
                <div className='ml-2 mr-2 mt-4 drop-shadow-lg'>{requestServiceButton}</div>
              )}
            </div>
            {totalPages > 1 && showPendingVisits ? (
              <div className='m-auto'>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onChange={handlePagination}
                />
              </div>
            ) : null}
            {totalPages > 1 && !showPendingVisits ? (
              <div className='m-auto'>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPageDone}
                  onChange={handlePagination}
                />
              </div>
            ) : null}
          </div>
          {showErrorModal ? modalError : null}
        </>
      )}
    </div>
  );
};

export default Visitas;
