import { createAndClickTemporaryLink } from '../../../../../utils';
import { QUERY_KEYS } from '../../../../../consts';
import { downloadPatientAttachedFileService } from '../../../../../services';
import { getAtomWithMutation } from '../../../../../utils';

export const downloadPatientAttachedFileAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.PATIENTS.DOWNLOAD_ATTACHED_FILE,
  mutationFn: () => downloadPatientAttachedFileService,
  onSuccess: () => (data, params) => createAndClickTemporaryLink(data, params.fileName),
});
