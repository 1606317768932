import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';

interface Props {
    onclick?: () => void;
}

export const PlayStore: React.FC<Props> = ({ onclick }) => {
    const { t } = useTranslation();
    return (
        <div
            className='px-3 py-4 rounded-xl border-2 border-solid text-center text-white gap-3 flex items-center cursor-pointer opacity-100 bg-black hover:opacity-75'
            style={{ height: 53.56, width: 165.09, borderColor: '#A6A6A6' }}
            onClick={() => {
                if (onclick) onclick();
            }}
        >
            <div className='w-1/4 text-2xl items-center'>
                <FontAwesomeIcon icon={faGooglePlay} />
            </div>
            <div className='w-3/4 flex flex-col items-start '>
                <p className='uppercase mb-0' style={{ fontSize: '0.7rem', lineHeight: 1 }}>
                    {t('playStoreAvailable')}
                </p>
                <p className='mt-0' style={{ fontSize: '1rem', lineHeight: 1 }}>
                    Play Store
                </p>
            </div>
        </div>
    );
};
