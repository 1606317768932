import { updateUserAddressService } from '../../../../../services';
import { QUERY_KEYS } from '../../../../../consts';
import { getAtomWithMutation } from '../../../../../utils';
import { queryClientGlobal } from '../../../../../store/global/query-client';
import { AddressData, AddressUpsert } from '../../../../../ts';
import { getUserAddressAtom } from '../get';
import { userInfoAtom } from '../../session-atom';

export const updateUserAddressAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.PAYMENT.USER.UPDATE_ADDRESS,
  mutationFn: get => async (newAddress: AddressData) => {
    const tutorId = get(userInfoAtom)?.id;

    if (tutorId == null) {
      throw new Error('errors.addressesNotFound');
    }

    const newAddressUpsert: AddressUpsert = {
      userId: tutorId,
      name: newAddress.Name,
      direction: newAddress.Direcci_n,
      cp: newAddress.Codigo_postal,
      city: newAddress.Ciudad,
      pais: newAddress.Pa_s,
    };

    const response = await updateUserAddressService(newAddressUpsert);
    return response;
  },
  onMutate: get => async newAddress => {
    await queryClientGlobal.cancelQueries({ queryKey: [QUERY_KEYS.PAYMENT.USER.ADDRESS] });

    const currentAddress = get(getUserAddressAtom).data;

    queryClientGlobal.setQueryData(
      [QUERY_KEYS.PAYMENT.USER.ADDRESS],
      (oldAddress: AddressData): AddressData => {
        return {
          ...newAddress,
          id: oldAddress.id,
        };
      },
    );

    return currentAddress;
  },
  onError: () => (error, newAddress, context) => {
    queryClientGlobal.setQueryData([QUERY_KEYS.PAYMENT.USER.ADDRESS], context);
  },
});
