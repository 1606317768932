import { getProductByIdService, getQuoteByIdService } from '../../services';
import { ProductShort, QuoteIdentifier } from '../../ts';

export const getQuoteProducts = async ({ quoteId }: QuoteIdentifier) => {
  const quote = await getQuoteByIdService({ quoteId });

  const quoteProducts = quote.Quoted_Items;

  const productsPromises = quoteProducts.map(item =>
    getProductByIdService({ productId: item.Product_Name.id })
  );

  const products = await Promise.all(productsPromises);

  const formattedProducts: ProductShort[] = quoteProducts.map((item, index) => {
    const fetchedProduct = products[index];
    return {
      description: fetchedProduct.Texto_del_Informe,
      id: fetchedProduct.id,
      name: item.Product_Name.name,
      price: item.Total_After_Discount,
      isSelected: true,
      isShowingDescription: false
    };
  });

  return formattedProducts;
};
