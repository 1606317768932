import { useShowErrorPopup } from './use-show-error-popup';

export const useShowSettingsErrorPopup = () => {
  const { showErrorPopup } = useShowErrorPopup();

  const showSettingsErrorPopup = () => {
    showErrorPopup('errors.settingNotFound');
    // showErrorPopup('errors.settingsError');
  };

  const showSingleSettingErrorPopup = () => {
    showErrorPopup('errors.settingNotFound');
  };

  return { showSettingsErrorPopup, showSingleSettingErrorPopup };
};
