import styles from './patient-card.module.css';
import { FC } from 'react';
import { PatientInfo } from '../../../ts';
import Persona from '../../../img/PersonaImg/persona.png';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSetSelectedPatient } from '../../../hooks';

const cx = classNames.bind(styles);

interface PatientCardProps {
  patientInfo: PatientInfo;
}

export const PatientCard: FC<PatientCardProps> = ({ patientInfo }) => {
  const { t } = useTranslation();
  const { id, title, residence, pendingDocs, pendingReports, pendingVisits } = patientInfo;
  const { setSelectedPatient } = useSetSelectedPatient();

  const handlePatientClck = (id: string) => {
    localStorage.setItem('AllPatients', 'true');
    setSelectedPatient(id);
  };

  const hasPendingActions = pendingDocs || pendingReports || pendingVisits;

  return (
    <Link
      to={`/datosPersona/${id}`}
      onClick={() => {
        handlePatientClck(id);
      }}
      className={cx('personas-card', 'personas-card--border', {
        'personas-card--selected': hasPendingActions
      })}
    >
      <div className={cx('text-container')}>
        <img src={Persona} className='w-10 h-10 mr-2' alt='persona image' />
        <div>
          <p className={cx('personas-text', 'personas-text--title')}>{title}</p>
          <p className={cx('personas-text', 'peronas-text--subtitle')}>{residence}</p>
          <p className={cx('mt-3', 'personas-text', 'personas-text--extra')}>
            {hasPendingActions
              ? GlobalService.uppercaseFirstLetter(t('pages.personas.pendingProcedures'))
              : ''}
          </p>
        </div>
      </div>
      {hasPendingActions ? <div className='w-3 h-3 rounded-full bg-red-600'></div> : null}
    </Link>
  );
};
