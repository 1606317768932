import styles from './button.module.css';
import { FC } from 'react';
import { ButtonBase } from '../button-base';
import { ButtonProps } from '../../../ts';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const typeToBackground = {
  primary: 'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)',
  secondary: 'none',
  error: 'none',
};

const typeToColor = {
  primary: '#fff',
  secondary: '#28337d',
  error: '#FC0E0E',
};

const typeToBorder = {
  primary: '1px solid #30357B',
  secondary: '1px solid #28337d',
  error: '1px solid #FC0E0E',
};

export const Button: FC<ButtonProps> = ({
  children,
  type,
  isLoading = false,
  onClick,
  disabled = false,
  fullWidth = false,
  className = '',
  ...rest
}) => {
  const background = typeToBackground[type];
  const color = typeToColor[type];
  const border = typeToBorder[type];

  return (
    <ButtonBase
      onClick={onClick}
      background={background}
      color={color}
      border={border}
      paddingX={5}
      paddingY={10}
      borderRadius='15px'
      loading={isLoading}
      disabled={disabled}
      buttonWidth={fullWidth ? '100%' : undefined}
      className={cx(className, 'button')}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
};
