import React, { CSSProperties } from 'react';
import '../../styles/Loaders.css';
import { tableService } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/table.services';

interface Props {
    minHeight?: string;
    fixedWidth?: string;
    minWidth?: number;
    maxWidth?: number;
    background?: string;
    borderRadius?: string;
}

const PulsatingLoader: React.FC<Props> = ({
    minHeight = '18px',
    fixedWidth = false,
    background = '#e2e8f0',
    borderRadius = '15px',
    minWidth = 60,
    maxWidth = 100
}) => {
    const loadingStyle: CSSProperties = {
        background: background,
        height: '100%',
        minHeight: minHeight,
        borderRadius: borderRadius,
        marginTop: '3px',
        marginBottom: '3px'
    };
    return (
        <div
            className='animate-pulse'
            style={{
                ...loadingStyle,
                width: `${fixedWidth ? fixedWidth + 'px' : tableService().randomIntFromInterval(minWidth, maxWidth) + 'px'}`
            }}
        >
            <span></span>
        </div>
    );
};

export default PulsatingLoader;
