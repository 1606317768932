import { useEffect, useState } from 'react';

// Components
import Input from '../components/Inputs/Input';
import ModalInfo from '../components/Modals/ModalInfo';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import TopMenu from './Menus/TopMenu';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Personas/Personas.css';

// Images
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Hooks
import { useViewport } from '../hooks/use-viewport';
import { usePatientsPagination, usePatientsValue } from '../hooks';
import { PatientCard } from '../components/patients';

const AllPatients = () => {
  const { t } = useTranslation();
  const { isLoading: isPersonasLoading } = usePatientsValue();
  const { patientsToShow, searchString, setSearchString, ref } = usePatientsPagination();
  const { viewportWidth } = useViewport();

  // TODO - remove in the future
  localStorage.removeItem('AllPatients');
  localStorage.removeItem('tot');

  /************
   * Handlers *
   ************/
  const handleChangeSearchString = (e: string) => {
    setSearchString(e);
  };

  /***************
   *  JSX Values *
   ***************/
  const isScreenBig = viewportWidth > 768;

  return (
    <div className='w-full h-screen '>
      <TopMenu title={GlobalService.uppercaseAllFirstsLetters(t('people'))} />
      <div className='patients-list'>
        <div className='patients-list__container'>
          <label
            className={`search-input-container flex items-center bg-white min-w-min cursor-text ${
              isScreenBig ? '' : 'mt-20'
            }`}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <Input
              id='searchInput'
              type='text'
              placeholder={GlobalService.uppercaseAllFirstsLetters(t('search'))}
              value={searchString}
              customClass='search-input'
              name='searchInput'
              onChange={handleChangeSearchString}
            />
          </label>

          {isPersonasLoading ? (
            <RegularSpinner />
          ) : patientsToShow.length ? (
            <>
              <div className='grid 3xs:grid-cols-0 md:grid-cols-2 3xs:gap-2 mt-4 md:gap-x-8 md:gap-y-2'>
                {patientsToShow.map(item => (
                  <PatientCard key={item.id} patientInfo={item} />
                ))}
              </div>
              <div ref={ref}></div>
            </>
          ) : (
            <p>{GlobalService.uppercaseAllFirstsLetters(t('nodata'))}</p>
          )}
        </div>
      </div>
      {/* <div
        className={`absolute flex items-center justify-center gap-2 inset-x-0 w-full Personas-Footer`}
        style={{ bottom: isScreenBig ? '2rem' : '6rem', left: isScreenBig ? '9rem' : '0' }}
      >
        {paginator}
      </div> */}
    </div>
  );
};

export default AllPatients;
