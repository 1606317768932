import React from 'react';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import NavigationBar from '../../components/Navigation/Navbar';

const elem = `const NavigationBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const activeStyle = {
        color: '#D74121',
        background: 'white',
        padding: '5px 20px',
        borderRadius: '15px'
    };

    const style = {
        color: 'white',
        background: '#E17159',
        padding: '5px 20px',
        borderRadius: '15px'
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        // To change the side of the bar switch to flex-col and change w-full to h-screen
        <div className='text-white h-screen flex flex-col justify-between items-center p-4' style={{ background: '#D74121' }}>
            <div>
                {/**here goes the logo */}
                <Image url='https://unsplash.it/80/80' width='80px' />
            </div>
            <ul className='text-base font-medium flex flex-col justify-between items-center gap-2'>
                <li className='mb-4'>
                    <NavLink
                        to='/clients'
                        className='hover:text-gray-400 flex flex-col justify-center items-center'
                        style={({ isActive }) => (isActive ? activeStyle : style)}
                    >
                        {GlobalService.uppercaseFirstLetter(t('clients'))}
                    </NavLink>
                </li>
                <li className='mb-4'>
                    <NavLink
                        to='/statistic'
                        className='hover:text-gray-400 flex flex-col justify-center items-center'
                        style={({ isActive }) => (isActive ? activeStyle : style)}
                    >
                        {GlobalService.uppercaseFirstLetter(t('statistics'))}
                    </NavLink>
                </li>
                <li className='mb-4'>
                    <NavLink
                        to='/tasks'
                        className='hover:text-gray-400 flex flex-col justify-center items-center'
                        style={({ isActive }) => (isActive ? activeStyle : style)}
                    >
                        {GlobalService.uppercaseFirstLetter(t('tasks'))}
                    </NavLink>
                </li>
                <li className='mb-4'>
                    <NavLink
                        to='/products'
                        className='hover:text-gray-400 flex flex-col justify-center items-center'
                        style={({ isActive }) => (isActive ? activeStyle : style)}
                    >
                        {GlobalService.uppercaseFirstLetter(t('products'))}
                    </NavLink>
                </li>
                <li className='mb-4'>
                    <NavLink
                        to='/settings'
                        className='hover:text-gray-400 flex flex-col justify-center items-center'
                        style={({ isActive }) => (isActive ? activeStyle : style)}
                    >
                        {GlobalService.uppercaseFirstLetter(t('settings'))}
                    </NavLink>
                </li>
            </ul>
            <div className='mb-4'>
                <Button
                    text={GlobalService.uppercaseFirstLetter(t('logout'))}
                    color='black'
                    background='white'
                    borderRadius='15px'
                    onclick={handleLogout}
                />
            </div>
        </div>
    );
};

export default NavigationBar;`;

const Navigation = () => {
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Navbar</div>
            <div className='text-lg mt-4'>
                The navbar is an element that allows you navigate through the different content of a website.
            </div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>This is the implementation of the navbar</div>
            <div className='text-lg'>In order to modify it it has to be manually done in the component.</div>
            <div className='text-lg'>The image represents the spot for the company logo or name.</div>
            <div className='text-lg'>It is usually implemente in the routes definition, so it appears in every page required.</div>
            <CopyBlock language={'jsx'} text={`<NavigationBar />`} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='flex w-30 justify-start'>
                <NavigationBar />
            </div>
        </div>
    );
};

export default Navigation;
