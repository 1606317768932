// CSS
import '../../styles/Personas/Personas.css';
import styles from './informes.module.css';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
// Components
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import Title from '../../components/Title';
import TopMenu from '../Menus/TopMenu';
import { Button } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IPatientReport } from '../../hexagonal-architecture-frontend-base/src/domain/models/IPersona';

// Images
import LeftArrow from '../../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import {
  usePatients,
  useSelectedPatient,
  useSetSelectedQuote,
  useSetUrlToReturn,
  useDownloadQuotePdf,
  useShowDocumentDownloadOkPopup,
  useShowErrorPopup,
} from '../../hooks';

const cx = classNames.bind(styles);

const Informes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const {
    patients,
    quotesError: reportsError,
    getAllPatients,
    isLoading: isPatientsLoading,
    isReportsLoading,
  } = usePatients();
  const { viewportWidth } = useViewport();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { setSelectedQuote } = useSetSelectedQuote();
  const { setUrlToReturn } = useSetUrlToReturn();
  const { downloadQuotePdf, isLoading: isDownloadingQuotePdf } = useDownloadQuotePdf();
  const { showDocumentDownloadOkPopup } = useShowDocumentDownloadOkPopup();
  const { showErrorPopup } = useShowErrorPopup();
  const quotes = selectedPatient.reportsDetails ?? [];

  /**********
   * States *
   **********/
  const [pdfBeingDownloaded, setPdfBeingDownloaded] = useState<string[]>([]);

  const isLoading = isPatientsLoading || isReportsLoading;

  /*********
   * Hooks *
   *********/
  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patients]);

  useEffect(() => {
    getReports();
  }, [selectedPatient]);

  /************
   * Handlers *
   ************/
  const handleOnClickDetailedReport = (report: IPatientReport) => {
    setSelectedQuote(report.id);
    setUrlToReturn(`/informes/${patientId}`);
    navigate(`/informeDetallado/${patientId}/${report.id}`);
  };

  const handleDownloadPdf = async (reportId: string) => {
    setPdfBeingDownloaded(currVal => [...currVal, reportId]);
    downloadQuotePdf(
      { quoteId: reportId },
      {
        onSuccess: () => showDocumentDownloadOkPopup(),
        onSettled: () => setPdfBeingDownloaded(currVal => currVal.filter(id => id !== reportId)),
        onError: error => showErrorPopup(`errors.${error.message}`),
      },
    );
  };

  // TODO - Make it possible to fetch the reports for a single patient
  const getReports = async () => {
    getAllPatients();
  };

  useEffect(() => {
    if (reportsError != null) {
      showErrorPopup(reportsError.message);
    }
  }, [patients, reportsError]);

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.reports.mainTitle'))}:`;
  const noData = <p>{GlobalService.uppercaseAllFirstsLetters(t('nodata'))}</p>;

  const formatReportFields = (report: IPatientReport) => {
    const name =
      report?.Nombre_del_Dentista?.name ||
      GlobalService.uppercaseJustFirstLetter(t('pages.visit.nodata'));
    const reportsFieldData = [
      ['reports.budgetNumber', report.Identificador],
      ['reports.date', report.Fecha_de_la_Visita],
      ['reports.specialist', name],
      ['informeDetallado.reportPhase', report.Quote_Stage],
    ];

    return reportsFieldData.map((fields: string[], index: number) => (
      <div key={index} className={index ? 'mt-3' : ''}>
        <p className={`Personas-Visitas ${isScreenBig ? '' : 'text-sm'}`}>
          {GlobalService.uppercaseFirstLetter(t(`pages.${fields[0]}`))}:
        </p>
        <p className={isScreenBig ? '' : 'text-sm'}>
          <strong>{fields[1]}</strong>
        </p>
      </div>
    ));
  };

  const formattedReports = quotes.map((report: IPatientReport, index: number) => {
    const isBeingDownloaded = pdfBeingDownloaded.includes(report.id);
    return (
      <div key={index} className={cx('report-card')}>
        <div className={cx('fields-container')}>{formatReportFields(report)}</div>

        <div className={cx('buttons-container')}>
          <Button
            type='primary'
            onClick={isDownloadingQuotePdf ? () => {} : () => handleDownloadPdf(report.id)}
            fullWidth={true}
            disabled={isDownloadingQuotePdf && !isBeingDownloaded}
          >
            {isBeingDownloaded ? (
              <RegularSpinner size='1.5rem' border='3px' />
            ) : (
              GlobalService.uppercaseFirstLetter(t('pages.reports.detailedReport'))
            )}
          </Button>

          <Button
            type='primary'
            onClick={() => handleOnClickDetailedReport(report)}
            fullWidth={true}
          >
            {GlobalService.uppercaseFirstLetter(t('continue'))}
          </Button>
        </div>
      </div>
    );
  });

  // Responsive values
  const responsivePageTitle = GlobalService.uppercaseFirstLetter(
    t('pages.reports.responsiveMainTitle'),
  );

  /*******
   * JSX *
   *******/
  return (
    <div className={cx('main-container')}>
      {isScreenBig ? (
        <>
          <div className={cx('top-menu-container')}>
            <TopMenu title={pageTitle} user={selectedPatient.title} />
          </div>
          <div className={cx('body-container')}>
            <div className='flex text-left mb-6'>
              <img
                onClick={() => {
                  navigate(`/datosDocumentos/${patientId}/1`);
                }}
                src={LeftArrow}
                alt='Back'
                className='Notificaciones-Arrow cursor-pointer'
              />

              <Title
                text={`${GlobalService.uppercaseAllFirstsLetters(t('pages.reports.title'))}:`}
                color='black'
                size={'24px'}
                margin={'0px 0px 0px 30px'}
              />
            </div>

            <div className={cx('reports-scroll')}>
              <div className={cx('reports-container')}>
                {isLoading ? <RegularSpinner /> : quotes.length ? formattedReports : noData}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title={responsivePageTitle}
            leftIcon={LeftArrowSmall}
            leftIconLink={`/datosDocumentos/${patientId}/1`}
          />
          <div className={cx('body-container', 'flex flex-col gap-y-2 mx-2 bg-white')}>
            {isLoading ? (
              <div className='bg-white p-4 mt-36'>
                <RegularSpinner />
              </div>
            ) : quotes.length ? (
              <div className={cx('reports-scroll')}>
                <div className={cx('reports-container')}>{formattedReports}</div>
              </div>
            ) : (
              noData
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Informes;
