import React from 'react';
import { useNavigate } from 'react-router-dom';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import './MobileNavigationUnLogged.css';

// Images
import ayudaColor from '../../img/Menu/ayuda-color.png';
import ayudaGrey from '../../img/Menu/ayuda-grey.png';
import blogColor from '../../img/Menu/blog-color.png';
import blogGray from '../../img/Menu/blog-grey.png';
import userLogin from '../../img/user-circle.png';

const MobileNavigationUnLogged = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const activeStyle = {
        color: '#28337D'
    };

    const style = {
        color: 'rgba(39, 50, 124, 0.49)'
    };

    const linksDefinition = [
        {
            title: GlobalService.uppercaseFirstLetter(t('pages.blog.title')),
            paths: ['/', '/blog'],
            image: [blogColor, blogGray],
            onClick: () => navigate('/blog')
        },
        {
            title: GlobalService.uppercaseFirstLetter(t('login')),
            paths: ['/login'],
            image: [userLogin, userLogin],
            onClick: () => navigate('/login')
        },
        {
            title: GlobalService.uppercaseFirstLetter(t('help')),
            paths: ['/help'],
            image: [ayudaColor, ayudaGrey],
            customImageNSClass: 'pt-[2px]',
            customImageSClass: 'pt-[5px]',
            onClick: () => navigate('/help')
        }
    ];

    const links = linksDefinition.map((item, index) => {
        return (
            <div
                onClick={item.onClick}
                key={index}
                className={`text-center cursor-pointer`}
                style={
                    (
                        item.paths[0] === '/'
                            ? item.paths.some(path => path === location.pathname)
                            : item.paths.some(path => location.pathname.includes(path))
                    )
                        ? activeStyle
                        : style
                }
            >
                <div className='m-auto w-[32px] h-[32px]'>
                    <img
                        src={
                            (
                                item.paths[0] === '/'
                                    ? item.paths.some(path => path === location.pathname)
                                    : item.paths.some(path => location.pathname.includes(path))
                            )
                                ? item.image[0]
                                : item.image[1]
                        }
                        className={`m-auto ${
                            item.paths.some(path => location.pathname.includes(path)) ? item?.customImageSClass : item?.customImageNSClass
                        }`}
                        alt=''
                    />
                </div>
                <a className='px-1 py-1'>{GlobalService.uppercaseFirstLetter(t(item.title))}</a>
            </div>
        );
    });

    return (
        // To change the side of the bar switch to flex-col and change w-full to h-screen
        <footer
            className='fixed bottom-0 left-0 w-screen bg-white py-4 px-8 flex justify-between md:hidden z-50 drop-shadow-2xl overflow-y-scroll'
            style={location.pathname === '/login' || location.pathname === '/signup' ? { display: 'none' } : {}}
        >
            {links}
        </footer>
    );
};

export default MobileNavigationUnLogged;
