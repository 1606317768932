import { Navigate, Outlet } from 'react-router-dom';
import { useSession } from '../hooks';

const PublicRoutes = () => {
  const { isUserLogged } = useSession();

  console.log('isUserLogged', isUserLogged);

  return <>{!isUserLogged ? <Outlet /> : <Navigate to={'/personas'} />}</>;
};

export default PublicRoutes;
