import { useEffect, useRef, useState } from 'react';

export const useDebounce = <T>(initialValue: T, delay = 300) => {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
  const setWithoutDebounceRef = useRef(false);

  useEffect(() => {
    if (setWithoutDebounceRef.current) {
      setDebouncedValue(value);
      setWithoutDebounceRef.current = false;
      return;
    }

    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeout);
  }, [value, delay]);

  const setValueProperly = (newValue: T | ((prevVal: T) => T), setWithoutDebounce = false) => {
    setValue(newValue);
    if (setWithoutDebounce) {
      setWithoutDebounceRef.current = true;
    }
  };

  return { value, debouncedValue, setValue: setValueProperly };
};
