import { useAtomValue } from 'jotai';
import { getInvoicesAtom } from '../../store/global/atoms';

export const useGetInvoices = () => {
  const getInvoices = useAtomValue(getInvoicesAtom);

  return {
    invoices: getInvoices.data,
    getInvoices: getInvoices.mutate,
    getInvoicesAsync: getInvoices.mutateAsync,
    isLoading: getInvoices.isPending,
    error: getInvoices.error,
  };
};
