import { Getter } from 'jotai';
import { userInfoAtom } from '../../../store/global/atoms';
import { PatientInfo } from '../../../ts';
import { personasServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';
import { personasRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';

export const getPatientsService = (get: Getter) => async () => {
  const userInfo = get(userInfoAtom);
  if (userInfo == null) throw new Error('userInfo is null');
  if (!userInfo.patientsAccess) return [];
  const userId = userInfo.id;
  const patients = await personasServices(personasRepositoryInstance).getPatients(userId);
  if (typeof patients === 'string') throw new Error(patients);
  return patients.data as PatientInfo[];
};
