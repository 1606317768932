import { useSetAtom } from 'jotai';
import { selectedPatientIdAtom } from '../../../store/global/atoms';

export const useSetSelectedPatient = () => {
  const setSelectedPatientId = useSetAtom(selectedPatientIdAtom);

  const setSelectedPatient = (patientId: string) => {
    setSelectedPatientId(patientId);
  };

  const removeSelectedPatient = () => {
    setSelectedPatientId(null);
  };

  return { setSelectedPatient, removeSelectedPatient };
};
