import React from 'react';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

interface Props {
    onChange: (e: string) => void;
    minDate?: string;
    maxDate?: string;
    startingDate?: string;
    pickedDate?: string;
    borderRadius?: string;
    isDisabled?: boolean;
}

const today = (): string => {
    const { day, month, year } = GlobalService.parseDate(Date.now());
    return `${year}-${month}-${day}`;
};

const InputDate: React.FC<Props> = ({
    onChange,
    minDate = '1900-01-01',
    maxDate = '2100-31-12',
    startingDate = today(),
    pickedDate,
    borderRadius,
    isDisabled
}) => {
    const handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    return (
        <div style={{ border: '1px solid #E5E7EB', borderRadius: borderRadius }}>
            <input
                type='date'
                value={pickedDate ? pickedDate : startingDate}
                min={minDate}
                max={maxDate}
                onChange={handleDate}
                className='p-2 w-full outline-0'
                disabled={isDisabled}
            />
        </div>
    );
};

export default InputDate;
