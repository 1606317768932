import { DownloadFileNoModuleParams } from 'src/ts';
import { downloadFileService } from './download-file.service';

export const downloadPatientAttachedFileService = async ({
  moduleEntryId,
  fileId,
  fileName,
}: DownloadFileNoModuleParams) => {
  const blob = await downloadFileService({
    module: 'Contacts',
    moduleEntryId,
    fileId,
    fileName,
  });

  return blob;
};
