import { PatientAttachedFileIdentifier } from '../../../ts';
import { personasRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';
import { personasServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';

export const getSinglePatientAttachedFilesService = async ({
  patientId,
}: PatientAttachedFileIdentifier) => {
  const data = await personasServices(personasRepositoryInstance).getSinglePatientAttachedFiles(
    patientId,
  );

  if (typeof data === 'string') throw new Error(data);

  return data;
};
