import { IAuth } from '../../domain/models/ITutor';
import { UserLogin, UserSignup } from '../../domain/models/User';
import { http } from '../plugins/http/http';
import { LOCAL_STORAGE_KEYS } from '../../../../consts';

// const saveResponse = (response: IAuth) => {
//   window.localStorage.setItem('token', response.accessToken);
// };

const userIsLogged = (): boolean => {
  const auth = window.localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH);
  if (auth == null) return false;
  const token = (JSON.parse(auth) as IAuth).accessToken;
  return token ? true : false;
};

export const authServices = () => ({
  forgotPassword: async (email: object) => {
    let returnValue = '';
    await http
      .post('/password/forgot', email)
      .then(() => {
        returnValue = 'ok';
      })
      .catch(() => {
        returnValue = 'error';
      });
    return returnValue;
  },
  login: async (user: UserLogin) => {
    user.username = user.username.toLowerCase();
    return await http
      .post('/auth/login', user)
      .then((response: any) => {
        console.log('response', response);
        const auth = { accessToken: response.access_token, id: response.Id } as IAuth;
        // saveResponse(auth);
        return auth;
      })
      .catch(error => {
        if (error?.response?.status === 403) return 'errors.updateMail';
        return 'errors.loginInvalid';
      });
  },
  logout: (): void => {
    const itemsToRemove: string[] = ['backRoute', 'tutorId', 'tutorName', 'token', LOCAL_STORAGE_KEYS.AUTH, LOCAL_STORAGE_KEYS.USER];
    itemsToRemove.forEach(item => window.localStorage.removeItem(item));
  },
  signup: async (user: UserSignup) => {
    let returnValue = '';
    await http
      .post('/users', user)
      .then(val => {
        returnValue = 'ok';
      })
      .catch(error => {
        returnValue = 'errors.signUpInvalid';
        console.error('error', error);
        if (error?.response?.status === 409) {
          returnValue =
            error.response.data.message === 'User already registered' ? 'errors.dniAlreadyRegistered' : 'errors.mailAlreadyRegistered';
        }
      });
    return returnValue;
  },
  userIsLogged: (): boolean => {
    return userIsLogged();
  }
});
