import React from 'react';
import { useNavigate } from 'react-router-dom';

const Doc = () => {
    const navigate = useNavigate();
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-3xl'>Components Docs</div>
            <br />
            <ol>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/avatars');
                    }}
                >
                    Avatars
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/buttons');
                    }}
                >
                    Buttons
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/cards');
                    }}
                >
                    Cards
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/checkboxes');
                    }}
                >
                    Checkboxes
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/forms');
                    }}
                >
                    Forms
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/formsClientRegister');
                    }}
                >
                    Client Register
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/formsClientEdit');
                    }}
                >
                    Client Edit Form
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/images');
                    }}
                >
                    Images
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/inputs');
                    }}
                >
                    Inputs
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/loaders');
                    }}
                >
                    Loaders
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/modals');
                    }}
                >
                    Modals
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/navbar');
                    }}
                >
                    Navigation
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/radios');
                    }}
                >
                    Radio buttons
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/tables');
                    }}
                >
                    Tables
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/tabs');
                    }}
                >
                    Tabs
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/tags');
                    }}
                >
                    Tags
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/times');
                    }}
                >
                    Time
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/toggles');
                    }}
                >
                    Toggles
                </li>

                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/starRatings');
                    }}
                >
                    Star Ratings
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/title');
                    }}
                >
                    Title Component
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/pagination');
                    }}
                >
                    Pagination
                </li>
                <li
                    className='mt-5 cursor-pointer'
                    onClick={() => {
                        navigate('/fallBacks');
                    }}
                >
                    Fallbacks
                </li>
            </ol>
        </div>
    );
};

export default Doc;
