import React from 'react';
import { Tag } from '../../components/Tags/Tag';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elem = `interface Props {
    // the text has to be translated and formatted
    // in case always capitalized add capitilized to div className
    text: string;
    color: string;
    background: string;
    weight?: string;
    border?: string;
    borderRadius?: string;
    paddingX?: number;
    paddingY?: number;
}

export const Tag: React.FC<Props> = ({ text, color, background, weight, border, borderRadius, paddingX, paddingY }) => {
    const padX = paddingX || paddingX === 0 ? paddingX : 32;
    const padY = paddingY || paddingY === 0 ? paddingY : 6;
    return (
        <div
            className='text-center w-fit h-fit'
            style={{
                background: background,
                border: border,
                borderRadius: borderRadius,
                padding: \`\${padY}px \${padX}px \${padY}px \${padX}px\`
            }}
        >
            <p style={{ color: color, fontWeight: weight }}>{text}</p>
        </div>
    );
};`;

const Tags = () => {
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Tags</div>
            <div className='text-lg mt-4'>A tag is an element to highligh a piece of data throughout the interface.</div>
            <div className='text-lg'>The text must be passed translated and formatted.</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Next some implementations of the element</div>
            <div className='text-base mt-2'>Different background and text colors</div>
            <CopyBlock
                language={'jsx'}
                text={`<Tag text='tag' background='pink' color='#2F3447' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Tag text='tag' background='none' color='#2F3447' />
                <Tag text='tag' background='pink' color='#2F3447' />
                <Tag text='tag' background='orange' color='#2F3447' />
                <Tag text='tag' background='blue' color='#2F3447' />
                <Tag text='tag' background='green' color='#2F3447' />
                <Tag text='tag' background='#2F3447' color='white' />
            </div>
            <div className='text-base mt-2'>Different border colors</div>
            <CopyBlock
                language={'jsx'}
                text={`<Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' />
                <Tag text='border' background='none' color='#2F3447' border='2px solid pink' />
                <Tag text='border' background='none' color='#2F3447' border='2px solid orange' />
                <Tag text='border' background='none' color='#2F3447' border='2px solid blue' />
                <Tag text='border' background='none' color='#2F3447' border='2px solid green' />
            </div>
            <div className='text-base mt-2'>Different paddings (number of pixels)</div>
            <CopyBlock
                language={'jsx'}
                text={`<Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' paddingX={0} paddingY={0} />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Tag text='padding' background='none' color='#2F3447' border='2px solid #2F3447' paddingX={0} paddingY={0} />
                <Tag text='padding' background='olive' color='white' paddingX={6} paddingY={10} />
                <Tag text='padding' background='indianred' color='white' paddingX={12} paddingY={3} />
                <Tag text='padding' background='darkcyan' color='white' paddingX={4} paddingY={1} />
                <Tag text='padding' background='none' color='#2F3447' border='2px solid green' paddingX={50} paddingY={10} />
            </div>
            <div className='text-base mt-2'>Different border radius</div>
            <CopyBlock
                language={'jsx'}
                text={`<Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' borderRadius='6px' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' borderRadius='6px' />
                <Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' borderRadius='12px' />
                <Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' borderRadius='18px' />
                <Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' borderRadius='24px' />
                <Tag text='border' background='none' color='#2F3447' border='2px solid #2F3447' borderRadius='30px' />
            </div>
            <div className='text-base mt-2'>Different font weight</div>
            <CopyBlock
                language={'jsx'}
                text={`<Tag text='thinfont' background='#E3E3E3' color='#2F3447' weight='200' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                flex-wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Tag text='hello world' background='#E3E3E3' color='#2F3447' weight='200' />
                <Tag text='hello world' background='#E3E3E3' color='#2F3447' weight='400' />
                <Tag text='hello world' background='#E3E3E3' color='#2F3447' weight='600' />
                <Tag text='hello world' background='#E3E3E3' color='#2F3447' weight='800' />
            </div>
        </div>
    );
};

export default Tags;
