import { QUERY_KEYS } from '../../../../../consts';
import { downloadDealFileService } from '../../../../../services';
import { getAtomWithMutation } from '../../../../../utils';
import { createAndClickTemporaryLink } from '../../../../../utils/create-and-click-temporary-link';

export const downloadDealFileAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.PATIENTS.DOWNLOAD_DEAL,
  mutationFn: () => downloadDealFileService,
  onSuccess: () => (data, params) => {
    createAndClickTemporaryLink(data, params.fileName);
  },
});
