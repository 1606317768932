import { convertPopupOptionsSetterToPopupOptions, createNewBuffer } from '../../../../utils';
import { PopupOptions, PopupOptionsAction } from '../../../../ts';
import { DEFAULT_POPUP_OPTIONS } from '../../../../consts';
import { atomWithReducer } from 'jotai/vanilla/utils';

const pupupOptionsReducer = (prev: PopupOptions, action: PopupOptionsAction): PopupOptions => {
  const { type } = action;

  if (type === 'open') {
    const { payload } = action;
    const newOptions = convertPopupOptionsSetterToPopupOptions(payload, prev.buffer);

    return newOptions;
  }

  if (type === 'openNextFromBuffer') {
    const { buffer } = prev;
    console.log('buffer', buffer);
    console.log('buffer?.length', buffer?.length);
    if (buffer == null || buffer.length === 0) {
      throw new Error('Buffer is empty');
    }

    const [nextPopup, ...restBuffer] = buffer;
    console.log('restBuffer', restBuffer);
    console.log('restBuffer.length', restBuffer.length);
    const newOptions = convertPopupOptionsSetterToPopupOptions(nextPopup, restBuffer);
    console.log('newOptions', newOptions);
    return newOptions;
  }

  if (type === 'close') {
    const { ignoreBuffer = false, ignoreForceOpen = false } = action.payload;

    if (prev.isKeepOpenForced && !ignoreForceOpen) return prev;

    prev.onClose?.();

    if (ignoreBuffer || prev.buffer == null || prev.buffer.length === 0)
      return DEFAULT_POPUP_OPTIONS;

    const [nextPopup, ...restBuffer] = prev.buffer;
    const newOptions = convertPopupOptionsSetterToPopupOptions(nextPopup, restBuffer);
    return newOptions;
  }

  if (type === 'setBuffer') {
    const { buffer, setBufferAction } = action.payload;
    const newBuffer = createNewBuffer(prev.buffer ?? [], buffer ?? [], setBufferAction);

    return {
      ...prev,
      buffer: newBuffer,
    };
  }

  if (type === 'setForceKeepOpen') {
    const { newValue } = action.payload;
    let newForceOpen = newValue;

    if (newValue === 'toggle') {
      newForceOpen = !prev.isKeepOpenForced;
    }

    return {
      ...prev,
      isKeepOpenForced: !!newForceOpen,
    };
  }

  if (type === 'setDisableClickOutside') {
    const { newValue } = action.payload;
    let newIsClickOutsideDisabled = newValue;

    if (newValue === 'toggle') {
      newIsClickOutsideDisabled = !prev.isClickOutsideDisabled;
    }

    return {
      ...prev,
      isClickOutsideDisabled: !!newIsClickOutsideDisabled,
    };
  }

  if (type === 'setOnClose') {
    const { newValue } = action.payload;

    return {
      ...prev,
      onClose: newValue,
    };
  }

  throw new Error('Invalid action type');
};

export const popupOptionsAtom = atomWithReducer(DEFAULT_POPUP_OPTIONS, pupupOptionsReducer);
