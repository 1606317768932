import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ButtonBase } from '../Buttons/button-base';

interface Props {
  placeholder?: string;
  onChange: (value: string) => void;
  borderRadius?: string;
  buttonRadius?: string;
  iconBG: string;
  iconText?: string;
  iconTextColor?: string;
  isDisabled?: boolean;
}

const InputSearch: React.FC<Props> = ({
  placeholder,
  onChange,
  borderRadius,
  iconBG,
  buttonRadius,
  iconText,
  iconTextColor = 'black',
  isDisabled,
}) => {
  let searchValue: string;
  const searchIcon = <FontAwesomeIcon icon={faMagnifyingGlass} className='opacity-50 pr-1 fa-sm' />;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchValue = e.target.value;
  };

  const sendInfo = () => {
    onChange(searchValue);
  };

  return (
    <div
      className=' flex w-max border'
      style={{
        borderRadius: borderRadius,
        backgroundColor: isDisabled ? 'rgb(226 232 240)' : 'white',
      }}
    >
      <div className='flex justify-between overflow-hidden items-center text-sm'>
        <input
          type='text'
          disabled={isDisabled}
          onChange={handleSearch}
          placeholder={placeholder}
          className=' w-4/5 outline-0 pl-4'
        />
        <ButtonBase
          text={iconText}
          onClick={sendInfo}
          background={iconBG}
          color={iconTextColor}
          iconLeft={searchIcon}
          minHeight={'-webkit-fill-available'}
          borderRadius={buttonRadius}
        />
      </div>
    </div>
  );
};

export default InputSearch;
