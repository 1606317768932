import React, { useState, ChangeEvent, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ButtonBase } from '../Buttons/button-base';
import InputSelector from './InputSelector';
import { inputServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/input.services';
import { phonePrefix } from '../../hexagonal-architecture-frontend-base/src/domain/models/PhonePrefix';

interface Props {
  firstPlaceholder: string;
  secondPlaceholder: string;
  thirdPlaceholder?: string;
  onChange: (value: any) => void;
  firstOnChangeSelector?: (value: Array<optionItem | phonePrefix>) => void;
  secondOnChangeSelector?: (value: Array<optionItem | phonePrefix>) => void;
  thirdOnChangeSelector?: (value: Array<optionItem | phonePrefix>) => void;
  borderRadius?: string;
  buttonRadius?: string;
  iconBG: string;
  iconText?: string;
  iconTextColor?: string;
  isTriple?: boolean;
  isFirstSelector?: boolean;
  isSecondSelector?: boolean;
  isThirdSelector?: boolean;
  options?: Array<optionItem | phonePrefix> | [];
  firstOptions?: Array<optionItem | phonePrefix> | [];
  firstSelectedOptions?: Array<optionItem | phonePrefix> | [];
  secondOptions?: Array<optionItem | phonePrefix> | [];
  secondSelectedOptions?: Array<optionItem | phonePrefix> | [];
  thirdOptions?: Array<optionItem | phonePrefix> | [];
  thirdSelectedOptions?: Array<optionItem | phonePrefix> | [];
  selectedOption?: Array<optionItem | phonePrefix> | [];
  firstSelectorIsMulti?: boolean;
  secondSelectorIsMulti?: boolean;
  thirdSelectorIsMulti?: boolean;
  isFirstFieldMandatory?: boolean;
  isSecondFieldMandatory?: boolean;
  isThirdFieldMandatory?: boolean;
  firstParam: string;
  secondParam: string;
  thirdParam: string;
  isFirstDisabled?: boolean;
  isSecondDisabled?: boolean;
  isThirdDisabled?: boolean;
}

interface optionItem {
  id: string | number;
  label: string;
  value: string;
  extra: any;
}
interface dataToFilter {
  firstValue: string;
  secondValue: string;
  thirdValue: string;
  firstSelectorValue?: Array<optionItem | phonePrefix>;
  secondSelectorValue?: Array<optionItem | phonePrefix>;
  thirdSelectorValue?: Array<optionItem | phonePrefix>;
}
const inputMultiSearch: React.FC<Props> = ({
  firstPlaceholder,
  secondPlaceholder,
  thirdPlaceholder,
  onChange,
  firstOnChangeSelector,
  secondOnChangeSelector,
  thirdOnChangeSelector,
  borderRadius,
  iconBG,
  buttonRadius,
  iconText,
  iconTextColor = 'black',
  isTriple,
  isFirstSelector,
  isSecondSelector,
  isThirdSelector,
  firstOptions,
  firstSelectedOptions,
  firstSelectorIsMulti,
  secondOptions,
  secondSelectedOptions,
  secondSelectorIsMulti,
  thirdOptions,
  thirdSelectedOptions,
  thirdSelectorIsMulti,
  isFirstFieldMandatory,
  isSecondFieldMandatory,
  isThirdFieldMandatory,
  firstParam,
  secondParam,
  thirdParam,
  isFirstDisabled,
  isSecondDisabled,
  isThirdDisabled,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [dataToFilter, setDataToFilter] = useState<dataToFilter>({
    firstValue: '',
    secondValue: '',
    thirdValue: '',
    firstSelectorValue: [],
    secondSelectorValue: [],
    thirdSelectorValue: [],
  });

  useEffect(() => {
    if (isFirstFieldMandatory) {
      setIsDisabled(
        inputServices().handleMandatoryField(
          isFirstSelector,
          dataToFilter.firstSelectorValue?.length,
          dataToFilter.firstValue,
        ),
      );
    }
    if (isSecondFieldMandatory) {
      setIsDisabled(
        inputServices().handleMandatoryField(
          isSecondSelector,
          dataToFilter.secondSelectorValue?.length,
          dataToFilter.secondValue,
        ),
      );
    }
    if (isThirdFieldMandatory) {
      setIsDisabled(
        inputServices().handleMandatoryField(
          isThirdSelector,
          dataToFilter.thirdSelectorValue?.length,
          dataToFilter.thirdValue,
        ),
      );
    }
  }, [dataToFilter]);

  const searchIcon = <FontAwesomeIcon icon={faMagnifyingGlass} className='opacity-50 pr-1' />;

  const handleSaveValue = (e: ChangeEvent<HTMLInputElement>, value: number) => {
    switch (value) {
      case 1:
        e.target.value
          ? setDataToFilter(dataToFilter => ({ ...dataToFilter, firstValue: e.target.value }))
          : setDataToFilter(dataToFilter => ({ ...dataToFilter, firstValue: '' }));
        break;
      case 2:
        e.target.value
          ? setDataToFilter(dataToFilter => ({ ...dataToFilter, secondValue: e.target.value }))
          : setDataToFilter(dataToFilter => ({ ...dataToFilter, secondValue: '' }));
        break;
      case 3:
        e.target.value
          ? setDataToFilter(dataToFilter => ({ ...dataToFilter, thirdValue: e.target.value }))
          : setDataToFilter(dataToFilter => ({ ...dataToFilter, thirdValue: '' }));
        break;
    }
  };

  const handleSelectorChange = (value: Array<optionItem | phonePrefix>, selector: number) => {
    switch (selector) {
      case 1:
        setDataToFilter(dataToFilter => ({ ...dataToFilter, firstSelectorValue: value }));
        firstOnChangeSelector ? firstOnChangeSelector(value) : null;
        break;
      case 2:
        setDataToFilter(dataToFilter => ({ ...dataToFilter, secondSelectorValue: value }));
        secondOnChangeSelector ? secondOnChangeSelector(value) : null;
        break;
      case 3:
        setDataToFilter(dataToFilter => ({ ...dataToFilter, thirdSelectorValue: value }));
        thirdOnChangeSelector ? thirdOnChangeSelector(value) : null;
        break;
    }
  };

  const setInfo = () => {
    if (isDisabled) return;
    onChange(dataToFilter);
  };

  return (
    <div className=' flex h-4/5 w-max border' style={{ borderRadius: borderRadius }}>
      <div className='flex justify-around items-center'>
        {isFirstSelector && firstOptions && firstSelectedOptions ? (
          <>
            <div className='border h-3/6'></div>
            <div className='w-4/5'>
              <InputSelector
                placeholder='Select an option...'
                options={firstOptions}
                selectedOption={firstSelectedOptions}
                onChange={e => handleSelectorChange(e, 1)}
                isMulti={firstSelectorIsMulti ? firstSelectorIsMulti : false}
                selectedOptionBG='#D3D3D3'
                param={firstParam}
                isDisabled={isFirstDisabled}
              />
            </div>
          </>
        ) : (
          <input
            type='text'
            placeholder={firstPlaceholder}
            onChange={e => handleSaveValue(e, 1)}
            className=' w-4/5 outline-0 pl-3'
            disabled={isFirstDisabled}
            style={{
              backgroundColor: isFirstDisabled ? 'rgb(226 232 240)' : 'wite',
              height: '100%',
            }}
          />
        )}

        {isSecondSelector && secondOptions && secondSelectedOptions ? (
          <>
            <div className='border h-3/6'></div>
            <div className='w-4/5'>
              <InputSelector
                placeholder='Select an option...'
                options={secondOptions}
                selectedOption={secondSelectedOptions}
                onChange={e => handleSelectorChange(e, 2)}
                isMulti={secondSelectorIsMulti ? secondSelectorIsMulti : false}
                selectedOptionBG='#D3D3D3'
                param={secondParam}
                isDisabled={isSecondDisabled}
              />
            </div>
          </>
        ) : (
          <>
            {' '}
            <div className='border h-3/6'></div>
            <input
              type='text'
              placeholder={secondPlaceholder}
              onChange={e => handleSaveValue(e, 2)}
              className='w-4/5  outline-0 pl-3'
              disabled={isSecondDisabled}
              style={{
                backgroundColor: isSecondDisabled ? 'rgb(226 232 240)' : 'wite',
                height: '100%',
              }}
            />
          </>
        )}
        {isTriple ? (
          isThirdSelector && thirdOptions && thirdSelectedOptions ? (
            <>
              <div className='border h-3/6'></div>
              <div className='w-4/5'>
                <InputSelector
                  placeholder='Select an option...'
                  options={thirdOptions}
                  selectedOption={thirdSelectedOptions}
                  onChange={e => handleSelectorChange(e, 3)}
                  isMulti={thirdSelectorIsMulti ? thirdSelectorIsMulti : false}
                  selectedOptionBG='#D3D3D3'
                  param={thirdParam}
                  isDisabled={isThirdDisabled}
                />
              </div>
            </>
          ) : (
            <>
              <div className='border h-3/6'></div>
              <input
                type='text'
                placeholder={thirdPlaceholder}
                onChange={e => handleSaveValue(e, 3)}
                className='w-4/5  outline-0 pl-3'
                disabled={isThirdDisabled}
                style={{
                  backgroundColor: isThirdDisabled ? 'rgb(226 232 240)' : 'wite',
                  height: '100%',
                }}
              />
            </>
          )
        ) : null}
      </div>
      <ButtonBase
        text={iconText}
        background={!isDisabled === true ? iconBG : 'grey'}
        color={iconTextColor}
        iconLeft={searchIcon}
        borderRadius={buttonRadius}
        onClick={setInfo}
      />
    </div>
  );
};

export default inputMultiSearch;
