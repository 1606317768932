import React from 'react';
import { Form } from '../../components/Form/Form';
import { FormConfig } from '../../hexagonal-architecture-frontend-base/src/domain/models/Form';
import { clientServiceMock } from '../../hexagonal-architecture-frontend-base/src/infrastructure/mocks/client.service.mock';

const ClientEditForm = () => {
    const config: FormConfig[] = [
        {
            key: 'name',
            label: 'Nombre',
            required: '*',
            type: 'text',
            width: '20%',
            disabled: true
        },
        {
            key: 'surname',
            label: 'Apellidos',
            type: 'text',
            width: '20%',
            disabled: true
        },
        {
            key: 'birthdate',
            label: 'Fecha de nacimiento',
            type: 'text',
            width: '20%',
            disabled: false
        },
        {
            key: 'nif',
            label: 'NIF/CIF',
            type: 'text',
            width: '20%',
            disabled: true
        },
        {
            key: 'phone',
            label: 'Teléfono fijo',
            type: 'text',
            details: 'toggle',
            width: '20%',
            disabled: false
        },
        {
            key: 'mobilephone',
            label: 'Teléfono móvil',
            required: '*',
            type: 'text',
            width: '20%',
            disabled: false
        },
        {
            key: 'direction',
            label: 'Dirección de facturación',
            type: 'text',
            width: '20%'
        },
        {
            key: 'email',
            label: 'Email',
            type: 'email',
            width: '20%'
        },
        {
            key: 'cp',
            label: 'CP',
            type: 'text',
            width: '20%',
            disabled: true
        },
        {
            key: 'city',
            label: 'Población',
            type: 'select',
            isMulti: false,
            singleChoice: true,
            width: '20%',
            options: [
                { id: 0, label: 'Viladecans', value: 'option1', extra: {} },
                { id: 1, label: 'Gavà', value: 'option2', extra: {} },
                { id: 2, label: 'Castelldefels', value: 'option3', extra: {} },
                { id: 3, label: 'Barcelona', value: 'option4', extra: {} }
            ]
        },
        {
            key: 'province',
            label: 'Província',
            type: 'select',
            isMulti: false,
            singleChoice: true,
            width: '20%',
            options: [
                { id: 0, label: 'Lleida', value: 'option1', extra: {} },
                { id: 1, label: 'Barcelona', value: 'option2', extra: {} },
                { id: 2, label: 'Girona', value: 'option3', extra: {} },
                { id: 3, label: 'Tarragona', value: 'option4', extra: {} }
            ]
        },
        {
            key: 'country',
            label: 'País',
            type: 'text',
            width: '20%',
            disabled: true
        },
        {
            key: 'iban',
            label: 'IBAN',
            type: 'text',
            width: '20%'
        },
        {
            key: 'state',
            label: 'Estado',
            type: 'select',
            isMulti: false,
            singleChoice: true,
            width: '20%',
            options: [
                { id: 0, label: 'Facturable', value: 'option1', extra: {} },
                { id: 1, label: 'Esporádico', value: 'option2', extra: {} },
                { id: 2, label: 'Captación', value: 'option3', extra: {} },
                { id: 3, label: 'Pendiente', value: 'option4', extra: {} }
            ]
        },
        {
            key: 'observations',
            label: 'Observaciones',
            details: 'button',
            text: 'View details',
            type: 'text',
            width: '41%'
        }
    ];

    return (
        <Form
            onFormSubmit={e => {
                console.log('FORM', e);
            }}
            config={config}
            flex='row'
            dataService={clientServiceMock().getClient}
            id='1'
        />
    );
};

export default ClientEditForm;
