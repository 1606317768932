import { FC } from 'react';
import { type IModalYesNoProps } from '../../../hexagonal-architecture-frontend-base/src/domain/models/IModals';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import ModalYesNo from '../ModalYesNo';
import { useLogOut } from '../../../hooks';

interface ModalCloseSessionProps {
  onClickNo: IModalYesNoProps['onClickNo'];
}

export const ModalCloseSession: FC<ModalCloseSessionProps> = ({ onClickNo }) => {
  const { t } = useTranslation();
  const { logOut } = useLogOut();

  const handleSignOut = () => {
    logOut(true);
  };

  const modalCloseSessionOptions: IModalYesNoProps = {
    buttonNoText: 'cancel',
    buttonYesText: 'pages.personas.ok',
    bodyQuestion: 'pages.personas.signoutText',
    onClickNo,
    onClickYes: handleSignOut,
    titleText: GlobalService.uppercaseFirstLetter(t('pages.personas.signout')),
    hideQuestionSign: true
  };

  return <ModalYesNo {...modalCloseSessionOptions} />;
};
