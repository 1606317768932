import { useAtomValue } from 'jotai';
import { authAtom, userInfoAtom } from '../../../store/global/atoms';

export const useSessionValue = () => {
  const auth = useAtomValue(authAtom);
  const userInfo = useAtomValue(userInfoAtom);

  const isUserLogged = userInfo != null && auth?.accessToken != null && userInfo.id != 0;

  return { auth, userInfo, isUserLogged };
};
