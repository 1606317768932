import { useAtomValue } from 'jotai';
import { downloadQuotePdfAtom } from '../../../store/global/atoms';

export const useDownloadQuotePdf = () => {
  const downloadQuotePdf = useAtomValue(downloadQuotePdfAtom);

  return {
    result: downloadQuotePdf.data,
    downloadQuotePdf: downloadQuotePdf.mutate,
    downloadQuotePdfAsync: downloadQuotePdf.mutateAsync,
    error: downloadQuotePdf.error,
    isLoading: downloadQuotePdf.isPending,
  };
};
