import { useShowErrorPopup } from './use-show-error-popup';

export const useShowUserErrorPopup = () => {
  const { showErrorPopup } = useShowErrorPopup();

  const showUserErrorPopup = () => {
    showErrorPopup('errors.userDataInvalid', 'login');
  };

  return { showUserErrorPopup };
};
