import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface Props {
    borderRadius?: string;
    customClass?: string;
    enableShowPassword?: boolean;
    id: string;
    isDisabled?: boolean;
    name: string;
    onChange?: (e: any) => void;
    placeholder?: string;
    type: 'text' | 'password' | 'email' | 'number';
    value: string | number;
}

const Input: React.FC<Props> = ({
    borderRadius,
    customClass = '',
    enableShowPassword,
    id,
    isDisabled,
    name,
    placeholder,
    onChange,
    type,
    value
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const classname =
        'appearance-none flex items-center block w-full border border-gray-200 text-gray-700 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ' +
        customClass;
    switch (type) {
        case 'text':
            return (
                <div className={classname} style={{ borderRadius: borderRadius }}>
                    <input
                        value={value}
                        type='text'
                        id={id}
                        name={name}
                        onChange={onChange ? e => onChange(e.target.value) : () => {}}
                        placeholder={placeholder}
                        style={{ borderRadius: borderRadius }}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                </div>
            );
        case 'number':
            return (
                <div className={classname} style={{ borderRadius: borderRadius }}>
                    <input
                        value={value}
                        type='number'
                        onChange={onChange ? e => onChange(e.target.value) : () => {}}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                </div>
            );
        case 'email':
            return (
                <div className={classname} style={{ borderRadius: borderRadius }}>
                    <input
                        value={value}
                        type='email'
                        onChange={onChange ? e => onChange(e.target.value) : () => {}}
                        placeholder={placeholder}
                        style={{ borderRadius: borderRadius }}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                </div>
            );
        case 'password':
            return (
                <div className={classname}>
                    <input
                        value={value}
                        type={showPassword ? 'text' : 'password'}
                        onChange={onChange ? e => onChange(e.target.value) : () => {}}
                        placeholder={placeholder}
                        style={{ borderRadius: borderRadius }}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                    {enableShowPassword ? (
                        !showPassword ? (
                            <FontAwesomeIcon
                                icon={faEye}
                                size='xs'
                                onClick={handleShowPassword}
                                className='opacity-50 cursor-auto hover:cursor-pointer'
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faEyeSlash}
                                size='xs'
                                onClick={handleShowPassword}
                                className='opacity-50 cursor-auto hover:cursor-pointer'
                            />
                        )
                    ) : null}
                </div>
            );
        default:
            return (
                <div className={classname} style={{ borderRadius: borderRadius }}>
                    <input
                        value={value}
                        type='text'
                        onChange={onChange ? e => onChange(e.target.value) : () => {}}
                        placeholder={placeholder}
                        style={{ borderRadius: borderRadius }}
                        disabled={isDisabled}
                        className='w-full outline-0'
                    />
                </div>
            );
    }
};

export default Input;
