import { useEffect } from 'react';
import { usePatientsValue } from './use-patients-value';
import { useSetPatients } from './use-set-patients';

export const usePatients = () => {
  const {
    patients,
    allDeals,
    allQuotes,
    allVisits,
    patientIdToDeals,
    patientIdToQuotes,
    patientIdToVisits,
    isLoading,
    isDealsLoading,
    isQuotesLoading: isReportsLoading,
    isVisitsLoading,
    isError,
    errorReason,
    numberOfPatients,
    dealsError,
    quotesError,
    visitsError,
  } = usePatientsValue();
  const { getAllPatients, getAllPatientsAsync, resetPatientsList } = useSetPatients();

  useEffect(() => {
    if (patients.length > 0 || isLoading) return;
    getAllPatients();
  }, []);

  return {
    patients,
    allDeals,
    allQuotes,
    allVisits,
    patientIdToDeals,
    patientIdToQuotes,
    patientIdToVisits,
    isLoading,
    isDealsLoading,
    isReportsLoading,
    isVisitsLoading,
    isError,
    errorReason,
    dealsError,
    quotesError,
    visitsError,
    numberOfPatients,
    getAllPatients,
    getAllPatientsAsync,
    resetPatientsList,
  };
};
