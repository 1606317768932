import { atomEffect } from 'jotai-effect';
import { isGettingAllPatientsAtom } from './get-patients-extra-info-atom-effect';
import { accumulatedPatientsAtom, patientsAtom } from '../patients-atoms';

export const setPatientsOnFetchEndAtomEffect = atomEffect((get, set) => {
  const isGettingAllPatients = get(isGettingAllPatientsAtom);

  if (isGettingAllPatients) return;

  const accumulatedPatients = get.peek(accumulatedPatientsAtom);

  set(patientsAtom, accumulatedPatients);
  set(accumulatedPatientsAtom, []);
});
