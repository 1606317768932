import React from 'react';
import { Image } from '../../components/Image';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elem = `interface Props {
    url: string;
    width: string;
    height?: string;
    borderRadius?: string;
}

export const Image: React.FC<Props> = ({ url, width, height = width, borderRadius }) => {
    return (
        <div
            style={{
                height: height,
                width: width,
                backgroundImage: 'url("url")',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                borderRadius: borderRadius
            }}
        ></div>
    );
};;`;

const Images = () => {
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Images</div>
            <div className='text-lg mt-4'>An image is an element with an image as a background.</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Next some implementations of the element</div>
            <div className='text-base mt-2'>Different square sizes, the size must include the unit.</div>
            <CopyBlock
                language={'jsx'}
                text={`<Image url='https://unsplash.it/54/52' width='55px' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Image url='https://unsplash.it/54/52' width='55px' />
                <Image url='https://unsplash.it/60/60' width='60px' />
                <Image url='https://unsplash.it/63/61' width='4rem' />
                <Image url='https://unsplash.it/62/61' width='4em' />
                <Image url='https://unsplash.it/70/71' width='7vh' />
            </div>
            <div className='text-base mt-2'>Different rectangular sizes, the size must include the unit.</div>
            <CopyBlock
                language={'jsx'}
                text={`<Image url='https://unsplash.it/30/30' width='150px' height='200px'/>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Image url='https://unsplash.it/150/200' width='150px' height='200px' />
                <Image url='https://unsplash.it/200/175' width='200px' height='175px' />
                <Image url='https://unsplash.it/175/200' width='10rem' height='12rem' />
                <Image url='https://unsplash.it/250/180' width='250px' height='180px' />
            </div>
            <div className='text-base mt-2'>Different border radius, the size must include the unit.</div>
            <CopyBlock
                language={'jsx'}
                text={`<Image url='https://unsplash.it/150/200' width='150px' height='200px' borderRadius='10px' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Image url='https://unsplash.it/150/200' width='150px' height='200px' borderRadius='10px' />
                <Image url='https://unsplash.it/150/200' width='150px' height='200px' borderRadius='30px' />
                <Image url='https://unsplash.it/150/200' width='150px' height='200px' borderRadius='40px' />
                <Image url='https://unsplash.it/150/200' width='150px' height='200px' borderRadius='50px' />
                <Image url='https://unsplash.it/150/200' width='150px' height='200px' borderRadius='70px' />
            </div>
        </div>
    );
};

export default Images;
