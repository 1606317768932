import axios from 'axios';

export interface UploadPatomediParams {
  file: FormData;
  contactId: string;
  dealId: string;
  fileId: { deal: string };
}

export const uploadPatomediService = async ({
  file,
  contactId,
  dealId,
  fileId,
}: UploadPatomediParams) => {
  file.append('metadata', JSON.stringify({ contactId, dealId, fileId }));

  try {
    const data = await axios.post('/patients/upload-patomedi', file);
    return data;
  } catch (error) {
    return 'uploadFail';
  }
};
