import { atom } from 'jotai';
import { allQuotesAtom } from '../getters/get-patients-quotes-atom';

const QUOTE_STAGES = {
  ACEPTADO: 'presupuesto aceptado',
};

export const paidAndUnpaidItemsAtom = atom(get => {
  const allQuotes = get(allQuotesAtom);

  if (allQuotes == null) return { paidItems: [], unpaidItems: [] };

  const paidItems = allQuotes
    .filter(quote => quote.Quote_Stage.toLocaleLowerCase() === QUOTE_STAGES.ACEPTADO)
    .sort((a, b) => (a.Subject > b.Subject ? 1 : -1));
  const unpaidItems = allQuotes
    .filter(quote => quote.Quote_Stage.toLocaleLowerCase() !== QUOTE_STAGES.ACEPTADO)
    .sort((a, b) => (a.Subject > b.Subject ? 1 : -1));

  return {
    paidItems,
    unpaidItems,
  };
});
