import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useViewport } from '../../../hooks/use-viewport';
import Input from '../../Inputs/Input';
import { TutorInfoErrors } from '../../../hexagonal-architecture-frontend-base/src/domain/models/User';

interface TutorFieldsDefinition {
  field: string;
  error: string;
  onChange: (e: string) => void;
  title: string;
  value: string;
}

interface TutorFieldsProps {
  tutorFieldsDefinition: TutorFieldsDefinition[];
  isEditing: boolean;
  errorTutorMessage: TutorInfoErrors;
}

export const TutorFields: FC<TutorFieldsProps> = ({
  tutorFieldsDefinition,
  isEditing,
  errorTutorMessage
}) => {
  const { t } = useTranslation();
  const { viewportWidth } = useViewport();

  const isScreenBig = viewportWidth > 768;
  const isScreenTiny = viewportWidth < 321;

  return (
    <>
      {tutorFieldsDefinition.map((field, index) => {
        const title =
          field.field === 'dni'
            ? t(field.title).toUpperCase()
            : GlobalService.uppercaseAllFirstsLetters(t(field.title));
        return (
          <div
            key={index}
            className={
              index
                ? `mt-4 ${
                    !isEditing
                      ? isScreenTiny
                        ? 'tiny-screen-max-width'
                        : isScreenBig
                        ? 'big-screen-max-width'
                        : ''
                      : ''
                  }`
                : `${
                    !isEditing
                      ? isScreenTiny
                        ? 'tiny-screen-max-width'
                        : 'big-screen-max-width'
                      : ''
                  }`
            }
          >
            <p
              className={`Personas-Label ${
                isScreenTiny && !isEditing ? 'tiny-screen-max-width' : ''
              }`}
            >
              {title}:{' '}
            </p>
            {!isEditing ? (
              <p
                className={`Personas-Info ${
                  isScreenTiny && !isEditing ? 'tiny-screen-max-width' : ''
                }`}
              >
                {field.value}
              </p>
            ) : (
              <Input
                id={field.field}
                name={field.field}
                placeholder={title}
                type='text'
                value={field.value || ''}
                customClass='Personas-Input'
                onChange={field.onChange}
              />
            )}
            {errorTutorMessage[`${field.field}Err` as keyof TutorInfoErrors] && isEditing ? (
              <p className='error-message my-2 mx-4'>
                {GlobalService.uppercaseFirstLetter(
                  t(errorTutorMessage[`${field.field}Err` as keyof TutorInfoErrors])
                )}
              </p>
            ) : null}
          </div>
        );
      })}
    </>
  );
};
