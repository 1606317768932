import React, { ReactNode } from 'react';

interface Props {
    status?: boolean;
    onToggle?: () => void;
    bgTrue: string;
    bgFalse: string;
    left?: ReactNode | string;
    right?: ReactNode | string;
}
export const Toggle: React.FC<Props> = ({ status, onToggle, bgTrue, bgFalse, left, right }) => {
    return (
        <div className='px-3 py-2 text-center w-fit flex items-center '>
            {left ? <div className='pr-3'> {left}</div> : null}
            <div
                className={`w-12 h-6 rounded-full top-0 left-0 cursor-pointer relative ${
                    status ? `top-0 left-none right-0 border-none` : ``
                } ${!left && !right ? 'px-4' : ''}`}
                style={{ background: status ? bgTrue : bgFalse }}
                onClick={() => {
                    if (onToggle) onToggle();
                }}
            >
                <div className={`w-4 h-4 rounded-full absolute bg-white top-1 ${status ? 'right-1' : 'left-1'}`}></div>
            </div>
            {right ? <div className='pl-3'> {right}</div> : null}
        </div>
    );
};
