import React from 'react';
import { Avatar } from '../../components/Avatar';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elem = `interface Props {
    url: string;
    size: string;
}

export const Avatar: React.FC<Props> = ({ url, size }) => {
    return (
        <div
            className='rounded-full'
            style={{
                height: size,
                width: size,
                backgroundImage: 'url("{url}")',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat'
            }}
        ></div>
    );
};`;

const Avatars = () => {
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Avatars</div>
            <div className='text-lg mt-4'>An avatar is a round element with an image as a background.</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Next some implementations of the element</div>
            <div className='text-base mt-2'>Different sizes, the size must include the unit.</div>
            <CopyBlock
                language={'jsx'}
                text={`<Avatar url='https://unsplash.it/30/30' size='30px' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Avatar url='https://unsplash.it/54/52' size='55px' />
                <Avatar url='https://unsplash.it/60/60' size='60px' />
                <Avatar url='https://unsplash.it/63/61' size='4rem' />
                <Avatar url='https://unsplash.it/62/61' size='4em' />
                <Avatar url='https://unsplash.it/70/71' size='7vh' />
            </div>
        </div>
    );
};

export default Avatars;
