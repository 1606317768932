import { ISetting } from '../../domain/models/ISettings';
import { SettingsRepository } from '../../domain/repositories/settings.repository';
import { http } from '../plugins/http/http';
import { LOCAL_STORAGE_KEYS } from '../../../../consts';
import { IAuth } from '../../domain/models/ITutor';

export const settingsRepository = (): SettingsRepository => ({
  getLinks: async (): Promise<ISetting[] | string> => {
    try {
      let userIsLogged = false;
      const auth = window.localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH);
      if (auth) {
        const token = (JSON.parse(auth) as IAuth).accessToken;
        userIsLogged = !!token;
      }
      const response = await http.cleanGet('/settings', { private: userIsLogged });
      return response.data as ISetting[];
    } catch (err) {
      return 'error';
    }
  }
});
