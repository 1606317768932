import React from 'react';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

interface Props {
    date: string | number;
    separator?: string;
    type?: string;
    color?: string;
    font?: string;
    weight?: string;
}

export const Date: React.FC<Props> = ({ date, separator = '/', type = 'regular', color, font, weight }) => {
    const { day, weekDay, weekDayAbb, month, monthName, monthNameAbb, year } = GlobalService.parseDate(date);
    const { hour, time, minutes } = GlobalService.parseTime(date);
    const style = {
        color: color,
        fontSize: font,
        fontWeight: weight
    };
    switch (type) {
        case 'regular':
            return <div style={style}>{day + separator + month + separator + year}</div>;
        case 'regularUSA':
            return <div style={style}>{year + separator + month + separator + day}</div>;
        case 'long':
            return <div style={style}>{`${weekDay} ${day} ${monthName} ${year}`}</div>;
        case 'abb':
            return <div style={style}>{`${weekDayAbb} ${day} ${monthNameAbb} ${year}`}</div>;
        case 'longMonth':
            return <div style={style}>{`${day} ${monthName} ${year}`}</div>;
        case 'abbMonth':
            return <div style={style}>{`${day} ${monthNameAbb} ${year}`}</div>;
        case 'regular_with_time':
            return <div style={style}>{day + separator + month + separator + year + ' | ' + hour + ':' + minutes + time}</div>;
        default:
            return <div style={style}>{day + separator + month + separator + year}</div>;
    }
};
