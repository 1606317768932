import React, { useEffect, useState } from 'react';

// Components
import { ButtonBase } from '../Buttons/button-base';
import Modal from './Modal';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { tiendaRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import {
  IOrder,
  IOrderItem,
} from '../../hexagonal-architecture-frontend-base/src/domain/models/IPago';
import { ICategory } from 'src/hexagonal-architecture-frontend-base/src/domain/models/ICategory';

// CSS
import '../../styles/Tienda/Tienda.css';

// Hooks
import { useViewport } from '../../hooks/use-viewport';

interface Props {
  orderItem: IOrder | undefined;
  onclickCancel: (order?: IOrder) => void;
  onclickContinue: (meses: number, order?: IOrder) => void;
  cancelButtonText: string;
  onClose: () => void;
}

const ModalRecurringPurchase: React.FC<Props> = ({
  orderItem,
  onclickCancel,
  onclickContinue,
  cancelButtonText,
  onClose,
}) => {
  const { t } = useTranslation();

  /**********
   * States *
   **********/
  const [meses, setMeses] = useState<number>(orderItem?.frequency || 1);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedOperation, setSelectedOperation] = useState<string>('+');
  const { viewportHeight, viewportWidth } = useViewport();

  // Get data
  useEffect(() => {
    setTimeout(() => {
      tiendaServices(tiendaRepositoryInstance)
        .getCategories()
        .then((categories: ICategory[] | string) => {
          if (typeof categories !== 'string') setCategories(categories);
        });
    }, 1000);
  }, []);

  /************
   * Handlers *
   ************/
  const handleChange = (operation: string) => {
    if (operation === '-') {
      setSelectedOperation('-');
      if (meses > 1) setMeses(meses - 1);
    } else {
      setSelectedOperation('+');
      if (meses < 6) setMeses(meses + 1);
    }
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const activeButton = {
    color: 'white',
    backgroundColor: '#28337d',
  };

  const inactiveButton = {
    color: '#808080',
    backgroundColor: '#EEEEEE',
  };

  const responsiveStyle = {
    alignItems: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: viewportHeight - 88,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 25,
  };

  const modalOptions: any = {
    addTitle: true,
    alignItemsParent: 'flex-end',
    backgroundColorParent: 'rgba(0, 0, 0, 0.5)',
    backgroundColor: 'white',
    borderColor: 'none',
    borderRadius: '25px',
    justifyContent: 'start',
    justifyContentParent: 'flex-end',
    marginChildren: isScreenBig ? 'auto' : '15px auto',
    onClose: onClose,
    overflow: isScreenBig ? 'hidden' : 'scroll',
    padding: isScreenBig ? '2rem' : '0.5rem',
    showButtons: false,
    titleText: GlobalService.uppercaseFirstLetter(t('pages.tienda.recurringPurchase')),
    titleMargin: 'auto',
    width: isScreenBig ? '542px' : '100%',
    forcedChildWidth: isScreenBig ? '542px' : '92%',
    forcedChildHeight: 'auto',
    height: '75vh',
  };

  if (!isScreenBig) {
    modalOptions['parentStyle'] = responsiveStyle;
  }
  const getImageFromItems = (item: IOrderItem): string => {
    let imageUrl = '';
    item?.Products?.image.map((image: string) => {
      if (image !== '' && imageUrl === '') imageUrl = image;
    });
    return imageUrl;
  };

  const getCategoryName = (item: IOrderItem): string => {
    let name = '';
    categories.map((category: ICategory) => {
      if (item.Products.categoryId === category.id) name = category.name;
    });
    return name;
  };

  const desktopModal = (
    <Modal {...modalOptions}>
      <div className='max-h-[45vh] overflow-scroll grid grid-cols-1 gap-y-2 md:gap-y-4 mt-3'>
        {orderItem
          ? orderItem.items.map((item: IOrderItem, index: number) => {
              return (
                <div
                  key={index}
                  className={`recurrent-buy-product ${
                    isScreenBig ? '' : 'text-sm'
                  } p-2 flex items-center gap-2`}
                >
                  <img src={getImageFromItems(item)} className='recurrent-buy-image' />
                  <div>
                    <p>
                      <strong>{item.Products.name}</strong>
                    </p>
                    <p>{getCategoryName(item)}</p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div>
        <div className='flex items-center gap-2 mt-4'>
          <p>
            <strong className={isScreenBig ? '' : 'text-sm'}>
              {GlobalService.uppercaseFirstLetter(t('pages.tienda.deliverEvery'))}:
            </strong>
          </p>
          <div className='flex justify-between items-center w-24 shadow border border-slate-200 rounded-3xl'>
            <button
              className='cart-button'
              style={selectedOperation === '-' ? activeButton : inactiveButton}
              onClick={() => handleChange('-')}
            >
              <p className='symbol'>-</p>
            </button>
            <p>{meses}</p>
            <button
              className='cart-button'
              style={selectedOperation === '+' ? activeButton : inactiveButton}
              onClick={() => handleChange('+')}
            >
              <p className='symbol'>+</p>
            </button>
          </div>
        </div>
        <p className={`${isScreenBig ? 'mt-4' : 'text-sm mt-2'}`}>
          · {GlobalService.uppercaseFirstLetter(t('pages.tienda.noFees'))}
        </p>
        <p className={`${isScreenBig ? '' : 'text-sm'} mb-4`}>
          {GlobalService.uppercaseFirstLetter(t('pages.tienda.cancelWhenYouWant'))}
        </p>
        <div className='flex justify-between gap-2 mt-2 md:mt-6'>
          <ButtonBase
            text={cancelButtonText}
            background='none'
            color='#28337d'
            border='1px solid #28337d'
            borderRadius='50px'
            buttonWidth='237px'
            onClick={() => onclickCancel(orderItem)}
          />
          <ButtonBase
            text={GlobalService.uppercaseFirstLetter(t('continue'))}
            background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
            color={'white'}
            borderRadius={'50px'}
            buttonWidth='237px'
            onClick={() => onclickContinue(meses, orderItem)}
          />
        </div>
      </div>
    </Modal>
  );
  return desktopModal;
};

export default ModalRecurringPurchase;
