import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { usePopup } from '../use-popup';
import {
  useShowAddressUpdateSuccessPopup,
  useShowErrorPopup,
  useUserAddress,
} from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { validate } from '../../../utils';
import { useState } from 'react';
import { AddressData, PopupOptionsSetter } from '../../../ts';
import { AddressInput, EditAddress } from '../../../components';

interface UserAddressErrors {
  Direcci_n?: string;
  Codigo_postal?: string;
  Ciudad?: string;
  Pa_s?: string;
}

export const useShowEditAddressPopup = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = usePopup();
  const { userAddress, updateUserAddress } = useUserAddress();
  const { showAddressUpdateSuccessPopup } = useShowAddressUpdateSuccessPopup();
  const { showErrorPopup } = useShowErrorPopup();
  const [newAddress, setNewAddress] = useState<AddressData>({ ...userAddress });
  const [errorMessage, setErrorMessage] = useState<UserAddressErrors>({
    Direcci_n: '',
    Codigo_postal: '',
    Ciudad: '',
    Pa_s: '',
  });

  const editAddressPopupOptions: PopupOptionsSetter = {
    title: '',
    children: <EditAddress />,
  };

  const showEditAddressPopup = () => {
    openPopup(editAddressPopupOptions);
  };

  return { showEditAddressPopup };
};
