import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';

export const viewportEffectAtom = atomEffect((get, set) => {
  const handleResize = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;

    set(viewportAtom, { height, width });
  };

  if (typeof window !== 'undefined') {
    handleResize();
    window.addEventListener('resize', handleResize);
  }

  return () => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', handleResize);
    }
  };
});

const viewportInitialValue =
  typeof window !== 'undefined'
    ? { height: window.innerHeight, width: window.innerWidth }
    : { height: 0, width: 0 };

export const viewportAtom = atom(viewportInitialValue);
