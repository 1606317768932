import { personasRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';
import { personasServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';
import { UpdateDealParams } from '../../../ts';

export const updateDealService = async ({ Deal_Name, DealId, data }: UpdateDealParams) => {
  const responseData = await personasServices(personasRepositoryInstance).updateDeal(
    Deal_Name,
    DealId,
    data
  );

  if (typeof responseData === 'string') throw new Error(responseData);

  return responseData;
};
