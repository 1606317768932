import { PatientInfo } from 'src/ts';
import { chunkArray } from '../chunk-array';

export const chunkPatientsNames = (patientsList: PatientInfo[]) => {
  const patientsName = patientsList.map(patient => {
    // const patientName = patient.title;
    const patientName = patient.title;
    if (patientName.includes('(')) return patientName.substring(0, patientName.indexOf('('));
    if (patientName.includes(',')) return patientName.substring(0, patientName.indexOf(','));
    return patientName.trim();
  });
  const chunkedPatientsName = chunkArray(patientsName, 100);
  return chunkedPatientsName;
};
