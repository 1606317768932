import React, { useState, useCallback, ReactNode, ReactElement } from 'react';

interface PropsTab {
    label: string;
    text: string;
    iconLeft?: ReactNode | string;
    iconRight?: ReactNode | string;
    children: ReactNode;
}

interface PropsTabs {
    children: ReactElement<PropsTab>[];
    activeDefault?: string;
    doSomething?: (type: string) => void;
}

const Tabs: React.FC<PropsTabs> = ({ children, activeDefault, doSomething }) => {
    const [activeTab, setActiveTab] = useState(activeDefault ? activeDefault : children[0].props.label);
    const handleActiveTab = useCallback((label: string) => setActiveTab(label), []);

    const tabs = children.map(tab => (
        <div
            key={tab.props.label}
            className={
                tab.props.label === activeTab
                    ? 'px-3 py-2 text-center w-fit flex items-center cursor-pointer opacity-100 hover:opacity-75 text-emerald-400 border-b-2 border-emerald-400'
                    : 'px-3 py-2 text-center w-fit flex items-center cursor-pointer opacity-100 hover:opacity-75 border-b-2'
            }
            onClick={e => {
                e.preventDefault();
                handleActiveTab(tab.props.label);
                if (doSomething !== undefined) doSomething(tab.props.text);
            }}
        >
            {tab.props.iconLeft ? <div className={`${tab.props.text ? 'pr-3' : ''}`}> {tab.props.iconLeft}</div> : null}
            {tab.props.text ? <p className={`${!tab.props.iconLeft && !tab.props.iconRight ? 'px-4' : ''}`}>{tab.props.text}</p> : null}
            {tab.props.iconRight ? <div className={`${tab.props.text ? 'pl-3' : ''}`}> {tab.props.iconRight}</div> : null}
        </div>
    ));

    const content = children.filter(tab => tab.props.label === activeTab);

    return (
        <div>
            <div className='flex flex-row mb-4'>{tabs}</div>
            <div>{content}</div>
        </div>
    );
};

function Tab({ children }: PropsTab) {
    return <>{children}</>;
}

export { Tab, Tabs };
