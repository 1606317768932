import styles from './language-selector.module.css';
import { AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES_NAMES } from '../../consts';
import { useLanguage } from '../../hooks/language/use-language';
import { useTranslation } from 'react-i18next';
import { LanguageFlag } from '../icons/flags';
import { Select } from '../selects';
import classNames from 'classnames/bind';
import { SelectProps, AvailableLanguage } from 'src/ts';

const cx = classNames.bind(styles);

export const LanguageSelector = () => {
  const { language, setLanguage } = useLanguage();
  const { t } = useTranslation();

  const handleClickOption = (value: AvailableLanguage) => {
    setLanguage(value);
  };

  const OPTIONS: SelectProps<AvailableLanguage> = {
    label: t('languages.selectDefaultHeader'),
    defaultValue: language,
    setValue: setLanguage,
    options: Object.values(AVAILABLE_LANGUAGES).map(value => ({
      value,
      handleClick: () => handleClickOption(value),
      content: (
        <div className={cx('option_container')}>
          <LanguageFlag language={value} className={cx('flag')} />
          <p>{AVAILABLE_LANGUAGES_NAMES[value]}</p>
        </div>
      )
    }))
  };

  return <Select {...OPTIONS} />;
};
