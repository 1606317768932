import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Card from '../components/Cards/Card';
import { Pagination } from '../components/Pagination';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import TopMenu from './Menus/TopMenu';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { notificationsServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/notifications.service';
import { notificationsRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/notificationsRepository';
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Notificaciones/Notificaciones.css';

// Images
import LeftArrow from '../img/Notificaciones/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import { INotification } from 'src/hexagonal-architecture-frontend-base/src/domain/models/INotification';

// Hooks
import {
  useViewport,
  useSessionValue,
  useGetNotificationExtraInfo,
  useShowErrorPopup,
} from '../hooks';

const Notificaciones = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfo } = useSessionValue();
  const { getNotificationExtraInfo } = useGetNotificationExtraInfo();
  const { showErrorPopup } = useShowErrorPopup();

  /**********
   * States *
   **********/
  const [numberItems] = useState<number>(8);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { viewportWidth } = useViewport();
  const [topMenuUpdate, setTopMenuUpdate] = useState<number>(0);

  const tutorName = userInfo?.name;

  // Get data
  const getNotificationsData = () => {
    setTimeout(() => {
      getNotificationsList(currentPage);
      notificationsServices(notificationsRepositoryInstance)
        .getNotificationsCount()
        .then(count => {
          if (typeof count !== 'string') {
            setTotalPages(Math.ceil(count / numberItems));
          } else {
            setTotalPages(0);
            showErrorPopup(count);
          }
        });
    }, 1000);
  };

  useEffect(() => {
    getNotificationsData();
  }, []);

  /************
   * Handlers *
   ************/
  const handleBackButton = () => {
    const backRoute = localStorage.getItem('backRoute');
    navigate(backRoute as string);
  };

  const handlePagination = (e: number) => {
    setCurrentPage(e);
    setIsLoading(true);
    getNotificationsList(e);
  };

  /**************
   * Functions *
   **************/
  const getNotificationsList = (page: number) => {
    notificationsServices(notificationsRepositoryInstance)
      .getNotifications(numberItems, page)
      .then(data => {
        if (typeof data !== 'string') {
          setNotifications(data);
        } else {
          setNotifications([]);
        }

        setIsLoading(false);
        setTopMenuUpdate(topMenuUpdate => topMenuUpdate + 1);
      });
  };

  const toggleNotification = (id: number) => {
    setNotifications(
      notifications.map((item: any) => {
        if (item.id === id) return { ...item, selected: !item.selected };
        return item;
      }),
    );
  };

  const selectItem = (id: number) => {
    toggleNotification(id);
  };

  const markNotificationAsSeen = (id: number) => {
    notificationsServices(notificationsRepositoryInstance)
      .markNotificationAsSeen(id)
      .then(() => {
        getNotificationsData();
        setTopMenuUpdate(topMenuUpdate => topMenuUpdate + 1);
      });
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const noData = <p>{GlobalService.uppercaseAllFirstsLetters(t('nodata'))}</p>;

  /*******
   * JSX *
   *******/
  const formattedNotifications = notifications.map((notification: any, index: any) => {
    const { title, message, path } = getNotificationExtraInfo(notification);

    return (
      <Card
        key={index}
        id={notification.id}
        numberItems={2}
        height={110}
        padding='1rem'
        borderRadius='10px'
        backgroundColor='white'
        borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
        border={`1px solid ${!notification.seen ? '#FC0E0E' : '#EEEEEE'}`}
        action={() => {
          selectItem(notification.id);
        }}
      >
        <div className='grid grid-cols-1 gap-y-2'>
          <p className='Notificaciones-Text' title={title}>
            {GlobalService.limitText(title, 30)}
          </p>
          <p className='Notificaciones-Comment' title={message}>
            {GlobalService.limitText(message, 150)}
          </p>
          {path == '' ? null : (
            <a
              className='Notificaciones-Link'
              target='_blank'
              href={path}
              rel='noreferrer'
              onClick={() => markNotificationAsSeen(notification.id)}
            >
              {GlobalService.uppercaseFirstLetter(t('pages.topMenu.clickHere'))}
            </a>
          )}
          <p className='Notificaciones-Date'>
            {GlobalService.parseDateForNotification(
              notification.createdAt,
              t('pages.topMenu.lt1hourtime'),
              t('pages.topMenu.1hourtime'),
              t('pages.topMenu.morehourtime'),
              t('pages.topMenu.at'),
            )}
          </p>
        </div>
      </Card>
    );
  });

  const paginator =
    totalPages > 1 ? (
      <div className='mx-auto my-4'>
        <Pagination totalPages={totalPages} currentPage={currentPage} onChange={handlePagination} />
      </div>
    ) : null;

  const topMenuProperties: any = {
    title: `${
      isScreenBig
        ? GlobalService.uppercaseFirstLetter(t('hi'))
        : GlobalService.uppercaseFirstLetter(t('pages.topMenu.notifications'))
    }`,
    fixed: !isScreenBig,
    user: `${isScreenBig ? tutorName : ''}`,
    textCentered: true,
  };

  if (!isScreenBig) {
    topMenuProperties.leftIcon = LeftArrowSmall;
    const backRoute = localStorage.getItem('backRoute');
    topMenuProperties.leftIconLink = backRoute;
  }

  return (
    <div className='w-full'>
      <TopMenu {...topMenuProperties} updateCount={topMenuUpdate}></TopMenu>
      <div className={`m-8`}>
        {isScreenBig ? (
          <div className='flex text-left mb-4'>
            <img
              onClick={handleBackButton}
              src={LeftArrow}
              alt='Back'
              className='Notificaciones-Arrow'
            />
            <Title
              text={GlobalService.uppercaseAllFirstsLetters(t('pages.topMenu.notifications'))}
              color='black'
              size={'24px'}
              margin={'0px 0px 0px 30px'}
            />
          </div>
        ) : null}
        {isLoading ? (
          <div className='mt-24 md:mt-0'>
            <RegularSpinner />
          </div>
        ) : (
          <>
            <div className='notificaciones-height grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-4 pb-4 mt-24 md:mt-2  items-center'>
              {notifications.length ? formattedNotifications : noData}
            </div>
            {paginator}
          </>
        )}
      </div>
    </div>
  );
};

export default Notificaciones;
