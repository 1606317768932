import { personasRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';
import { personasServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';
import { Product } from '../../ts';

export interface ProductIdentifier {
  productId: string;
}

export const getProductByIdService = async ({ productId }: ProductIdentifier) => {
  const data = await personasServices(personasRepositoryInstance).getProduct(productId);

  if (typeof data === 'string') throw new Error(data);

  return data[0] as Product;
};
