// Services
import { settingsServices } from '../../infrastructure/services/settings.services';
import { settingsRepositoryInstance } from '../../infrastructure/instances/settingsRepository';
import { GlobalService } from './Global.service';

// Interfaces
import { ISetting } from '../models/ISettings';
import { IPatientReport } from '../models/IPersona';

/**********
 * Values *
 **********/
let currentSettings: ISetting[] = [];

/***********
 * Helpers *
 ***********/
const getKey = (key: string): string => {
  // console.log('key', key);
  // console.log('currentSettings', currentSettings);
  if (currentSettings.length) {
    const result = currentSettings.find(item => item.key.toLowerCase() === key.toLowerCase())?.value;
    if (result) return result as string;
    return 'settingNotFound';
  }
  return 'settingNotFound';
};

export const SettingsService = {
  getReportLink: (report: IPatientReport): string => {
    const middleLink = `/?quote_id=${report.ReportId}`;
    const nameLink = `&zc_FileName=Informe_Bucodental_${GlobalService.uppercaseAllFirstLettersPlusReplace(
      report?.Contact_Name?.name,
      ' '
    )}_${report?.Identificador}`;
    let linkToPdf = 'https://creatorapp.zohopublic.com/export/coordinacion_dentalresidency/presupuesto/pdf/';
    switch (report.Tipo_de_informe?.toLowerCase()) {
      case 'it':
        linkToPdf = `${linkToPdf}${getKey('pdfKeyIt')}`;
        break;
      case 'boca sana':
        linkToPdf = `${linkToPdf}${getKey('pdfKeyBocaSana')}`;
        break;
      case 'ro':
        linkToPdf = `${linkToPdf}${getKey('pdfKeyRO')}`;
        break;
      case 'no colabora':
        linkToPdf = `${linkToPdf}${getKey('pdfKeyNoColabora')}`;
        break;
    }
    return `${linkToPdf}${middleLink}${nameLink}`;
  },
  getSettings: async (): Promise<ISetting[] | string> => {
    if (!currentSettings.length) {
      const response: ISetting[] | string = await settingsServices(settingsRepositoryInstance).getLinks();
      if (typeof response !== 'string') {
        currentSettings = response;
        return currentSettings;
      } else {
        return response;
      }
    } else {
      return currentSettings;
    }
  },
  getSettingKey: (key: string): string => {
    return getKey(key);
  }
};
