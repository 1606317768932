import React from 'react';
import { Tab, Tabs } from '../../components/Tabs';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faGear } from '@fortawesome/free-solid-svg-icons';

const elem = `interface PropsTab {
    label: string;
    text: string;
    iconLeft?: ReactNode | string;
    iconRight?: ReactNode | string;
    children: ReactNode;
}

interface PropsTabs {
    children: ReactElement<PropsTab>[];
    activeDefault?: string;
    doSomething?: (type: string) => void;
}

const Tabs: React.FC<PropsTabs> = ({ children, activeDefault, doSomething }) => {
    const [activeTab, setActiveTab] = useState(activeDefault ? activeDefault : children[0].props.label);
    const handleActiveTab = useCallback((label: string) => setActiveTab(label), []);

    const tabs = children.map(tab => (
        <div
            key={tab.props.label}
            className={
                tab.props.label === activeTab
                    ? 'px-3 py-2 text-center w-fit flex items-center cursor-pointer opacity-100 hover:opacity-75 text-emerald-400 border-b-2 border-emerald-400'
                    : 'px-3 py-2 text-center w-fit flex items-center cursor-pointer opacity-100 hover:opacity-75 border-b-2'
            }
            onClick={e => {
                e.preventDefault();
                handleActiveTab(tab.props.label);
                if (doSomething !== undefined) doSomething(tab.props.text);
            }}
        >
            {tab.props.iconLeft ? <div className={'{tab.props.text ? 'pr-3' : ''}'}> {tab.props.iconLeft}</div> : null}
            {tab.props.text ? <p className={'{!tab.props.iconLeft && !tab.props.iconRight ? 'px-4' : ''}'}>{tab.props.text}</p> : null}
            {tab.props.iconRight ? <div className={'{tab.props.text ? 'pl-3' : ''}'}> {tab.props.iconRight}</div> : null}
        </div>
    ));

    const content = children.filter(tab => tab.props.label === activeTab);

    return (
        <div>
            <div className='flex flex-row mb-4'>{tabs}</div>
            <div>{content}</div>
        </div>
    );
};

function Tab({ children }: PropsTab) {
    return <>{children}</>;
}

export { Tab, Tabs };`;

const simpleTabs = `<div className='flex my-2 flex-wrap w-auto'>
    <Tabs>
        <Tab label='first' text='First Tab'>
            First element
        </Tab>
        <Tab label='second' text='Second Tab'>
            Second element
        </Tab>
        <Tab label='third' text='Third Tab'>
            Third element
        </Tab>
        <Tab label='forth' text='Forth Tab'>
            Forth element
        </Tab>
    </Tabs>
</div>`;

const simpleDefaultTabs = `<div className='flex my-2 flex-wrap w-auto'>
    <Tabs activeDefault='third'>
        <Tab label='first' text='First Tab'>
            First element
        </Tab>
        <Tab label='second' text='Second Tab'>
            Second element
        </Tab>
        <Tab label='third' text='Third Tab'>
            Third element
        </Tab>
        <Tab label='forth' text='Forth Tab'>
            Forth element
        </Tab>
    </Tabs>
</div>`;

const simpleActionTabs = `<div className='flex my-2 flex-wrap w-auto'>
    <Tabs doSomething={onClick}>
        <Tab label='first' text='First Tab'>
            First element
        </Tab>
        <Tab label='second' text='Second Tab'>
            Second element
        </Tab>
        <Tab label='third' text='Third Tab'>
            Third element
        </Tab>
        <Tab label='forth' text='Forth Tab'>
            Forth element
        </Tab>
    </Tabs>
</div>`;

const leftIconTabs = `<div className='flex my-2 flex-wrap w-auto'>
    <Tabs>
        <Tab label='first' text='First Tab' iconLeft={house}>
            Home element
        </Tab>
        <Tab label='second' text='Second Tab' iconLeft={gear}>
            Settings element
        </Tab>
    </Tabs>
</div>`;

const rigthIconTabs = `<div className='flex my-2 flex-wrap w-auto'>
<Tabs>
    <Tab label='first' text='First Tab' iconRight={house}>
        Home element
    </Tab>
    <Tab label='second' text='Second Tab' iconRight={gear}>
        Settings element
    </Tab>
</Tabs>
</div>`;

const house = <FontAwesomeIcon icon={faHouse} />;
const gear = <FontAwesomeIcon icon={faGear} />;

const TabsContent = () => {
    const onClick = () => {
        alert('you cicked me!');
    };
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Tabs</div>
            <div className='text-lg mt-4'>A tab is an element to switch between the page content.</div>
            <div className='text-lg'>The text must be passed translated and formatted.</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Next some implementations of the element</div>
            <div className='text-base mt-2'>A simple example</div>
            <CopyBlock language={'jsx'} text={simpleTabs} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='flex my-2 flex-wrap w-auto'>
                <Tabs>
                    <Tab label='first' text='First Tab'>
                        First element
                    </Tab>
                    <Tab label='second' text='Second Tab'>
                        Second element
                    </Tab>
                    <Tab label='third' text='Third Tab'>
                        Third element
                    </Tab>
                    <Tab label='forth' text='Forth Tab'>
                        Forth element
                    </Tab>
                </Tabs>
            </div>
            <div className='text-base mt-2'>An example with a default value</div>
            <CopyBlock language={'jsx'} text={simpleDefaultTabs} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='flex my-2 flex-wrap w-auto'>
                <Tabs activeDefault='third'>
                    <Tab label='first' text='First Tab'>
                        First element
                    </Tab>
                    <Tab label='second' text='Second Tab'>
                        Second element
                    </Tab>
                    <Tab label='third' text='Third Tab'>
                        Third element
                    </Tab>
                    <Tab label='forth' text='Forth Tab'>
                        Forth element
                    </Tab>
                </Tabs>
            </div>
            <div className='text-base mt-2'>An example with a simple action</div>
            <CopyBlock language={'jsx'} text={simpleActionTabs} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='flex my-2 flex-wrap w-auto'>
                <Tabs doSomething={onClick}>
                    <Tab label='first' text='First Tab'>
                        First element
                    </Tab>
                    <Tab label='second' text='Second Tab'>
                        Second element
                    </Tab>
                    <Tab label='third' text='Third Tab'>
                        Third element
                    </Tab>
                    <Tab label='forth' text='Forth Tab'>
                        Forth element
                    </Tab>
                </Tabs>
            </div>
            <div className='text-base mt-2'>An example with left icons</div>
            <CopyBlock language={'jsx'} text={leftIconTabs} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='flex my-2 flex-wrap w-auto'>
                <Tabs>
                    <Tab label='first' text='First Tab' iconLeft={house}>
                        Home element
                    </Tab>
                    <Tab label='second' text='Second Tab' iconLeft={gear}>
                        Settings element
                    </Tab>
                </Tabs>
            </div>
            <div className='text-base mt-2'>An example with rigth icons</div>
            <CopyBlock language={'jsx'} text={rigthIconTabs} showLineNumbers={false} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='flex my-2 flex-wrap w-auto'>
                <Tabs>
                    <Tab label='first' text='First Tab' iconRight={house}>
                        Home element
                    </Tab>
                    <Tab label='second' text='Second Tab' iconRight={gear}>
                        Settings element
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default TabsContent;
