import { useState, useEffect } from 'react';
import { INewPatient } from '../../hexagonal-architecture-frontend-base/src/domain/models/ITutor';
import { type TutorInfoErrors } from '../../hexagonal-architecture-frontend-base/src/domain/models/User';
import { validate } from '../../hexagonal-architecture-frontend-base/src/domain/services/user.service';
import { useUpdateUser } from '../';

export const useEditProfile = () => {
  const { user, handleUpdateUser } = useUpdateUser();
  const [isEditing, setIsEditing] = useState(false);
  // Setter => no return
  const [isSaveTutorButtonDisabled, setIsSaveTutorButtonDisabled] = useState<boolean>(true);
  // Value => no return & Setter => no return
  const [userBackup, setUserBackup] = useState<INewPatient>({
    name: user?.name ?? '',
    dni: user?.dni ?? '',
    center: user?.phone ?? '',
  });
  // Value => no return & Setter => no return
  const [userEdit, setUserEdit] = useState<INewPatient>({
    name: user?.name ?? '',
    dni: user?.dni ?? '',
    center: user?.phone ?? '',
  });
  // Setter => no return
  const [errorTutorMessage, setErrorTutorMessage] = useState<TutorInfoErrors>({
    nameErr: '',
    dniErr: '',
    emailErr: '',
  });
  // Setter => no return

  useEffect(() => {
    if (user == null) return;
    const userInfo = {
      name: user.name,
      dni: user.dni,
      center: user.phone,
    };
    setUserBackup(userInfo);
    setUserEdit(userInfo);
  }, [user]);

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setUserEdit(userBackup);
    setErrorTutorMessage({
      nameErr: '',
      dniErr: '',
      emailErr: '',
    });
  };

  const handleUpdateUserInfo = async () => {
    setIsEditing(false);

    const newDni = userEdit.dni;
    const newName = userEdit.name;
    const newPhone = userEdit.center;

    const success = await handleUpdateUser({ newDni, newName, newPhone });

    if (success) {
      setIsSaveTutorButtonDisabled(true);
      return true;
    } else {
      return false;
    }
  };

  const handleTutorValidation = (field: string, value: string) => {
    if (value) {
      const errMsg: string = tutorFieldsDefinition.filter(item => item.field === field)[0].error;
      const fieldToSet = `${field}Err`;
      const validation = validate(value, field as 'dni' | 'phone' | 'name');
      setErrorTutorMessage(error => ({
        ...error,
        [fieldToSet]: validation ? '' : errMsg,
      }));
      setIsSaveTutorButtonDisabled(!validation);
    }
    const newUser = structuredClone(userEdit) as INewPatient;
    const parsedField = field === 'phone' ? 'center' : field;
    newUser[parsedField as 'name' | 'dni' | 'center'] = value;
    setUserEdit(newUser);
  };

  const tutorFieldsDefinition = [
    {
      field: 'name',
      error: 'errors.noNumbersSpecCharsAllowedMax50',
      onChange: (e: string) => handleTutorValidation('name', e),
      title: 'fullName',
      value: userEdit.name,
    },
    {
      field: 'phone',
      error: 'errors.phoneInvalid',
      onChange: (e: string) => handleTutorValidation('phone', e),
      title: 'phoneno',
      value: userEdit.center,
    },
    {
      field: 'dni',
      error: 'errors.dniInvalid',
      onChange: (e: string) => handleTutorValidation('dni', e),
      title: 'dni',
      value: userEdit.dni,
    },
  ];

  return {
    isEditing,
    setIsEditing,
    isSaveTutorButtonDisabled,
    errorTutorMessage,
    handleStartEdit,
    handleCancelEdit,
    handleUpdateUser: handleUpdateUserInfo,
    tutorFieldsDefinition,
  };
};
