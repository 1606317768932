import { useEffect } from 'react';

type UseClickOutside = (ref: React.RefObject<HTMLElement>, onClickOutside: () => void) => void;

/**
 *
 * @param ref Reference of the element to check if click is outside
 * @param onClickOutside Callback function called when click is outside
 * @returns void
 * @description Hook to detect click outside of an element
 */
export const useClickOutside: UseClickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current != null && !ref.current.contains(e.target as Node) && e.button === 0) {
        onClickOutside != null && onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
};
