import React, { useState } from 'react';
import Title from '../components/Title';
import { ButtonBase } from '../components/Buttons/button-base';
import { useNavigate } from 'react-router-dom';
import Input from '../components/Inputs/Input';
import InputDate from '../components/Inputs/InputDate';
import { personasServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';
import { personasRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';

const SolicitudVisitaServicio = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<any>({
    tipo: '',
    fecha: '2023-03-15',
    paciente: 'MARIA TERESA RODRIGUEZ',
  });
  return (
    <div className='flex flex-col gap-2 w-full p-5'>
      <div className='text-center flex justify-between'>
        <Title text='SOLICITUD DE VISTA / SERVICIO' color='black' size={'20px'} />
        <img
          onClick={() => {
            navigate('/notificaciones');
          }}
          src='https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-bell-512.png'
          alt='Puerta'
          className='w-10 h-10'
        />
      </div>

      <div className='mt-16'>
        <p className='mb-2'>Tipo de servicio</p>
        <Input
          id='name'
          name='name'
          placeholder='Enter your name here'
          type='text'
          value={data.tipo}
          onChange={e => setData((data: any) => ({ ...data, tipo: e }))}
          borderRadius='6px'
          isDisabled={false}
        />
        <p className='mb-2 mt-5'>Fecha y hora aproximada</p>
        <InputDate
          startingDate='2023-03-15'
          pickedDate={data.fecha}
          minDate='2023-01-01'
          maxDate='2200-12-31'
          onChange={e => setData((data: any) => ({ ...data, fecha: e }))}
          borderRadius='6px'
        />
        <p className='mt-3 text-xs'>
          Una vez recibamos tu solicitud, nos pondremos en contacto contigo para proporcionarte la
          fecha exacta
        </p>

        <p className='mb-2 mt-5'>Paciente</p>
        <Input
          id='name'
          name='name'
          placeholder='Enter your name here'
          type='text'
          value={data.paciente}
          onChange={e => setData((data: any) => ({ ...data, paciente: e }))}
          borderRadius='6px'
          isDisabled={true}
        />
      </div>
      <div className='absolute bottom-24 w-2/3 left-0 right-0 m-auto'>
        <ButtonBase
          text='ENVIAR SOLICITUD'
          background='#E3E3E3'
          color='#2F3447'
          buttonWidth='100%'
          paddingX={5}
          paddingY={5}
          onClick={() => {
            personasServices(personasRepositoryInstance)
              .sendSolicitud(data)
              .then(() => {
                navigate('/visitas');
              });
          }}
        />
      </div>
    </div>
  );
};

export default SolicitudVisitaServicio;
