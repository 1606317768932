import { personasRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/personasRepository';
import { personasServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/personas.service';
import { Quote, QuoteIdentifier } from '../../../ts';

export const getQuoteByIdService = async ({ quoteId }: QuoteIdentifier) => {
  const data = await personasServices(personasRepositoryInstance).getReportArticles(quoteId);

  if (typeof data === 'string') throw new Error(data);

  return data[0] as Quote;
};
