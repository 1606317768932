import { UserRepository } from '../../domain/repositories/user.repository';

export const userServices = (repository: UserRepository): UserRepository => ({
  createUser: user => {
    // validate username, password & password confirmation
    return repository.createUser(user);
  },
  deleteUser: id => {
    return repository.deleteUser(id);
  },
  getActiveUser: () => {
    return repository.getActiveUser();
  },
  getTutor: () => {
    return repository.getTutor();
  },
  getUser: id => {
    return repository.getUser(id);
  },
  updateUser: user => {
    // validate password and password confirmation
    return repository.updateUser(user);
  },
  verifyEmail: data => {
    return repository.verifyEmail(data);
  }
});
