import React, { useState } from 'react';
import { Pagination } from '../../components/Pagination';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import Card from '../../components/Cards/Card';

const elem = `interface Props {
    totalPages: number;
    currentPage: number;
    currentPageBG?: string;
    buttonBG?: string;
    onChange: (e: number) => void;
}

export const Pagination: React.FC<Props> = ({ totalPages, currentPage, buttonBG, currentPageBG = '#D74121', onChange }) => {
    const handleForward = () => {
        if (currentPage === totalPages) return;
        else onChange(currentPage + 1);
    };

    const handleBackwards = () => {
        if (currentPage === 1) return;
        else onChange(currentPage - 1);
    };

    const handleGoToFirst = () => {
        onChange(1);
    };

    const handleGoToEnd = () => {
        onChange(totalPages);
    };

    return (
        <div className='w-full grid grid-cols-3'>
            {currentPage !== 1 ? (
                <div className='flex gap-1 m-auto'>
                    <button onClick={handleGoToFirst} className='w-11  rounded-full p-1 ' style={{ background: buttonBG }}>
                        <FontAwesomeIcon icon={faBackwardFast} className='opacity-50 pt-1 fa-sm' />
                    </button>
                    <button onClick={handleBackwards} className='w-11 rounded-full p-1 ' style={{ background: buttonBG }}>
                        <FontAwesomeIcon icon={faBackwardStep} className='opacity-50 pt-1 fa-sm' />
                    </button>
                </div>
            ) : null}

            <div className='flex gap-1 ml-2 mr-2 m-auto'>
                {currentPage !== 1 ? <div className='border rounded-full w-full p-1 pr-3 pl-3 '>{currentPage - 1}</div> : null}
                <div className='rounded-full text-white w-full p-1 pr-3 pl-3  bg-slate-400' style={{ background: currentPageBG }}>
                    {currentPage}
                </div>
                {currentPage !== totalPages ? <div className='border rounded-full w-full p-1 pr-3 pl-3 '>{currentPage + 1}</div> : null}
            </div>
            {currentPage !== totalPages ? (
                <div className='flex gap-1 m-auto'>
                    <button onClick={handleForward} className='w-11  rounded-full p-1 ' style={{ background: buttonBG }}>
                        <FontAwesomeIcon icon={faForwardStep} className='opacity-50 pt-1 fa-sm' />
                    </button>
                    <button onClick={handleGoToEnd} className='w-11  rounded-full p-1 ' style={{ background: buttonBG }}>
                        <FontAwesomeIcon icon={faForwardFast} className='opacity-50 pt-1 fa-sm' />
                    </button>
                </div>
            ) : null}
        </div>
    );
};`;

const elemImplementation1 = `<Pagination totalPages={10} currentPage={actualStep} onChange={setActualStep} />`;

const elemImplementation2 = `<Pagination
totalPages={25}
currentPage={actualStep2}
onChange={setActualStep2}
buttonBG='#b3ecec'
currentPageBG='#9fdjf9'
/>`;

const PaginationTest = () => {
    const [actualStep, setActualStep] = useState(1);
    const [actualStep2, setActualStep2] = useState(1);
    const getStepContent = (step: number) => {
        switch (step) {
            case 1:
                return (
                    <div>
                        Page 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget luctus ipsum. Nunc at dui finibus,
                        vulputate ipsum et, ultricies tellus. Etiam magna nisl, facilisis blandit arcu quis, venenatis semper justo. Aliquam
                        quis tempus libero. Nulla facilisi. Sed facilisis urna eu sapien pretium posuere. Integer viverra mi in leo
                        imperdiet tempus.
                    </div>
                );
            case 2:
                return (
                    <div>
                        Page 2: Fusce rutrum in arcu sed euismod. Etiam euismod tincidunt metus id sodales. Integer tincidunt libero ac
                        mauris imperdiet luctus. Aenean convallis commodo nibh, eget elementum ex pellentesque id.
                    </div>
                );
            case 3:
                return (
                    <div>
                        Page 3: Mauris eget mi id ex congue elementum. Orci varius natoque penatibus et magnis dis parturient montes,
                        nascetur ridiculus mus. Duis porta sodales tortor vitae accumsan. In mi magna, molestie et ex vel, auctor fringilla
                        purus. Maecenas est libero, maximus eget vulputate non, tincidunt nec tellus. Integer eget nibh vel arcu consequat
                        luctus.
                    </div>
                );
            case 4:
                return (
                    <div>
                        Page 4: Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam vestibulum
                        vel sem quis ornare. Morbi at urna eget ex ultrices lobortis. Quisque erat nisi, posuere eu quam sit amet, sodales
                        aliquet dolor. Quisque lacinia arcu at lectus rutrum, vitae efficitur massa convallis.
                    </div>
                );
            case 5:
                return (
                    <div>
                        Page 5: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget luctus ipsum. Nunc at dui finibus,
                        vulputate ipsum et, ultricies tellus. Etiam magna nisl, facilisis blandit arcu quis, venenatis semper justo. Aliquam
                        quis tempus libero. Nulla facilisi. Sed facilisis urna eu sapien pretium posuere. Integer viverra mi in leo
                        imperdiet tempus.
                    </div>
                );
            case 6:
                return (
                    <div>
                        Page 6: Mauris eget mi id ex congue elementum. Orci varius natoque penatibus et magnis dis parturient montes,
                        nascetur ridiculus mus. Duis porta sodales tortor vitae accumsan. In mi magna, molestie et ex vel, auctor fringilla
                        purus. Maecenas est libero, maximus eget vulputate non, tincidunt nec tellus. Integer eget nibh vel arcu consequat
                        luctus.
                    </div>
                );
            case 7:
                return (
                    <div>
                        Page 7: Fusce rutrum in arcu sed euismod. Etiam euismod tincidunt metus id sodales. Integer tincidunt libero ac
                        mauris imperdiet luctus. Aenean convallis commodo nibh, eget elementum ex pellentesque id.
                    </div>
                );
            case 8:
                return (
                    <div>
                        Page 8: Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam vestibulum
                        vel sem quis ornare. Morbi at urna eget ex ultrices lobortis. Quisque erat nisi, posuere eu quam sit amet, sodales
                        aliquet dolor. Quisque lacinia arcu at lectus rutrum, vitae efficitur massa convallis.
                    </div>
                );
            case 9:
                return (
                    <div>
                        Page 9: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget luctus ipsum. Nunc at dui finibus,
                        vulputate ipsum et, ultricies tellus. Etiam magna nisl, facilisis blandit arcu quis, venenatis semper justo. Aliquam
                        quis tempus libero. Nulla facilisi. Sed facilisis urna eu sapien pretium posuere. Integer viverra mi in leo
                        imperdiet tempus.
                    </div>
                );
            case 10:
                return (
                    <div>
                        Page 10: Fusce rutrum in arcu sed euismod. Etiam euismod tincidunt metus id sodales. Integer tincidunt libero ac
                        mauris imperdiet luctus. Aenean convallis commodo nibh, eget elementum ex pellentesque id.
                    </div>
                );
        }
    };

    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Pagination</div>
            <div className='text-lg mt-4'>A Component used to handle different pages.</div>
            {/*  eslint-disable-next-line */}
            <p>'currentPage' and 'totalPages' are mandatory for the component to work.</p>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Next some implementations of the component</div>
            <CopyBlock language={'jsx'} text={elemImplementation1} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='flex justify-center'>
                <Card
                    id='1'
                    topImage='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZpPDnjQBynSxEHYXo-orWYtvNybtifvhMUdsVjEhuOFpkDYPshISj_TpITVzJullxW4&usqp=CAU'
                    width={300}
                    padding='3rem'
                    borderRadius='0px'
                    backgroundColor='white'
                    borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
                    action={() => console.log('Card clicked!')}
                >
                    <div>{getStepContent(actualStep)}</div>
                </Card>
            </div>
            <div className=' m-auto mb-8'>
                <Pagination totalPages={10} currentPage={actualStep} onChange={setActualStep} />
            </div>
            <p>Implementation with custom colors</p>
            <CopyBlock language={'jsx'} text={elemImplementation2} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className=' m-auto mb-8'>
                <Pagination
                    totalPages={25}
                    currentPage={actualStep2}
                    onChange={setActualStep2}
                    buttonBG='#b3ecec'
                    currentPageBG='#9fdjf9'
                />
            </div>
        </div>
    );
};

export default PaginationTest;
