import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import TopMenu from './Menus/TopMenu';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Personas/Personas.css';

// Images
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';

// Hooks
import {
  useViewport,
  usePatientsValue,
  useSelectedPatient,
  useSettings,
  useGetSinglePatientAttachedFiles,
} from '../hooks';
import { DocumentsCard } from '../components/documents/documents-card';
import { useDocumentCardsObject } from '../hooks/documents/use-document-cards-object';
import { DocsCard } from '../ts';

// import * as fs from 'fs/promises';

const Documentos = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { viewportWidth } = useViewport();
  const { patientId } = useParams();
  const { patients, isLoading: isPatientsLoading } = usePatientsValue();
  const { isLoading: isSettingsLoading } = useSettings();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { getSinglePatientAttachedFiles, isLoading: isLoadingAttachedFiles } =
    useGetSinglePatientAttachedFiles();

  const isLoading = isPatientsLoading || isSettingsLoading || isLoadingAttachedFiles;

  const { documentos } = useDocumentCardsObject(isLoading);

  /*********
   * Hooks *
   *********/
  useEffect(() => {
    if (patients.length === 0) return;
    setSelectedPatient(patientId as string);
  }, [patients]);

  // TODO do inside the selected patient hook
  useEffect(() => {
    if (
      selectedPatient.attachments != null ||
      isLoadingAttachedFiles ||
      !selectedPatient.eternalReviewAuth
    ) {
      return;
    }

    getSinglePatientAttachedFiles({ patientId: selectedPatient.id });
  }, [selectedPatient]);

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;

  const formattedDocs =
    documentos == null
      ? null
      : Object.values(documentos).map((item: DocsCard) => {
          if (item.hidden) return null;
          return (
            <DocumentsCard
              key={item.documentCard.title}
              isLoading={item.documentCard.isLoading}
              title={item.documentCard.title}
              icons={item.documentCard.icons}
              notification={item.documentCard.notification}
            />
          );
        });
  const noData = <p>{GlobalService.uppercaseAllFirstsLetters(t('nodata'))}</p>;
  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('historyOf'))}:`;

  /**********
   * Modals *
   **********/

  // Responsive values
  const responsivePageTitle = GlobalService.uppercaseAllFirstsLetters(t('documentation'));

  /*******
   * JSX *
   *******/
  return (
    <div className='w-full'>
      {isScreenBig ? (
        <>
          <TopMenu title={pageTitle} user={selectedPatient.title}></TopMenu>
          <div className='w-full p-5'>
            <div className='flex text-left'>
              <img
                onClick={() => {
                  navigate(`/datosDocumentos/${patientId}/1`);
                }}
                src={LeftArrow}
                alt='Back'
                className='Notificaciones-Arrow cursor-pointer'
              />
              <Title
                text={`${GlobalService.uppercaseFirstLetter(t('documentation'))} :`}
                color='black'
                size={'24px'}
                margin={'0px 0px 0px 30px'}
              />
            </div>
            <div className='grid grid-cols-1 xl:grid-cols-2 gap-y-6 gap-x-8 w-full p-5'>
              {isLoading ? <RegularSpinner /> : documentos != null ? formattedDocs : noData}
            </div>
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title={responsivePageTitle}
            leftIcon={LeftArrowSmall}
            leftIconLink={`/datosDocumentos/${patientId}/1`}
          ></TopMenu>
          <div className='grid gap-y-6 gap-x-8 w-full p-5 mt-24'>
            {isLoading ? <RegularSpinner /> : documentos != null ? formattedDocs : noData}
          </div>
        </>
      )}
    </div>
  );
};

export default Documentos;
