import React from 'react';
import Title from '../../components/Title';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elemTitle = `interface Props {
    text: string;
    size?: string | number;
    color: string;
    fontWeight?: string | number;
}

const Title: React.FC<Props> = ({ text, size, color, fontWeight }) => {
    const titleStyle: CSSProperties = {
        fontSize: size,
        color: color,
        fontWeight: fontWeight
    };

    return <div style={titleStyle}>{text}</div>;
};`;

const elemImplementation = `<Title text='Big title of example' color='red' fontWeight={'bold'} />`;
const elemImplementation1 = `<Title text='A title goes here....' color='blue' size={'24px'} />`;
const elemImplementation2 = `<Title text='A title' color='green' size={'50px'} />`;

const TitleTest = () => {
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Title Component</div>
            <div className='text-lg'>Renders the passed text.</div>
            <div>Font size, weight and color are customizable.</div>
            <CopyBlock language={'jsx'} text={elemTitle} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-12 '>Implementations of the Input with different colors and sizes.</div>
            <div className='mt-8'>
                <CopyBlock
                    language={'jsx'}
                    text={elemImplementation}
                    showLineNumbers={true}
                    theme={atomOneDark}
                    wrapLines={true}
                    codeBlock
                />
                <Title text='Title of example' color='red' fontWeight={'bold'} />
            </div>
            <div className='mt-8'>
                <CopyBlock
                    language={'jsx'}
                    text={elemImplementation1}
                    showLineNumbers={true}
                    theme={atomOneDark}
                    wrapLines={true}
                    codeBlock
                    className
                />
                <Title text='A title goes here....' color='blue' size={'24px'} fontWeight={'200'} />
            </div>
            <div className='mt-8'>
                <CopyBlock
                    language={'jsx'}
                    text={elemImplementation2}
                    showLineNumbers={true}
                    theme={atomOneDark}
                    wrapLines={true}
                    codeBlock
                    className
                />
                <Title text='A Big title' color='green' size={'50px'} />
            </div>
        </div>
    );
};

export default TitleTest;
