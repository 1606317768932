import { IdToPatientExtraInfo, PatientInfo, PatientQuote } from '../../ts';
import { parseFechaVisita } from '../parse-fecha-visita';

export const assignQuotesToPatients = (
  patientsList: PatientInfo[],
  reports: IdToPatientExtraInfo<PatientQuote>,
) => {
  const newPatientsList: PatientInfo[] = [];
  for (const patient of patientsList) {
    const { id } = patient;
    const patientReports = reports[id] || [];

    if (patientReports.length === 0) {
      const newPatient: PatientInfo = { ...patient };
      newPatientsList.push(newPatient);
      continue;
    }

    const parsedPatientReports = patientReports
      .sort((a, b) => {
        return new Date(a.Fecha_de_la_Visita) > new Date(b.Fecha_de_la_Visita) ? 1 : -1;
      })
      .map(report => {
        const dates = parseFechaVisita(report.Fecha_de_la_Visita);
        return {
          ...report,
          Fecha_de_la_Visita: dates.fecha,
          Fecha_de_la_Visita_raw: dates.fechaRaw,
        };
      });

    const pendingReports = parsedPatientReports.some(
      report =>
        report.Quote_Stage.toLocaleLowerCase().includes('presupuesto revisado') ||
        report.Quote_Stage.toLocaleLowerCase().includes('presupuesto en espera'),
    );

    const newPatient: PatientInfo = {
      ...patient,
      reportsDetails: [...parsedPatientReports],
      pendingReports,
    };

    newPatientsList.push(newPatient);
  }

  return newPatientsList;
};
