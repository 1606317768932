import axios from 'axios';

interface GetInvoicesParams {
  patientId?: string;
}

export const getInvoicesService = async ({ patientId }: GetInvoicesParams) => {
  if (patientId == null) return [];

  const facturas = await axios.get('/invoices', {
    params: { id: patientId, page: 0, perPage: 200 },
  });

  if (facturas.data == null) throw new Error('noInvoicesFound');

  return facturas.data;
};
