import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { ButtonBase } from '../components/Buttons/button-base';
import { Pagination } from '../components/Pagination';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import { Tag } from '../components/Tags/Tag';
import TopMenu from './Menus/TopMenu';
import ModalInfo from '../components/Modals/ModalInfo';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { SettingsService } from '../hexagonal-architecture-frontend-base/src/domain/services/settings.service';
import { tiendaRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';

// CSS
import '../styles/Tienda/Tienda.css';

// Models
import { ICategory } from 'src/hexagonal-architecture-frontend-base/src/domain/models/ICategory';
import { IShopItem } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IShopItem';
import { ICartItem } from 'src/hexagonal-architecture-frontend-base/src/domain/models/ICartItem';
import { ICart } from 'src/hexagonal-architecture-frontend-base/src/domain/models/ICart';

// Hooks
import { useViewport } from '../hooks/use-viewport';

// TODO - use Jotai state here
const Tienda = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const iva = 0.21;

  // States
  const [cache, setCache] = useState<IShopItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<ICategory[]>([]);
  const [items, setItems] = useState<IShopItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [numberItems, setNumberItems] = useState<number>(8);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [totalCartItems, setTotalCartItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { viewportWidth } = useViewport();

  if ((window.innerHeight === 1366 || window.innerHeight === 600) && window.innerWidth === 1024) {
    if (numberItems !== 9) setNumberItems(9);
  }
  /*********
   * Hooks *
   *********/

  //Get data when page changes
  useEffect(() => {
    getShopItems(selectedFilter, currentPage);
  }, [currentPage]);

  //Get data when searching
  useEffect(() => {
    setCurrentPage(1);
    getShopItems();
  }, [searchText]);

  // Get data
  useEffect(() => {
    getCategories();
    getShopItems();
    calculateTotalCartItems();
    SettingsService.getSettings().then(response => {
      if (typeof response !== 'string') {
        if (SettingsService.getSettingKey('hideShop') !== 'false') navigate('/');
      }
    });
  }, []);

  // Get screen Height & Width
  useEffect(() => {
    if (viewportWidth >= 1280) setNumberItems(8); // With 4 columns
    if (viewportWidth >= 1024) setNumberItems(9); // With 3 columns
    else setNumberItems(8); // With 2 columns
  }, [viewportWidth]);

  /************
   * Handlers *
   ************/
  const handleFilterSelection = (id: number) => {
    setCurrentPage(1);
    setSelectedFilter(id === selectedFilter ? 0 : id);
    getShopItems(id, 1);
  };

  const handlePagination = (e: number) => {
    setCurrentPage(e);
  };

  const handleAddToBasket = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    id: number,
  ) => {
    e.stopPropagation();
    let quantity = 1;

    await tiendaServices(tiendaRepositoryInstance)
      .getBasketItems()
      .then(async (res: ICart | string) => {
        if (typeof res !== 'string') {
          const selectedItem: ICartItem | undefined = res.items.find(item => item.productId === id);
          if (selectedItem) quantity += selectedItem.quantity;

          const message = await tiendaServices(tiendaRepositoryInstance).addItemToBasket(
            id,
            quantity,
          );

          setModalMessage(message);
          setModalTitle('confirmed');
          setShowModal(true);
        } else {
          setModalMessage(res);
          setShowModal(true);
        }
        setLoading(false);
      });
    calculateTotalCartItems();
  };

  const handleClickModal = () => {
    setModalMessage('');
    setModalTitle('');
    setShowModal(false);
  };

  /***********
   * Helpers *
   ***********/
  const calculateTotalCartItems = async () => {
    await tiendaServices(tiendaRepositoryInstance)
      .getBasketItems(true)
      .then((res: ICart | string) => {
        if (typeof res !== 'string') {
          setTotalCartItems(res.details.totalItems);
        } else {
          setModalMessage(res);
          setModalTitle('error');
          setShowModal(true);
        }
        setLoading(false);
      });
  };

  const getCategories = () => {
    tiendaServices(tiendaRepositoryInstance)
      .getCategories()
      .then((res: ICategory[] | string) => {
        if (typeof res !== 'string') setFilters(res);
        else {
          setModalMessage(res);
          setModalTitle('error');
          setShowModal(true);
        }
      });
  };

  const getShopItems = async (filters = selectedFilter, page = 1) => {
    setLoading(true);
    if (cache.length) {
      const data = serveFromCache(filters, page);
      setItems(data.data);
      setTotalPages(data.totalPages);
      setLoading(false);
    } else {
      await tiendaServices(tiendaRepositoryInstance)
        .getProductos(filters, searchText, 100, page)
        .then((res: IShopItem[] | string) => {
          if (typeof res !== 'string') {
            const editedItems = res.map((item: IShopItem) => {
              item.formattedPrice = tiendaServices(tiendaRepositoryInstance).formatPrice(
                item.price,
              );
              return item;
            });
            setItems(startCache(editedItems, numberItems, page));
          } else {
            setModalMessage(res);
            setModalTitle('error');
            setShowModal(true);
          }

          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  };

  const serveFromCache = (filters: number, page: number) => {
    const filteredData = cache.filter(item => item.categoryId === filters);
    const searchFilteredData = searchText
      ? filteredData.filter(item => item.name?.toLowerCase().includes(searchText.toLowerCase()))
      : filteredData;
    const searchCache = searchText
      ? cache.filter(item => item.name?.toLowerCase().includes(searchText.toLowerCase()))
      : cache;
    const data = filters
      ? searchFilteredData.slice((page - 1) * numberItems, numberItems * page)
      : searchCache.slice((page - 1) * numberItems, numberItems * page);
    const totalPages = filters
      ? Math.ceil(searchFilteredData.length / numberItems)
      : Math.ceil(searchCache.length / numberItems);
    return { totalPages, data };
  };

  const startCache = (products: IShopItem[], per_page: number, page: number): IShopItem[] => {
    setCache(products);
    setTotalPages(Math.ceil(products.length / numberItems));
    return products.slice((page - 1) * numberItems, numberItems * page);
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t('pages.tienda.title'))}`;
  const isScreenBig = viewportWidth > 769;
  const handleFormatFilters = () => {
    const formatedFilters = filters.map((item: ICategory, index: number) => (
      <div key={index} onClick={() => handleFilterSelection(item.id)}>
        {isScreenBig ? (
          <Tag
            text={item.name}
            color='#2F3447'
            weight={item.id === selectedFilter ? '500' : '400'}
            paddingLeft={0}
            paddingRight={0}
            extraClass='cursor-pointer'
          />
        ) : (
          <Tag
            text={item.name.split(' ')[0]}
            color='#2F3447'
            fontSize='14px'
            paddingLeft={0}
            paddingRight={0}
            weight={item.id === selectedFilter ? '700' : '400'}
          />
        )}

        {item.id === selectedFilter ? <div className='is-selected'></div> : ''}
      </div>
    ));

    return formatedFilters;
  };
  const formatedShopItems = items.map((item: IShopItem, index: number) => (
    <div
      key={index}
      onClick={() => {
        navigate(`/producto/${item.id}`);
      }}
      className='product-card'
    >
      <img
        className='product-image md:h-56 h-36'
        src={item.image.length > 0 ? item.image[0] : ''}
        alt={item.name}
      />
      <div className='product-information mt-2'>
        {isScreenBig ? (
          <p>{item.name}</p>
        ) : (
          <p className='product-minHeight text-sm'>{item.name}</p>
        )}
        <p style={{ color: '#28337d' }}>
          {tiendaServices(tiendaRepositoryInstance).formatPrice(item.price * (1 + iva))}
        </p>
      </div>
      <div
        className={`mt-2 w-full ${isScreenBig ? '' : 'text-sm'}`}
        onClick={(e: any) => handleAddToBasket(e, item.id)}
      >
        <ButtonBase
          text={GlobalService.uppercaseFirstLetter(t('pages.tienda.addToBasket'))}
          background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
          color={'white'}
          borderRadius={'50px'}
          buttonWidth='100%'
          fontSize={isScreenBig ? '16px' : '14px'}
        />
      </div>
    </div>
  ));
  const noData = <p>{GlobalService.uppercaseAllFirstsLetters(t('nodata'))}</p>;
  const paginator = (
    <div className='m-auto pb-4'>
      <Pagination totalPages={totalPages} currentPage={currentPage} onChange={handlePagination} />
    </div>
  );

  // HTML
  return (
    <div className='w-full overflow-hidden'>
      {isScreenBig ? (
        <>
          <TopMenu
            title={GlobalService.uppercaseAllFirstsLetters(t('pages.tienda.title'))}
            searchInput={true}
            searchValue={searchText}
            handleSearch={setSearchText}
            cartiItems={totalCartItems}
          />
          <div className='flex flex-col gap-2 w-full'>
            <div className='w-full bg-white px-10'>
              <div className='flex overflow-x-auto gap-16 cursor-pointer'>
                {handleFormatFilters()}
              </div>
            </div>
            <div className='lg:px-10 px-4 products-height'>
              {loading ? (
                <div className='my-16'>
                  <RegularSpinner />
                </div>
              ) : (
                <div className='tienda-height grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center my-5'>
                  {items.length ? formatedShopItems : noData}
                </div>
              )}
              {totalPages > 1 && !loading ? paginator : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title={
              viewportWidth > 1100
                ? GlobalService.uppercaseAllFirstsLetters(t('pages.tienda.title'))
                : ''
            }
            searchInput={true}
            searchValue={searchText}
            handleSearch={setSearchText}
            cartiItems={totalCartItems}
          ></TopMenu>
          <div className='flex flex-col gap-2 w-full py-2 px-4 mt-16'>
            <div className='flex overflow-x-auto gap-4 bg-grey cursor-pointer'>
              {handleFormatFilters()}
            </div>
          </div>
          <div className='products-height-mobile flex flex-col gap-2 w-full py-2 px-4'>
            {loading ? (
              <div className='my-4'>
                <RegularSpinner />
              </div>
            ) : (
              <div className='grid grid-cols-2 gap-4 justify-center'>
                {items.length ? formatedShopItems : noData}
              </div>
            )}
          </div>
          {totalPages > 1 && !loading ? paginator : null}
        </>
      )}

      {showModal ? (
        <ModalInfo
          titleText={modalTitle}
          bodyText={modalMessage}
          buttonText='Cerrar'
          onClickOk={handleClickModal}
        />
      ) : null}
    </div>
  );
};

export default Tienda;
