import { FC } from 'react';
import { ButtonBase } from '../../Buttons/button-base';
import { useViewport } from '../../../hooks/use-viewport';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

interface TutorButtons {
  isEditing: boolean;
  isSaveTutorButtonDisabled: boolean;
  handleClickEdit: () => void;
  handleOnClickSignOut: () => void;
  handleCancelEdit: () => void;
  handleClickUpdateUser: () => Promise<void>;
}

export const TutorButtons: FC<TutorButtons> = ({
  isEditing,
  isSaveTutorButtonDisabled,
  handleClickEdit,
  handleOnClickSignOut,
  handleCancelEdit,
  handleClickUpdateUser,
}) => {
  const { t } = useTranslation();
  const { viewportWidth } = useViewport();
  const isScreenBig = viewportWidth > 768;

  return (
    <>
      {!isEditing ? (
        <div className='absolute h-full top-0 right-4 flex flex-col gap-6 py-4'>
          <div className='flex h-full justify-between flex-col gap-1'>
            <ButtonBase
              background='#FFFFFF'
              border='1px solid #28337D'
              borderRadius='50px'
              buttonWidth={isScreenBig ? '130px' : '80px'}
              color='#28337D'
              extraClass='cursor-pointer'
              fontSize={isScreenBig ? '16px' : '10px'}
              margin='0 0 10px 0'
              paddingY={4}
              onClick={handleClickEdit}
              text={GlobalService.uppercaseFirstLetter(t('edit'))}
            />
            <ButtonBase
              background='#FFFFFF'
              buttonWidth={isScreenBig ? '130px' : '80px'}
              border='1px solid #28337D'
              borderRadius='50px'
              color='#28337D'
              extraClass='cursor-pointer'
              fontSize={isScreenBig ? '16px' : '10px'}
              onClick={handleOnClickSignOut}
              paddingY={4}
              text={GlobalService.uppercaseFirstLetter(t('pages.personas.signout'))}
            />
          </div>
        </div>
      ) : (
        <div className='grid grid-cols-2 gap-x-2 mt-12'>
          <ButtonBase
            text={GlobalService.uppercaseAllFirstsLetters(t('cancel'))}
            background='linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'
            color='white'
            border={` ${isSaveTutorButtonDisabled ? '1px solid #28337D' : ''}`}
            borderRadius='50px'
            paddingY={4}
            onClick={handleCancelEdit}
            fontSize={isScreenBig ? '16px' : '10px'}
            buttonWidth={isScreenBig ? '100%' : '110px'}
          />
          <ButtonBase
            text={GlobalService.uppercaseAllFirstsLetters(t('saveChanges'))}
            background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
            color='white'
            border={` ${isSaveTutorButtonDisabled ? '1px solid #28337D' : ''}`}
            borderRadius='50px'
            paddingY={4}
            onClick={handleClickUpdateUser}
            fontSize={isScreenBig ? '16px' : '10px'}
            buttonWidth={isScreenBig ? '100%' : '110px'}
            disabled={isSaveTutorButtonDisabled}
          />
        </div>
      )}
    </>
  );
};
