import { useShowErrorPopup } from './use-show-error-popup';

export const useShowFileDownloadError = () => {
  const { showErrorPopup } = useShowErrorPopup();

  const showFileDownloadError = () => {
    showErrorPopup('invalidFile');
  };

  return { showFileDownloadError };
};
