import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { optionItem } from '../../hexagonal-architecture-frontend-base/src/domain/models/Input';
import { phonePrefix } from '../../hexagonal-architecture-frontend-base/src/domain/models/PhonePrefix';
import { inputServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/input.services';

interface Props {
    placeholder: string;
    options?: Array<optionItem | phonePrefix>;
    selectedOption?: Array<optionItem | phonePrefix>;
    onChange?: (value: Array<optionItem | phonePrefix>) => void;
    selectedOptionBG: string;
    isMulti?: boolean;
    singleChoice?: boolean;
    borderStyle?: string;
    borderStyleRadius?: string;
    param: string;
    isDisabled?: boolean;
    width?: string;
    noDelete?: boolean;
}

const InputSelector: React.FC<Props> = ({
    placeholder,
    options,
    selectedOption,
    onChange,
    selectedOptionBG,
    isMulti,
    singleChoice,
    borderStyle,
    borderStyleRadius,
    param,
    isDisabled,
    // eslint-disable-next-line
    width,
    noDelete
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const arrayOptionsSelected: Array<optionItem | phonePrefix> | undefined = selectedOption;

    useEffect(() => {
        const handler = () => setShowOptions(false);
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    });

    const handleInputClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (isDisabled) return;
        e.stopPropagation();
        // eslint-disable-next-line
        setShowOptions(!showOptions);
    };

    const isSelected = (option: string) => {
        // eslint-disable-next-line
        return inputServices().isSelected(option, selectedOption!, param);
    };

    const handleOnChange = (option: optionItem | phonePrefix, isMulti: boolean | undefined, removeItem: boolean) => {
        // eslint-disable-next-line
        onChange!(
            // eslint-disable-next-line
            inputServices().handleOnChange(arrayOptionsSelected!, option, isMulti!, removeItem, singleChoice ? singleChoice : false, param)
        );
    };

    const onTagRemove = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, option: optionItem | phonePrefix) => {
        e.stopPropagation();
        handleOnChange(option, false, true);
    };

    return (
        <div className='scrollbar-hidden text-left leading-tight'>
            <div
                onClick={handleInputClick}
                style={{ border: borderStyle, borderRadius: borderStyleRadius, backgroundColor: isDisabled ? 'rgb(226 232 240)' : 'wite' }}
                className='dropdown-input flex items-center justify-between select-none '
            >
                <div className='ml-2'>
                    {selectedOption?.length ? (
                        <div className='flex max-w-52 overflow-auto'>
                            {selectedOption.map((element: optionItem | phonePrefix, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className='m-0.5  pl-2 pr-2 text-sm rounded flex items-center'
                                        style={{ background: selectedOptionBG }}
                                    >
                                        <p className='overflow-hidden text-ellipsis whitespace-nowrap'>
                                            {element[param as keyof typeof element]}
                                        </p>
                                        {noDelete ? null : (
                                            <span onClick={e => onTagRemove(e, element)} className='pl-2'>
                                                <FontAwesomeIcon
                                                    icon={faCircleXmark}
                                                    size='xs'
                                                    className='opacity-50 hover:cursor-pointer'
                                                />
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className='text-sm text-slate-400'>{placeholder}</div>
                    )}
                </div>
                <div>
                    <div className='p-2 pr-4'>
                        {!showOptions ? (
                            <FontAwesomeIcon icon={faAngleDown} className='opacity-50' />
                        ) : (
                            <FontAwesomeIcon icon={faAngleUp} className='opacity-50' />
                        )}
                    </div>
                </div>
                {showOptions ? (
                    <div
                        style={{ width: 'auto' }}
                        className='absolute z-10  mt-44 -ml-0.5 overflow-auto transform scale-4 border  border-gray-200 rounded  max-h-36 bg-white'
                    >
                        {options?.map((option: optionItem | phonePrefix, index: number) => (
                            <div
                                key={index}
                                onClick={() => handleOnChange(option, isMulti, false)}
                                className='p-2 cursor-pointer hover:bg-slate-100'
                                style={{ background: isSelected(option[param as keyof typeof option]) ? selectedOptionBG : '' }}
                            >
                                {option[param as keyof typeof option]}
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default InputSelector;
