import { signRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/signRepository';
import { signServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/sign.service';
import { SignTemplate, SignUrlIdentifier } from '../../../ts';

export const getSignUrlService = async ({ data, docId }: SignUrlIdentifier) => {
  docId ??= process.env.REACT_APP_TO_SIGN_TEMPLATE_ID as string;
  const response = await fetchFunction({ data, docId });

  if (typeof response === 'string') throw new Error(response);

  return response.data as { url: string; docId: string; reqId: string };
};

// Temporary function to avoid data type error until the type is fixed on the signRepositoryInstance
const fetchFunction = async ({ data, docId }: SignUrlIdentifier) => {
  return await signServices(signRepositoryInstance).getSignUrl({
    data: data as SignTemplate,
    docId: docId as string
  });
};
