import React from 'react';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import TableWrapper from '../../components/Tables/TableWrapper';
import { listServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/list.services';
import { listRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/listRepository';

const Tables = () => {
    const useFunc = `interface SpecialColumn {
        column: number;
        element: string;
        module: string;
    }

    interface TableProps {
        setRowValue?: (value: string) => void;
        setModal?: (value: boolean) => void;
        modalState?: boolean;
        filterSearch: boolean;
        borderTable?: string;
        borderRow?: string;
        padding?: string;
        textAlignTable?: CSSProperties['textAlign'] | undefined;
        backgroundRow?: string;
        backgroundTable?: string;
        textPrimary?: string;
        textSecondary?: string;
        width: string;
        serviceCall: any;
        specialColumns: SpecialColumn[];
    }

    const TableWrapper = ({
        setRowValue,
        setModal,
        modalState,
        filterSearch,
        borderTable,
        borderRow,
        padding,
        textAlignTable,
        backgroundRow,
        backgroundTable,
        textPrimary,
        textSecondary,
        width,
        serviceCall,
        specialColumns
    }: TableProps): JSX.Element => {
        const [tableData, setTableData] = useState<Row[][]>([]);
        const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

        useEffect(() => {
            const fetchTableData = async () => {
                const data = await serviceCall;
                setTableData(data);
            };
            fetchTableData();
        }, []);

        useEffect(() => {
            const handleResize = () => {
                setViewportWidth(window.innerWidth);
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, [viewportWidth]);
        const [filter, setFilter] = useState('');

        const columnTitles = tableData.length ? tableData[0].filter(cell => cell.showing).map(cell => cell.title) : null;

        const tableStyles: CSSProperties = {
            padding: viewportWidth <= 1024 ? '0em' : '1em',
            overflowX: 'auto',
            border: borderTable ? borderTable : 'unset',
            whiteSpace: 'nowrap',
            display: viewportWidth <= 1024 ? 'block' : 'auto',
            backgroundColor: backgroundTable ? backgroundTable : 'transparent',
            width: viewportWidth <= 1024 ? '100%' : width
        };

        return (
            <>
                {filterSearch ? (
                    <input type='text' value={filter} onChange={event => setFilter(event.target.value)} placeholder={Filter} />
                ) : null}
                {tableData.length && columnTitles?.length ? (
                    <>
                        <table style={tableStyles}>
                            <TableHeader
                                columns={columnTitles}
                                borderRow={borderRow}
                                padding={padding}
                                textAlignTable={textAlignTable}
                                backgroundRow={backgroundRow}
                                textPrimary={textPrimary}
                                width={width}
                            />
                            <tbody>
                                {tableData.map((row, index) => (
                                    <TableRows
                                        setRowValue={setRowValue}
                                        setModal={setModal}
                                        modalState={modalState}
                                        borderRow={borderRow}
                                        key={index}
                                        columns={columnTitles}
                                        row={row}
                                        padding={padding}
                                        textAlignTable={textAlignTable}
                                        backgroundRow={backgroundRow}
                                        textPrimary={textPrimary}
                                        textSecondary={textSecondary}
                                        width={width}
                                        specialColumns={specialColumns}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </>
        );
    }`;

    const useHeaders = `
    interface Props {
        columns: string[];
        borderRow?: string;
        padding?: string;
        textAlignTable?: CSSProperties['textAlign'] | undefined;
        backgroundRow?: string;
        textPrimary?: string;
    }

    const TableHeader: React.FC<Props> = ({ columns, borderRow, padding, textAlignTable, backgroundRow, textPrimary }) => {
        const headerStyle: CSSProperties = {
            borderBottom: borderRow ? borderRow : 'transparent',
            padding: padding ? padding : 'Opx',
            textAlign: textAlignTable ? textAlignTable : 'unset',
            backgroundColor: backgroundRow ? backgroundRow : 'transparent',
            color: textPrimary ? textPrimary : 'black',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        };

        return (
            <thead>
                <tr>
                    {columns.map(column => (
                        <th style={headerStyle} key={column}>
                            {column}
                        </th>
                    ))}
                </tr>
            </thead>
        );
    };
    `;
    const useRows = `interface SpecialColumn {
        column: number;
        element: string;
        module: string;
    }

    interface Props {
        setRowValue?: (value: string) => void;
        setModal?: (value: boolean) => void;
        modalState?: boolean;
        row: Row[];
        columns: string[];
        borderRow?: string;
        padding?: string;
        textAlignTable?: CSSProperties['textAlign'] | undefined;
        backgroundRow?: string;
        textSecondary?: string;
        width?: string;
        specialColumns?: SpecialColumn[];
    }

    const TableRows: React.FC<Props> = ({ setRowValue, setModal, modalState, row, columns, borderRow, padding, textAlignTable, backgroundRow, textSecondary, specialColumns }) => {
        const rowStyle: CSSProperties = {
            borderBottom: borderRow ? borderRow : 'unset',
            padding: padding ? padding : 'Opx',
            textAlign: textAlignTable ? textAlignTable : 'unset',
            backgroundColor: backgroundRow ? backgroundRow : 'transparent'
        };

        const cellRowStyle: CSSProperties = {
            padding: padding ? padding : 'Opx',
            textAlign: textAlignTable ? textAlignTable : 'unset',
            color: textSecondary ? textSecondary : 'black',
            width: 'auto',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        };

        return (
            <tr style={rowStyle}>
                {columns.map((column: string, index: number) => {
                    const cell = row.find((cell: Row) => cell.title === column);
                    const value = cell?.value || '';

                    switch (cell?.type) {
                        case 'custom':
                            if (specialColumns?.length) {
                                for (let i = 0; i < specialColumns.length; i++) {
                                    if (specialColumns[i].column === index) {
                                        const Component = lazy(() =>
                                            import('../' + specialColumns[i].element).then(module => ({
                                                default: module[specialColumns[i].module]
                                            }))
                                        );

                                        return (
                                            <td style={cellRowStyle} key={column.toLowerCase()}>
                                                <Suspense fallback={<div></div>}>
                                                    <Component {...cell.props} />
                                                </Suspense>
                                            </td>
                                        );
                                    }
                                }
                            }
                            break;
                        case 'text':
                            return (
                                <td style={cellRowStyle} key={column.toLowerCase()}>
                                    <span>{value}</span>
                                </td>
                            );
                    }
                })}
            </tr>
        );
    };`;

    const exampleTable = `
        <TableWrapper
            filterSearch={false}
            borderRow='1px solid #E17159'
            padding={'1em'}
            textAlignTable={'start'}
            backgroundRow={'transparent'}
            backgroundTable={'transparent'}
            textPrimary={'black'}
            textSecondary={'black'}
            width={'100%'}
            serviceCall={listServices(listRepositoryInstance).getListMappedFibra()}
            specialColumns={[
                {
                    column: 5,
                    element: 'Buttons/Button',
                    module: 'Button'
                },
                {
                    column: 6,
                    element: 'Tags/Tag',
                    module: 'Tag'
                }
            ]}
        />`;
    const exampleService2 = `
    getListMappedComments: async () => {
        const data: ListComments = await repository.getListComments();
        return data.map((row: ListItemComment): Row[] => [
            {
                name: 'id',
                title: 'ID',
                showing: true,
                type: 'text',
                value: row.id
            },
            {
                name: 'date',
                title: 'Fecha y hora',
                showing: true,
                type: 'custom',
                value: row.date,
                props: {
                    date: row.date,
                    type: 'regular_with_time'
                }
            },
            {
                name: 'user',
                title: 'Usuario',
                showing: true,
                type: 'text',
                value: row.user
            },
            {
                name: 'reason',
                title: 'Motivo',
                showing: true,
                type: 'text',
                value: row.reason
            },
            {
                name: 'comment',
                title: 'Comentario',
                showing: true,
                type: 'text',
                value: row.comment
            }
        ]);
    }`;

    const exampleSevice = `
    getListMappedFibra: async () => {
        const data: ListFibra = await repository.getListFibra();
        return data.map((row: ListItemFibra): Row[] => [
            {
                name: 'id',
                title: 'ID',
                showing: true,
                type: 'text',
                value: row.id
            },
            {
                name: 'nombre',
                title: 'Nombre',
                showing: true,
                type: 'text',
                value: row.name
            },
            {
                name: 'nif',
                title: 'NIF',
                showing: true,
                type: 'text',
                value: row.nif
            },
            {
                name: 'dirección',
                title: 'Dirección',
                showing: true,
                type: 'text',
                value: row.address
            },
            {
                name: 'teléfono',
                title: 'Teléfono',
                showing: true,
                type: 'text',
                value: row.phone
            },
            {
                name: 'acciones',
                title: 'Acciones',
                showing: true,
                type: 'custom',
                value: 'Ver',
                link: \`/users/\${row.id}\`,
                props: {
                    text: 'Ver perfil',
                    background: 'transparent',
                    color: '#D74121',
                    onClickNavigationRoute: \`/clients/\${row.id}\`,
                    paddingX: 0,
                    paddingY: 0
                }
            },
            {
                name: 'status',
                title: 'Status',
                showing: true,
                type: 'custom',
                value: 'Ver',
                link: '',
                props: {
                        text: row.state === 1 ? 'Alta' : row.state === 2 ? 'Pending' : 'Baja',
                        background: 'transparent',
                        color: row.state === 1 ? '#14CA1B' : row.state === 2 ? '#FA7F0F' : '#DE121E',
                        paddingX: 0,
                        paddingY: 0
                }
            }
        ]);
    }
`;

    const rowModel = `
        export type Row = {
            name: string;
            title: string;
            showing: boolean;
            type: string;
            value: string | number | boolean;
            link?: string;
            props?: { [key: string]: string | void | boolean | number | (() => void) };
    };`;
    const specialProps = `
    {
        name: 'acciones',
        title: 'Acciones',
        showing: true,
        type: 'custom',
        value: 'Ver',
        link: \`/users/\${row.id}\`,
        props: {
            text: 'Ver perfil',
            background: 'transparent',
            color: '#D74121',
            onClickNavigationRoute: \`/clients/\${row.id}\`,
            paddingX: 0,
            paddingY: 0
        }
    },
    {
        name: 'status',
        title: 'Status',
        showing: true,
        type: 'custom',
        value: 'Ver',
        link: '',
        props: {
                text: row.state === 1 ? 'Alta' : row.state === 2 ? 'Pending' : 'Baja',
                background: 'transparent',
                color: row.state === 1 ? '#14CA1B' : row.state === 2 ? '#FA7F0F' : '#DE121E',
                paddingX: 0,
                paddingY: 0
        }
    }`;

    const specialColumns = `
    specialColumns={[
        {
            column: 5,
            element: 'Buttons/Button',
            module: 'Button'
        },
        {
            column: 6,
            element: 'Tags/Tag',
            module: 'Tag'
        }
    ]}`;

    const exampleTable2 = `<TableWrapper
                filterSearch={false}
                borderRow='1px solid #D74121'
                padding={'1em'}
                textAlignTable={'start'}
                backgroundRow={'transparent'}
                backgroundTable={'transparent'}
                textPrimary={'#D74121'}
                textSecondary={'black'}
                width={'100%'}
                serviceCall={listServices(listRepositoryInstance).getListMappedComments()}
                specialColumns={[
                    {
                        column: 1,
                        element: 'Dates/Date',
                        module: 'Date'
                    }
                ]}
            />`;

    const specialProps2 = `
    {
        name: 'date',
        title: 'Fecha y hora',
        showing: true,
        type: 'custom',
        value: row.date,
        props: {
            date: row.date,
            type: 'regular_with_time'
        }
    },`;

    const specialColumns2 = `
        specialColumns={[
            {
                column: 1,
                element: 'Dates/Date',
                module: 'Date'
            }
        ]}`;

    const listServicesCustomClick = `
        {
            name: 'acciones',
            title: 'Acciones',
            showing: true,
            type: 'custom',
            value: 'Ver',
            link: \`/clients/\${row.id}\`,
            props: {
                text: 'Ver perfil',
                background: 'transparent',
                color: '#D74121',
                onclick: 'custom',
                paddingX: 0,
                paddingY: 0
            }
        }`;

    const customclickoption = `
    if (specialColumns?.length) {
        for (let i = 0; i < specialColumns.length; i++) {
            if (specialColumns[i].column === index) {
                const Component = lazy(() =>
                    import('../' + specialColumns[i].element).then(module => ({
                        default: module[specialColumns[i].module]
                    }))
                );
                const cellProps = { ...cell.props };
                if (specialColumns[i].module === 'Button') {
                    console.log(cellProps, 'cellProps');

                    if (cellProps.onclick === 'custom' && setRowValue && setModal) {
                        cellProps.onclick = () => {
                            setModal ? setModal(!modalState) : null;
                            setRowValue ? setRowValue(cell.value ? String(cell.value) : String(cell.value)) : null;
                        };
                    }
                }

                return (
                    <td style={cellRowStyle} key={column.toLowerCase()}>
                        <Suspense fallback={<div></div>}>
                            <Component {...cellProps} />
                        </Suspense>
                    </td>
                );
            }
        }
    }
        `;
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 20%' }}>
            <h1 className='text-2xl'>TableWrapper</h1>
            <div className='text-lg mt-4'>
                The <strong>TableWrapper</strong> component is a reusable component that can be used to display data in a tabular format. It
                has a number of configurable props that can be used to customize the appearance and functionality of the table. An array of
                column titles to be displayed in the table header is created. It first checks if tableData has at least one row of data. If
                it does, it selects the first row (tableData[0]) and filters out any cells where the showing property is false. It then maps
                the remaining cells to their title property to create an array of column titles. If tableData does not have at least one row
                of data, columnTitles is set to null. This array is passed to TableHeader component as a prop.This component works in
                conjunction with the <strong>TableHeader and TableRows components</strong>, and <strong>listService</strong> with a specific
                mapped data to render an entire table.
                <div className='text-xl mt-4'>Props</div>
                <ul className='my-5'>
                    <li className='mb-5'>
                        <strong>filterSearch: </strong>a boolean that indicates whether to display an input for filtering the table rows
                    </li>
                    <li className='mb-5'>
                        <strong>borderTable (optional): </strong>a string that sets the border of the table. If prop is not passed, it will
                        get unset value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>borderRow (optional): </strong>a string that sets the border of the table rows. If prop is not passed, it
                        will get unset value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>padding (optional): </strong>a string that sets the padding of the table cells. If prop is not passed, it
                        will get 0px value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>textAlignTable (optional): </strong>a string that sets the text alignment of the table. If prop is not
                        passed, it will get unset value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>backgroundRow (optional): </strong>a string that sets the background color of the cells. If prop is not
                        passed, it will get transparent value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>backgroundTable (optional): </strong>a string that sets the background color of the table. If prop is not
                        passed, it will get transparent value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>textPrimary: </strong>a string that sets the text color of the column titles text. If prop is not passed, it
                        will get black value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>textSecondary: </strong>a string that sets the text color of the rows text. If prop is not passed, it will
                        get black value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>width: </strong>a string that sets the width of the table.
                    </li>
                    <li className='mb-5'>
                        <strong>serviceCall: </strong>a function that fetches the table data from an external source and returns a promise
                        that resolves with an array of arrays of objects that represent the table rows and columns.
                    </li>
                    <li className='mb-5'>
                        <strong>specialColumns: </strong>an array of objects that specify the columns in which special elements will be
                        rendered. Each object has three properties:
                    </li>

                    <li className='mb-2 ml-5'>
                        <strong>column:</strong> The index of the column that should render the custom component.
                    </li>
                    <li className='mb-2 ml-5'>
                        <strong>element:</strong> The index of the column that should render the custom component.
                    </li>
                    <li className='mb-2 ml-5'>
                        <strong>module:</strong> The name of the exported component from the file at the given path.
                    </li>
                </ul>
            </div>
            <CopyBlock language={'jsx'} text={useFunc} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <h2 className='text-2xl mt-9'>TableHeader</h2>
            <div className='text-lg mt-4'>
                The <strong>TableHeader</strong> component is a functional component responsible for rendering the header of the table. The
                component accepts the following props:
                <ul className='my-5'>
                    <li className='mb-5'>
                        <strong>columns: </strong>an array of strings that represent the titles of the columns in the table.
                    </li>
                    <li className='mb-5'>
                        <strong>borderRow (optional): </strong>a string that sets the border of the table rows. If prop is not passed, it
                        will get unset value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>padding (optional): </strong>a string that sets the padding of the table cells. If prop is not passed, it
                        will get 0px value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>textAlignTable (optional): </strong>a string that sets the text alignment of the table. If prop is not
                        passed, it will get unset value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>backgroundRow (optional): </strong>a string that sets the background color of the cells. If prop is not
                        passed, it will get transparent value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>textPrimary: </strong>a string that sets the text color of the column titles text. If prop is not passed, it
                        will get black value by default.
                    </li>
                </ul>
            </div>
            <CopyBlock language={'jsx'} text={useHeaders} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <h2 className='text-2xl mt-9'>TableRows</h2>
            <div className='text-lg mt-4'>
                The <strong>TableRows</strong> component is a functional component responsible for rendering a row of data in a table. The
                component iterates over the columns array and checks if the corresponding Row object exists in the row array. If it does, it
                extracts the value property of the Row object and renders it in a table cell. If the type property of the Row object is
                custom, the component looks for a matching SpecialColumn object in the specialColumns array prop and renders a component
                based on the element and module properties of that object. It accepts the following props:
                <ul className='my-5'>
                    <li className='mb-5'>
                        <strong>row: </strong>An array of Row objects that represent the data to be rendered for each row in the table.
                    </li>
                    <li className='mb-5'>
                        <strong>columns: </strong>An array of strings that represent the column headers for the table.
                    </li>
                    <li className='mb-5'>
                        <strong>borderRow (optional): </strong>a string that sets the border of the table rows. If prop is not passed, it
                        will get unset value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>padding (optional): </strong>a string that sets the padding of the table cells. If prop is not passed, it
                        will get 0px value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>textAlignTable (optional): </strong>a string that sets the text alignment of the table. If prop is not
                        passed, it will get unset value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>backgroundRow (optional): </strong>a string that sets the background color of the cells. If prop is not
                        passed, it will get transparent value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>textSecondary: </strong>a string that sets the text color of the rows text. If prop is not passed, it will
                        get black value by default.
                    </li>
                    <li className='mb-5'>
                        <strong>specialColumns: </strong>An array of objects that represent columns with custom components. Each object
                        contains the index of the column and the path to the custom component.
                    </li>
                </ul>
                <div className='mb-7'>
                    <strong> IMPORTANT DETAILS</strong>
                </div>
                <ul>
                    <li className='mb-5'>
                        ---- It first creates a row element with the provided style using the rowStyle object. Then it loops through each
                        column title in the columns array, and tries to find a corresponding cell object in the row array by looking for the
                        cell with the same title.
                    </li>
                    <li className='mb-5'>
                        ---- If the cell type is custom, it checks if there are any specialColumns provided and looks for the special column
                        that matches the current column index. If there is a match, it imports the specified module using dynamic import and
                        renders the custom component with the props specified in the cell object. The fallback component for the lazy loaded
                        component is an empty div.
                    </li>
                    <li className='mb-5'>
                        ---- If the cell type is text, it simply renders the cell value inside a span element with the style specified in
                        the cellRowStyle object.
                    </li>
                </ul>
            </div>
            <CopyBlock language={'jsx'} text={useRows} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <h2 className='text-2xl my-5'>EXAMPLE OF USE</h2>
            <TableWrapper
                borderRow='1px solid #E17159'
                padding={'1em'}
                textAlignTable={'start'}
                backgroundRow={'transparent'}
                backgroundTable={'transparent'}
                textPrimary={'black'}
                textSecondary={'black'}
                width={'100%'}
                serviceCall={listServices(listRepositoryInstance).getListMappedFibra}
                specialColumns={[
                    {
                        column: 5,
                        element: 'Buttons/Button',
                        module: 'Button'
                    },
                    {
                        column: 6,
                        element: 'Tags/Tag',
                        module: 'Tag'
                    }
                ]}
            />
            <CopyBlock language={'jsx'} text={exampleTable} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <p>And this is the service that gets the data and map it properly based on the Row model.</p>
            <CopyBlock language={'jsx'} text={exampleSevice} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />

            <p className='my-6'>
                <strong>OVERVIEW</strong>
            </p>
            <ul>
                <li className='mb-5'>
                    <strong>getListMappedFibra</strong> returns a Promise that resolves to an array of objects. The function first calls an
                    asynchronous function getListFibra() on a repository to get a list of data.
                </li>
                <li className='mb-5'>
                    The returned data is then processed using the Array.map() method to create a new array of objects with a specific format
                    based on <strong>Row model</strong>. Each element of the new array corresponds to a row in a table and each object
                    contains properties that define the cell contents of a given column in the table.
                </li>

                <strong className='mb-5'>IMPORTANT: EVERY TABLE DATA SHOULD BE MAPPED BASED ON THE ROW MODEL!!</strong>
                <CopyBlock language={'jsx'} text={rowModel} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />

                <li className='my-5'>
                    <strong>Title</strong> property that represents the header of a column in the table.
                </li>

                <li className='mb-5'>
                    The <strong>value</strong> property represents the cell content for the corresponding row and column.
                </li>

                <li className='mb-5'>
                    The <strong>type</strong> property is used to determine how the value property should be rendered.
                </li>

                <li className='mb-5 ml-8'>
                    If type is <strong>text</strong>, the value property is simply displayed as a string.
                </li>

                <li className='mb-5 ml-8'>
                    If type is <strong>custom</strong>, a custom component will be used to render the cell contents. When it is type custom,
                    it is assumed it will render a custom component, so it is mandatory to{' '}
                    <strong>indicate the props that will receive</strong>. Remember that the type of component will be part of TableWrapper
                    props in SpecialColumns property.
                </li>
                <li className='mb-5'></li>
            </ul>
            <CopyBlock language={'jsx'} text={specialProps} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <CopyBlock language={'jsx'} text={specialColumns} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <p className='my-5'>
                <strong>
                    In the example table above, we see that the table has seven columns: ID, Nombre, NIF, Dirección, Teléfono, Acciones, and
                    Status. The first five columns are of type text and display the id, name, nif, address, and phone properties of each row
                    respectively. The sixth column is of type custom and displays a Ver perfil button with a link. The seventh column is
                    also of type custom and displays a tag with text Alta, Pending, or Baja based on the state property of each row.
                </strong>
            </p>
            <CopyBlock
                language={'jsx'}
                text={listServicesCustomClick}
                showLineNumbers={true}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <CopyBlock language={'jsx'} text={customclickoption} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />

            <p className='my-5'>
                In the previous specific blocks, it is checking whether the module of the special column is a Button, and if so, it checks
                whether the onclick property of the cellProps object is set to <b>custom</b>, indicating that a custom click function is to
                be used. If this condition is met, and the setRowValue and setModal functions are passed as props, it sets the onclick
                property of the cellProps object to a new function that calls the setModal and setRowValue functions, passing the modalState
                and cell.value as arguments respectively. This new function is then passed as a prop to the Button component, allowing it to
                trigger the setModal and setRowValue functions when clicked, if the onclick property was set to <b>custom</b> in the
                original props object of the specialColumn.
            </p>
            <h2 className='text-2xl my-5'>ANOTHER EXAMPLE</h2>
            <TableWrapper
                borderRow='1px solid #D74121'
                padding={'1em'}
                textAlignTable={'start'}
                backgroundRow={'transparent'}
                backgroundTable={'transparent'}
                textPrimary={'#D74121'}
                textSecondary={'black'}
                width={'100%'}
                serviceCall={listServices(listRepositoryInstance).getListMappedComments}
                specialColumns={[
                    {
                        column: 1,
                        element: 'Dates/Date',
                        module: 'Date'
                    }
                ]}
            />
            <CopyBlock language={'jsx'} text={exampleTable2} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <p className='my-8'>And this is the service that gets the data and map it properly based on the Row model.</p>
            <CopyBlock language={'jsx'} text={exampleService2} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <p className='my-6'>
                <strong>OVERVIEW</strong>
            </p>
            <ul>
                <li className='mb-5'>
                    <strong>getListMappedComments</strong> returns a Promise that resolves to an array of objects. The function first calls
                    an asynchronous function getListComments() on a repository to get a list of data.
                </li>
                <li className='mb-5'>
                    The returned data is then processed using the Array.map() method to create a new array of objects with a specific format
                    based on <strong>Row model</strong>. Each element of the new array corresponds to a row in a table and each object
                    contains properties that define the cell contents of a given column in the table.
                </li>

                <strong className='mb-5'>IMPORTANT: EVERY TABLE DATA SHOULD BE MAPPED BASED ON THE ROW MODEL!!</strong>
                <CopyBlock language={'jsx'} text={rowModel} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />

                <li className='my-5'>
                    <strong>Title</strong> property that represents the header of a column in the table.
                </li>

                <li className='mb-5'>
                    The <strong>value</strong> property represents the cell content for the corresponding row and column.
                </li>

                <li className='mb-5'>
                    The <strong>type</strong> property is used to determine how the value property should be rendered.
                </li>

                <li className='mb-5 ml-8'>
                    If type is <strong>text</strong>, the value property is simply displayed as a string.
                </li>

                <li className='mb-5 ml-8'>
                    If type is <strong>custom</strong>, a custom component will be used to render the cell contents. When it is type custom,
                    it is assumed it will render a custom component, so it is mandatory to{' '}
                    <strong>indicate the props that will receive</strong>. Remember that the type of component will be part of TableWrapper
                    props in SpecialColumns property.
                </li>
                <li className='mb-5'></li>
            </ul>
            <CopyBlock language={'jsx'} text={specialProps2} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <CopyBlock language={'jsx'} text={specialColumns2} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <p className='my-5'>
                <strong>
                    In the example table above, we see that the table has five columns: ID, Fecha y hora, Usuario, Motivo, and Comentario.
                    The first column is of type text and displays the id property of each row. The second column is of type custom and
                    displays a formatted date and time based on the date property of each row using a custom component that takes the date
                    and type properties as props. The third, fourth, and fifth columns are also of type text and display the user, reason,
                    and comment properties of each row respectively. In this case, we are only gonna be rendering a custom column that will
                    render Date component.
                </strong>
            </p>
        </div>
    );
};

export default Tables;
