import { MutateOptions } from '@tanstack/query-core';
import { useAtomValue } from 'jotai';
import { downloadPatientAttachedFileAtom } from '../../../store/global/atoms';
import { DownloadFileNoModuleParams } from '../../../ts';

export const useDownloadPatientAttachedFile = () => {
  const downloadPatientAttachedFileBase = useAtomValue(downloadPatientAttachedFileAtom);

  const downloadPatientAttachedFile = (
    { moduleEntryId, fileId, fileName }: DownloadFileNoModuleParams,
    options?: MutateOptions<string, Error, DownloadFileNoModuleParams, unknown> | undefined,
  ) => {
    !downloadPatientAttachedFileBase.isPending &&
      downloadPatientAttachedFileBase.mutate({ moduleEntryId, fileId, fileName }, options);
  };

  const downloadPatientAttachedFileAsync = async (
    { moduleEntryId, fileId, fileName }: DownloadFileNoModuleParams,
    options?: MutateOptions<string, Error, DownloadFileNoModuleParams, unknown> | undefined,
  ) => {
    if (downloadPatientAttachedFileBase.isPending) return false;
    return await downloadPatientAttachedFileBase.mutateAsync(
      { moduleEntryId, fileId, fileName },
      options,
    );
  };

  return {
    data: downloadPatientAttachedFileBase.data,
    downloadPatientAttachedFile,
    downloadPatientAttachedFileAsync,
    isLoading: downloadPatientAttachedFileBase.isPending,
    error: downloadPatientAttachedFileBase.error,
  };
};
