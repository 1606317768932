import { useSetAtom } from 'jotai';
import { popupOptionsAtom } from '../../../store/global/atoms';
import { PopupOptions, PopupOptionsSetter, SetBufferAction } from '../../../ts';

type Buffer = Required<PopupOptions>['buffer'];

export const useSetPopup = () => {
  const setPopupOptions = useSetAtom(popupOptionsAtom);

  const openPopup = (options: PopupOptionsSetter) => {
    setPopupOptions({ type: 'open', payload: options });
  };

  const openNextPopup = () => {
    setPopupOptions({ type: 'openNextFromBuffer' });
  };

  const closePopup = (ignoreBuffer = true) => {
    setPopupOptions({ type: 'close', payload: { ignoreBuffer } });
  };

  const forceClosePopup = (ignoreBuffer = true) => {
    setPopupOptions({ type: 'close', payload: { ignoreBuffer, ignoreForceOpen: true } });
  };

  const setBufferBase = (
    buffer: PopupOptions['buffer'] = undefined,
    setBufferAction: SetBufferAction = 'replace',
  ) => {
    setPopupOptions({ type: 'setBuffer', payload: { buffer, setBufferAction } });
  };

  const setNewBuffer = (buffer: Buffer) => {
    setBufferBase(buffer, 'replace');
  };

  const unshiftBuffer = (buffer: Buffer) => {
    setBufferBase(buffer, 'unshift');
  };

  const pushBuffer = (buffer: Buffer) => {
    setBufferBase(buffer, 'push');
  };

  const removeBuffer = () => {
    setBufferBase();
  };

  const setBuffer = {
    new: setNewBuffer,
    unshift: unshiftBuffer,
    push: pushBuffer,
    remove: removeBuffer,
  };

  const setForceKeepOpen = {
    enable: () => setPopupOptions({ type: 'setForceKeepOpen', payload: { newValue: true } }),
    disable: () => setPopupOptions({ type: 'setForceKeepOpen', payload: { newValue: false } }),
    toggle: () => setPopupOptions({ type: 'setForceKeepOpen', payload: { newValue: 'toggle' } }),
  };

  const setClickOutside = {
    enable: () => setPopupOptions({ type: 'setDisableClickOutside', payload: { newValue: false } }),
    disable: () => setPopupOptions({ type: 'setDisableClickOutside', payload: { newValue: true } }),
    toggle: () =>
      setPopupOptions({ type: 'setDisableClickOutside', payload: { newValue: 'toggle' } }),
  };

  const setOnClose = (newOnClose: Required<PopupOptions>['onClose']) => {
    setPopupOptions({ type: 'setOnClose', payload: { newValue: newOnClose } });
  };

  const removeOnClose = () =>
    setPopupOptions({ type: 'setOnClose', payload: { newValue: undefined } });

  return {
    openPopup,
    openNextPopup,
    closePopup,
    forceClosePopup,
    setBuffer,
    setNewBuffer,
    unshiftBuffer,
    pushBuffer,
    removeBuffer,
    setForceKeepOpen,
    setClickOutside,
    setOnClose,
    removeOnClose,
  };
};
