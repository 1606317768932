import { QUERY_KEYS } from '../../../../../consts';
import { getPatientsVisitsService } from '../../../../../services';
import { ExtraInfoMutationProps, IdToPatientExtraInfo, PatientVisit } from '../../../../../ts';
import { getAtomWithMutation } from '../../../../../utils';

export const getPatientsVisitsAtom = getAtomWithMutation<
  IdToPatientExtraInfo<PatientVisit>,
  ExtraInfoMutationProps
>({
  mutationKey: QUERY_KEYS.PATIENTS.ALL_PATIENTS_VISITS,
  mutationFn: getPatientsVisitsService,
});
