import React from 'react';
import { Date } from '../../components/Dates/Date';
import { CopyBlock, atomOneDark } from 'react-code-blocks';

const elem = `interface Props {
    date: string | number;
    separator?: string;
    type?: string;
    color?: string;
    font?: string;
    weight?: string;
}

export const Date: React.FC<Props> = ({ date, separator = '/', type = 'regular', color, font, weight }) => {
    const { day, weekDay, weekDayAbb, month, monthName, monthNameAbb, year } = GlobalService.parseDate(date);
    const style = {
        color: color,
        fontSize: font,
        fontWeight: weight
    };
    switch (type) {
        case 'regular':
            return <div style={style}>{day + separator + month + separator + year}</div>;
        case 'regularUSA':
            return <div style={style}>{year + separator + month + separator + day}</div>;
        case 'long':
            return <div style={style}>{weekDay day monthName year}</div>;
        case 'abb':
            return <div style={style}>{weekDayAbb day monthNameAbb year}</div>;
        case 'longMonth':
            return <div style={style}>{day monthName year}</div>;
        case 'abbMonth':
            return <div style={style}>{day monthNameAbb year}</div>;
        default:
            return <div style={style}>{day + separator + month + separator + year}</div>;
    }
};`;

const Dates = () => {
    const date = '2023-03-10T01:23:45.678Z';
    // const date = 1678411425678;
    return (
        <div className='flex flex-col gap-2' style={{ margin: '1% 25%' }}>
            <div className='text-2xl'>Date</div>
            <div className='text-lg mt-4'>A date transforms a string timestamp or unix numeric value to one of the following formats</div>
            <CopyBlock language={'jsx'} text={elem} showLineNumbers={true} theme={atomOneDark} wrapLines={true} codeBlock />
            <div className='text-lg mt-4'>Next some implementations of the element</div>
            <div className='text-base mt-2'>Default date with different separators.</div>
            <CopyBlock
                language={'jsx'}
                text={`<Date date='${date}' separator=' '/>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Date date={date} />
                <Date date={date} separator=' ' />
                <Date date={date} separator='|' />
                <Date date={date} separator='\' />
                <Date date={date} separator='-' />
            </div>
            <div className='text-base mt-2'>Default date reversed with different separators.</div>
            <CopyBlock
                language={'jsx'}
                text={`<Date date={1678411425678} type='regularUSA'/>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex gap-4 my-2 flex-wrap w-auto'>
                <Date date={date} type='regularUSA' />
                <Date date={date} type='regularUSA' separator=' ' />
                <Date date={date} type='regularUSA' separator='|' />
                <Date date={date} type='regularUSA' separator='\' />
                <Date date={date} type='regularUSA' separator='-' />
            </div>
            <div className='text-base mt-2'>Different types of format: long | abb | longMonth | abbMonth .</div>
            <CopyBlock
                language={'jsx'}
                text={`<Date date='${date}' type='long'/>`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex flex-col gap-4 my-2 h-auto w-auto'>
                <Date date={date} type='long' />
                <Date date={date} type='abb' />
                <Date date={date} type='longMonth' />
                <Date date={date} type='abbMonth' />
            </div>
            <div className='text-base mt-2'>Different color, font size and font weight .</div>
            <CopyBlock
                language={'jsx'}
                text={`<Date date={date} type='abb' color='gray' font='15px' weight='200' />`}
                showLineNumbers={false}
                theme={atomOneDark}
                wrapLines={true}
                codeBlock
            />
            <div className='flex flex-col gap-4 my-2 h-auto w-auto'>
                <Date date={date} type='abb' color='gray' font='15px' weight='200' />
                <Date date={date} type='abb' color='gray' font='15px' weight='600' />
                <Date date={date} type='abb' color='#E06C75' font='20px' weight='400' />
                <Date date={date} type='abb' color='#E06C75' font='20px' weight='800' />
            </div>
        </div>
    );
};

export default Dates;
