import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useEventCallback } from './use-event-callback';
import { makeObservable } from '../utils/make-observable';
import { LocalStorageKey } from '../ts/types';
import { LOCAL_STORAGE_KEYS } from '../consts';

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

interface UseLocalStorageOptions<T> {
  serializer?: (value: T) => string;
  deserializer?: (value: string) => T;
  initializeWithStoredValue?: boolean;
}

const IS_SERVER = typeof window === 'undefined';

const localStorageStore = makeObservable<Record<LocalStorageKey, any>>({
  [LOCAL_STORAGE_KEYS.USER]: undefined,
  [LOCAL_STORAGE_KEYS.AUTH]: undefined,
  [LOCAL_STORAGE_KEYS.LANGUAGE]: undefined
});

export const useLocalStorage = <T>(
  key: LocalStorageKey,
  initialValue: T | (() => T),
  options: UseLocalStorageOptions<T> = {}
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const { initializeWithStoredValue = true } = options;

  const saveInLocalStorage = (newValue: T) => {
    window.localStorage.setItem(key, serializer(newValue));
  };

  const saveInStore = (newVal: T) => {
    localStorageStore.set(curVal => ({ ...curVal, [key]: newVal }));
  };

  const getValueFromInitialValue = () =>
    initialValue instanceof Function ? initialValue() : initialValue;

  const serializer = useCallback(
    (value: T) => {
      if (options.serializer) return options.serializer(value);

      return JSON.stringify(value);
    },
    [options]
  );

  const deserializer = useCallback(
    (value: string) => {
      if (options.deserializer) return options.deserializer(value);

      if (value === 'null') return null as T;
      if (value === 'undefined') return undefined as T;

      let parsed: unknown;
      try {
        parsed = JSON.parse(value);
      } catch (error) {
        console.error('Error parsing JSON: ', error);
        return getValueFromInitialValue(); // Return initial value if parsing fails
      }

      return parsed as T;
    },
    [options, initialValue]
  );

  // Get from local storage, then parse stored json or return initialValue
  const readValue = useCallback((): T => {
    const initialValueToUse = getValueFromInitialValue();

    // Prevent build error "window is undefined" but keep working
    if (IS_SERVER) return initialValueToUse;

    try {
      const raw = localStorage.getItem(key);
      return raw ? deserializer(raw) : initialValueToUse;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}": `, error);
      return initialValueToUse;
    }
  }, [initialValue, key, deserializer]);

  // Setter function to store the new value in the state and in localStorage
  const setValue: Dispatch<SetStateAction<T>> = useEventCallback(value => {
    if (IS_SERVER) {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    try {
      // Allow the value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(readValue()) : value;

      // Save to localStorage
      saveInLocalStorage(newValue);

      // Save state
      saveInStore(newValue);

      // Dispatch a custom event so every similar useLocalStorage hook is notified
      window.dispatchEvent(new StorageEvent('local-storage', { key }));
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}": `, error);
    }
  });

  const removeValue = useEventCallback(() => {
    if (IS_SERVER) {
      console.warn(
        `Tried removing localStorage key “${key}” even though environment is not a client`
      );
    }

    const defaultValue = getValueFromInitialValue();

    window.localStorage.removeItem(key);

    saveInStore(defaultValue);

    window.dispatchEvent(new StorageEvent('local-storage', { key }));
  });

  const [storedValue, setStoredValue] = useState(() => {
    const localStorageStoreValue = localStorageStore.get();

    let firstValue = localStorageStoreValue[key] as T | undefined;

    if (firstValue === undefined) {
      firstValue = initializeWithStoredValue ? readValue() : getValueFromInitialValue();
      saveInStore(firstValue);
    }

    saveInLocalStorage(firstValue);

    return localStorageStore.get();
  });

  useEffect(() => {
    return localStorageStore.subscribe(setStoredValue);
  }, [storedValue, setStoredValue]);

  return [storedValue[key] as T, setValue, removeValue];
};
