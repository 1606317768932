import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonBase } from '../../components/Buttons/button-base';
import Input from '../../components/Inputs/Input';
import Title from '../../components/Title';
import { NotificationsButton } from '../../components/Buttons/notifications-button';
import { UserButton } from '../../components/Buttons/user-button';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { notificationsServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/notifications.service';
import { notificationsRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/notificationsRepository';
import { useTranslation } from 'react-i18next';

// CSS
import '../../styles/Notificaciones/Notificaciones.css';
import '../../styles/TopMenu/TopMenu.css';

// Icons
import { faMagnifyingGlass, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INotification } from 'src/hexagonal-architecture-frontend-base/src/domain/models/INotification';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { useSession } from '../../hooks';

interface Props {
  handleSearch?: (searchText: string) => void;
  leftIcon?: string;
  leftIconLink?: string;
  searchInput?: boolean;
  searchValue?: string;
  title: string;
  user?: string;
  cartiItems?: number;
  textCentered?: boolean;
  updateCount?: number;
}

const TopMenu: React.FC<Props> = ({
  handleSearch,
  leftIcon,
  leftIconLink = '',
  searchInput,
  searchValue = '',
  title,
  user,
  cartiItems = 0,
  textCentered = false,
  updateCount = 0,
}) => {
  const { t } = useTranslation();
  const pathName = new URLSearchParams(window.location.pathname);
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { isUserLogged } = useSession();

  /**********
   * States *
   **********/
  const { viewportWidth } = useViewport();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      getInformation();
      getInformationCount();
    }, 1000);

    return () => clearTimeout(timer);
  }, [updateCount]);

  useEffect(() => {
    // console.log('updateCount', updateCount);
    getInformationCount();
  }, [updateCount]);

  useEffect(() => {
    if (GlobalService.reloadNotificationsEvery5Minutes) {
      getInformationEvery5Minutes();
      GlobalService.reloadNotificationsEvery5Minutes = false;
    }
  });

  /************
   * Handlers *
   ************/
  const goToLeftIconLink = () => {
    const path = searchParams.get('url');
    if (path) navigate(`${path.replace('=', '')}`);
    else navigate(leftIconLink);
  };

  /**************
   * Functions *
   **************/
  const getInformationEvery5Minutes = () => {
    setTimeout(() => {
      if (isUserLogged) getInformationCount();
      getInformationEvery5Minutes();
    }, 60000);
  };

  const getInformation = () => {
    notificationsServices(notificationsRepositoryInstance)
      .getNotifications(5, 1, 'false')
      .then(data => {
        if (typeof data !== 'string') {
          setNotifications(data);
        } else {
          setNotifications([]);
        }
      });
  };

  const getInformationCount = () => {
    notificationsServices(notificationsRepositoryInstance)
      .getNotificationsNonReadCount()
      .then(data => {
        if (typeof data !== 'string') {
          setTotalNotifications(data);
        } else {
          setTotalNotifications(0);
        }
      });
  };

  const markNotificationAsSeen = (id: number) => {
    notificationsServices(notificationsRepositoryInstance)
      .markNotificationAsSeen(id)
      .then(() => {
        getInformationCount();
        getInformation();
      });
  };

  const goToCarrito = () => {
    navigate(`/carrito?url=${pathName}`);
  };

  const getSizeOfText = () => {
    if (
      location.pathname.includes('blog') ||
      location.pathname.includes('producto') ||
      location.pathname.includes('pagos') ||
      location.pathname.includes('help') ||
      location.pathname.includes('carrito')
    )
      return 50;
    if (location.pathname.includes('tienda') && viewportWidth > 1300) return 50;
    if (viewportWidth > 1300) return 30;
    // if (viewportWidth > 1200) return 25;
    // if (viewportWidth > 1100) return 20;
    // if (viewportWidth > 1000) return 25;
    if (viewportWidth > 900) return 25;
    if (viewportWidth > 890) return 23;
    if (viewportWidth > 880) return 22;
    if (viewportWidth > 870) return 21;
    if (viewportWidth > 860) return 20;
    if (viewportWidth > 850) return 19;
    if (viewportWidth > 840) return 18;
    if (viewportWidth > 830) return 17;
    if (viewportWidth > 820) return 16;
    if (viewportWidth > 810) return 15;
    if (viewportWidth > 800) return 14;
    if (viewportWidth > 750) return 30;
    if (viewportWidth > 500) return 30;
    if (viewportWidth > 400) return 20;
    if (viewportWidth > 300) return 15;
    if (viewportWidth > 100) return 10;
    return 30;
  };

  /*******
   * JSX *
   *******/
  return (
    <div
      className={`top_menu_container flex justify-between items-center bg-white min-w-[280px] ${
        viewportWidth > 768 ? 'border-b' : 'w-full fixed z-20 p-4'
      }`}
    >
      <div
        className={`${textCentered ? 'w-full ' : ''} flex gap-2 text-left flex-row items-center`}
      >
        {leftIcon ? (
          <img
            src={leftIcon}
            alt='Back'
            className='title-icon cursor-pointer'
            onClick={goToLeftIconLink}
          />
        ) : undefined}
        <div className={`${textCentered ? 'w-full md:w-fit text-center md:text-left' : ''}`}>
          <Title text={title} color='#28337D' fontWeight={300} className='top-menu-title' />
        </div>
        <h2 className='top-menu-title' style={{ color: '#28337D' }}>
          {user ? GlobalService.limitText(user, getSizeOfText()) : ''}
        </h2>
      </div>

      {!isUserLogged && viewportWidth > 768 ? (
        <div className='gap-x-2 hidden xs:flex'>
          <ButtonBase
            text={GlobalService.uppercaseFirstLetter(t('pages.login.title'))}
            background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
            color={'white'}
            borderRadius={'50px'}
            onClickNavigationRoute='/login'
          />
          <ButtonBase
            text={GlobalService.uppercaseFirstLetter(t('pages.signup.register'))}
            background={'white'}
            color={'#28337D'}
            borderRadius={'50px'}
            border={'1px solid #28337D'}
            onClickNavigationRoute='/signup'
          />
        </div>
      ) : (
        <div className='flex items-center'>
          <div className='flex gap-x-4 items-center'>
            {searchInput && handleSearch ? (
              <div className='search-input-container flex items-center cursor-pointer bg-white min-w-min'>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <Input
                  id='searchInput'
                  type='text'
                  placeholder={GlobalService.uppercaseAllFirstsLetters(t('search'))}
                  value={searchValue}
                  customClass='search-input'
                  name='searchInput'
                  onChange={e => handleSearch(e)}
                />
              </div>
            ) : null}
            {searchInput ? (
              <div
                className='bell-container flex justify-center items-center'
                onClick={() => goToCarrito()}
              >
                <FontAwesomeIcon icon={faCartShopping} onClick={() => goToCarrito()} />
                {cartiItems > 0 ? (
                  <div className='notification-advice flex items-center justify-center'>
                    <p>{cartiItems}</p>
                  </div>
                ) : null}
              </div>
            ) : null}

            {isUserLogged && viewportWidth >= 769 ? (
              <NotificationsButton
                numberNotifications={totalNotifications}
                notifications={notifications}
                onClickNotificationLink={markNotificationAsSeen}
              />
            ) : null}

            <UserButton />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopMenu;
